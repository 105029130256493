// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Helpers
// import * as Yup from "yup";
import { toast } from "react-toastify";

// Utils
import { getOnlyFullDate } from "../../../utils/date-functions";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { fileUpload } from "../../../services/api/apiHelpers";

// Redux actions
import { getAllLanguages } from "../../../redux/adminSlice/languageSlice";
import {
  getAllMerchants,
  getAllDraftMerchants,
} from "../../../redux/adminSlice/merchantActionsSlice";
import { newMerchantActivity } from "../../../redux/adminSlice/activitySlice";

// Components
// import { Formik } from "formik";
import { CountryDropdown } from "react-country-region-selector";
import DatePicker from "react-date-picker";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./styles.css";

let initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  langId: "",
  mobile: "",
  city: "",
  country: "",
  zip: "",
  image: "",
  commisionPercentage: "",
  marBankAccNo: "",
  marBankIfscCode: "",
  marBankName: "",
  marAccHolderName: "",
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  companyMobile: "",
  directorName: "",
  individualName: "",
  companyDob: "",
  companyStreetAddress: "",
  postalCode: "",
  compCity: "",
  compState: "",
  compCountry: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  compamyRegistrationImage: "",
  saveAsDraft: false,
};

let createErrors = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  langId: "",
  mobile: "",
  city: "",
  country: "",
  zip: "",
  image: "",
  commisionPercentage: "",
  marBankAccNo: "",
  marBankIfscCode: "",
  marBankName: "",
  marAccHolderName: "",
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  companyMobile: "",
  directorName: "",
  individualName: "",
  companyDob: "",
  companyStreetAddress: "",
  postalCode: "",
  compCity: "",
  compState: "",
  compCountry: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  compamyRegistrationImage: "",
};

var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;
var emailReg = /^[a-z0-9]+@[a-z]+.[a-z]{2,3}$/;

export default function ManageMerchantAddDrafts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [searchText, setsearchText] = useState("");

  // const [checkedAccess, setcheckedAccess] = useState(accessPrivileges);
  // const [allAccessChecked, setallAccessChecked] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [editObject, seteditObject] = useState(initialValues);
  const [deleteId, setdeleteId] = useState("");
  const [country, setcountry] = useState("");
  const [compCountry, setcompCountry] = useState("");
  const imgInputRef = useRef(null);
  const [errors, seterrors] = useState(createErrors);
  // const [checkedAccess, setcheckedAccess] = useState(accessPrivileges);
  // const [allAccessChecked, setallAccessChecked] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [uploadedImg, setuploadedImg] = useState("");
  const [uploadedLogo, setuploadedLogo] = useState("");
  const [uploadedcompRegCert, setuploadedcompRegCert] = useState("");

  // Redux stores
  const { languages } = useSelector((state) => state.languageSlice);
  const { merchants, draft_merchants } = useSelector(
    (state) => state.adminMerchantActionSlice
  );

  let userdata = localStorage.getItem('userdata');
  userdata = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType = userdata?.userType;




  // Component did mount
  useEffect(() => {
    if (localStorage.getItem("Authorization") &&
      localStorage.getItem("userType")) {
      dispatch(getAllLanguages());
      dispatch(getAllMerchants());
      dispatch(getAllDraftMerchants());
      dispatch(newMerchantActivity());
    }
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const handleImage = async (event, errors) => {
    console.log(event.target.name);
    if (imageReg.test(event.target.files[0].type)) {
      if (event.target.files[0].size <= 2560000) {
        errors[event.target.name] = "";

        if (event.target.name === "image") {
          setuploadedImg(URL.createObjectURL(event.target.files[0]));
        } else if (event.target.name === "logo") {
          setuploadedLogo(URL.createObjectURL(event.target.files[0]));
        } else if (event.target.name === "compamyRegistrationImage") {
          setuploadedcompRegCert(URL.createObjectURL(event.target.files[0]));
        }

        const DATA = new FormData();
        DATA.append("image", event.target.files[0]);
        let imgUpRes = await fileUpload("/admin/uploadMerimage", "POST", DATA);
        console.log("res", imgUpRes);
        if (imgUpRes && imgUpRes.status) {
          seteditObject((prev) => ({
            ...prev,
            [event.target.name]: imgUpRes.url,
          }));
        } else {
          toast.error(imgUpRes.message);
        }
      } else {
        errors[event.target.name] = "Image should not exceed more the 2.5MB";
        if (event.target.name === "image") {
          setuploadedImg("");
        } else if (event.target.name === "logo") {
          setuploadedLogo("");
        } else {
          setuploadedcompRegCert("");
        }
      }
    } else {
      errors[event.target.name] = "File is not an image";
      if (event.target.name === "image") {
        setuploadedImg("");
      } else if (event.target.name === "logo") {
        setuploadedLogo("");
      } else {
        setuploadedcompRegCert("");
      }
    }
  };

  const handleDateChange = (date) => {
    // console.log("date", getOnlyFullDate(date));
    seteditObject({
      ...editObject,
      companyDob: getOnlyFullDate(date),
    });
  };

  const handleChange = (event) => {
    // console.log(event?.target?.name, event?.target?.value);
    if (
      event?.target?.name === "mobile" ||
      event?.target?.name === "zip" ||
      event?.target?.name === "commisionPercentage" ||
      event?.target?.name === "companyMobile" ||
      event?.target?.name === "postalCode"
    ) {
      if (event.target.value < 0) {
        event.target.value = "";
      }
      let inputValue = event.target.value.replace(/\D+/g, "");
      seteditObject({ ...editObject, [event.target.name]: inputValue });
    }
    seteditObject({
      ...editObject,
      [event?.target?.name]: event?.target?.value,
    });
  };

  // const merchantEditValidationSchema = Yup.object().shape({
  //   firstName: Yup.string().required("First name is required."),
  //   lastName: Yup.string().required("Last name is required."),
  //   email: Yup.string()
  //     .required("Email is required.")
  //     .matches(
  //       /^[a-z0-9]+@[a-z]+.[a-z]{2,3}$/,
  //       "Please enter correct email format."
  //     ),
  //   password: Yup.string().required("Password is required."),
  //   // langId: Yup.string().test("len", "Please select a language", (val) => val?.length > 0),
  //   mobile: Yup.string().test(
  //     "len",
  //     "Please enter a 10 digit mobile number",
  //     (val) => val?.length === 10
  //   ),
  //   city: Yup.string().required("City is required."),
  //   country: Yup.string().required("Country is required."),
  //   zip: Yup.string().required("Zip is required."),
  //   commisionPercentage: Yup.string()
  //     .matches(/^\d+$/, "Only digits are accepted")
  //     .required("Commission percentage is required in numbers."),
  //   // profileImg: Yup.mixed()
  //   //   .test("fileType", "File should be a .png or .jpg", (value) => {
  //   //     // console.log("Yup file value", value);
  //   //     if (!value) return true; // attachment is optional
  //   //     return value?.type === "image/jpeg" || value?.type === "image/png";
  //   //   })
  //   //   .test("fileSize", "Image should not exceed more the 2.5MB", (value) => {
  //   //     if (!value) return true; // attachment is optional
  //   //     return value?.size <= 2560000;
  //   //   }),
  //   marBankAccNo: Yup.string().required("Bank account number is required."),
  //   marBankIfscCode: Yup.string().required("Bank IFS code is required."),
  //   marBankName: Yup.string().required("Bank name is required."),
  //   marAccHolderName: Yup.string().required("Account holder name is required."),
  // });

  const handleValidation = () => {
    const {
      firstName,
      lastName,
      email,
      password,
      langId,
      mobile,
      commisionPercentage,
      city,
      zip,
      marBankAccNo,
      marBankIfscCode,
      marBankName,
      marAccHolderName,
      legalStatus,
      companyName,
      legalCompanyName,
      companyMobile,
      directorName,
      individualName,
      companyDob,
      companyStreetAddress,
      postalCode,
      compCity,
      compState,
      licenseNumber,
      registrationNumber,
      description,
      socialLink,
      insurancePolicy,
    } = editObject;

    if (firstName === "") {
      seterrors({
        ...createErrors,
        firstName: "First name is required.",
      });

      return false;
    }

    if (lastName === "") {
      seterrors({
        ...createErrors,
        lastName: "Last name is required.",
      });

      return false;
    }
    if (email === "") {
      seterrors({ ...createErrors, email: "Email is required." });

      return false;
    }
    if (!emailReg.test(email)) {
      seterrors({
        ...createErrors,
        email: "Please enter correct email format.",
      });

      return false;
    }
    if (password === "") {
      seterrors({
        ...createErrors,
        password: "Password is required.",
      });

      return false;
    }
    if (password.length < 8) {
      seterrors({
        ...createErrors,
        password: "Please enter minimum 8 characters.",
      });

      return false;
    }
    if (langId === "") {
      seterrors({
        ...createErrors,
        langId: "Language is required.",
      });

      return false;
    }
    if (mobile === "") {
      seterrors({
        ...createErrors,
        mobile: "Merchant mobile number is required.",
      });

      return false;
    }
    if (mobile.length < 10) {
      seterrors({
        ...createErrors,
        mobile: "Please enter atleast 10 digit mobile number.",
      });

      return false;
    }
    if (mobile.length > 11) {
      seterrors({
        ...createErrors,
        mobile: "Please enter atmost 11 digit mobile number.",
      });

      return false;
    }
    if (isNaN(mobile)) {
      seterrors({
        ...createErrors,
        mobile: "Please enter only numerics",
      });

      return false;
    }
    // if (
    //   mobile.charAt(0) !== 9 ||
    //   mobile.charAt(0) !== 8 ||
    //   mobile.charAt(0) !== 7 ||
    //   mobile.charAt(0) !== 6
    // ) {
    //   seterrors({
    //     ...createErrors,
    //     mobile: "Please enter a valid mobile number",
    //   });

    //   return false;
    // }
    if (commisionPercentage === "") {
      seterrors({
        ...createErrors,
        commisionPercentage: "Commission percentage is required.",
      });

      return false;
    }
    if (isNaN(commisionPercentage)) {
      seterrors({
        ...createErrors,
        commisionPercentage: "Please enter only numerics",
      });

      return false;
    }
    if (city === "") {
      seterrors({
        ...createErrors,
        city: "Merchant city name is required.",
      });

      return false;
    }
    // if (country === "") {
    //   seterrors({
    //     ...createErrors,
    //     country: "Merchant country name is required.",
    //   });

    //   return false;
    // }
    if (zip === "") {
      seterrors({
        ...createErrors,
        zip: "Merchant address zip is required.",
      });

      return false;
    }
    if (country === 'India' && isNaN(zip)) {
      seterrors({
        ...createErrors,
        zip: "Please enter only numerics",
      });

      return false;
    }
    if (marBankAccNo === "") {
      seterrors({
        ...createErrors,
        marBankAccNo: "Merchant bank account number is required.",
      });

      return false;
    }
    if (marBankIfscCode === "") {
      seterrors({
        ...createErrors,
        marBankIfscCode: "Merchant bank IFSC is required.",
      });

      return false;
    }
    if (marBankName === "") {
      seterrors({
        ...createErrors,
        marBankName: "Merchant bank name is required.",
      });

      return false;
    }
    if (marAccHolderName === "") {
      seterrors({
        ...createErrors,
        marAccHolderName: "Merchant bank account holder name is required.",
      });

      return false;
    }
    if (legalStatus === "") {
      seterrors({
        ...createErrors,
        legalStatus: "Company legal status is required.",
      });

      return false;
    }
    if (companyName === "") {
      seterrors({
        ...createErrors,
        companyName: "Company name is required.",
      });

      return false;
    }
    if (legalCompanyName === "") {
      seterrors({
        ...createErrors,
        legalCompanyName: "Company legal name is required.",
      });

      return false;
    }
    if (companyDob === "") {
      seterrors({
        ...createErrors,
        companyDob: "Company formation date is required.",
      });

      return false;
    }
    if (directorName === "") {
      seterrors({
        ...createErrors,
        directorName: "Company director name is required.",
      });

      return false;
    }
    if (companyStreetAddress === "") {
      seterrors({
        ...createErrors,
        companyStreetAddress: "Company address street is required.",
      });

      return false;
    }
    if (compCity === "") {
      seterrors({
        ...createErrors,
        compCity: "Company city name is required.",
      });

      return false;
    }
    if (compState === "") {
      seterrors({
        ...createErrors,
        compState: "Company state name is required.",
      });

      return false;
    }
    // if (compCountry === "") {
    //   seterrors({
    //     ...createErrors,
    //     compCountry: "Company country name is required.",
    //   });

    //   return false;
    // }
    if (postalCode === "") {
      seterrors({
        ...createErrors,
        postalCode: "Company address zip is required.",
      });

      return false;
    }
    if (isNaN(postalCode)) {
      seterrors({
        ...createErrors,
        postalCode: "Please enter only numerics",
      });

      return false;
    }
    if (licenseNumber === "") {
      seterrors({
        ...createErrors,
        licenseNumber: "Company license is required.",
      });

      return false;
    }
    if (registrationNumber === "") {
      seterrors({
        ...createErrors,
        registrationNumber: "Company registration is required.",
      });

      return false;
    }
    if (description === "") {
      seterrors({
        ...createErrors,
        description: "Company business description is required.",
      });

      return false;
    }
    if (socialLink === "") {
      seterrors({
        ...createErrors,
        socialLink: "Company's one social media link is required.",
      });

      return false;
    }
    if (insurancePolicy === "") {
      seterrors({
        ...createErrors,
        insurancePolicy: "Company insurance is required.",
      });

      return false;
    }
    if (individualName === "") {
      seterrors({
        ...createErrors,
        individualName: "Company contact person name is required.",
      });

      return false;
    }
    if (companyMobile === "") {
      seterrors({
        ...createErrors,
        companyMobile: "Contact person mobile number is required.",
      });

      return false;
    }
    // if (companyMobile.length < 10) {
    //   seterrors({
    //     ...createErrors,
    //     companyMobile: "Please enter atleast 10 digit mobile number.",
    //   });

    //   return false;
    // }
    // if (companyMobile.length > 11) {
    //   seterrors({
    //     ...createErrors,
    //     companyMobile: "Please enter atmost 11 digit mobile number.",
    //   });

    //   return false;
    // }
    if (isNaN(companyMobile)) {
      seterrors({
        ...createErrors,
        companyMobile: "Please enter only numerics",
      });

      return false;
    }
    // if (
    //   companyMobile.charAt(0) !== 9 ||
    //   companyMobile.charAt(0) !== 8 ||
    //   companyMobile.charAt(0) !== 7 ||
    //   companyMobile.charAt(0) !== 6
    // ) {
    //   seterrors({
    //     ...createErrors,
    //     companyMobile: "Please enter a valid mobile number",
    //   });

    //   return false;
    // }

    return true;
  };

  // const editMerchantInfo = async (values, resetForm) => {
  //   let bodyData = { id: `${merchants[itemIndex]._id}`, ...values };
  //   // console.log(bodyData);

  //   try {
  //     setisLoading(true);
  //     let res = await JSON_API["adminEditMerchant"](bodyData);

  //     if (res.isSuccess) {
  //       dispatch(getAllMerchants());
  //       setModal(false);
  //       seteditObject(null);
  //       resetForm(editObject);
  //       setisLoading(false);
  //       MerchantApiCallSuccess(res.data);
  //     } else {
  //       setisLoading(false);
  //       MerchantApiCallError(res?.error?.message);
  //     }
  //   } catch (error) {
  //     setisLoading(false);
  //     MerchantApiCallError(error.message);
  //   }
  // };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    if (handleValidation()) {
      let bodyData = {
        ...editObject,
        id: `${draft_merchants[itemIndex]._id}`,
        country: country,
        compCountry: compCountry,
      };
      // console.log(bodyData);
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditMerchant"](bodyData);
        // console.log(res);

        if (res.isSuccess) {
          dispatch(getAllMerchants());
          if (imgInputRef.current) {
            imgInputRef.current.value = "";
          }
          setuploadedImg("");
          setuploadedLogo("");
          setuploadedcompRegCert("");
          seteditObject(initialValues);
          seterrors(createErrors);
          setModal(false);
          setisLoading(false);
          MerchantApiCallSuccess(res.data);
        } else {
          setisLoading(false);
          MerchantApiCallError(res?.error?.message);
        }
      } catch (error) {
        setisLoading(false);
        MerchantApiCallError(error.message);
      }
    }
  };

  const deleteMerchant = async (id) => {
    // console.log("here", id);
    try {
      setisLoading(true);
      let res = await JSON_API["adminDeleteMerchant"]({ id: id });
      console.log("Res", res);

      if (res.isSuccess) {
        dispatch(getAllMerchants());
        setModal(false);
        setisLoading(false);
        MerchantApiCallSuccess(res.data);
        dispatch(getAllDraftMerchants());
      } else {
        setisLoading(false);
        MerchantApiCallError(res?.response?.data?.error?.msg);
      }
    } catch (error) {
      setisLoading(false);
      MerchantApiCallError(error.message);
    }
  };

  const MerchantApiCallSuccess = (data) => {
    // setkey(Math.random());
    toast.success("Action successful!");
  };

  const MerchantApiCallError = (errorMsg) => {
    toast.error(errorMsg);
    setError(errorMsg);
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 300,
      },
      {
        label: "Contact No.",
        field: "ContactNo",
        sort: "asc",
        width: 200,
      },
      {
        label: "Company Contact No.",
        field: "CompanyContactNo",
        sort: "asc",
        width: 200,
      },
      //   {
      //     label: "Email",
      //     field: "email",
      //     sort: "asc",
      //     width: 200,
      //   },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows:
      draft_merchants?.length > 0
        ? draft_merchants.map((item, idx) => {
          return {
            sl: idx + 1,
            ContactNo: (
              <div>
                {item.phonecode}-{item.mobile},
              </div>
            ),
            CompanyContactNo: (
              <div>
                {item.phonecodecompany}-{item.companyMobile}
              </div>
            ),
            name: (
              <div className="widget-content p-0">
                <div
                  className="widget-content-wrapper"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                  }}
                >
                  <div className="widget-content-left me-3">
                    <div className="widget-content-left">
                      <img
                        style={{
                          borderRadius: "3px",
                          height: "80px",
                          width: "80px",
                        }}
                        src={
                          item?.image
                            ? item?.image
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                        }
                        alt="Avatar"
                      />
                    </div>
                  </div>
                  <div className="widget-content-left flex2">
                    <div
                      className="widget-heading"
                      style={{ opacity: 1, textAlign: "center" }}
                    >
                      {item?.firstName && item?.lastName
                        ? item?.firstName + " " + item?.lastName
                        : "no_name"}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),

            // email: item.email,
            action: (
              <div>
                <button
                  title="Delete Merchant"
                  className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                  onClick={() => {
                    //openModal();
                    //setmodalToggleView(1);
                    // console.log("Delete id", allMerchantData[idx]?._id);
                    deleteMerchant(item?._id);
                    //setdeleteId(item?._id);
                  }}
                >
                  <i className="fa-solid fa-trash-can" />
                </button>

                {/* <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(1);
                      // console.log("Delete id", draft_merchants[idx]._id);
                      setdeleteId(draft_merchants[idx]._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button> */}

                {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('edit'))) && (
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/edit-merchant-drafts/${item?._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                )}
              </div>
            ),
          };
        })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Merchant Info</h5>
            </div>
            {/* <Formik
              key={key}
              initialValues={editObject}
              validationSchema={merchantEditValidationSchema}
              onSubmit={(values, { resetForm }) => {
                editMerchantInfo(values, resetForm);
              }}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                errors,
              }) => (
              )}
            </Formik> */}
            <form className="">
              <div className="modal-body">
                <div className="row" style={{ padding: "1rem" }}>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        name="firstName"
                        id="firstName"
                        placeholder="Your first name, e.g. Walter"
                        type="text"
                        className="form-control"
                        value={editObject.firstName}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </div>
                    {errors.firstName && (
                      <span className="errorMessageText text-danger">
                        {errors.firstName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        name="lastName"
                        id="lastName"
                        placeholder="Your first name, e.g. Smith"
                        type="text"
                        className="form-control"
                        value={editObject.lastName}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </div>
                    {errors.lastName && (
                      <span className="errorMessageText text-danger">
                        {errors.lastName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        name="email"
                        id="email"
                        placeholder="E.g.,waltersmith@ymail.com"
                        type="email"
                        className="form-control"
                        value={editObject.email}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </div>
                    {errors.email && (
                      <span className="errorMessageText text-danger">
                        {errors.email}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        name="password"
                        id="password"
                        type="password"
                        className="form-control"
                        value={editObject.password}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </div>
                    {errors.password && (
                      <span className="errorMessageText text-danger">
                        {errors.password}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="langId" className="form-label">
                        Language
                      </label>
                      <select
                        name="langId"
                        id="langId"
                        className="form-control form-select"
                        value={editObject.langId}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      >
                        <option value="" key={0}>
                          Select
                        </option>
                        {languages.map((item, idx) => {
                          return (
                            <>
                              <option key={idx + 1} value={`${item?._id}`}>
                                {item?.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    {errors.langId && (
                      <span className="errorMessageText text-danger">
                        {errors.langId}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="mobile" className="form-label">
                        Mobile
                      </label>
                      <input
                        name="mobile"
                        id="mobile"
                        placeholder="E.g. 9477280560"
                        type="text"
                        className="form-control"
                        value={editObject.mobile}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </div>
                    {errors.mobile && (
                      <span className="errorMessageText text-danger">
                        {errors.mobile}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row" style={{ padding: "1rem" }}>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="commisionPercentage"
                        className="form-label"
                      >
                        Commission Percentage
                      </label>
                      <input
                        name="commisionPercentage"
                        id="commisionPercentage"
                        type="text"
                        value={editObject.commisionPercentage}
                        // ref={imgInputRef}
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                      {errors.commisionPercentage && (
                        <span className="errorMessageText text-danger">
                          {errors.commisionPercentage}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="image" className="form-label">
                        Image
                      </label>
                      <input
                        name="image"
                        id="image"
                        type="file"
                        ref={imgInputRef}
                        className="form-control"
                        onChange={(e) => {
                          handleImage(e, errors);
                        }}
                        required={true}
                        accept="image/*"
                      />
                      {uploadedImg.length > 0 ? (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={uploadedImg}
                          alt="merchantImg"
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={editObject.image}
                           alt="merchantImg"
                        />
                      )}
                      {errors.image && (
                        <span className="errorMessageText text-danger">
                          {errors.image}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="exampleCity" className="form-label">
                        City
                      </label>
                      <input
                        name="city"
                        id="exampleCity"
                        type="text"
                        className="form-control"
                        value={editObject.city}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </div>
                    {errors.city && (
                      <span className="errorMessageText text-danger">
                        {errors.city}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="country">
                        Country
                      </label>
                      <CountryDropdown
                        className="form-select"
                        value={country ? country : editObject?.country}
                        onChange={(val) => setcountry(val)}
                      />
                    </div>
                    {errors.country && (
                      <span className="errorMessageText text-danger">
                        {errors.country}
                      </span>
                    )}
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="exampleZip" className="form-label">
                        Zip
                      </label>
                      <input
                        name="zip"
                        id="exampleZip"
                        type="text"
                        className="form-control"
                        value={editObject.zip}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </div>
                    {errors.zip && (
                      <span className="errorMessageText text-danger">
                        {errors.zip}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row" style={{ padding: "1rem" }}>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="marBankAccNo" className="form-label">
                        Bank Account Number
                      </label>
                      <input
                        name="marBankAccNo"
                        id="marBankAccNo"
                        type="text"
                        value={editObject.marBankAccNo}
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                      {errors.marBankAccNo && (
                        <span className="errorMessageText text-danger">
                          {errors.marBankAccNo}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="marBankIfscCode" className="form-label">
                        Bank IFS Code
                      </label>
                      <input
                        name="marBankIfscCode"
                        id="marBankIfscCode"
                        type="text"
                        value={editObject.marBankIfscCode}
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                      {errors.marBankIfscCode && (
                        <span className="errorMessageText text-danger">
                          {errors.marBankIfscCode}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="marBankName" className="form-label">
                        Bank Name
                      </label>
                      <input
                        name="marBankName"
                        id="marBankName"
                        type="text"
                        value={editObject.marBankName}
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                      {errors.marBankName && (
                        <span className="errorMessageText text-danger">
                          {errors.marBankName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="marAccHolderName" className="form-label">
                        Account Holder Name
                      </label>
                      <input
                        name="marAccHolderName"
                        id="marAccHolderName"
                        type="text"
                        value={editObject.marAccHolderName}
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                      {errors.marAccHolderName && (
                        <span className="errorMessageText text-danger">
                          {errors.marAccHolderName}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <hr />
                <div className="font-weight-bold row">Company Details</div>
                <hr />

                <div className="row" style={{ padding: "1rem" }}>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="legalStatus">
                        Legal Status
                      </label>
                      <select
                        className="form-select"
                        id="legalStatus"
                        name="legalStatus"
                        onChange={(e) => handleChange(e)}
                        value={editObject.legalStatus}
                      >
                        <option value="" disabled={true} selected={true}>
                          Select legal status
                        </option>
                        <option value="registered_company">
                          Registered Company
                        </option>
                        <option value="individual_traders">
                          Individual Trader
                        </option>
                      </select>
                    </div>
                    {errors.legalStatus && (
                      <span className="errorMessageText text-danger">
                        {errors.legalStatus}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="companyName">
                        Company Name
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        className="form-control"
                        id="companyName"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.companyName}
                        required
                      />
                    </div>
                    {errors.companyName && (
                      <span className="errorMessageText text-danger">
                        {errors.companyName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        className="font-weight-bold"
                        htmlFor="legalCompanyName"
                      >
                        Legal Company Name
                      </label>
                      <input
                        type="text"
                        name="legalCompanyName"
                        className="form-control"
                        id="legalCompanyName"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.legalCompanyName}
                        required
                      />
                    </div>
                    {errors.legalCompanyName && (
                      <span className="errorMessageText text-danger">
                        {errors.legalCompanyName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="companyDob">
                        Company Formation Date
                      </label>
                      <DatePicker
                        calendarIcon={null}
                        className="form-control"
                        clearIcon={null}
                        value={editObject.companyDob}
                        format="dd/MM/y"
                        // closeCalendar  // on 'select'
                        shouldCloseCalendar={({ reason }) =>
                          reason === "select" || reason === "outsideAction"
                        }
                        onChange={handleDateChange}
                      />
                    </div>
                    {errors.companyDob && (
                      <span className="errorMessageText text-danger">
                        {errors.companyDob}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        className="font-weight-bold"
                        htmlFor="directorName"
                      >
                        Director Name
                      </label>
                      <input
                        type="text"
                        name="directorName"
                        className="form-control"
                        id="directorName"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.directorName}
                        required
                      />
                    </div>
                    {errors.directorName && (
                      <span className="errorMessageText text-danger">
                        {errors.directorName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        className="font-weight-bold"
                        htmlFor="companyStreetAddress"
                      >
                        Street Address
                      </label>
                      <input
                        type="text"
                        name="companyStreetAddress"
                        className="form-control"
                        id="companyStreetAddress"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.companyStreetAddress}
                        required
                      />
                    </div>
                    {errors.companyStreetAddress && (
                      <span className="errorMessageText text-danger">
                        {errors.companyStreetAddress}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="compCity">
                        Company City
                      </label>
                      <input
                        type="text"
                        name="compCity"
                        className="form-control"
                        id="compCity"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.compCity}
                        required
                      />
                    </div>
                    {errors.compCity && (
                      <span className="errorMessageText text-danger">
                        {errors.compCity}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="compState">
                        Company State
                      </label>
                      <input
                        type="text"
                        name="compState"
                        className="form-control"
                        id="compState"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.compState}
                        required
                      />
                    </div>
                    {errors.compState && (
                      <span className="errorMessageText text-danger">
                        {errors.compState}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="compCountry">
                        Company Country
                      </label>
                      <CountryDropdown
                        className="form-select"
                        value={
                          compCountry ? compCountry : editObject?.compCountry
                        }
                        onChange={(val) => setcompCountry(val)}
                      />
                    </div>
                    {errors.compCountry && (
                      <span className="errorMessageText text-danger">
                        {errors.compCountry}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="postalCode">
                        Company Zip
                      </label>
                      <input
                        type="number"
                        name="postalCode"
                        className="form-control"
                        id="postalCode"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        min={0}
                        onChange={(e) => handleChange(e)}
                        value={editObject.postalCode}
                        required
                      />
                    </div>
                    {errors.postalCode && (
                      <span className="errorMessageText text-danger">
                        {errors.postalCode}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        className="font-weight-bold"
                        htmlFor="licenseNumber"
                      >
                        License Number
                      </label>
                      <input
                        type="text"
                        name="licenseNumber"
                        className="form-control"
                        id="licenseNumber"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.licenseNumber}
                        required
                      />
                    </div>
                    {errors.licenseNumber && (
                      <span className="errorMessageText text-danger">
                        {errors.licenseNumber}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        className="font-weight-bold"
                        htmlFor="registrationNumber"
                      >
                        Registration Number
                      </label>
                      <input
                        type="text"
                        name="registrationNumber"
                        className="form-control"
                        id="registrationNumber"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.registrationNumber}
                        required
                      />
                    </div>
                    {errors.registrationNumber && (
                      <span className="errorMessageText text-danger">
                        {errors.registrationNumber}
                      </span>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="description">
                        Description
                      </label>
                      <textarea
                        name="description"
                        className="form-control"
                        id="description"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.description}
                        required
                      />
                    </div>
                    {errors.description && (
                      <span className="errorMessageText text-danger">
                        {errors.description}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="font-weight-bold" htmlFor="socialLink">
                        Social Link
                      </label>
                      <input
                        type="text"
                        name="socialLink"
                        className="form-control"
                        id="socialLink"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.socialLink}
                        required
                      />
                    </div>
                    {errors.socialLink && (
                      <span className="errorMessageText text-danger">
                        {errors.socialLink}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="font-weight-bold" htmlFor="logo">
                      Company Logo
                    </label>
                    <input
                      name="logo"
                      id="logo"
                      type="file"
                      ref={imgInputRef}
                      className="form-control"
                      onChange={(e) => {
                        handleImage(e, errors);
                      }}
                      required
                      accept="image/*"
                    />
                    {uploadedLogo.length > 0 ? (
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={uploadedLogo}
                         alt="merchantImg"
                      />
                    ) : (
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={editObject.logo}
                         alt="merchantImg"
                      />
                    )}
                    {errors.logo && (
                      <span className="errorMessageText text-danger">
                        {errors.logo}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        className="font-weight-bold"
                        htmlFor="insurancePolicy"
                      >
                        Insurance Policy
                      </label>
                      <input
                        type="text"
                        name="insurancePolicy"
                        className="form-control"
                        id="insurancePolicy"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.insurancePolicy}
                        required
                      />
                    </div>
                    {errors.insurancePolicy && (
                      <span className="errorMessageText text-danger">
                        {errors.insurancePolicy}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label
                      className="font-weight-bold"
                      htmlFor="compamyRegistrationImage"
                    >
                      Company Registration Certificate
                    </label>
                    <input
                      name="compamyRegistrationImage"
                      id="compamyRegistrationImage"
                      type="file"
                      ref={imgInputRef}
                      className="form-control"
                      onChange={(e) => {
                        handleImage(e, errors);
                      }}
                      required
                      accept="image/*"
                    />
                    {uploadedcompRegCert.length > 0 ? (
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={uploadedcompRegCert}
                         alt="merchantImg"
                      />
                    ) : (
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={editObject.compamyRegistrationImage}
                         alt="merchantImg"
                      />
                    )}
                    {errors.compamyRegistrationImage && (
                      <span className="errorMessageText text-danger">
                        {errors.compamyRegistrationImage}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        className="font-weight-bold"
                        htmlFor="individualName"
                      >
                        Contact Person Name
                      </label>
                      <input
                        type="text"
                        name="individualName"
                        className="form-control"
                        id="individualName"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.individualName}
                        required
                      />
                    </div>
                    {errors.individualName && (
                      <span className="errorMessageText text-danger">
                        {errors.individualName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        className="font-weight-bold"
                        htmlFor="companyMobile"
                      >
                        Contact Person Mobile Number
                      </label>
                      <input
                        type="text"
                        name="companyMobile"
                        className="form-control"
                        id="companyMobile"
                        aria-describedby="emailHelp"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={editObject.companyMobile}
                        required
                      />
                    </div>
                    {errors.companyMobile && (
                      <span className="errorMessageText text-danger">
                        {errors.companyMobile}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                  setuploadedImg("");
                  setuploadedLogo("");
                  setuploadedcompRegCert("");
                  seteditObject(initialValues);
                  seterrors(createErrors);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => handleEditSubmit(e)}
              >
                Edit
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete merchant</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => deleteMerchant(deleteId)}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle="All Merchants"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
  // }
}
