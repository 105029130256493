// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utilities
import { apiCallSuccess, apiCallError } from "../../../utils";

// API functions
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSitemaps } from "../../../redux/adminSlice/sitemapSlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import HomeService from "../../../services/api/HomeService";

const initialValues = {
  continent: "",
  countryId: "",
};

const createErrors = {
  continent: "",
  countryId: "",
  //   activityTypeId: "",
};

function ManageFaqType() {
  const dispatch = useDispatch();
  const [category, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [categoryEdit, setCategoryEdit] = useState(initialValues);
  const [deleteId, setdeleteId] = useState("");
  const [allFaq, setAllFaq] = useState([]);

  // Component did mount
  useEffect(() => {
    // dispatch(getAllSitemaps());
    // dispatch(getAllcountres());
    getAllfaqType();
  }, []);

  // Redux stores
  const { countres } = useSelector((state) => state.countrySlice);
  const { sitemaps } = useSelector((state) => state.sitemapSlice);
  console.log("sitemaps", sitemaps);

  const openModal = () => {
    setModal(true);
  };

  const getAllfaqType = () => {
    HomeService.getAllFaqType()
      .then((result) => {
        // console.log("result", result);
        if (result && result.status) {
          setAllFaq(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleEditChange = (event) => {
    event.preventDefault();
    console.log(event.target.name, event.target.value);
    setCategoryEdit({
      ...categoryEdit,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditValidation = () => {
    const { continent } = categoryEdit;

    if (continent === "") {
      seterrors({ ...createErrors, continent: "Continent is required" });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    console.log("Edit submit", categoryEdit);

    if (handleEditValidation()) {
      try {
        let res = await JSON_API["adminEditSitemap"]({
          id: sitemaps[itemIndex]._id,
          ...categoryEdit,
        });

        if (res.isSuccess) {
          dispatch(getAllSitemaps());
          setModal(false);
          seterrors(createErrors);
          setCategoryEdit(initialValues);
          apiCallSuccess(res.data);
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
    }
  };

  const deleteCategory = async (id) => {
    try {
      let res = await JSON_API["adminDeleteSitemap"]({ id: id });
      // console.log("Res", res);

      if (res.isSuccess) {
        dispatch(getAllSitemaps());
        setModal(false);
        apiCallSuccess(res.data);
      } else {
        // ActivityApiCallError(res?.error?.message);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Faq Type",
        field: "Faqtype",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "asc",
      //   width: 150,
      // },
    ],
    rows:
      allFaq?.length > 0
        ? allFaq.map((item, idx) => {
            console.log("bal item", item);
            return {
              sl: idx + 1,
              Faqtype: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item.name}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              // action: (
              //   <div>
              //     <button
              //       className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
              //       onClick={() => {
              //         openModal();
              //         setmodalToggleView(1);
              //         // console.log("Delete id", activity_types[idx]._id);
              //         setdeleteId(sitemaps[idx]._id);
              //       }}
              //     >
              //       <i className="fa-solid fa-trash-can" />
              //     </button>
              //     <button
              //       className="me-2 btn-icon btn-icon-only btn btn-outline-success"
              //       onClick={() => {
              //         openModal();
              //         setmodalToggleView(0);
              //         setitemIndex(idx);
              //         // console.log("edit", activity_types[idx]?.name);
              //         setCategoryEdit({
              //           ...categoryEdit,
              //           continent: sitemaps[idx]?.continent,
              //           countryId: sitemaps[idx]?.countryId,
              //         });
              //       }}
              //     >
              //       <i class="fa-regular fa-pen-to-square"></i>
              //     </button>
              //   </div>
              // ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Info</h5>
            </div>
            <form
              className=""
              onSubmit={(e) => handleEditSubmit(e)}
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="continent" className="form-label">
                      Continent
                    </label>
                    <input
                      name="continent"
                      id="continent"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={categoryEdit.continent}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.continent && (
                    <span className="errorMessageText text-danger">
                      {errors.continent}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="countryId" className="form-label">
                      Country
                    </label>
                    <select
                      name="countryId"
                      id="countryId"
                      className="form-control form-select"
                      onChange={handleEditChange}
                      value={categoryEdit.countryId}
                    >
                      <option value="" key={0}>
                        Select
                      </option>
                      {countres.map((item, idx) => {
                        return (
                          <>
                            <option key={idx + 1} value={`${item?._id}`}>
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  {errors.desc && (
                    <span className="errorMessageText text-danger">
                      {errors.desc}
                    </span>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setModal(false);
                    setCategoryEdit(initialValues);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Edit
                </button>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Category</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCategory(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Table
      striped
      tableTitle="All Faq Type"
      data={tableData}
      modal={modal}
      setModal={setModal}
      modalToggleView={modalToggleView}
      renderModalView={renderModalView}
    />
  );
}

export default ManageFaqType;
