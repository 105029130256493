import React from "react";

export default function Modal({ modalToggleView, renderModalView, closeModal }) {
  return (
    <>
      <div className="modal fade show" role="dialog" tabIndex={-1}>
        <div className="modal-dialog" role="document">
          <button
            className="btn-close"
            style={{
              position: "absolute",
              top: 15,
              right: 15,
              zIndex: "1",
              cursor: "pointer",
            }}
            onClick={() => {
              closeModal(false);
            }}
          ></button>
          {renderModalView(modalToggleView)}
        </div>
      </div>
    </>
  );
}
