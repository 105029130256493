import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  capitalizeFirstLetter,
  allowOnlyPositiveInteger,
  getOnlyFullDate,
  checkFileDimension,
  scrollToErrorElement,
} from "../../../utils";

import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { fileUpload } from "../../../services/api/apiHelpers";
import { requestData } from "../../../services/api/apiHelpers";

import { getAllActivityParticipants } from "../../../redux/adminSlice/participantsSlice";
import {
  getAllActivityTypes,
  getAllActivitySites,
  getAllDraftActivities,
  getAllSitesForACity,
} from "../../../redux/adminSlice/activitySlice";
import { getAllSections } from "../../../redux/adminSlice/sectionSlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";
import { getAllLanguages } from "../../../redux/adminSlice/languageSlice";
import { getAllCategories } from "../../../redux/adminSlice/categorySlice";
import { getAllSpecialOffers } from "../../../redux/adminSlice/specialOfferSlice";
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Components
import Loader from "../../../Component/Loader";
import DatePicker from "react-date-picker";
import Select from "react-select";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./add_edit_activity_styles.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TextInput from "../../../Component/TextInput";
import FileSelect from "../../../Component/FileSelect";
import { fetchParticipantTypePrice, getCurrency, getparticipentType, getVideoDuration } from "../../../utils/common-utils";
import useActivityMethods from "../../../Hooks/useActivityMethods";
import SelectInput from "../../../Component/SelectInput";
import { convertDurationTime } from "../../../utils/time-functions";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;

let initialValues = {
  activityTitle: "",
  productCode: "",
  description: "",
  categoryId: "",
  activityTypesId: "",
  countryId: "",
  countryName: "",
  cityId: "",
  cityName: "",
  activitySiteId: "",
  activitySiteName: "",
  destinationData: "",
  destination: "",
  destinationName: "",
  languageId: "",
  tourModuleId: "",
  tourModuleName: "",
  tourPerson: "",
  slug: "",
  image: [],
  startDate: "",
  endDate: "",
  tourDuration: { value: 0, unit: "" },
  duration_value: "",
  duration_unit: "",
  activityActualPrice: "",
  currency: "",
  specialOfferId: "",
  specialOfferName: "",
  participentType: [],
  tourActivity: [],
  information: [],
  meetingPoint: [],
  activityCoordinates: [],
  topPriority: "",
  addedByid: "",
  addedByName: "",
  saveAsDraft: true,
  videos: [],
  frontRowSitting: false,
  frontRowSeats: 0,
  travelAgencyDetails: [],
  thirdPartyId: "",
  thirdPartyName: "",
  thirdPartyProductId: "",
  thirdPertyProductLink: "",
};

let createErrors = {
  activityTitle: "",
  productCode: "",
  categoryId: "",
  activityTypesId: "",
  activitySiteId: "",
  cityId: "",
  countryId: "",
  languageId: "",
  tourModuleId: "",
  tourPerson: "",
  slug: "",
  description: "",
  image: "",
  startDate: "",
  endDate: "",
  duration_value: "",
  duration_unit: "",
  activityActualPrice: "",
  currency: "",
  startDate: "",
  endDate: "",
  participentType: "",
  tourActivity: "",
  information: "",
  importentInfo: "",
  meetingPoint: "",
  topPriority: "",
  addedByid: "",
  frontRowSeats: "",
  thirdPartyProductId: ""
};

let currencies = [];
for (const [key, value] of Object.entries(
  require("../../../constants/currencies.json")
)) {
  currencies.push(value);
}

const duration_units = ["minutes", "hours", "days"];

function EditActivity() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const [prevCountry, setprevCountry] = useState("");
  const [activity, setactivity] = useState(initialValues);

  const [errors, seterrors] = useState(createErrors);
  const [citiesForCountry, setcitiesForCountry] = useState([]);
  const [siteDestinations, setsiteDestinations] = useState([]);
  const datetimeInputRef = useRef(null);
  const [tourActivityData, settourActivityData] = useState([
    {
      term: "",
      description: "",
    },
  ]);
  const [experienceDetails, setExperienceDetails] = useState([
    {
      id: uuidv4(),
      title: "Highlights",
      desc: [""],
    },
    {
      id: uuidv4(),
      title: "Full description",
      desc: [""],
    },
    {
      id: uuidv4(),
      title: "Includes",
      desc: [""],
    },
    {
      id: uuidv4(),
      title: "Not suitable for",
      desc: [""],
    },
  ]);
  const [meetingPointData, setmeetingPointData] = useState([
    {
      desc: "",
      link: "",
    },
  ]);
  const [importentInfo, setImportentInfo] = useState([
    {
      title: "",
      description: [""],
    },
  ]);
  const [tourCoordinates, settourCoordinates] = useState([
    {
      location: "",
      lat: "",
      lng: "",
    },
  ]);
  const [participantType, setparticipantType] = useState([
    {
      pertypeId: "",
      pertype: "",
      age: "",
      price: 0,
      rateId:[],
      selectedRateId:'',
      frontRowSeatPrice: 0,
    },
  ]);
  const [participantData, setParticipantData] = useState([]);
  const [importantQuestion, setImportantQuestion] = useState([
    {
      question: "",
      answer: "",
    },
  ]);
  const [isLoading, setisLoading] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);

  const [thirdPartyReviewrating, setThirdPartyReviewRating] = useState([
    {
      travelAdvisorname: "",
      onlineTravelAdvisorId: undefined,
      GuideExperienceStar: null,
      GuideExperienceView: null,
      OrganizationStar: null,
      OrganizationView: null,
      ServiceStar: null,
      ServiceView: null,
      ValueForMoneyStar: null,
      ValueForMoneyView: null
    }
  ])
  const [travelAgenciesData, setTravelAgenciesData] = useState([])
  const [filterAgencyData, setFilterAgencyData] = useState([])

  // Redux stores
  const { participants } = useSelector(
    (state) => state.adminActivityParticipantsSlice
  );
  const { activity_types, sites_of_a_city, draft_activities } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const { categories } = useSelector((state) => state.adminCategoryActionSlice);
  const { countres } = useSelector((state) => state.countrySlice);
  const { languages } = useSelector((state) => state.languageSlice);
  const { special_offers } = useSelector((state) => state.specialOfferSlice);
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const { tourModules, fetchTourModulesAgainstMerchant, loading } =
    useActivityMethods();
  const [selectedItems, setSelectedItems] = useState([]);
  const [thirdPartyData, setThirdPartyData] = useState([])

  const filterparticipent = () => {
    const removeParticipant = participants.filter((item) => {
      return !participantType?.some((pt) => item.type === pt.pertype);
    });
    setParticipantData(removeParticipant);
  };

  const filterTravelAgency = () => {
    const removeTravelAgency = travelAgenciesData?.filter((item) => {
      return !thirdPartyReviewrating?.some((travel) => item._id === travel.onlineTravelAdvisorId);
    });
    setFilterAgencyData(removeTravelAgency);
  }

  const getAllTravelAgencies = async () => {
    try {
      setisLoading(true)
      let res = await requestData("/admin/view-online-Travel-advisor", "GET");
      if (res) {
        setTravelAgenciesData(res.data)
        setisLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setisLoading(false)
    }
  }

  const getAllThirdParty = async () => {
    try {
      setisLoading(true)
      let res = await requestData("/admin/viewThirdPerty", "GET");

      if (res) {
        res.data.push({ _id: '', name: "No thirdPerty" })
        setThirdPartyData(res.data)
        setisLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setisLoading(false)
    }
  }

  // Component did mount
  useEffect(() => {
    getAllThirdParty();
    getAllTravelAgencies();
    filterparticipent();
    dispatch(getAllActivityParticipants());
    dispatch(getAllDraftActivities());
    dispatch(getAllActivitySites());
    dispatch(getAllActivityTypes());
    dispatch(getAllSections());
    dispatch(getAllCategories());
    dispatch(getAllcountres());
    dispatch(getAllLanguages());
    dispatch(getAllSpecialOffers());
    dispatch(getAllMerchants());
    setactivity((prev) => {
      let { _id, ...rest } = draft_activities.find(
        (item) => item?._id === params.id
      );
      return { ...prev, ...rest };
    });
    setprevCountry(activity?.countryId);
    getCitiesByCountry(
      draft_activities.find((item) => item?._id === params.id)?.countryId
    );
    getSiteDestinations(
      draft_activities.find((item) => item?._id === params.id)?.activitySiteId
    );
    setparticipantType(
      draft_activities.find((item) => item?._id === params.id)?.participentType
        ?.length > 0
        ? draft_activities
          .find((item) => item?._id === params.id)
          ?.participentType?.map((ele) => {
            return {
              pertype: ele?.pertype,
              age: ele?.age,
              price: ele?.price,
              rateId:ele.rateId,
              selectedRateId:ele.selectedRateId,
              frontRowSeatPrice: ele?.frontRowSeatPrice,
              pricingCategoryId: ele.pricingCategoryId || null
            };
          })
        : (state) => {
          return state;
        }
    );
    setThirdPartyReviewRating(
      draft_activities.find((item) => item?._id === params.id)?.travelAgencyDetails?.length > 0
        ? draft_activities
          .find((item) => item?._id === params.id)
          ?.travelAgencyDetails?.map((ele) => ({
            travelAdvisorname: ele.travelAdvisorname,
            onlineTravelAdvisorId: ele?.onlineTravelAdvisorId,
            GuideExperienceStar: ele?.GuideExperienceStar,
            GuideExperienceView: ele?.GuideExperienceView,
            OrganizationStar: ele?.OrganizationStar,
            OrganizationView: ele?.OrganizationView,
            ServiceStar: ele?.ServiceStar,
            ServiceView: ele?.ServiceView,
            ValueForMoneyStar: ele?.ValueForMoneyStar,
            ValueForMoneyView: ele?.ValueForMoneyView
          }))
        : []
    );

    setExperienceDetails(
      draft_activities
        .find((item) => item?._id === params.id)
        ?.information?.map((ele) => {
          return {
            title: ele?.title,
            desc: ele?.desc?.map((it) => {
              return it;
            }),
          };
        })
    );
    setImportentInfo(
      draft_activities
        .find((item) => item?._id === params.id)
        ?.importentInfo?.map((ele) => {
          return {
            title: ele?.title,
            description: ele?.description?.map((it) => it),
          };
        })
    );
    settourCoordinates(
      draft_activities
        .find((item) => item?._id === params.id)
        ?.activityCoordinates?.map((ele) => {
          return {
            location: ele?.location,
            lat: ele?.lat,
            lng: ele?.lng,
          };
        })
    );
    settourActivityData(
      draft_activities
        .find((item) => item?._id === params.id)
        ?.tourActivity?.map((item) => {
          return {
            term: item?.term,
            description: item?.description,
          };
        })
    );
    setmeetingPointData(
      draft_activities
        .find((item) => item?._id === params.id)
        ?.meetingPoint?.map((ele) => {
          return {
            desc: ele?.desc,
            link: ele?.link,
          };
        })
    );
    let draftActivity = draft_activities.find(
      (item) => item?._id === params.id
    );
    if (
      draftActivity.importantQuestionAboutThisTour &&
      draftActivity?.importantQuestionAboutThisTour?.length > 0
    ) {
      setImportantQuestion(
        draftActivity?.importantQuestionAboutThisTour?.map((ele) => {
          return {
            question: ele?.question,
            answer: ele?.answer,
          };
        })
      );
    }
    if (draftActivity?.addedByid) getAllStuff(draftActivity?.addedByid);

    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    filterTravelAgency();
  }, [travelAgenciesData]);

  const [stuff, setstuff] = useState("");
  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem._id !== item._id)
      );
    }
  };

  useEffect(() => {
    setSelectedItems(activity.TourAdditionalStuff);
    fetchTourModulesAgainstMerchant({
      cityId: activity?.cityId,
      merchantId: activity.addedByid,
    });
  }, [activity.cityId, activity.addedByid]);

  useEffect(() => {
    setisLoading(loading);
  }, [loading]);

  useEffect(() => {
    getCitiesByCountry(prevCountry);
  }, [prevCountry]);

  const getAllStuff = async (merchantId) => {
    try {
      let res = await requestData(`/merchant/getAll-stuff`, "POST", {
        addedById: merchantId,
      });
      if (res && res?.status) {
        setstuff(res.data);
      } else toast.error(res?.error?.message);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleChange = (event) => {
    if (participantType) filterparticipent();
    let input;

    if (
      event.target.name === "productCode" ||
      event.target.name === "description" ||
      event.target.name === "destination" ||
      event.target.name === "currency" ||
      event.target.name === "duration_unit" ||
      event.target.name === "slug"
    ) {
      input = event.target.value;
    } else if (event.target.name === "topPriority") {
      input = Number(event.target.value);
    } else {
      input = capitalizeFirstLetter(event);
    }
    setactivity({
      ...activity,
      [event.target.name]: input,
    });
  };

  const getCitiesByCountry = async (countryId) => {
    if (!countryId) return;
    try {
      let res = await requestData(
        `/admin/viewCountryAgainstCity/${countryId}`,
        "GET"
      );
      if (res.status) {
        setcitiesForCountry(res?.data);
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getSiteDestinations = async (siteId) => {
    try {
      let res = await requestData(`/admin/view-destination/${siteId}`, "GET");

      if (res.status) {
        setsiteDestinations(res?.data);
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const imageHandle = async (files) => {
    // if (files.length < 5) {
    //   scrollToErrorElement("image");
    //   seterrors({ ...createErrors, image: "Atleast 5 images are required" });
    //   return
    // }
    if (files.length >= 5) {
      setisLoading(true);
    }


    let imgArr = [];

    for (const file of files) {
      if (imageReg.test(file.type)) {
        let fileObj = await checkFileDimension(file);
        if (fileObj?.height > 720 || fileObj?.width > 1280) {
          toast.error("(image size should be ( 720px * 1280px)) ")
          return;
        }


        seterrors({ ...errors, image: "" });

        let data = new FormData();
        data.append("image", file);
        let imgUpRes = await fileUpload("/admin/tourImage", "POST", data);
        if (imgUpRes && imgUpRes.status) {
          imgArr.push(imgUpRes.url);
        } else {
          toast.error(imgUpRes.message);
        }
      } else {
        toast.error("Please Upload Valid Image");
      }
    }

    setactivity((prev) => ({ ...prev, image: [...prev.image, ...imgArr] }));
    setisLoading(false);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleStartDateChange = (date) => {
    setactivity({
      ...activity,
      startDate: getOnlyFullDate(date),
    });
  };
  const handleEndDateChange = (date) => {
    setactivity({
      ...activity,
      endDate: getOnlyFullDate(date),
    });
  };

  const handleEditValidation = (draftstatus) => {
    if (draftstatus) {
      return true;
    } else {
      const {
        activityTitle,
        productCode,
        categoryId,
        activityTypesId,
        cityId,
        countryId,
        languageId,
        tourModuleId,
        tourPerson,
        slug,
        description,
        activityActualPrice,
        currency,
        startDate,
        endDate,
        duration_value,
        duration_unit,
        tourDuration,
        tourActivity,
        information,
        meetingPoint,
        activityCoordinates,
        image,
        topPriority,
      } = activity;

      if (activityTitle === "") {
        setSelectedIndex(0);
        scrollToErrorElement("activityTitle");
        seterrors({
          ...createErrors,
          activityTitle: "Activity Title is required",
        });

        return false;
      }

      // if (productCode === "") {
      //   scrollToErrorElement("productCode");
      //   seterrors({
      //     ...createErrors,
      //     productCode: "Activity Title is required",
      //   });

      //   return false;
      // }

      if (description === "") {
        setSelectedIndex(0);
        scrollToErrorElement("description");
        seterrors({
          ...createErrors,
          description: "Activity description is required",
        });

        return false;
      }
      if (categoryId === "") {
        setSelectedIndex(0);
        scrollToErrorElement("categoryId");
        seterrors({ ...createErrors, categoryId: "Category is required" });

        return false;
      }
      if (activityTypesId === "") {
        setSelectedIndex(0);
        scrollToErrorElement("activityTypesId");
        seterrors({
          ...createErrors,
          activityTypesId: "Activity Type is required",
        });

        return false;
      }

      if (countryId === "") {
        setSelectedIndex(0);
        scrollToErrorElement("countryId");
        seterrors({ ...createErrors, countryId: "Country is required" });

        return false;
      }
      if (cityId === "") {
        setSelectedIndex(0);
        scrollToErrorElement("cityId");
        seterrors({ ...createErrors, cityId: "City is required" });

        return false;
      }

      if (!tourModuleId) {
        setSelectedIndex(0);
        scrollToErrorElement("tourModuleId");
        seterrors({
          ...createErrors,
          tourModuleId: "Tour module is required.",
        });

        return false;
      }
      if (!languageId) {
        seterrors({ ...errors, languageId: "Language is required" });

        return false;
      }

      // if (activityActualPrice === "" || activityActualPrice === null) {
      //   setSelectedIndex(0);
      //   scrollToErrorElement("activityActualPrice");
      //   seterrors({
      //     ...createErrors,
      //     activityActualPrice: "Activity price is required",
      //   });

      //   return false;
      // }

      if (!currency) {
        setSelectedIndex(0);
        scrollToErrorElement("currency");
        seterrors({ ...createErrors, currency: "Please select a currency" });

        return false;
      }

      if (tourPerson === "") {
        setSelectedIndex(0);
        scrollToErrorElement("tourPerson");
        seterrors({
          ...createErrors,
          tourPerson: "Atleast 1 person for tour is required",
        });

        return false;
      }
      if (startDate === "" || startDate === null) {
        setSelectedIndex(0);
        scrollToErrorElement("startDate");
        seterrors({
          ...createErrors,
          startDate: "Activity start date is required",
        });

        return false;
      }
      if (endDate === "" || endDate === null) {
        setSelectedIndex(0);
        scrollToErrorElement("endDate");
        seterrors({
          ...createErrors,
          endDate: "Activity end date is required",
        });

        return false;
      }
      if (!duration_value) {
        setSelectedIndex(0);
        scrollToErrorElement("duration_value");
        seterrors({
          ...createErrors,
          duration_value: "Duration value is required.",
        });

        return false;
      }
      if (duration_unit === "") {
        setSelectedIndex(0);
        scrollToErrorElement("duration_unit");
        seterrors({
          ...createErrors,
          duration_unit: "Duration unit is required.",
        });

        return false;
      }
      if (slug === "") {
        setSelectedIndex(0);
        scrollToErrorElement("slug");
        seterrors({ ...createErrors, slug: "Slug is required" });

        return false;
      }
      if (image.length == 0 || image.length < 5) {
        setSelectedIndex(0);
        scrollToErrorElement("image");
        seterrors({ ...createErrors, image: "Atleast 5 images are required" });
        return false;
      }

      if (participantType[participantType.length - 1].pertype === "") {
        setSelectedIndex(1);
        scrollToErrorElement("participant_types");
        seterrors({
          ...createErrors,
          participentType: "Please provide a participant type.",
        });

        return false;
      }
      if (participantType[participantType.length - 1].age.trim() === "") {
        setSelectedIndex(1);
        scrollToErrorElement("participant_types");
        seterrors({
          ...createErrors,
          participentType: "Please provide participant age range.",
        });

        return false;
      }
      if (participantType[participantType.length - 1].price === 0) {
        setSelectedIndex(1);
        scrollToErrorElement("participant_types");
        seterrors({
          ...createErrors,
          participentType: "Please provide participant type price.",
        });

        return false;
      }
      if (tourActivityData[tourActivityData.length - 1].term.trim() === "") {
        setSelectedIndex(2);
        scrollToErrorElement("activity_facilities");
        seterrors({ ...createErrors, tourActivity: "Please provide a topic." });

        return false;
      }
      if (
        tourActivityData[tourActivityData.length - 1].description.trim() === ""
      ) {
        setSelectedIndex(2);
        scrollToErrorElement("activity_facilities");
        seterrors({
          ...createErrors,
          tourActivity: "Please provide a description.",
        });

        return false;
      }
      if (
        experienceDetails[experienceDetails.length - 1].title !==
        "Not suitable for" &&
        experienceDetails[experienceDetails.length - 1].title.trim() === ""
      ) {
        setSelectedIndex(3);
        scrollToErrorElement("activity_experiences");
        seterrors({
          ...createErrors,
          information: "Please provide an experience title",
        });

        return false;
      }
      // if (
      //   experienceDetails[experienceDetails.length - 1].title !==
      //   "Not suitable for" &&
      //   experienceDetails[experienceDetails.length - 1].desc[
      //     experienceDetails[experienceDetails.length - 1].desc.length - 1
      //   ].trim() === ""
      // ) {
      //   setSelectedIndex(3);
      //   scrollToErrorElement("activity_experiences");
      //   seterrors({
      //     ...createErrors,
      //     information: "Please provide an experience description",
      //   });

      //   return false;
      // }

      let blankDesc = experienceDetails.filter((item) => {
        let des = item.desc.filter((description) => description === "");
        return des.length === 0 || item.title == "Not suitable for"
          ? false
          : true;
      });
      if (blankDesc.length > 0) {
        setSelectedIndex(3);
        // toast.error("Details can not be blank");
        scrollToErrorElement("activity_experiences");
        seterrors({
          ...createErrors,
          information: "Please provide an experience description",
        });
        return false;
      }

      if (meetingPointData[meetingPointData.length - 1].desc.trim() === "") {
        setSelectedIndex(4);
        scrollToErrorElement("meeting_point");
        seterrors({
          ...createErrors,
          meetingPoint: "Please provide a meeting point description.",
        });

        return false;
      }
      if (meetingPointData[meetingPointData.length - 1].link.trim() === "") {
        setSelectedIndex(4);
        scrollToErrorElement("meeting_point");
        seterrors({
          ...createErrors,
          meetingPoint: "Please provide a location link.",
        });

        return false;
      }
      if (importentInfo[importentInfo.length - 1].title.trim() === "") {
        setSelectedIndex(5);
        scrollToErrorElement("important_info");
        seterrors({
          ...createErrors,
          importentInfo: "Please provide a title",
        });

        return false;
      }
      if (
        importentInfo[importentInfo.length - 1].description[
          importentInfo[importentInfo.length - 1].description.length - 1
        ].trim() === ""
      ) {
        setSelectedIndex(5);
        scrollToErrorElement("important_info");
        seterrors({
          ...createErrors,
          importentInfo: "Please provide an experience description",
        });

        return false;
      }
      if (!topPriority) {
        setSelectedIndex(6);
        scrollToErrorElement("topPriority");
        seterrors({
          ...createErrors,
          topPriority: "Please provide a priority number.",
        });

        return false;
      }
      // if (addedByid === "") {
      //   scrollToErrorElement("addedByid");
      //   seterrors({
      //     ...createErrors,
      //     addedByid: "Please assign the activity to a merchant",
      //   });

      //   return false;
      // }
      return true;
    }
  };



  const handleEditSubmit = async (draftstatus) => {
    if (!handleEditValidation(draftstatus)) return;
    const convertData = convertDurationTime(activity.duration_value, activity.duration_unit);
    let bodyData = {
      ...activity,
      languageId: activity?.languageId,
      // tourDuration: {
      //   value: activity.duration_value || 0,
      //   unit: activity.duration_unit || "",
      // },
      tourDuration: {
        value: Number(convertData.duration_value)?.toFixed(1) ?? 0,
        unit: convertData.duration_unit ?? "",
      },
      duration_value: Number(convertData.duration_value)?.toFixed(1),
      duration_unit: convertData.duration_unit,
      TourAdditionalStuff: selectedItems,
      participentType: participantType,
      tourActivity: tourActivityData,
      information: experienceDetails,
      meetingPoint: meetingPointData,
      importentInfo: importentInfo,
      activityCoordinates: tourCoordinates,
      saveAsDraft: draftstatus,
      importantQuestionAboutThisTour: importantQuestion.filter(
        (list) => list.question !== "" || list.answer !== ""
      ),
      travelAgencyDetails: thirdPartyReviewrating,
    };
    bodyData.duration_value = convertData.duration_value.toFixed(1)
    bodyData.duration_unit = convertData.duration_unit
    if (activity?.activitySiteId === "") {
      delete bodyData?.activitySiteId;
    }
    if (activity?.destination === "") {
      delete bodyData?.destination;
    }
    if (activity.specialOfferId === "") {
      delete bodyData.specialOfferId;
    }

    if (activity.cityId === "") {
      delete bodyData.cityId;
    }

    if (activity.categoryId === "") {
      delete bodyData.categoryId;
    }

    if (activity.activityTypesId === "") {
      delete bodyData.activityTypesId;
    }
    if (activity.countryId === "") {
      delete bodyData.countryId;
    }
    if (activity.languageId === "") {
      delete bodyData.languageId;
    }

    if (activity.tourModuleId === "") {
      delete bodyData.tourModuleId;
    }
    if (draftstatus)
      bodyData.isApproval = false;
    else
      bodyData.isApproval = true;
    try {
      setisLoading(true);
      let res = await requestData(
        `/admin/editActivityDetails/${params.id}`,
        "PUT",
        bodyData
      );
      if (res && res.status) {
        toast.success(res?.data?.message);
        navigate("/manage-activity-drafts");
      } else {
        toast.error(res?.error?.message);
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      toast.error(error.message);
    }
  };

  const { _id, ...selectedcurrency } = activity.currency;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const addNewEntry = () => {
    let filteredQuestion = importantQuestion.filter(
      (item) => item.question.trim() === "" || item.answer === ""
    );

    if (filteredQuestion.length > 0) {
      toast.error("Qusetion and answer should not be empty");
      return;
    }
    setImportantQuestion((prev) => [...prev, { question: "", answer: "" }]);
  };
  const videoUpload = async (file) => {
    if (!file) return;
    let duration = await getVideoDuration(file);
    if (duration < 5 || duration > 15) {
      return toast.warn("Video duration should be between 5 to 15 seconds.");
      // Optionally, reset the file input to clear the selected file
    } else {
      setVideoUploading(true);
      let data = new FormData();
      data.append("image", file);

      let response = await fileUpload("/admin/tourImage", "POST", data);
      if (response.status) {
        setactivity((prev) => ({ ...prev, videos: [response.url] }));
      } else {
        toast.error("Error on video uploading!!!");
      }
      setVideoUploading(false);

      // Video duration meets the criteria, continue with your logic
    }
  };

  const FetchedBokunProduct = async (pid) => {
    const bodyData = { productId: pid };
    let res = await JSON_API["bokunActivityFetch"](bodyData);
    
    if (res && res.isSuccess) {
      let resval = res.data.data;
  
      // Store currency separately
      const currency = getCurrency(resval.actualVendor.currencyCode, activity);
      
      const bokunpartytipe = await fetchParticipantTypePrice(pid, currency);
  
      if (resval.pricingCategories?.length > 0 && resval.nextDefaultPriceMoney) {
        let bokunparticipentType = getparticipentType(bokunpartytipe, resval.pricingCategories);

        bokunparticipentType = bokunparticipentType.map((item) => ({
          ...item,
          price: item.price !== undefined ? item.price : 0, 
        }));
        
        setparticipantType(bokunparticipentType)
      }
    }
  };
  
  useEffect(() => {
    if (activity?.thirdPartyProductId) {
      FetchedBokunProduct(activity?.thirdPartyProductId);
    }
  }, [activity?.thirdPartyProductId])


  return (
    <>
      {isLoading && <Loader />}
      <form
        className=""
        // onSubmit={handleEditSubmit}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Draft Tour Activity</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-12">
                  <Tabs
                    selectedIndex={selectedIndex}
                    onSelect={(index) => setSelectedIndex(index)}
                  >
                    <TabList>
                      <Tab>Tour Details</Tab>
                      <Tab>Participant Type*</Tab>
                      <Tab>Activity Details</Tab>
                      <Tab>Experience</Tab>
                      <Tab>Meeting Point</Tab>
                      <Tab>Important Info</Tab>
                      <Tab>Tour Places</Tab>
                      <Tab>Additional Stuff</Tab>
                      <Tab>Important To Know About This Tour</Tab>
                      <Tab>Third Party Revire Rating</Tab>
                    </TabList>
                    <TabPanel>
                      <div className="row" style={{ margin: "0.1rem" }}>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="activityTitle"
                              className="form-label"
                            >
                              Product Title{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <input
                              name="activityTitle"
                              id="activityTitle"
                              // placeholder="Your first name, e.g. Walter"
                              type="text"
                              className="form-control"
                              autoComplete="false"
                              value={activity.activityTitle}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          {errors.activityTitle && (
                            <span className="errorMessageText text-danger">
                              {errors.activityTitle}
                            </span>
                          )}
                        </div>


                        <div className="col-md-6">
                          <label
                            htmlFor=""
                            className="form-label"
                            style={{
                              fontSize: "15px",
                            }}
                          >
                            Third Party Id
                          </label>
                          <select
                            name="thirdPartyId"
                            id="thirdPartyId"
                            className="form-control form-select"
                            disabled={activity.thirdPartyId ? true : false}
                            value={activity.thirdPartyId}
                            onChange={(e) => {
                              setactivity((prev) => {
                                return {
                                  ...prev,
                                  thirdPartyId: e.target.value,
                                };
                              });
                            }}
                          >
                            <option value="" key={0}>
                              Select
                            </option>
                            {thirdPartyData?.map((item, idx) => {
                              return (
                                <>
                                  <option
                                    key={idx + 1}
                                    value={`${item?._id}`}
                                  >
                                    {item?.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>

                        </div>

                        <div className="row">
                          {/* <div
                          className="mb-3 col-md-6"
                          style={{
                            display: activity.thirdPartyId ? "block" : "none",
                          }}
                        >
                          <label htmlFor="activityTitle" className="form-label">
                            Bokun Link{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </label>
                          <input
                            name="bokunTitle"
                            id="bokunTitle"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={bokunLink}
                            onChange={(e) => {
                              setbokunTitle(e.target.value);
                              FetchedBokunProduct(bokunIdFind(e.target.value));
                            }}
                          />
                        </div> */}
                          <div
                            className="mb-3 col-md-6"
                            style={{
                              display: activity.thirdPartyId ? "block" : "none",
                            }}
                          >
                            <label htmlFor="activityTitle" className="form-label">
                              Bokun Id{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <input
                              //  disabled={activity?.thirdPertyProductLink?true:false}
                              disabled
                              name="bokunTitle"
                              id="bokunTitle"

                              type="text"

                              className="form-control"
                              autoComplete="false"
                              value={activity.thirdPartyProductId}
                              onChange={(e) => setactivity((prev) => ({
                                ...prev,
                                thirdPartyProductId: e.target.value,
                              }))}
                            />
                          </div>
                        </div>



                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="productCode" className="form-label">
                              Product Code{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <input
                              name="productCode"
                              id="productCode"
                              // placeholder="Your first name, e.g. Walter"
                              type="text"
                              className="form-control"
                              autoComplete="false"
                              value={activity.productCode}
                              disabled
                            //onChange={(e) => handleChange(e)}
                            />
                          </div>
                          {errors.productCode && (
                            <span className="errorMessageText text-danger">
                              {errors.productCode}
                            </span>
                          )}
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="description" className="form-label">
                              Description{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <div id="description">
                              <p style={{ fontSize: "14px" }}>
                                This is the customer’s first introduction to
                                your activity. Describe your activity in 2 to 3
                                sentences including all the main selling points
                                of the activity (This is not the main
                                description so keep it short and aim to attract
                                attention)
                              </p>
                            </div>
                            <CKEditor
                              className="editor-container"
                              editor={ClassicEditor}
                              data={activity.description}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setactivity((state) => ({
                                  ...state,
                                  description: data,
                                }));
                              }}
                            />
                          </div>
                          {errors.description && (
                            <span className="errorMessageText text-danger">
                              {errors.description}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="categoryId" className="form-label">
                              Category{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <select
                              name="categoryId"
                              id="categoryId"
                              className="form-control form-select"
                              value={activity.categoryId}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="" key={0}>
                                Select
                              </option>
                              {categories?.map((item, idx) => {

                                return (
                                  <>
                                    <option
                                      key={idx + 1}
                                      value={`${item?._id}`}
                                    >
                                      {item?.categoryName}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.categoryId && (
                            <span className="errorMessageText text-danger">
                              {errors.categoryId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="activityTypesId"
                              className="form-label"
                            >
                              Top activities
                            </label>
                            <select
                              name="activityTypesId"
                              id="activityTypesId"
                              className="form-control form-select"
                              value={activity.activityTypesId}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="" key={0}>
                                Select
                              </option>
                              {activity_types?.map((item, idx) => {
                                return (
                                  <>
                                    <option
                                      key={idx + 1}
                                      value={`${item?._id}`}
                                    >
                                      {item?.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.activityTypesId && (
                            <span className="errorMessageText text-danger">
                              {errors.activityTypesId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6 zd-none">
                          <div className="mb-3">
                            <label htmlFor="countryId" className="form-label">
                              Country{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <Select
                              name="countryId"
                              id="countryId"
                              classNamePrefix="select"
                              options={countres?.map((item) => ({
                                value: item?._id,
                                label: item?.name,
                              }))}
                              // isMulti
                              value={{
                                value: activity.countryId,
                                label: activity.countryName,
                              }}
                              onChange={(val) => {
                                getCitiesByCountry(val.value);
                                setactivity((prev) => {
                                  return {
                                    ...prev,
                                    countryId: val?.value,
                                    countryName: val?.label,
                                    cityId: "",
                                    cityName: "",
                                    activitySiteId: "",
                                    activitySiteName: "",
                                    destinationData: "",
                                    destination: "",
                                    tourModuleId: "",
                                    tourModuleName: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.countryId && (
                            <span className="errorMessageText text-danger">
                              {errors.countryId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="cityId" className="form-label">
                              City{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <Select
                              name="cityId"
                              id="cityId"
                              classNamePrefix="select"
                              options={citiesForCountry.map((item) => ({
                                value: item?._id,
                                label: item?.cityName,
                              }))}
                              // isMulti
                              value={{
                                value: activity?.tourmoduledetails?.cityId,
                                label: activity.cityName,
                              }}
                              onChange={(val) => {
                                dispatch(getAllSitesForACity(val?.value));
                                // dispatch(getAllTourModuleByCity(val?.value));
                                fetchTourModulesAgainstMerchant({
                                  cityId: val?.value,
                                  merchantId: activity.addedByid,
                                });
                                setactivity((prev) => {
                                  return {
                                    ...prev,
                                    cityId: val?.value,
                                    cityName: val?.label,
                                    activitySiteId: "",
                                    activitySiteName: "",
                                    destinationData: "",
                                    destination: "",
                                    destinationName: "",
                                    tourModuleId: "",
                                    tourModuleName: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.cityId && (
                            <span className="errorMessageText text-danger">
                              {errors.cityId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="activitySiteId"
                              className="form-label"
                            >
                              Activity location: (Select the area where your
                              activity takes place or start from. If no suitable
                              selection is available contact us to add it to our
                              system)
                            </label>
                            <Select
                              name="activitySiteId"
                              id="activitySiteId"
                              classNamePrefix="select"
                              options={sites_of_a_city?.map((item) => ({
                                value: item?._id,
                                label: item?.siteName,
                              }))}
                              // isMulti
                              value={{
                                value: activity.activitySiteId,
                                label: activity.activitySiteName,
                              }}
                              onChange={(val) => {
                                getSiteDestinations(val?.value);
                                setactivity((prev) => {
                                  return {
                                    ...prev,
                                    activitySiteId: val?.value,
                                    activitySiteName: val?.label,
                                    destinationData: "",
                                    destination: "",
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="destination" className="form-label">
                              Activities main destination <br />
                              (If nothing suitable please contact us directly to
                              add a suitable option)
                            </label>
                            <Select
                              name="destination"
                              id="destination"
                              className="select"
                              options={siteDestinations?.map((item) => ({
                                value: item?._id,
                                label: item?.name,
                              }))}
                              // isMulti
                              value={{
                                value: activity.destination,
                                label: activity.destinationName,
                              }}
                              onChange={(val) => {
                                setactivity((prev) => {
                                  return {
                                    ...prev,
                                    destinationData: {
                                      _id: val.value,
                                      name: val.label,
                                    },
                                    destination: val?.value,
                                    destinationName: val?.label,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              htmlFor="tourModuleId"
                              className="form-label"
                            >
                              {/* Tour Module* */}
                              Tour Activity/Module (Select the option that best
                              describe your type of activity or create your own
                              from the merchant sidebar)
                            </label>
                            <Select
                              name="tourModuleId"
                              id="tourModuleId"
                              classNamePrefix="select"
                              options={tourModules?.map((item, idx) => ({
                                value: item?._id,
                                label: item?.name,
                              }))}
                              noOptionsMessage={() => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span>There is no Tour Module</span>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => navigate("/add-tour-module")}
                                  >
                                    Add Module
                                  </button>
                                </div>
                              )}
                              // isMulti
                              value={{
                                value: activity.tourModuleId,
                                label: activity.tourModuleName,
                              }}
                              onChange={(val) => {
                                setactivity((prev) => {
                                  return {
                                    ...prev,
                                    tourModuleId: val?.value,
                                    tourModuleName: val?.label,
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.tourModuleId && (
                            <span className="errorMessageText text-danger">
                              {errors.tourModuleId}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row" style={{ margin: "0.1rem" }}>
                        <div className="col-md-6 d-none">
                          <div className="mb-3">
                            <label
                              htmlFor="activityActualPrice"
                              className="form-label"
                            >
                              Activity Price{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <input
                              name="activityActualPrice"
                              id="activityActualPrice"
                              // placeholder="Your first name, e.g. Walter"
                              type="number"
                              min={"0"}
                              className="form-control"
                              autoComplete="false"
                              value={activity.activityActualPrice}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = "";
                                }
                                setactivity((prev) => ({
                                  ...prev,
                                  activityActualPrice: e.target.value,
                                }));
                              }}
                            />
                          </div>
                          {errors.activityActualPrice && (
                            <span className="errorMessageText text-danger">
                              {errors.activityActualPrice}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="languageId" className="form-label">
                              Tour Language{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <select
                              name="languageId"
                              id="languageId"
                              className="form-control form-select"
                              value={activity.languageId}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="" key={0}>
                                Select
                              </option>
                              {languages?.map((item, idx) => {
                                return (
                                  <>
                                    <option
                                      key={idx + 1}
                                      value={`${item?._id}`}
                                    >
                                      {item?.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.languageId && (
                            <span className="errorMessageText text-danger">
                              {errors.languageId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="currency" className="form-label">
                              Currency{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <select
                              name="currency"
                              id="currency"
                              className="form-control form-select"
                              value={JSON.stringify(selectedcurrency)}
                              onChange={(e) => {
                                const selectedCurrency = JSON.parse(
                                  e.target.value
                                );
                                setactivity((prev) => ({
                                  ...prev,
                                  currency: selectedCurrency,
                                }));
                              }}
                            >
                              <option
                                value={JSON.stringify({
                                  name: "",
                                  abbreviation: "",
                                  symbol: "",
                                })}
                                key={0}
                              >
                                Select
                              </option>
                              {currencies?.map((item, idx) => {
                                return (
                                  <>
                                    <option
                                      key={idx + 1}
                                      value={JSON.stringify({
                                        name: item?.name,
                                        abbreviation: item?.code,
                                        symbol: item?.symbol_native,
                                      })}
                                    >
                                      {item?.name + " (" + item?.code + ")"}
                                    </option>
                                  </>
                                );
                              })}
                            </select>

                          </div>
                          {errors.currency && (
                            <span className="errorMessageText text-danger">
                              {errors.currency}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="specialOfferId"
                              className="form-label"
                            >
                              Special Offer
                            </label>
                            <Select
                              name="specialOfferId"
                              id="specialOfferId"
                              classNamePrefix="select"
                              ref={inputRef}
                              options={[
                                ...special_offers?.map((item) => ({
                                  value: item?._id,
                                  label: item?.specialOfferName,
                                })) || [],
                                {
                                  value: null,
                                  label: "No Offer",
                                },
                              ]
                              }
                              value={{
                                value: activity?.specialOfferId,
                                label: activity?.specialOfferName,
                              }}
                              onChange={(val) => {
                                setactivity((prev) => {
                                  return {
                                    ...prev,
                                    specialOfferId: val?.value,
                                    specialOfferName: val?.label,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="tourPerson" className="form-label">
                              Maximum Person For Activity{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <input
                              name="tourPerson"
                              id="tourPerson"
                              type="number"
                              min={"0"}
                              className="form-control"
                              autoComplete="false"
                              value={activity.tourPerson}
                              onChange={(e) =>
                                setactivity({
                                  ...activity,
                                  tourPerson: allowOnlyPositiveInteger(e),
                                })
                              }
                            />
                          </div>
                          {errors.tourPerson && (
                            <span className="errorMessageText text-danger">
                              {errors.tourPerson}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 d-none">
                          <div className="mb-3">
                            <label
                              htmlFor="frontRowSitting"
                              className="form-label"
                            >
                              Front Row Sitting{" "}
                            </label>
                            <Select
                              name="frontRowSitting"
                              id="frontRowSitting"
                              classNamePrefix="select"
                              ref={inputRef}
                              options={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                              ]}
                              value={
                                activity?.frontRowSitting !== undefined
                                  ? {
                                    value: activity?.frontRowSitting,
                                    label:
                                      activity?.frontRowSitting === true
                                        ? "Yes"
                                        : "No",
                                  }
                                  : { value: false, label: "Yes" }
                              }
                              onChange={(val) => {
                                setactivity((prev) => ({
                                  ...prev,
                                  frontRowSitting: val?.value,
                                }));
                              }}
                            />
                          </div>
                          {errors.frontRowSitting && (
                            <span className="errorMessageText text-danger">
                              {errors.frontRowSitting}
                            </span>
                          )}
                        </div>
                        {activity.frontRowSitting && (
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="frontRowSeats"
                                className="form-label"
                              >
                                Front Row Seats{" "}
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "23px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  *
                                </span>
                              </label>
                              <input
                                name="frontRowSeats"
                                id="frontRowSeats"
                                type="number"
                                min={"0"}
                                className="form-control"
                                autoComplete="false"
                                value={activity.frontRowSeats}
                                onChange={(e) =>
                                  setactivity((prev) => ({
                                    ...prev,
                                    frontRowSeats: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            {errors.frontRowSeats && (
                              <span className="errorMessageText text-danger">
                                {errors.frontRowSeats}
                              </span>
                            )}
                          </div>
                        )}

                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="startDate">
                              Activity Starts On{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <DatePicker
                              inputRef={datetimeInputRef}
                              id="startDate"
                              calendarIcon={null}
                              className="form-control"
                              clearIcon={null}
                              value={activity.startDate}
                              format="y-MM-dd"
                              shouldCloseCalendar={({ reason }) =>
                                reason === "select" ||
                                reason === "outsideAction"
                              }
                              minDate={today}
                              onChange={handleStartDateChange}
                            />
                          </div>
                          {errors.startDate && (
                            <span className="errorMessageText text-danger">
                              {errors.startDate}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="endDate">
                              Activity Ends On{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <DatePicker
                              inputRef={datetimeInputRef}
                              id="endDate"
                              calendarIcon={null}
                              className="form-control"
                              clearIcon={null}
                              value={activity.endDate}
                              format="y-MM-dd"
                              shouldCloseCalendar={({ reason }) =>
                                reason === "select" ||
                                reason === "outsideAction"
                              }
                              minDate={today}
                              onChange={handleEndDateChange}
                            />
                          </div>
                          {errors.endDate && (
                            <span className="errorMessageText text-danger">
                              {errors.endDate}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div>
                            <label
                              htmlFor="duration_value"
                              className="form-label"
                            >
                              Duration Time{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                          </div>
                          <div className="" style={{ display: "flex" }}>
                            <div
                              className="col-md-6"
                              style={{ marginLeft: "-15px" }}
                            >
                              <div className="mb-3">
                                <input
                                  type="number"
                                  name="duration_value"
                                  id="duration_value"
                                  min={0}
                                  className="form-control"
                                  value={activity?.duration_value}
                                  onChange={(e) => {
                                    setactivity({
                                      ...activity,
                                      duration_value:
                                        allowOnlyPositiveInteger(e),
                                    });
                                  }}
                                />
                              </div>
                              {errors.duration_value && (
                                <span className="errorMessageText text-danger">
                                  {errors.duration_value}
                                </span>
                              )}
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <select
                                  name="duration_unit"
                                  id="duration_unit"
                                  className="form-control form-select"
                                  value={activity?.duration_unit}
                                  onChange={(e) => {
                                    setactivity((prev) => {
                                      return {
                                        ...prev,
                                        duration_unit: e?.target.value,
                                      };

                                      // let updated = { ...prev };
                                      // updated.tourDuration.unit = e.target.value;

                                      // return JSON.parse(JSON.stringify(updated));
                                    });
                                  }}
                                >
                                  <option value="" key={0}>
                                    Select
                                  </option>
                                  {duration_units?.map((item, idx) => {
                                    return (
                                      <>
                                        <option key={idx + 1} value={`${item}`}>
                                          {item}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.duration_unit && (
                                <span className="errorMessageText text-danger">
                                  {errors.duration_unit}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="slug" className="form-label">
                              {/* Slug */}
                              Keywords (Add keywords that matches your activity
                              E.g: Cape town , Deepsea fishing Cape town,
                              sportfishing Cape Town)
                            </label>
                            <input
                              name="slug"
                              id="slug"
                              type="text"
                              className="form-control"
                              autoComplete="false"
                              value={activity.slug}
                              onKeyDown={(e) => {
                                if (e.key === "/" || e.key === "#") {
                                  e.preventDefault();
                                }
                                if (e.ctrlKey === true && e.key === "v") {
                                  e.preventDefault();
                                  toast.error(
                                    "Copy-pasting slug value is not allowed."
                                  );
                                }
                              }}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          {errors.slug && (
                            <span className="errorMessageText text-danger">
                              {errors.slug}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="image" className="form-label">
                              Pictures ( please upload atleast 5 pictures of
                              sizes 720px * 1280px)*
                            </label>
                            <input
                              name="image"
                              id="image"
                              type="file"
                              accept={"image/*"}
                              ref={fileInputRef}
                              className="form-control"
                              multiple
                              onChange={(e) =>
                                imageHandle(Array.from(e.target.files))
                              }
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            {activity.image.map((item, i) => {
                              return (
                                <div style={{ display: "flex" }}>
                                  <img
                                    src={item}
                                    style={{ height: "100px", width: "100px" }}
                                    className="img-fluid px-2"
                                    alt="activityImg"
                                  />
                                  <i
                                    onClick={() => {
                                      let upadted = [...activity.image];
                                      upadted.splice(i, 1);

                                      setactivity((prev) => ({
                                        ...prev,
                                        image: upadted,
                                      }));
                                    }}
                                    style={{ top: "0", cursor: "pointer" }}
                                    className="fa fa-times-circle-o"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              );
                            })}
                          </div>
                          <span style={{ color: errors.image ? "blue" : "red" }}>
                            By adding a picture here I agree that I’m liable for
                            any copyright infringement. <br />
                          </span>

                          {errors.image && (
                            <span className="errorMessageText text-danger">
                              {errors.image}
                            </span>
                          )}
                        </div>

                        <div className="col-md-12">
                          <FileSelect
                            label={
                              " Tour video (Min Video length 5 second Max video length 15 second)"
                            }
                            className={"col-md-12 mb-3"}
                            onChange={(e) => videoUpload(e.target.files[0])}
                            //  labelStyle={{ fontSize: "20px", color: "black" }}
                            videos={activity?.videos ?? []}
                            fileType="video/*"
                            uploading={videoUploading}
                          />
                          <span style={{ color: "red" }}>
                            By adding a video here I agree that I’m liable for
                            any copyright infringement.
                          </span>
                        </div>

                        <SelectInput
                          className="col-md-12"
                          label="Assign To Merchant"
                          options={merchants}
                          labelKey={["firstName", "lastName", "marchentCode"]}
                          valueKey="_id"
                          value={activity.addedByid}
                          onChange={(val) => {
                            setactivity((prev) => ({
                              ...prev,
                              addedByid: val._id,
                            }));
                            getAllStuff(val._id);
                          }}
                        />
                      </div>
                      {errors.addedByid && (
                        <span className="errorMessageText text-danger">
                          {errors.addedByid}
                        </span>
                      )}
                    </TabPanel>

                    <TabPanel>
                      <label
                        htmlFor=""
                        className="form-label"
                        style={{
                          marginLeft: "15px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Participant Type{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <div className="field-add-btn">
                        <button
                          type="button"
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            let lastEle =
                              participantType[participantType.length - 1];

                            if (lastEle.pertype.trim() === "") {
                              toast.error("Please select a participant type.");
                              return;
                            }
                            if (lastEle.age.trim() === "") {
                              toast.error("Please put an age range");
                              return;
                            }
                            if (lastEle.price === 0) {
                              toast.error("Please put a price.");
                              return;
                            }
                            if (
                              activity?.frontRowSitting &&
                              !lastEle.frontRowSeatPrice
                            ) {
                              toast.error("Please put front row seat price.");
                              return;
                            }
                            filterparticipent();
                            setparticipantType((prev) => [
                              ...prev,
                              {
                                pertype: "",
                                age: "",
                                price: 0,
                                frontRowSeatPrice: 0,
                              },
                            ]);
                          }}
                        >
                          +
                        </button>
                      </div>

                      <div id="participant_types">
                        {participantType.map((item, index) => (
                          <div
                            className="d-flex"
                            key={index}
                            style={{ alignItems: "center" }}
                          >
                            <div className="card_add_more col-md-11">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor={`pertype_${index}`}
                                    className="form-label"
                                  >
                                    Type Of Participant{" "}
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: "red",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  <Select
                                    isDisabled={activity.thirdPartyId ? true : false}
                                    name={`pertype_${index}`}
                                    id={`pertype_${index}`}
                                    classNamePrefix="select"
                                    options={participantData.map((it) => {
                                      return {
                                        value: {
                                          id: it?._id,
                                          age: it?.ageRange,
                                        },
                                        label: it?.type,
                                      };
                                    })}
                                    value={{
                                      value: item?.pertypeId,
                                      label: item?.pertype,
                                    }}
                                    onChange={(val) => {
                                      setparticipantType((state) => {
                                        state[index].pertypeId = val?.value?.id;
                                        state[index].pertype = val?.label;
                                        state[index].age = val?.value?.age;
                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              {/* Age Range* */}
                              <div className="col-md-12">
                                <label
                                  htmlFor={`age_${index}`}
                                  className="form-label"
                                >
                                  Age Range{" "}
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    {" "}
                                    *
                                  </span>
                                </label>
                                <input
                                  id={`age_${index}`}
                                  name={`age_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.age}
                                  disabled
                                />
                              </div>
                              {/* Price* */}
                              <div className="col-md-12">
                                <label htmlFor="" className="form-label">
                                  Price{" "}
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    {" "}
                                    *
                                  </span>
                                </label>
                                <input
                                  id={`price_per_person_${index}`}
                                  name={`price_per_person_${index}`}
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.price}
                                  onChange={(e) => {
                                    if (e.target.value < 0) {
                                      e.target.value = 0;
                                    }

                                    setparticipantType((state) => {
                                      state[index].price = e.target.value;

                                      return JSON.parse(JSON.stringify(state));
                                    });
                                  }}
                                />
                              </div>

                              {activity?.frontRowSitting && (
                                <div className="col-md-12">
                                  <label
                                    htmlFor={`front_row_seat_price_${index}`}
                                    className="form-label"
                                  >
                                    Front Row Seat Price{" "}
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "23px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  <input
                                    id={`front_row_seat_price_${index}`}
                                    name={`front_row_seat_price_${index}`}
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    autoComplete="false"
                                    value={item.frontRowSeatPrice}
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                      }

                                      setparticipantType((state) => {
                                        state[index].frontRowSeatPrice =
                                          e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            {index !== 0 && (
                              <button
                                type="button"
                                className="btn btn-danger ml-auto d-table"
                                onClick={() =>
                                  setparticipantType((prev) => {
                                    let updated = [...prev];
                                    updated.splice(index, 1);
                                    return updated;
                                  })
                                }
                              >
                                -
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                      <div>
                        {errors.participentType && (
                          <span className="errorMessageText text-danger">
                            {errors.participentType}
                          </span>
                        )}
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <label
                        htmlFor=""
                        className="form-label"
                        style={{
                          marginLeft: "15px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        About This Activity{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <div className="field-add-btn">
                        <button
                          type="button"
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            let lastEle =
                              tourActivityData[tourActivityData.length - 1];

                            if (lastEle.term.trim() === "") {
                              toast.error("Please provide a topic.");
                              return;
                            }
                            if (lastEle.description.trim() === "") {
                              toast.error("Please provide a description");
                              return;
                            }
                            settourActivityData((prev) => [
                              ...prev,
                              { term: "", description: "" },
                            ]);
                          }}
                        >
                          +
                        </button>
                      </div>

                      <div id="activity_facilities">
                        {tourActivityData?.map((item, index) => {
                          return (
                            <div
                              className="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <div
                                className="card_add_more col-md-11"
                                key={index}
                              >
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`tour_term_${index}`}
                                      className="form-label"
                                    >
                                      {/* Topic */}
                                      Short main activity description/keywords
                                      (Eg: Rome: Colosseum, Palatine Hill and
                                      Roman Forum Guided Tour)
                                    </label>
                                    <input
                                      name={`tour_term_${index}`}
                                      id={`tour_term_${index}`}
                                      type="text"
                                      className="form-control"
                                      autoComplete="false"
                                      value={item.term}
                                      onChange={(e) => {
                                        settourActivityData((state) => {
                                          state[index].term = e.target.value;
                                          return JSON.parse(
                                            JSON.stringify(state)
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="siteName"
                                      className="form-label"
                                    >
                                      {/* Description* */}
                                      Description (Short description of the main
                                      points/sites visited and keywords to
                                      attract attention to your product E.g:
                                      Visit the Colosseum, the largest
                                      amphitheater in the Roman world, the
                                      Palatine Hill, the oldest part of the
                                      city, and the Roman Forum, the beating
                                      heart of ancient Rome, with an expert
                                      guide.)
                                    </label>
                                    <CKEditor
                                      className="editor-container"
                                      editor={ClassicEditor}
                                      data={item.description}
                                      onChange={(event, editor) => {
                                        const data = editor.getData();
                                        settourActivityData((state) => {
                                          state[index].description = data;
                                          return JSON.parse(
                                            JSON.stringify(state)
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              {index !== 0 && (
                                <button
                                  type="button"
                                  className="btn btn-danger ml-auto d-table"
                                  onClick={() =>
                                    settourActivityData((prev) => {
                                      let updated = [...prev];
                                      updated.splice(index, 1);
                                      return updated;
                                    })
                                  }
                                >
                                  -
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {errors.tourActivity && (
                          <span className="errorMessageText text-danger">
                            {errors.tourActivity}
                          </span>
                        )}
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <label
                        htmlFor="siteName"
                        className="form-label"
                        style={{
                          marginLeft: "15px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Experience{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <div className="field-add-btn">
                        <button
                          type="button"
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            let lastEle =
                              experienceDetails[experienceDetails.length - 1];

                            if (lastEle.title.trim() === "") {
                              toast.error("Please provide a title.");
                              return;
                            }
                            if (lastEle.desc[0].trim() === "") {
                              toast.error("Please provide a description");
                              return;
                            }
                            setExperienceDetails((prev) => [
                              ...prev,
                              { id: uuidv4(), title: "", desc: [""] },
                            ]);
                          }}
                        >
                          +
                        </button>
                      </div>

                      <div id="activity_experiences">
                        {experienceDetails.map((item, index) => {
                          return (
                            <div
                              className="d-flex"
                              key={item.id}
                              style={{ alignItems: "center" }}
                            >
                              <div className="card_add_more col-md-11">
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="siteName"
                                      className="form-label"
                                    >
                                      {/* Experience Title* */}
                                      {item.title === "Highlights"
                                        ? "Add the highlights of this activity one at a time by using the plus box (Keep it short and precise)"
                                        : item.title === "Full description"
                                          ? "Write a full description including everything that is included and will be seen/experience on this activity. Keep the description in correct order as the customer will see/experience this activity. This is your main description/selling point so make it stand out with at least 1000 characters"
                                          : item.title === "Includes"
                                            ? "Describe everything that is included in the tour price (E.g.: Entrance fees , bottle of water , transport. Use the plus button to add one at a time)"
                                            : item.title === "Excludes"
                                              ? "Describe everything that is excluded in the tour price (E.g.: Entrance fees , bottle of water , transport. Use the plus button to add one at a time)"
                                              : " Experience Title"}
                                    </label>
                                    <input
                                      disabled={
                                        item.title === "Highlights" ||
                                        item.title === "Full description" ||
                                        item.title === "Includes" ||
                                        item.title === "Excludes" ||
                                        item.title === "Not suitable for"
                                      }
                                      name={`exp_title_${index}`}
                                      id={`exp_title_${index}`}
                                      type="text"
                                      className="form-control"
                                      autoComplete="false"
                                      value={item.title}
                                      onChange={(e) => {
                                        setExperienceDetails((state) => {
                                          state[index].title = e.target.value;

                                          return JSON.parse(
                                            JSON.stringify(state)
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="movePlusBtn">
                                  <label
                                    htmlFor="siteName"
                                    className="form-label"
                                    style={{ marginLeft: "18px" }}
                                  >
                                    {/* Experience Details */}
                                    Details{" "}
                                    {item?.title !== "Not suitable for" && (
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          color: "red",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    )}
                                  </label>
                                  <button
                                    type="button"
                                    className="btn btn-primary mb-3 ml-3"
                                    onClick={() => {
                                      let lastEle =
                                        item.desc[item.desc.length - 1];

                                      if (lastEle === "") {
                                        toast.error(
                                          "Please provide a description"
                                        );
                                        return;
                                      }
                                      // setExperienceDetails((state) => {
                                      //   state[index].desc.push("");

                                      //   return JSON.parse(JSON.stringify(state));
                                      // });
                                      setExperienceDetails((state) => {
                                        // Create a copy of the current state
                                        const newState = [...state];

                                        // Add an empty string to the desc array of the specific item
                                        newState[index] = {
                                          ...newState[index],
                                          desc: [...newState[index].desc, ""],
                                        };

                                        // Return the new state
                                        return newState;
                                      });
                                    }}
                                  >
                                    +
                                  </button>
                                </div>

                                {item.desc.map((it, key) => {
                                  return (
                                    <div
                                      className="d-flex"
                                      style={{ alignItems: "center" }}
                                    >
                                      <div className="col-md-11" key={key}>
                                        <div className="mb-3">
                                          <CKEditor
                                            className="editor-container"
                                            editor={ClassicEditor}
                                            data={it}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setExperienceDetails((state) => {
                                                state[index].desc[key] = data;

                                                return JSON.parse(
                                                  JSON.stringify(state)
                                                );
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>

                                      {key !== 0 && (
                                        <button
                                          type="button"
                                          className="btn btn-danger ml-auto d-table"
                                          onClick={() =>
                                            setExperienceDetails((state) => {
                                              state[index].desc.splice(key, 1);

                                              return JSON.parse(
                                                JSON.stringify(state)
                                              );
                                            })
                                          }
                                        >
                                          -
                                        </button>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>

                              {item.title !== "Highlights" &&
                                item.title !== "Full description" &&
                                item.title !== "Includes" &&
                                item.title !== "Not suitable for" && (
                                  <button
                                    type="button"
                                    className="btn btn-danger ml-auto d-table"
                                    onClick={() =>
                                      setExperienceDetails((prev) => {
                                        let updated = [...prev];
                                        updated.splice(index, 1);
                                        return updated;
                                      })
                                    }
                                  >
                                    -
                                  </button>
                                )}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {errors.information && (
                          <span className="errorMessageText text-danger">
                            {errors.information}
                          </span>
                        )}
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <label
                        htmlFor=""
                        className="form-label"
                        style={{
                          marginLeft: "15px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Meeting Point{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <div className="field-add-btn">
                        <button
                          type="button"
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            let lastEle =
                              meetingPointData[meetingPointData.length - 1];

                            if (lastEle.desc.trim() === "") {
                              toast.error(
                                "Please provide a meeting point description."
                              );
                              return;
                            }
                            if (lastEle.link.trim() === "") {
                              toast.error("Please provide a location link");
                              return;
                            }
                            setmeetingPointData((prev) => [
                              ...prev,
                              { desc: "", link: "" },
                            ]);
                          }}
                        >
                          +
                        </button>
                      </div>

                      <div id="meeting_point">
                        {meetingPointData?.map((item, index) => {
                          return (
                            <div
                              className="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <div
                                className="card_add_more col-md-11"
                                key={index}
                              >
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label htmlFor="" className="form-label">
                                      Meeting Point Description (Also add your
                                      drop of/end point if applicable){" "}
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          color: "red",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </label>
                                    <CKEditor
                                      className="editor-container"
                                      editor={ClassicEditor}
                                      data={item.desc}
                                      onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setmeetingPointData((state) => {
                                          state[index].desc = data;

                                          return JSON.parse(
                                            JSON.stringify(state)
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="siteName"
                                      className="form-label"
                                    >
                                      Meeting Point Link{" "}
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          color: "red",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </label>
                                    <input
                                      name={`meeting_point_link_${index}`}
                                      id={`meeting_point_link_${index}`}
                                      type="text"
                                      className="form-control"
                                      autoComplete="false"
                                      value={item.link}
                                      onChange={(e) => {
                                        setmeetingPointData((state) => {
                                          state[index].link = e.target.value;

                                          return JSON.parse(
                                            JSON.stringify(state)
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              {index !== 0 && (
                                <button
                                  type="button"
                                  className="btn btn-danger ml-auto d-table"
                                  onClick={() =>
                                    setmeetingPointData((prev) => {
                                      let updated = [...prev];
                                      updated.splice(index, 1);
                                      return updated;
                                    })
                                  }
                                >
                                  -
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {errors.meetingPoint && (
                          <span className="errorMessageText text-danger">
                            {errors.meetingPoint}
                          </span>
                        )}
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <label
                        htmlFor="siteName"
                        className="form-label"
                        style={{
                          marginLeft: "15px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Important Info{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <div className="field-add-btn">
                        <button
                          type="button"
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            let lastEle =
                              importentInfo[importentInfo.length - 1];

                            if (lastEle.title.trim() === "") {
                              toast.error("Please provide a title.");
                              return;
                            }
                            if (lastEle.description[0].trim() === "") {
                              toast.error("Please provide a description");
                              return;
                            }
                            setImportentInfo((prev) => [
                              ...prev,
                              { title: "", description: [""] },
                            ]);
                          }}
                        >
                          +
                        </button>
                      </div>

                      <div id="important_info">
                        {importentInfo.map((item, index) => {
                          return (
                            <div
                              className="d-flex"
                              key={index}
                              style={{ alignItems: "center" }}
                            >
                              <div className="card_add_more col-md-11">
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="siteName"
                                      className="form-label"
                                    >
                                      {/* Title* */}
                                      Important information about your activity
                                    </label>
                                    <input
                                      name={`exp_title_${index}`}
                                      id={`exp_title_${index}`}
                                      type="text"
                                      className="form-control"
                                      autoComplete="false"
                                      value={item.title}
                                      onChange={(e) => {
                                        setImportentInfo((state) => {
                                          state[index].title = e.target.value;

                                          return JSON.parse(
                                            JSON.stringify(state)
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <label
                                  htmlFor="siteName"
                                  className="form-label ml-3"
                                >
                                  Description (Add important info that the
                                  customer have to be aware of E.g.: Bring ID ,
                                  Height restriction etc.. Add one at a time
                                  using the plus button.){" "}
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    {" "}
                                    *
                                  </span>
                                </label>
                                <div className="field-add-btn">
                                  <button
                                    type="button"
                                    className="btn btn-primary mb-3"
                                    onClick={() => {
                                      let lastEle =
                                        item.description[
                                        item.description.length - 1
                                        ];

                                      if (lastEle === "") {
                                        toast.error(
                                          "Please provide a description"
                                        );
                                        return;
                                      }
                                      setImportentInfo((state) => {
                                        const newState = [...state];
                                        newState[index] = {
                                          ...newState[index],
                                          description: [
                                            ...newState[index].description,
                                            "",
                                          ],
                                        };
                                        return newState;
                                      });
                                    }}
                                  >
                                    +
                                  </button>
                                </div>

                                {item.description.map((it, key) => {
                                  return (
                                    <div
                                      className="d-flex"
                                      key={key}
                                      style={{ alignItems: "center" }}
                                    >
                                      <div className="col-md-11">
                                        <div className="mb-3">
                                          <CKEditor
                                            className="editor-container"
                                            editor={ClassicEditor}
                                            data={it}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setImportentInfo((state) => {
                                                state[index].description[key] =
                                                  data;

                                                return JSON.parse(
                                                  JSON.stringify(state)
                                                );
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>

                                      {key !== 0 && (
                                        <button
                                          type="button"
                                          className="btn btn-danger ml-auto d-table"
                                          onClick={() =>
                                            setImportentInfo((state) => {
                                              state[index].description.splice(
                                                key,
                                                1
                                              );

                                              return JSON.parse(
                                                JSON.stringify(state)
                                              );
                                            })
                                          }
                                        >
                                          -
                                        </button>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>

                              {index !== 0 && (
                                <button
                                  type="button"
                                  className="btn btn-danger ml-auto d-table"
                                  onClick={() =>
                                    setImportentInfo((prev) => {
                                      let updated = [...prev];
                                      updated.splice(index, 1);
                                      return updated;
                                    })
                                  }
                                >
                                  -
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {errors.importentInfo && (
                          <span className="errorMessageText text-danger">
                            {errors.importentInfo}
                          </span>
                        )}
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <label
                        htmlFor=""
                        className="form-label"
                        style={{
                          marginLeft: "15px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Tour Places
                      </label>
                      <div className="field-add-btn">
                        <button
                          type="button"
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            let lastEle =
                              tourCoordinates[tourCoordinates.length - 1];

                            if (lastEle.location.trim() === "") {
                              toast.error(
                                "Please provide a activity location point name."
                              );
                              return;
                            }
                            if (lastEle.lat.trim() === "") {
                              toast.error(
                                "Please provide the latitude of the activity location point."
                              );
                              return;
                            }
                            if (lastEle.lng.trim() === "") {
                              toast.error(
                                "Please provide the latitude of the activity location point."
                              );
                              return;
                            }
                            settourCoordinates((prev) => [
                              ...prev,
                              { location: "", lat: "", lng: "" },
                            ]);
                          }}
                        >
                          +
                        </button>
                      </div>

                      {tourCoordinates?.map((item, index) => {
                        return (
                          <div
                            className="d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <div
                              className="card_add_more col-md-11"
                              key={index}
                            >
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label htmlFor="" className="form-label">
                                    {/* Location Name */}
                                    Add all locations that will be visited
                                    during this activity (Use the plus button to
                                    add them one at a time)
                                  </label>
                                  <CKEditor
                                    className="editor-container"
                                    editor={ClassicEditor}
                                    data={item.location}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      settourCoordinates((state) => {
                                        state[index].location = data;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 d-none">
                                <div className="mb-3">
                                  <label htmlFor="" className="form-label">
                                    Location Latitude
                                  </label>
                                  <input
                                    name={`location_latitude_${index}`}
                                    id={`location_latitude_${index}`}
                                    type="number"
                                    className="form-control"
                                    autoComplete="false"
                                    value={item.lat}
                                    onChange={(e) => {
                                      settourCoordinates((state) => {
                                        state[index].lat = e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 d-none">
                                <div className="mb-3">
                                  <label htmlFor="" className="form-label">
                                    Location Longitude
                                  </label>
                                  <input
                                    name={`location_longitude_${index}`}
                                    id={`location_longitude_${index}`}
                                    type="number"
                                    className="form-control"
                                    autoComplete="false"
                                    value={item.lng}
                                    onChange={(e) => {
                                      settourCoordinates((state) => {
                                        state[index].lng = e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {index !== 0 && (
                              <button
                                type="button"
                                className="btn btn-danger ml-auto d-table"
                                onClick={() =>
                                  settourCoordinates((prev) => {
                                    let updated = [...prev];
                                    updated.splice(index, 1);
                                    return updated;
                                  })
                                }
                              >
                                -
                              </button>
                            )}
                          </div>
                        );
                      })}

                      <div className="row" style={{ margin: "0.1rem" }}>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="topPriority"
                              className="form-label font-weight-bold"
                            >
                              Priority{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <input
                              name="topPriority"
                              id="topPriority"
                              type="number"
                              // min={0}
                              className="form-control"
                              value={activity.topPriority}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.topPriority && (
                            <span className="errorMessageText text-danger">
                              {errors.topPriority}
                            </span>
                          )}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="card">
                        <div className="card-body">
                          <button
                            type="button"
                            className="btn btn-primary ml-auto d-table "
                            onClick={() => navigate("/manageAdditionalStuff")}
                          >
                            +
                          </button>
                          {stuff &&
                            stuff?.map((item) => (
                              <div key={item._id}>
                                <label>
                                  <input
                                    type="checkbox"
                                    name={item.stuffName}
                                    checked={selectedItems?.some(
                                      (selectedItem) =>
                                        selectedItem === item._id
                                    )}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, item._id)
                                    }
                                  />
                                  {item.stuffName} - $ {item.stuffprice}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="card">
                        <div className="card-body">
                          <button
                            type="button"
                            className="btn btn-primary ml-auto d-table "
                            onClick={addNewEntry}
                          >
                            +
                          </button>
                          {importantQuestion.map((list, i) => {
                            return (
                              <div className="card my-3" key={i}>
                                <div className="card-body row">
                                  <div className="col-11">
                                    <TextInput
                                      label={
                                        "Provide your customers with the most frequently asked question and answers about your activity. E.g.: Is there a toilet on the bus?"
                                      }
                                      value={list.question}
                                      onChange={(e) => {
                                        let updated = [...importantQuestion];
                                        updated[i].question = e.target.value;
                                        setImportantQuestion(updated);
                                      }}
                                    />
                                    <TextInput
                                      label={
                                        "Answer: Yes , a toilet is a available on our busses.(Add as many Q&A as to limit the amount of times our team have to contact you to clear up simple questions saving everyone time.)"
                                      }
                                      value={list.answer}
                                      onChange={(e) => {
                                        let updated = [...importantQuestion];
                                        updated[i].answer = e.target.value;
                                        setImportantQuestion(updated);
                                      }}
                                    />
                                  </div>
                                  <div className="col-1 m-auto">
                                    {i !== 0 && (
                                      <button
                                        type="button"
                                        className="btn btn-danger ml-auto d-table"
                                        onClick={() => {
                                          let updated = [...importantQuestion];
                                          updated.splice(i, 1);
                                          setImportantQuestion(updated);
                                        }}
                                      >
                                        -
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <label
                        htmlFor=""
                        className="form-label"
                        style={{
                          marginLeft: "15px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Third Party Review
                      </label>
                      <div className="field-add-btn">
                        <label
                          htmlFor="siteName"
                          className="form-label"
                          style={{
                            marginRight: "15px",
                            fontSize: "15px",
                          }}
                        >
                          Add more review here :
                        </label>

                        <button
                          type="button"
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            if (thirdPartyReviewrating && thirdPartyReviewrating?.length) {
                              let lastEle =
                                thirdPartyReviewrating[thirdPartyReviewrating?.length - 1];

                              if (lastEle.onlineTravelAdvisorId.trim() === undefined) {
                                toast.error("Please select a travel advisor");
                                return;
                              }
                              if (!lastEle.GuideExperienceStar) {
                                toast.error("Please put guide experience star");
                                return;
                              }
                              if (!lastEle.GuideExperienceView) {
                                toast.error("Please put guide experience review.");
                                return;
                              }
                              if (!lastEle.OrganizationStar) {
                                toast.error("Please put organization star");
                                return;
                              }
                              if (!lastEle.OrganizationView) {
                                toast.error("Please put organization review.");
                                return;
                              }
                              if (!lastEle.ServiceStar) {
                                toast.error("Please put service star");
                                return;
                              }
                              if (!lastEle.ServiceView) {
                                toast.error("Please put service review.");
                                return;
                              }
                              if (!lastEle.ValueForMoneyStar) {
                                toast.error("Please put value for money star");
                                return;
                              }
                              if (!lastEle.ValueForMoneyView) {
                                toast.error("Please put value for money review.");
                                return;
                              }
                              filterTravelAgency();
                              setThirdPartyReviewRating((prev) => [
                                ...prev,
                                {
                                  travelAdvisorname: "",
                                  onlineTravelAdvisorId: undefined,
                                  GuideExperienceStar: null,
                                  GuideExperienceView: null,
                                  OrganizationStar: null,
                                  OrganizationView: null,
                                  ServiceStar: null,
                                  ServiceView: null,
                                  ValueForMoneyStar: null,
                                  ValueForMoneyView: null
                                },
                              ]);
                            }
                            else {
                              filterTravelAgency();
                              setThirdPartyReviewRating([
                                {
                                  travelAdvisorname: "",
                                  onlineTravelAdvisorId: undefined,
                                  GuideExperienceStar: null,
                                  GuideExperienceView: null,
                                  OrganizationStar: null,
                                  OrganizationView: null,
                                  ServiceStar: null,
                                  ServiceView: null,
                                  ValueForMoneyStar: null,
                                  ValueForMoneyView: null
                                },
                              ]);
                            }
                          }}
                        >
                          +
                        </button>
                      </div>

                      <div id="participant_types">
                        {thirdPartyReviewrating.map((item, index) => (
                          <div
                            className="d-flex"
                            key={index}
                            style={{ alignItems: "center" }}
                          >
                            <div className="card_add_more col-md-11 ">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`onlineTravelAdvisorId_${index}`}
                                      className="form-label"
                                    >
                                      Travel Advisor Name

                                    </label>
                                    <Select
                                      name={`onlineTravelAdvisorId_${index}`}
                                      id={`onlineTravelAdvisorId_${index}`}
                                      classNamePrefix="select"
                                      options={filterAgencyData.map((it) => {
                                        return {
                                          value: it?._id,
                                          label: it?.name,
                                        };
                                      })}
                                      value={{
                                        value: item?.onlineTravelAdvisorId,
                                        label: item?.travelAdvisorname,
                                      }}
                                      onChange={(selectedOption) => {
                                        setThirdPartyReviewRating((state) => {
                                          const newState = [...state];
                                          newState[index].onlineTravelAdvisorId = selectedOption.value;
                                          newState[index].travelAdvisorname = selectedOption.label;
                                          return newState;
                                        });
                                      }}
                                    />

                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <label
                                    htmlFor={`GuideExperienceStar${index}`}
                                    className="form-label"
                                  >
                                    Guide Experience Star
                                  </label>
                                  <input
                                    id={`GuideExperienceStar${index}`}
                                    name={`GuideExperienceStar${index}`}
                                    type="number"
                                    className="form-control"
                                    autoComplete="false"
                                    min={0}
                                    max={5}
                                    value={item.GuideExperienceStar}
                                    // placeholder="Give Star from 1 to 5"
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                      } else if (e.target.value > 5) {
                                        toast.error("You can give a rating from 0 to 5 stars.");
                                      }

                                      setThirdPartyReviewRating((state) => {
                                        state[index].GuideExperienceStar =
                                          e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label
                                    htmlFor={`GuideExperienceView${index}`}
                                    className="form-label"
                                  >
                                    Guide Experience view
                                  </label>
                                  <input
                                    id={`GuideExperienceView${index}`}
                                    name={`GuideExperienceView${index}`}
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    autoComplete="false"
                                    value={item.GuideExperienceView}
                                    max={5}
                                    // placeholder="Give No of view "
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                      }

                                      setThirdPartyReviewRating((state) => {
                                        state[index].GuideExperienceView = e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label
                                    htmlFor={`OrganizationStar${index}`}
                                    className="form-label"
                                  >
                                    Organization Star
                                  </label>
                                  <input
                                    id={`OrganizationStar${index}`}
                                    name={`OrganizationStar${index}`}
                                    type="number"
                                    className="form-control"
                                    autoComplete="false"
                                    min={0}
                                    max={5}
                                    value={item.OrganizationStar}
                                    // placeholder="Give Star from 1 to 5"
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                      } else if (e.target.value > 5) {
                                        toast.error("You can give a rating from 0 to 5 stars.");
                                      }

                                      setThirdPartyReviewRating((state) => {
                                        state[index].OrganizationStar =
                                          e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label
                                    htmlFor={`OrganizationView${index}`}
                                    className="form-label"
                                  >
                                    Organization view
                                  </label>
                                  <input
                                    id={`OrganizationView${index}`}
                                    name={`OrganizationView${index}`}
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    autoComplete="false"
                                    value={item.OrganizationView}
                                    max={5}
                                    // placeholder="Give No of view "
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                      }

                                      setThirdPartyReviewRating((state) => {
                                        state[index].OrganizationView = e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label
                                    htmlFor={`ServiceStar${index}`}
                                    className="form-label"
                                  >
                                    Service Star
                                  </label>
                                  <input
                                    id={`ServiceStar${index}`}
                                    name={`ServiceStar${index}`}
                                    type="number"
                                    className="form-control"
                                    autoComplete="false"
                                    min={0}
                                    max={5}
                                    value={item.ServiceStar}
                                    // placeholder="Give Star from 1 to 5"
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                      } else if (e.target.value > 5) {
                                        toast.error("You can give a rating from 0 to 5 stars.");
                                      }

                                      setThirdPartyReviewRating((state) => {
                                        state[index].ServiceStar =
                                          e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label
                                    htmlFor={`ServiceView${index}`}
                                    className="form-label"
                                  >
                                    Service view
                                  </label>
                                  <input
                                    id={`ServiceView${index}`}
                                    name={`ServiceView${index}`}
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    autoComplete="false"
                                    value={item.ServiceView}
                                    max={5}
                                    // placeholder="Give No of view "
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                      }

                                      setThirdPartyReviewRating((state) => {
                                        state[index].ServiceView = e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label
                                    htmlFor={`ValueForMoneyStar${index}`}
                                    className="form-label"
                                  >
                                    Value For Money Star
                                  </label>
                                  <input
                                    id={`ValueForMoneyStar${index}`}
                                    name={`ValueForMoneyStar${index}`}
                                    type="number"
                                    className="form-control"
                                    autoComplete="false"
                                    min={0}
                                    max={5}
                                    value={item.ValueForMoneyStar}
                                    // placeholder="Give Star from 1 to 5"
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                      } else if (e.target.value > 5) {
                                        toast.error("You can give a rating from 0 to 5 stars.");
                                      }

                                      setThirdPartyReviewRating((state) => {
                                        state[index].ValueForMoneyStar =
                                          e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label
                                    htmlFor={`ValueForMoneyView${index}`}
                                    className="form-label"
                                  >
                                    Value For Money view
                                  </label>
                                  <input
                                    id={`ValueForMoneyView${index}`}
                                    name={`ValueForMoneyView${index}`}
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    autoComplete="false"
                                    value={item.ValueForMoneyView}
                                    max={5}
                                    // placeholder="Give No of view "
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                      }

                                      setThirdPartyReviewRating((state) => {
                                        state[index].ValueForMoneyView = e.target.value;

                                        return JSON.parse(
                                          JSON.stringify(state)
                                        );
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {index !== 0 && (
                              <button
                                type="button"
                                className="btn btn-danger ml-auto d-table"
                                onClick={() => {
                                  // filterTravelAgency()
                                  setThirdPartyReviewRating((prev) => {
                                    let updated = [...prev];
                                    updated.splice(index, 1);
                                    return updated;
                                  })
                                }
                                }
                              >
                                -
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                      <div>
                        {errors.participentType && (
                          <span className="errorMessageText text-danger">
                            {errors.participentType}
                          </span>
                        )}
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="mt-2 btn btn-link d-table"
                  onClick={() => {
                    navigate("/manage-activity-drafts");
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={(e) => {
                    handleEditSubmit(true);
                  }}
                  className="mt-2 btn btn-secondary d-table"
                  type="button"
                >
                  Save As Draft
                </button>{" "}
                &nbsp; &nbsp;
                <button
                  className="mt-2 btn btn-primary d-table"
                  type="button"
                  onClick={(e) => {
                    handleEditSubmit(false);
                  }}
                >
                  Submit
                </button>
              </div>

              <br />
              <br />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditActivity;
