// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Utils
import {
  apiCallError,
} from "../../../utils/common-utils";

// Helpers
import { toast } from "react-toastify";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllPressReleases } from "../../../redux/adminSlice/pressSlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

const ManagePress = () => {
  // function AddPress() {
  const dispatch = useDispatch();
  const [submitting, setsubmitting] = useState(false);
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();

  // Component did mount
  useEffect(() => {
    dispatch(getAllPressReleases());
  }, []);

  // Redux stores
  const { press_releases } = useSelector((state) => state.pressSlice);
  console.log("press_releases", press_releases);

  const openModal = () => {
    setModal(true);
  };

  const deletePress = async (id) => {
    try {
      setsubmitting(true);
      let res = await JSON_API["adminDeletePress"]({ id: id });
      // console.log("Res", res);

      if (res.isSuccess) {
        dispatch(getAllPressReleases());
        setModal(false);
        // apiCallSuccess(res.data);
        toast.success("Press release deleted successfully.");
      } else {
        apiCallError(res?.error?.message, setError);
      }
      setsubmitting(false);
    } catch (error) {
      setsubmitting(false);
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Picture",
        field: "picture",
        sort: "asc",
        width: 100,
        height: 100,
      },
      {
        label: "Topic",
        field: "topic",
        sort: "asc",
        width: 100,
      },
      {
        label: "Subtopic",
        field: "subTopic",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      press_releases?.length > 0
        ? press_releases.map((item, idx) => {
            return {
              sl: idx + 1,
              picture: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <img
                      src={`${item.image}`}
                      alt=""
                      className="img-fluid"
                      width="100px"
                      height="100px"
                      style={{ borderRadius: "3px" }}
                    />
                  </div>
                </div>
              ),
              topic: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item.topic}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              subTopic: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item?.subTopic}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setdeleteId(press_releases[idx]?._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/edit-press/${item._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deletePress(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {submitting && <Loader />}

      <Table
        striped
        tableTitle="All Press Releases"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
  //   }
};

export default ManagePress;
