import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getAllActivities,
  getAvailabilityForAMonth,
  getActivityAvailabilityForADate,
} from "../../../redux/adminSlice/activitySlice";

import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import Calendar from "../../../Calendar/Calendar";

import "./styles.css";
import HomeService from "../../../services/api/HomeService";
import toast from "react-hot-toast";
import { format } from 'date-fns';

function ManageActivity() {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(0);
  const [itemId, setitemId] = useState("");
  const [activityTitle, setActivityTitle] = useState("");
  const [activityCode, setActivityCode] = useState("");
  const navigate = useNavigate();

  const { activities, slots_for_the_month, slots_for_a_date } = useSelector(
    (state) => state.adminActivityActionSlice
  );

  const [hoverDate, setHoverDate] = useState("");

  useEffect(() => {
    dispatch(getAllActivities());
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const deleteactivityAvailability = (id) =>{
    HomeService.deleteavailability(id)
    .then((res)=>{
      if (res && res.status) {
        toast.success('Deleted Successfully')
        setModal(false)
      }else{
        toast.error('Something Wrong')
      }
    })
    .catch((err)=>{
      toast.error(err.message)
    })
  }
  
  const filterData = () => {
    if (searchText !== "") {
      return activities.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return activities;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 50,
        },
        {
          label: "Merchant Code",
          field: "merchant_code",
          sort: "asc",
          width: 100,
        },
        {
          label: "Activity Code",
          field: "activity_code",
          sort: "asc",
          width: 100,
        },
        {
          label: "Merchant",
          field: "merchant_company",
          sort: "asc",
          width: 100,
        },
        {
          label: "Activity Name",
          field: "activity_name",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 130,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
            return {
              sl: idx + 1,
              merchant_code: <div>{item?.merchant?.marchentCode}</div>,
              activity_code: <div>{item?.productCode}</div>,
              merchant_company: <div>{item?.merchant?.companyName}</div>,
              activity_name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="zwidget-heading"
                        style={{ opaactivity: 1, textAlign: "left" }}
                      >
                        {item.activityTitle}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-primary"
                    onClick={() => {
                      setActivityTitle(item.activityTitle);
                      setActivityCode(item.productCode);
                      openModal();
                      setmodalToggleView(0);
                      setitemId(item?._id);
                      dispatch(
                        getAvailabilityForAMonth({
                          year: new Date().getFullYear(),
                          month: new Date().getMonth() + 1,
                          activityId: item?._id,
                        })
                      );
                    }}
                  >
                    <i class="fa-regular fa fa-eye"></i>
                  </button>
                  <button
                      title="Tour Availability"
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        navigate(`/activity-availability/${item?._id}`);
                      }}
                    >
                        <i class="fa fa-cogs" aria-hidden="true"></i>
                        
                      </button>

                  <button
                      title="Edit Activity"
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        navigate(`/edit-activity/${item?._id}`);
                      }}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </button>

                    <button
                        className='me-2 btn-icon btn-icon-only btn btn-outline-success'
                        onClick={() => {
                          navigate(`/edit-merchant/${item?.merchant?._id}`);
                        }}
                      >
                        <i class="fa fa-user-circle"></i>
                    </button>
                </div>
              ),
            };
          })
          : [],
    };
  }, [activities, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div
            className="modal-content"
            style={{ height: "32rem", overflowY: "auto" }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                {activityTitle} &nbsp;
                ({activityCode})
              </h5>
            </div>
            <div style={{ height: "100%", margin: "0", padding: "0" }}>
              <Calendar
                dateData={slots_for_the_month}
                onDateClick={(date) => {
                  setmodalToggleView(1);
                  dispatch(
                    getActivityAvailabilityForADate({
                      date,
                      activityId: itemId,
                    })
                  );
                }}
                onDateHover={(date) => {
                  setHoverDate(date);
                }
                }
                nextMonthClick={(data) =>
                  dispatch(
                    getAvailabilityForAMonth({
                      year: data?.year,
                      month: data?.month,
                      activityId: itemId,
                    })
                  )
                }
                prevMonthClick={(data) =>
                  dispatch(
                    getAvailabilityForAMonth({
                      year: data?.year,
                      month: data?.month,
                      activityId: itemId,
                    })
                  )
                }
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {activityTitle} &nbsp;
                ({activityCode})
              </h5>
            </div>
            <div className="modal-body">
              <table style={{width:"800px"}}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Remaining Seats</th>
                    <th>Start Time</th>
                    <th>Cutoff Time</th>
                    <th>Price/Person</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {slots_for_a_date.length > 0 ? (
                    slots_for_a_date.map((item, idx) => {
                      return (
                        <tr key={idx} style={{paddingBottom:"25px"}}>
                          <td>{format(new Date(item?.tourDate), 'dd/MM/yyyy')}</td>
                          <td>{item?.remeningUser}</td>
                          <td>{item?.time}</td>
                          <td>{item?.cutoffTime}</td>
                          <td>{item?.changePrice}</td>
                          <td>
                            <button
                              className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                              onClick={() =>
                                navigate(
                                  `/edit-activity-availability/${itemId}/${item?._id}?date=${item?.tourDate}&time_slot=${item?.time}`
                                )
                              }
                            >
                              <i className="fa-regular fa-pen-to-square"></i>
                            </button>
                            <button
                              className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                              onClick={() =>
                                deleteactivityAvailability(item?._id)
                              }
                            >
                              <i className="fa-solid fa-trash-can" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p>Currently there are no slots for this date.</p>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Tour Activities Availability"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
}

export default ManageActivity;
