// Hooks
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Layout from "../../../Layout";
import { Formik } from "formik";
import Table from "../../../Component/Table";

// Helpers
import * as Yup from "yup";
import { toast } from "react-toastify";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

let initialValues = {
  managmentPersonName: "",
  contactInformation: "",
  comapnyRegistration: "",
  VATRegistrationNo: "",
  address: "",
};

export default function AddLegalNotice() {
  const [content, setcontent] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const [key, setkey] = useState(Math.random());
  const [error, setError] = useState("");
  const mobileRegex =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; // /^[0-9]{15}$/;
  const vatRegex = /^[A-Za-z0-9 ]+$/;

  const legalNoticeSchema = Yup.object().shape({
    managmentPersonName: Yup.string().required(
      "Contact person name is required."
    ),
    contactInformation: Yup.string()
      .required("Contact number is required.")
      .matches(mobileRegex, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      })
      .min(10, "Please enter a mobile number between 10 to 15 digits")
      .max(15, "Please enter a mobile number between 10 to 15 digits"),
    comapnyRegistration: Yup.string().required(
      "Company registration number is required."
    ),
    VATRegistrationNo: Yup.string()
      .required("VAT registration number is required.")
      .matches(vatRegex, {
        message: "Please enter valid VAT Registration number.",
        excludeEmptyString: false,
      })
      .test(
        "len",
        "Please enter a 15 digit VAT number",
        (val) => val.length === 15
      ),
    address: Yup.string().required("Company address is required."),
  });

  const submitLegalNotice = async (values, resetFormFunc) => {
    // console.log(values);
    let bodyData = {
      managmentPersonName: values?.managmentPersonName,
      contactInformation: values?.contactInformation,
      comapnyRegistration: values?.comapnyRegistration,
      VATRegistrationNo: values?.VATRegistrationNo,
      address: values?.address,
    };

    try {
      let res = await JSON_API["adminAddLegalNotice"](bodyData);
      console.log("RES", res);

      if (res.isSuccess) {
        setkey(Math.random());
        resetFormFunc(initialValues);
        // apiCallSuccess(res?.data);
        toast.success("Legal notice added successfully.");
      } else {
        apiCallError(res?.error?.msg, setError);
      }
    } catch (err) {
      apiCallError(err.message, setError);
    }
  };


  const handleSubmit=async()=>{
    const bodyData={legalNotice:content}
    try {
      let res = await JSON_API["adminAddLegalNotice"](bodyData);
      console.log("RES", res);

      if (res.isSuccess) {
   
     
        toast.success("Legal notice added successfully.");
      } else {
        apiCallError(res?.error?.msg, setError);
      }
    } catch (err) {
      apiCallError(err.message, setError);
    }
  }

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
            <div className="main-card mb-3 card">
              <div className="card-body">
                <div className="card-title">Add Legal Notice</div>

                {/* <div className="row"> */}
                <CKEditor
                    className="editor-container"
                    editor={ClassicEditor}
                    data={content}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                      setcontent(data);
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                {/* </div> */}
          
                <button
                  className="mt-2 btn btn-primary ml-auto d-table"
                  type="submit"
                  onClick={(e) => handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>














      {/* <Form btntxt="Add" city={false} textarea={false} radio={false} inputFile={false} check={false} /> */}
      {/* <Formik
        key={key}
        initialValues={initialValues}
        validationSchema={legalNoticeSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { resetForm }) => {
          submitLegalNotice(values, resetForm);
        }}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <div component="div" className="TabsAnimation appear-done enter-done">
            <div className="main-card mb-3 card">
              <div className="card-body">
                <div className="card-title">Add Legal Notice</div>

                <div className="row">
                  
                </div>
                {/* <div className="row" style={{ margin: "0.1rem" }}>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="managmentPersonName"
                        className="form-label font-weight-bold"
                      >
                        Full name of the person*
                      </label>
                      <input
                        name="managmentPersonName"
                        id="managmentPersonName"
                        placeholder="For example, Walter Ray Smith"
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        value={values.managmentPersonName}
                        onChange={(e) =>
                          setFieldValue("managmentPersonName", e.target.value)
                        }
                      />
                    </div>
                    {errors.managmentPersonName && (
                      <span className="errorMessageText text-danger">
                        {errors.managmentPersonName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="contactInformation"
                        className="form-label font-weight-bold"
                      >
                        Contact number*
                      </label>
                      <input
                        name="contactInformation"
                        id="contactInformation"
                        placeholder="7866345235 or 03325512397"
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        value={values.contactInformation}
                        onChange={(e) =>
                          setFieldValue("contactInformation", e.target.value)
                        }
                      />
                    </div>
                    {errors.contactInformation && (
                      <span className="errorMessageText text-danger">
                        {errors.contactInformation}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="comapnyRegistration"
                        className="form-label font-weight-bold"
                      >
                        Company Regt.No.*
                      </label>
                      <input
                        name="comapnyRegistration"
                        id="comapnyRegistration"
                        // placeholder="E.g.,waltersmith@ymail.com"
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        value={values.comapnyRegistration}
                        onChange={(e) =>
                          setFieldValue("comapnyRegistration", e.target.value)
                        }
                      />
                    </div>
                    {errors.comapnyRegistration && (
                      <span className="errorMessageText text-danger">
                        {errors.comapnyRegistration}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="VATRegistrationNo"
                        className="form-label font-weight-bold"
                      >
                        VAT Registration No.*
                      </label>
                      <input
                        name="VATRegistrationNo"
                        id="VATRegistrationNo"
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        value={values.VATRegistrationNo}
                        onChange={(e) =>
                          setFieldValue("VATRegistrationNo", e.target.value)
                        }
                      />
                    </div>
                    {errors.VATRegistrationNo && (
                      <span className="errorMessageText text-danger">
                        {errors.VATRegistrationNo}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="address"
                      className="form-label font-weight-bold"
                    >
                      Company Address*
                    </label>
                    <textarea
                      name="address"
                      id="address"
                      className="form-control"
                      autoComplete="false"
                      value={values.address}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                    />
                  </div>
                  {errors.address && (
                    <span className="errorMessageText text-danger">
                      {errors.address}
                    </span>
                  )}
                </div> */}

                {/* <button
                  className="mt-2 btn btn-primary ml-auto d-table"
                  type="submit"
                  onClick={(e) => handleSubmit()}
                >
                  Submit
                </button>
              </div> */}
            {/* </div> */}
          {/* </div> */}
        {/* // )} */}
      {/* // </Formik> */} 

      {/* <Table
          striped
          tableTitle={"All Legal Notice Receivers"}
          data={tableData}
          modal={modal}
          setModal={setModal}
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
        /> */}
    </>
  );
}
