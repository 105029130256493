import { useState } from "react";
import { requestData } from "../services/api/apiHelpers";

function useActivityMethods() {
  const [tourModules, setTourModules] = useState([]);
  const [loadings,setLoadings] = useState(false)

  const fetchTourModulesAgainstMerchant = async (data) => {
    setLoadings(true)
    let result = await requestData("/admin/view-tourModule", "POST", data);
    if (result && result.status) {
      setTourModules(result.data);
      setLoadings(false)
    }
  };
  return {tourModules, fetchTourModulesAgainstMerchant, loadings};
}

export default useActivityMethods;
