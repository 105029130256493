import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import { getAllMerchantAdditions } from "../../../redux/adminSlice/activitySlice";
import Table from "../../../Component/Table";
import { Link } from 'react-router-dom';

function ActivityChangeRequests() {
  const dispatch = useDispatch();
  const [changedActivities, setchangedActivities] = useState([]);
  const [searchText, setsearchText] = useState("");
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const { merchant_additions } = useSelector((state) => state.adminActivityActionSlice);

  useEffect(() => {
    dispatch(getAllMerchants());
    dispatch(getAllMerchantAdditions());

  }, [dispatch]);

  useEffect(() => {
    setchangedActivities(
      merchant_additions.filter((item) => item?.isEdited && !item.rejectedStatus)
    );
  }, [merchant_additions])

  const filterData = useMemo(() => {
    if (changedActivities?.length && searchText !== "") {
      let updated = changedActivities.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
      return updated;
    }

    return changedActivities;
  }, [changedActivities, searchText]);

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Merchant Code",
        field: "merchant_code",
        sort: "asc",
        width: 100,
      },
      {
        label: "Tour Code",
        field: "tour_code",
        sort: "asc",
        width: 100,
      },
      {
        label: "Assigned Merchant Company",
        field: "assigned_merchant",
        sort: "asc",
        width: 100,
      },
      {
        label: "Tour Name",
        field: "activity_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 130,
      },
    ],
    rows:
      filterData?.length > 0
        ? filterData.map((item, idx) => {
          return {
            sl: idx + 1,
            assigned_merchant: (
              <div className="widget-content p-0" key={idx}>
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="zwidget-heading"
                      style={{ opaactivity: 1, textAlign: "center" }}
                    >
                      {merchants
                        .find((ele) => ele._id === item.addedByid)
                        ?.legalCompanyName?.charAt(0)
                        .toUpperCase() +
                        merchants
                          .find((ele) => ele._id === item.addedByid)
                          ?.legalCompanyName?.slice(1) 
                        }
                    </div>
                  </div>
                </div>
              </div>
            ),
            merchant_code: (
              <div className="widget-content p-0" key={idx}>
                {item.productCode}
              </div>
            ),
            tour_code: (
              <div className="widget-content p-0" key={idx}>
                {item.productCode}
              </div>
            ),
            activity_name: (
              <div className="widget-content p-0" key={idx}>
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="zwidget-heading"
                      style={{ opaactivity: 1, textAlign: "left" }}
                    >
                      {item.activityTitle}
                    </div>
                  </div>
                </div>
              </div>
            ),
            action: (
              <div>
                <Link to={`/product-change-details/${item._id}`} state={item}>
                  <button className="me-2 btn-icon btn-icon-only btn btn-outline-primary">
                    <i class="fa-regular fa fa-eye"></i>
                  </button>
                </Link>
              </div>
            ),
          };
        })
        : [],
  };

  return (
    <>
      <Table
        striped
        tableTitle="Product Change Requests"
        data={{
          columns: tableData?.columns,
          rows: tableData?.rows,
        }}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
}

export default ActivityChangeRequests;
