// Hooks
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";
import useImgUploader from "../../../../Hooks/use-img-uploader";
import Loader from "../../../../Component/Loader";
import { capitalizeFirstLetter } from "../../../../utils";

const initialValues = {
  name: "",
  logo: "",
};

const createErrors = {
  name: "",
  logo: "",
};
const config = {
  url: "/admin/activity-image-upload",
  propertyName: "data",
};

function AddAndManageActivityType() {
  const fileInputRef = useRef(null);
  const [activity, setactivity] = useState(initialValues);
  const navigate = useNavigate();
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setIsLoading] = useState(false);
  const { Img, imageHandler, imgComp, resetter } = useImgUploader(config);

  const handleChange = (event) => {
    event.preventDefault();
    let input = capitalizeFirstLetter(event);
    setactivity({ ...activity, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { name } = activity;

    if (name === "") {
      seterrors({ ...errors, name: "Activity name is required" });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isLoading) return;
    if (Img === "") return toast.error("Activity logo is required");

    if (handleValidation()) {
      setIsLoading(true);
      try {
        let res = await JSON_API["adminAddActivityType"]({
          ...activity,
          logo: Img,
        });

        if (res.isSuccess) {
          seterrors(createErrors);
          setactivity(initialValues);
          resetter();
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setIsLoading(false);
          ActivityApiCallSuccess(res.data);
          navigate("/manage-activity-type");
        } else {
          setIsLoading(false);
          ActivityApiCallError(res?.error?.message);
        }
      } catch (error) {
        setIsLoading(false);
        ActivityApiCallError(error.message);
      }
    }
  };

  const ActivityApiCallSuccess = (data) => {
    toast.success("Activity type added successfully!");
  };

  const ActivityApiCallError = (errorMsg) => {
    toast.error(errorMsg);
  };

  return (
    <>
      {isLoading && <Loader />}
      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Activity Type</div>
              {/* <div className="row"> */}
              <div className="">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name*
                  </label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={activity.name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.name && (
                  <span className="errorMessageText text-danger">
                    {errors.name}
                  </span>
                )}
              </div>
              <div className="">
                <div className="mb-3">
                  <label htmlFor="logo" className="form-label">
                    Activity logo*
                  </label>
                  <input
                    name="logo"
                    id="logo"
                    type="file"
                    className="form-control"
                    ref={fileInputRef}
                    onChange={imageHandler}
                  />
                </div>
                {imgComp}
              </div>
              {/* </div> */}

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddAndManageActivityType;
