import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";
import { getAllActivityTypes } from "../../../../redux/adminSlice/activitySlice";
import Loader from "../../../../Component/Loader";
import useImgUploader from "../../../../Hooks/use-img-uploader";
import { capitalizeFirstLetter } from "../../../../utils";

const initialValues = {
  name: "",
  logo: "",
  priority: 0,
};

const createErrors = {
  name: "",
  logo: "",
};
const config = {
  url: "/admin/activity-image-upload",
  propertyName: "data",
};

function EditActivityType() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [errors, seterrors] = useState(createErrors);
  const [activityEdit, setactivityEdit] = useState(initialValues);
  const [isLoading, setisLoading] = useState(false);
  const { Img, setImg, imageHandler, imgComp, imgLoading } =
    useImgUploader(config);
  const { activity_types } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const activityTypeData = activity_types.find(
    (item) => item?._id === params.id
  );
  useEffect(() => {
    dispatch(getAllActivityTypes());
    setactivityEdit({
      ...activityEdit,
      name: activityTypeData?.name,
      logo: activityTypeData?.logo,
      priority:activityTypeData?.priority||0
    });
    setImg(activityTypeData?.logo);
  }, [dispatch]);

  const handleEditChange = (event) => {
    event.preventDefault();

    let input = capitalizeFirstLetter(event);
    setactivityEdit({
      ...activityEdit,
      [event.target.name]: input,
    });
  };

  const handleEditValidation = () => {
    const { name } = activityEdit;

    if (name === "") {
      seterrors({ ...errors, name: "Activity name is required" });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    if (imgLoading) return;
    if (isLoading) return;

    let bodyData = { id: `${params.id}`, ...activityEdit };
    if (handleEditValidation()) {
      if (Img === "") return toast.error("Activity logo is required");
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditActivityType"]({
          ...bodyData,
          logo: Img,
        });

        if (res.isSuccess) {
          dispatch(getAllActivityTypes());
          setactivityEdit(initialValues);
          ActivityApiCallSuccess(res.data);
          setisLoading(false);
          navigate("/manage-activity-type");
        } else {
          setisLoading(false);
          ActivityApiCallError(res?.error?.message);
        }
      } catch (error) {
        setisLoading(false);
        ActivityApiCallError(error.message);
      }
    }
  };

  const ActivityApiCallSuccess = (data) => {
    toast.success("Activity type edited successfully!");
  };

  const ActivityApiCallError = (errorMsg) => {
    toast.error(errorMsg);
  };

  return (
    <>
      {isLoading && <Loader />}
      <form
        className=""
        onSubmit={(e) => handleEditSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Activity Type</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name*
                  </label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={activityEdit.name}
                    onChange={(e) => handleEditChange(e)}
                  />
                </div>
                {errors.name && (
                  <span className="errorMessageText text-danger">
                    {errors.name}
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="priority" className="form-label">
                    Priority*
                  </label>
                  <input
                    name="priority"
                    id="priority"
                    type="number"
                    className="form-control"
                    value={activityEdit.priority}
                    onChange={(e) => handleEditChange(e)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="logo" className="form-label">
                    Activity logo*
                  </label>
                  <input
                    name="logo"
                    id="logo"
                    type="file"
                    className="form-control"
                    ref={fileInputRef}
                    onChange={imageHandler}
                  />
                </div>
                {imgComp}
              </div>

              <button
                type="button"
                className="mt-2 btn btn-link"
                onClick={() => {
                  setactivityEdit(initialValues);
                  navigate("/manage-activity-type");
                }}
              >
                Cancel
              </button>
              <button type="submit" className="mt-2 btn btn-primary">
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditActivityType;
