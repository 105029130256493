import React from 'react'

export default function Footer() {
    return (
        <>
            <div className="app-footer">
                <div className="app-footer__inner">
                    <div className="app-footer-left">
                    </div>
                    <div className="app-footer-right">
                        <ul className="nav">
                        </ul>
                    </div>
                </div>
            </div>


        </>
    )
}
