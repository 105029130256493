// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";
import { countries } from "country-flags-svg";

// Utils
import {
  apiCallSuccess,
  apiCallError,
  checkFileDimension,
} from "../../../utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { fileUpload, requestData } from "../../../services/api/apiHelpers";

// Redux actions
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

// Component
import Loader from "../../../Component/Loader";
import Select from "react-select";

let createErrors = {
  name: "",
  picture: "",
  topPriority: "",
};

function AddCountry() {
  const dispatch = useDispatch();
  const [country, setcountry] = useState({
    name: "",
    flag: "",
    topPriority: 0,
  });
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [Img, setImg] = useState("");
  // Redux stores
  const { countres } = useSelector(state => state.countrySlice);

  useEffect(() => {
    dispatch(getAllcountres());
  }, []);

  const handleChange = (event) => {
    if (event.target.name === "topPriority") {
      setcountry({
        ...country,
        [event.target.name]: Number(event.target.value),
      });
    }

    setcountry({
      ...country,
      [event.target.name]: event.target.value,
    });
  };

  console.log("country data", country);

  const handleValidation = () => {
    const { name, topPriority } = country;

    if (name === "") {
      seterrors({ ...createErrors, name: "Country name is required." });

      return false;
    }

    if (Img === "") {
      seterrors({ ...createErrors, picture: "Picture is required" });

      return false;
    }

    if (topPriority === 0) {
      seterrors({
        ...createErrors,
        topPriority: "Please provide a priority number.",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(country);
    // if (imgLoading) return;
    // if (Img === "") {
    //   toast.error("Picture is required");
    // }

    // if (country.name === "") {
    //   toast.error("Please select a country");
    // } else if (country.topPriority === 0) {
    //   toast.error("Please enter priority number");
    // } else {
    if (handleValidation()) {
      let checkData = countres;
      let existStatus = false;
      if (
        checkData?.filter((item) => item?.name === country.name)
          ?.length > 0
      ) {
        existStatus = true;
      }

      if (existStatus) {
        return toast.error("Country already exists.");
      } else {
        try {
          setisLoading(true);
          let res = await JSON_API["addCountry"]({ ...country, image: Img });
          console.log("this response", res);

          if (res.isSuccess) {
            dispatch(getAllcountres());
            seterrors(createErrors);
            setcountry({ name: "", topPriority: 0, img: "" });
            setImg("");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            // apiCallSuccess(res.data);
            toast.success("Country added successfully!");
          } else {
            apiCallError(res?.error?.message, setError);
          }
          setisLoading(false);
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      }
    }
    // }
  };
  // console.log("edit info", newCountry);
  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    if (imgLoading) return;
    const x = Array.from(e.target.files)[0];
    setImg(URL.createObjectURL(x));
    let fileObj = await checkFileDimension(x);
    // console.log(fileObj);
    if (fileObj?.height > 720) {
      seterrors({
        ...errors,
        picture: "File height should be less than 720px",
      });

      return;
    }
    if (fileObj?.width > 1280) {
      seterrors({
        ...errors,
        picture: "File width should be less than 1280px",
      });

      return;
    }

    seterrors({ ...errors, picture: "" });
    const DATA = new FormData();
    DATA.append("image", e.target.files[0]);
    try {
      setImgLoading(true);
      let imgUpRes = await fileUpload(
        "/admin/upload-bannerImage",
        "POST",
        DATA
      );
      if (imgUpRes?.status) {
        setImg(imgUpRes?.data);
      } else {
        toast.error(
          "Something went wrong while uploading image! Please try after some time."
        );
      }
    } catch (error) {
      toast.error(
        "Something went wrong while uploading image! Please try after some time."
      );
    }
    setImgLoading(false);
  };

  return (
    <>
      {isLoading && <Loader />}

      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Country</div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="mb-3"
                    // style={{ display: "flex", paddingTop: "5px" }}
                  >
                    <label
                      htmlFor="name"
                      className="form-label font-weight-bold"
                      // style={{ margin: "0.5rem 0.5rem" }}
                    >
                      Name*
                    </label>
                    {/* <CountryDropdown
                    value={country}
                    onChange={(val) => setcountry(val)}
                  /> */}
                    <Select
                      name="name"
                      id="name"
                      classNamePrefix="select"
                      // ref={inputRef}
                      options={countries?.map((item, idx) => ({
                        value: item?.flag,
                        label: item?.name,
                      }))}
                      value={{
                        value: country.flag,
                        label: country.name,
                      }}
                      // isMulti
                      onChange={(val) => {
                        // console.log(val);
                        setcountry((prev) => {
                          // console.log(prev);
                          return {
                            ...prev,
                            flag: val?.value,
                            name: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.name && (
                    <span className="errorMessageText text-danger">
                      {errors.name}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                      Image*
                    </label>
                    <input
                      name="image"
                      id="image"
                      type="file"
                      accept={"image/*"}
                      ref={fileInputRef}
                      className="form-control"
                      // onClick={() => setuploadedImg('')}
                      // onChange={e => {
                      //   setImg(e.target.files[0]);
                      //   handleImg(e.target.files[0], setuploadedImg, errors, seterrors, 'picture');
                      // }}
                      onChange={imageHandler}
                    />
                  </div>
                  {/* {uploadedImg.length > 0 && (
                    <img alt="" style={{ height: '100px', width: '100px' }} src={uploadedImg} />
                  )} */}
                  {errors.picture && (
                    <span className="errorMessageText text-danger">
                      {errors.picture}
                    </span>
                  )}
                  <p className="text-primary mt-2">
                    {imgLoading && "Uploading please wait..."}
                  </p>
                  {Img !== "" && (
                    <>
                      <img
                        alt=""
                        style={{
                          height: "70px",
                          width: "120px",
                          borderRadius: "3px",
                        }}
                        src={Img}
                      ></img>
                    </>
                  )}
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="topPriority" className="font-weight-bold">
                      Priority*
                    </label>
                    <input
                      name="topPriority"
                      id="topPriority"
                      type="number"
                      min={0}
                      className="form-control"
                      value={country.topPriority}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.topPriority && (
                    <span className="errorMessageText text-danger">
                      {errors.topPriority}
                    </span>
                  )}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                style={{ margin: "5px auto" }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddCountry;
