// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Helpers
// import * as Yup from "yup";
import { toast } from "react-toastify";

// Utils
import { capitalizeFirstLetter, checkFileDimension } from "../../../utils";

// API functions
import { fileUpload, requestData } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSubadmins } from "../../../redux/adminSlice/subadminActionsSlice";

// Components
import Table from "../../../Component/Table";
// import { Formik } from "formik";
import Loader from "../../../Component/Loader";
import { update } from "firebase/database";

let createErrors = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  assignAccess: "",
  image: "",
};

let initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  assignAccess: "",
  image: "",
  // city: '',
  // state: '',
  // zip: '',
  // roll_description: '',
  // designation: '',
  // file: ''
};

let accessPrivileges = [
  { id: 1, name: "view", checked: false },
  { id: 2, name: "create", checked: false },
  { id: 3, name: "edit", checked: false },
  { id: 4, name: "delete", checked: false },
];

var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;
var emailReg = /^[a-z0-9]+@[a-z]+.[a-z]{2,3}$/;

function EditSubAdmin() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const imgInputRef = useRef(null);
  // const [key, setkey] = useState(Math.random());
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [subadminData, setsubadminData] = useState([]);
  const [modal, setModal] = useState(false);
  const [checkedAccess, setcheckedAccess] = useState([...accessPrivileges]);
  const [allAccessChecked, setallAccessChecked] = useState(false);
  const [editObject, seteditObject] = useState(initialValues);
  const [isLoading, setisLoading] = useState(false);
  const [uploadedImg, setuploadedImg] = useState("");
  const [statusLoaders, setStatusLoaders] = useState([]);

  const INITIAL_CHECKBOX_DATA = {
    // all: false,
    view: false,
    create: false,
    edit: false,
    delete: false,
  };
  const [checkboxData, setCheckboxData] = useState({
    ...INITIAL_CHECKBOX_DATA,
  });
  const [sendCheckboxData, setSendCheckboxData] = useState([]);
  // Redux stores
  const { subadmins } = useSelector((state) => state.adminSubadminActionSlice);
  // const { isLogdedIn } = useSelector((state) => state.adminProfileSlice);
  console.log("subadmins", subadmins);
  const subadmin = subadmins.find((item) => item?._id === params.id);

  let flags;
  let update;

  // Component did mount
  useEffect(() => {
    window.scrollTo(0, 0);
    // if (isLogdedIn) {
    dispatch(getAllSubadmins());
    setsubadminData(subadmins);
    setStatusLoaders(
      subadmins.map((item) => {
        return { loader: false };
      })
    );
    flags = subadmin?.assignAccess;
    setCheckboxData((prev) => {
      update = JSON.parse(JSON.stringify(prev));
      // update.all = flags?.length === 4 ? true : false;
      update.create = flags?.includes("create");
      update.edit = flags?.includes("edit");
      update.view = flags?.includes("view");
      update.delete = flags?.includes("delete");
      return { ...update };
    });
    setSendCheckboxData(flags);

    setuploadedImg("");
    seteditObject({
      ...editObject,
      firstName: subadmin?.firstName,
      lastName: subadmin?.lastName,
      email: subadmin?.email,
      password: subadmin?.password,
      assignAccess: subadmin?.assignAccess,
      image: subadmin?.image,
    });
    // }
  }, []);
  // Component did update
  useEffect(() => {
    setsubadminData(subadmins);
  }, [subadmins]);

  const handleEditChange = (event) => {
    let input;
    if (event.target.name === "password" || 
        event.target.name === "email") {
      input = event.target.value;
    } else {
      input = capitalizeFirstLetter(event);
    }

    seteditObject({ ...editObject, [event.target.name]: input });
  };

  const handleAllAccessChecked = () => {
    let accesses = [...accessPrivileges];
    for (let i = 0; i < accesses.length; i++) {
      accesses[i].checked = !allAccessChecked;
    }

    setallAccessChecked(!allAccessChecked);
    setcheckedAccess(accesses);
  };

  // console.log("allAccessChecked", allAccessChecked);
  // console.log("checkedAccess", checkedAccess);

  const handleCheckedChange = (event, index) => {
    // console.log("accessStatus", accessStatus);
    let accesses = checkedAccess.map((item, idx) => {
      if (index === idx) {
        // console.log(item);
        return {
          ...item,
          checked: !item.checked,
        };
      }

      return item;
    });

    let flag = true;
    for (let i = 0; i < accesses?.length; i++) {
      if (!accesses[i].checked) {
        flag = false;
      }
    }

    if (flag) {
      setallAccessChecked(true);
    } else {
      setallAccessChecked(false);
    }
    setcheckedAccess(accesses);
  };

  console.log("checkedAccess", checkedAccess);

  const handleImage = async (file) => {
    if (typeof file === "undefined") return;
    if (imageReg.test(file.type)) {
      setuploadedImg(URL.createObjectURL(file));
      let fileObj = await checkFileDimension(file);
      // console.log(fileObj);
      if (fileObj?.height > 720) {
        seterrors({
          ...errors,
          image: "File height should be less than 720px",
        });

        return;
      }
      if (fileObj?.width > 1280) {
        seterrors({
          ...errors,
          image: "File width should be less than 1280px",
        });

        return;
      }

      seterrors({ ...errors, image: "" });

      let DATA = new FormData();
      DATA.append("image", file);
      let imgUpRes = await fileUpload("/admin/uploadSubImage", "POST", DATA);
      console.log("res", imgUpRes);
      if (imgUpRes && imgUpRes.status) {
        seteditObject((prev) => ({ ...prev, image: imgUpRes.url }));
      } else {
        toast.error(imgUpRes.message);
      }
    } else {
      seterrors({ ...errors, image: "File is not an image" });
      setuploadedImg("");
    }
  };

  // const subadminInfoEditValidationSchema = Yup.object().shape({
  //   firstName: Yup.string().required("First name is required."),
  //   lastName: Yup.string().required("Last name is required."),
  //   email: Yup.string().required("Email is required."),
  //   password: Yup.string()
  //     .required("Password is required.")
  //     .min(8, "Password must be at least 8 characters"),
  //   assignAccess: Yup.array().min(1, "Atleast 1 priviledge is required"),
  //   profileImg: Yup.mixed()
  //     .test("fileType", "File should be a .png or .jpg", (value) => {
  //       // console.log("Yup file value", value);
  //       if (!value) return true; // attachment is optional
  //       return value?.type === "image/jpeg" || value?.type === "image/png";
  //     })
  //     .test("fileSize", "Image should not exceed more the 2.5MB", (value) => {
  //       if (!value) return true; // attachment is optional
  //       return value?.size <= 2560000;
  //     }),
  // });

  const handleEditValidation = () => {
    const { firstName, lastName, email, password } = editObject;

    console.log('Email Id', firstName, email);


    if (email === "") {
      seterrors({ ...createErrors, email: "Email is required." });

      return false;
    }
    if (!emailReg.test(email)) {
      seterrors({
        ...createErrors,
        email: "Please enter correct email format.",
      });

      return false;
    }
    if (password === "") {
      seterrors({
        ...createErrors,
        password: "Password is required.",
      });

      return false;
    }
    if (firstName === "") {
      seterrors({
        ...createErrors,
        firstName: "First name is required.",
      });

      return false;
    }

    if (lastName === "") {
      seterrors({
        ...createErrors,
        lastName: "Last name is required.",
      });

      return false;
    }

    return true;
  };

  // const editSubadminInfo = async (values, resetFormFunc) => {
  //   console.log("id", subadmins[itemIndex]?._id);
  //   let selectedRoles = checkedAccess.filter((item) => item.checked === true);
  //   console.log({ selectedRoles });
  //   let roleNames = [];
  //   selectedRoles.forEach((item) => roleNames.push(item.name));
  //   console.log({ roleNames });

  //   let bodyData = {
  //     id: `${subadmins[itemIndex]?._id}`,
  //     firstName: values?.firstName,
  //     lastName: values?.lastName,
  //     email: values?.email,
  //     password: values?.password,
  //     assignAccess: roleNames,
  //   };

  //   const DATA = new FormData();
  //   Object.keys(bodyData).forEach((key) => {
  //     DATA.append(key, bodyData[key]);
  //   });
  //   if (!values?.profileImg) {
  //     DATA.append("image", { name: "image.jpg", file: "" });
  //   } else {
  //     DATA.append("image", values?.profileImg);
  //   }
  //   DATA.append("id", `${subadmins[itemIndex]?._id}`);

  //   try {
  //     let res = await FormData_API["editSubadminInfo"](DATA);

  //     if (res.isSuccess) {
  //       dispatch(getAllSubadmins());
  //       setModal(false);
  //       seteditObject(initialValues);
  //       resetFormFunc(initialValues);
  //       setisLoading(false);
  //       SubadminApiCallSuccess(res?.data);
  //     } else {
  //       setisLoading(false);
  //       SubadminApiCallError(res?.error?.message);
  //     }
  //   } catch (err) {
  //     setisLoading(false);
  //     console.log("catch err", err.message);
  //   }
  // };
  const checkBoxHandler = (e) => {
    setCheckboxData((prev) => {
      let update = JSON.parse(JSON.stringify(prev));
      update[e.target.name] = e.target.checked;
      // if (sendCheckboxData?.length < 4) update.all = false;
      // else update.all = true;
      return { ...update };
    });

    if (e.target.checked) {
      setSendCheckboxData((prev) => {
        let update = JSON.parse(JSON.stringify(prev));
        update.push(e.target.name);
        return [...update];
      });
      return;
    }

    setSendCheckboxData((prev) => {
      let update = JSON.parse(JSON.stringify(prev));
      let x = update.findIndex((item) => item === e.target.name);
      update.splice(x, 1);
      return [...update];
    });
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    // let selectedRoles = checkedAccess.filter(item => item.checked === true);
    // console.log({ selectedRoles });
    // let roleNames = '';
    // selectedRoles.forEach(item => roleNames + item.name + ', '); // roleNames.push(item.name)
    // for (let i = 0; i < selectedRoles.length; i++) {
    //   roleNames += selectedRoles[i].name + ', ';
    // }
    // console.log({ roleNames });
    if (sendCheckboxData?.length === 0)
      return toast.error("Atleast one access priviledege must be selected");
    if (handleEditValidation()) {
      // if (roleNames === '') {
      //   seterrors({
      //     ...createErrors,
      //     assignAccess: 'Atleast 1 priviledge is required.',
      //   });
      // } else {
      let bodyData = {
        // id: `${subadminData[itemIndex]?._id}`,
        ...editObject,
        // assignAccess: roleNames.slice(0, -2),
        assignAccess: sendCheckboxData,
      };
      console.log("bodyData", bodyData);

      try {
        setisLoading(true);
        // let res = await JSON_API["editSubadminInfo"](bodyData);
        let res = await requestData(
          `/admin/update-member-profile/${params.id}`,
          "PUT",
          bodyData
        );

        // if (res.isSuccess) {
        if (res && res.status) {
          setModal(false);
          dispatch(getAllSubadmins());
          seteditObject(initialValues);
          if (imgInputRef.current) {
            imgInputRef.current.value = "";
          }
          setuploadedImg("");
          seterrors(createErrors);
          setisLoading(false);
          SubadminApiCallSuccess(res?.data);
          navigate("/manage-admin");
        } else {
          setisLoading(false);
          SubadminApiCallError(res?.error?.message);
        }
      } catch (error) {
        setisLoading(false);
        console.log("catch err", error.message);
      }
      // }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const SubadminApiCallSuccess = (data) => {
    // setkey(Math.random());
    checkedAccess.forEach((item) => (item.checked = false));
    toast.success("Admin edited successfully");
  };

  const SubadminApiCallError = (errorMsg) => {
    toast.error("Something went wrong.");
    setError(errorMsg);
  };


  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Admin</div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email*
                    </label>
                    <input
                      name="email"
                      id="email"
                      placeholder="E.g.,waltersmith@ymail.com"
                      type="email"
                      className="form-control"
                      autoComplete="false"
                      value={editObject.email}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.email && (
                    <span className="errorMessageText text-danger">
                      {errors.email}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password*
                    </label>
                    <input
                      name="password"
                      id="password"
                      placeholder="Your first name, e.g. Smith"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={editObject.password}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.password && (
                    <span className="errorMessageText text-danger">
                      {errors.password}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      First Name*
                    </label>
                    <input
                      name="firstName"
                      id="firstName"
                      placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={editObject.firstName}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.firstName && (
                    <span className="errorMessageText text-danger">
                      {errors.firstName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">
                      Last Name*
                    </label>
                    <input
                      name="lastName"
                      id="lastName"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={editObject.lastName}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.lastName && (
                    <span className="errorMessageText text-danger">
                      {errors.lastName}
                    </span>
                  )}
                </div>
              
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="bannerImg" className="form-label">
                      Access Priviledege*
                    </label>
                    <div className="d-flex px-2 mx-2">
                      {/* <div className="access-levels d-flex">
                            <input
                              type="checkbox"
                              name="all"
                              id="all"
                              checked={checkboxData?.all}
                              onChange={e => {
                                setCheckboxData(prev => {
                                  let update = JSON.parse(JSON.stringify(prev));
                                  update.all = e.target.checked;
                                  update.view = e.target.checked;
                                  update.edit = e.target.checked;
                                  update.create = e.target.checked;
                                  update.delete = e.target.checked;
                                  return { ...update };
                                });
                                if (e.target.checked) {
                                  setSendCheckboxData(['view', 'create', 'edit', 'delete']);
                                  return;
                                } else {
                                  setSendCheckboxData([]);
                                }
                              }}
                            />
                            <label htmlFor="all_priviledges">All</label>
                          </div> */}
                      {/* {checkedAccess.map((item, idx) => {
                            return (
                              <div className="access-levels d-flex">
                                <input
                                  type="checkbox"
                                  name="assignAccess"
                                  // checked={subadmins[itemIndex]?.assignAccess.includes(item.name)}
                                  checked={item?.checked}
                                  onChange={e => {
                                    handleCheckedChange(e, idx);
                                    // handleChange(checkedAccess);
                                  }}
                                />
                                <label>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</label>
                              </div>
                            );
                          })} */}
                      <div className="access-levels d-flex">
                        <input
                          type="checkbox"
                          name="view"
                          checked={checkboxData.view}
                          onChange={checkBoxHandler}
                        />
                        <label>View</label>
                      </div>
                      <div className="access-levels d-flex">
                        <input
                          type="checkbox"
                          name="create"
                          checked={checkboxData.create}
                          onChange={checkBoxHandler}
                        />
                        <label>Create</label>
                      </div>
                      <div className="access-levels d-flex">
                        <input
                          type="checkbox"
                          name="edit"
                          checked={checkboxData.edit}
                          onChange={checkBoxHandler}
                        />
                        <label>Edit</label>
                      </div>
                      <div className="access-levels d-flex">
                        <input
                          type="checkbox"
                          name="delete"
                          checked={checkboxData.delete}
                          onChange={checkBoxHandler}
                        />
                        <label>Delete</label>
                      </div>
                    </div>
                    {errors.assignAccess && (
                      <span className="errorMessageText text-danger">
                        {errors.assignAccess}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="profileImg" className="form-label">
                      Image*
                    </label>
                    <input
                      name="profileImg"
                      id="profileImg"
                      type="file"
                      accept={"image/*"}
                      ref={imgInputRef}
                      className="form-control"
                      onChange={(e) => {
                        // setimg(e.target.files[0]);
                        handleImage(e.target.files[0]);
                      }}
                    />
                    {uploadedImg.length > 0 ? (
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={uploadedImg}
                      />
                    ) : (
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={editObject.image}
                      />
                    )}
                    {errors && (
                      <span className="errorMessageText text-danger">
                        {errors.image}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="d-flex mt-2">
              <button
                type="button"
                className="mt-2 btn btn-link ml-auto d-table"
                onClick={() => {
                  setModal(false);
                  seteditObject(null);
                  setuploadedImg("");
                  if (imgInputRef.current) {
                    imgInputRef.current.value = "";
                  }
                  navigate('/manage-admin');
                }}
              >
                Cancel
              </button>
              <button
                className="mt-2 btn btn-primary  d-table"
                type="submit"
                onClick={(e) => handleEditSubmit(e)}
              >
                Submit
              </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditSubAdmin;
