// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import { apiCallError } from "../../../utils/common-utils";

// Helpers
import { toast } from "react-toastify";

import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";

let initialValues = {
  activityId: [],
  assignTo: "",
  assignName:""
};

let createErrors = {
  activityId: "",
  assignTo: "",
};

function AddAssignActivity() {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [activity, setactivity] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);

  // Component did mount
  useEffect(() => {
    dispatch(getAllActivities());
    dispatch(getAllMerchants());
  }, []);

  // Redux stores
  const { activities } = useSelector((state) => state.adminActivityActionSlice);
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);

  const handleValidation = () => {
    const { activityId, assignTo } = activity;

    if (activityId.length === 0) {
      seterrors({
        ...createErrors,
        activityId: "Atleast 1 activity is required.",
      });

      return false;
    }
    if (assignTo === "") {
      seterrors({
        ...createErrors,
        assignTo: "Merchant is required",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["adminAssignActivity"](activity);

        if (res.isSuccess) {
          seterrors(createErrors);
          setactivity(initialValues);
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          setisLoading(false);
          toast.success('Activity assigned to merchant successfully!');
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Assign Activities</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="activityId" className="form-label">
                    Activities*
                  </label>
                  <Select
                      name="activityId"
                      id="activityId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={activities.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      value={activity.activityId.map((item) => {
                        let find = activities.find((it) => it._id === item);
                        return {
                          value: find?._id,
                          label: find?.activityTitle,
                        };
                      })}
                      isMulti
                      onChange={(val) => {
                        setactivity((prev) => {
                          return {
                            ...prev,
                            activityId: val.map((item) => item.value),
                          };
                        });
                      }}
                    />
                </div>
                {errors.activityId && (
                  <span className="errorMessageText text-danger">
                    {errors.activityId}
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="assignTo" className="form-label">
                    Merchants*
                  </label>
                  {/* <select
                    name="assignTo"
                    id="assignTo"
                    className="form-control form-select"
                    // value={activity.assignTo}
                    ref={inputRef}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="" key={0}>
                      Select
                    </option>
                    {merchants?.map((item, idx) => {
                      return (
                        <>
                          <option key={idx + 1} value={`${item?._id}`}>
                            {item?.firstName.charAt(0).toUpperCase() +
                              item?.firstName.slice(1) +
                              " " +
                              item?.lastName.charAt(0).toUpperCase() +
                              item?.lastName.slice(1)}
                          </option>
                        </>
                      );
                    })}
                  </select> */}
                  <Select
                      name="assignTo"
                      id="assignTo"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={merchants.map((item, idx) => ({
                        value: item?._id,
                        label: item?.firstName.charAt(0).toUpperCase() +
                          item?.firstName.slice(1) +
                          " " +
                          item?.lastName.charAt(0).toUpperCase() +
                          item?.lastName.slice(1),
                      }))}
                      
                      
                      onChange={(val) => {
                        
                        setactivity((prev) => {
                          return {
                            ...prev,
                            assignTo:val?.value,
                            assignName:val?.label
                          };
                        });                        
                      }}
                    />
                </div>
                {errors.assignTo && (
                  <span className="errorMessageText text-danger">
                    {errors.assignTo}
                  </span>
                )}
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={() => {}}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddAssignActivity;
