// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Utils
import { apiCallError } from "../../../utils/common-utils";

// Helpers
import { toast } from "react-toastify";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import { getProfileDetails } from "../../../redux/authSlice/adminProfileSlice";
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import Select from "react-select";

function ManageActivity() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [allActivities, setallActivities] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [statusLoaders, setStatusLoaders] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [productType, setProductType] = useState([
    {
      label: "Bookable Products",
      value: "bookable_products",
    },
    {
      label: "Expired Product",
      value: "expire_product",
    },
  ]);
  const [selectedproductType, setSelectedProduct] =
    useState("bookable_products");
  const navigate = useNavigate();
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const { activities } = useSelector((state) => state.adminActivityActionSlice);

  useEffect(() => {
    dispatch(getAllMerchants());
    dispatch(getProfileDetails());
    dispatch(getAllActivities(selectedproductType));
    setStatusLoaders(activities?.map(() => ({ loader: false })));

    let filterActivity = activities?.filter((item) => item?.isApproval === true)
    setallActivities(filterActivity)


  }, [selectedproductType]);

  useEffect(() => {

    let filterActivity = activities?.filter((item) => item?.isApproval === true)
    setallActivities(filterActivity)

  }, [activities]);

  const openModal = () => {
    setModal(true);
  };

  const handleActivityStatus = async (id, index) => {
    try {
      setStatusLoaders((prev) => {
        let update = JSON.parse(JSON.stringify(prev));
        update[index].loader = true;
        return [...update];
      });
      let res = await JSON_API["adminSetActivityStatus"]({ id: id });

      if (res.isSuccess) {
        dispatch(getAllActivities());
        toast.success("Activity visibility changed.");
      } else {
        apiCallError(
          "Could not disable activity. Refresh page and try again.",
          setError
        );
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
    setStatusLoaders((prev) => {
      let update = JSON.parse(JSON.stringify(prev));
      update[index].loader = false;
      return [...update];
    });
  };
  const handleActivityTranding = async (id, status) => {

    setisLoading(true)
    try {
      const data = { isTranding: status }
      let res = await JSON_API["setActivitytrandingStatus"]({ id: id, data: data });
      if (res.isSuccess) {

        dispatch(getAllActivities());
        toast.success("Activity Updated sucsessfully");
        setisLoading(false)
      } else {
        setisLoading(false)
        toast.error("server Error")
      }
    } catch (error) {
      setisLoading(false)
      toast.error("server Error")
    }

  };

  const deleteActivity = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["adminDeleteActivity"]({ id: id });
      if (res.isSuccess) {
        dispatch(getAllActivities());
        setModal(false);
        setisLoading(false);
        toast.success("Activity deleted successfully.");
      } else {
        setisLoading(false);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message, setError);
    }
  };

  const filterData = useMemo(() => {
    if (allActivities?.length && searchText != "") {
      let updated = allActivities.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
      return updated;
    }

    return allActivities;
  }, [allActivities, searchText]);

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 50,
        },
        {
          label: "Merchant Code",
          field: "merchant_code",
          sort: "asc",
          width: 100,
        },
        {
          label: "Merchant Company Name",
          field: "assigned_merchant",
          sort: "asc",
          width: 100,
        },
        {
          label: "Tour Code",
          field: "tour_code",
          sort: "asc",
          width: 100,
        },
        {
          label: "Activity Type",
          field: "activity_type",
          sort: "asc",
          width: 100,
        },

        {
          label: "Tour Name",
          field: "tour_name",
          sort: "asc",
          width: 100,
        },
        {
          label: "View Product",
          field: "info",
          sort: "asc",
          width: 100,
        },
        {
          label: "Max. Capacity",
          field: "max_cap",
          sort: "asc",
          width: 100,
        },
        {
          label: "Approval Status",
          field: "approval_status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Status",
          field: "activity_status",
          sort: "asc",
          width: 100,
        },

        {
          label: "Availability",
          field: "Availability",
          sort: "asc",
          width: 100,
        },
        {
          label: "Trending",
          field: "tranding_status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 130,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
            console.log("asdgfhagshfg", item)
            return {
              sl: idx + 1,
              activity_type: (
                <div>
                  {item?.activitytypeName}
                </div>
              ),
              booking_status: (
                <div>
                </div>
              ),
              merchant_code: (
                <div style={{ textAlign: "center" }}>
                  {
                    merchants.find((ele) => ele._id == item.addedByid)?.marchentCode
                  }
                </div>
              ),
              approval_status: (
                <div style={{ textAlign: "center" }}>
                  <i className={item?.isApproval ? 'fa-solid fa-check' : 'fa-solid fa-xmark'} style={{ color: item?.isApproval ? "#46b616" : '#cf2507' }} />
                  <div style={{ color: item?.isApproval ? "#46b616" : "#cf2507" }}>
                    {item?.isApproval ? 'Approved' : 'Not Approved'}
                  </div>
                </div>
              ),
              assigned_merchant: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        style={{ textAlign: "center" }}
                      >
                        {merchants
                          .find((ele) => ele._id == item.addedByid)
                          ?.legalCompanyName?.charAt(0)
                          .toUpperCase() +
                          merchants
                            .find((ele) => ele._id == item.addedByid)
                            ?.legalCompanyName?.slice(1)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ),
              tour_code: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      {item.productCode}
                    </div>
                  </div>
                </div>
              ),
              tour_name: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      {item?.activityTitle}
                    </div>
                  </div>
                </div>
              ),
              price: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2" style={{ opaactivity: 1, textAlign: "center" }}>
                      {item?.activityActualPrice}
                    </div>
                  </div>
                </div>
              ),
              info: (
                <div className="single_info">
                  <i class="fa-solid fa-circle-info" onClick={(e) => {
                    const url = `https://www.nowthingstodo.com/activity/${item?._id}/${item?.slug}`;
                    // const url = `http://localhost:3000/activity/${item?._id}/${item?.slug}`;
                    window.open(url, '_blank');
                  }}></i>
                </div>
              ),
              max_cap: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2" style={{ opaactivity: 1, textAlign: "center" }}>

                      {item?.tourPerson}
                    </div>
                  </div>
                </div>
              ),
              activity_status: (
                <div>
                  {!statusLoaders[idx]?.loader ? (
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked={item?.status}
                        onChange={() => handleActivityStatus(item?._id, idx)}
                      />
                    </div>
                  ) : (
                    <div
                      className="spinner-grow spinner-grow-sm text-primary"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>{" "}
                    </div>
                  )}
                </div>
              ),

              Availability: (
                <div>
                  <div className="form-check form-switch" style={{ display: item?.thirdPartyId ? "none" : "block" }}>
                    <button
                      onClick={() => {
                        navigate(`/ManageAvailability/${item?._id}`);
                      }}
                    >   <i class="fa fa-sliders" aria-hidden="true"></i> </button>

                  </div>
                </div>
              ),
              tranding_status: (

                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={item?.isTranding}
                    onChange={() => handleActivityTranding(item?._id, !item?.isTranding)}
                  />
                </div>

              ),

              action: (
                <div>
                  <button
                    title="Delete Activity"
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setdeleteId(item?._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    title="Edit Activity"
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/edit-activity/${item?._id}`);
                    }}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <button
                    title="Activity Review"
                    className="me-2 btn-icon btn-icon-only btn btn-outline-primary"
                    onClick={() => {
                      navigate(`/viewReviewAndRating/${item?._id}`);
                    }}
                  >
                    <i class="fa-regular fa fa-eye"></i>
                  </button>
                </div>
              ),
            };
          })
          : [],
    };
  }, [filterData, statusLoaders]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information? </span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => deleteActivity(deleteId)}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="card-title">Tour Management</div>
            <div className="row" style={{ padding: "1rem" }}>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="filter"
                    className="form-label font-weight-bold"
                  >
                    Filter By Tour Type
                  </label>
                  <Select
                    name="filter"
                    id="filter"
                    classNamePrefix="select"
                    options={productType}
                    value={[
                      {
                        label: selectedproductType,
                        value: selectedproductType,
                      },
                    ]}
                    onChange={(val) => {
                      setSelectedProduct(val.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Table
        striped
        tableTitle="Tour List"
        data={{
          columns: tableData?.columns,
          rows: tableData?.rows,
        }}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        searching={true}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
}

export default ManageActivity;
