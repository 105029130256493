import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  terms: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllTerms = createAsyncThunk(
  "get_all_terms",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/viewTermsCondition",
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const TermsSlice = createSlice({
  name: "terms_actions",
  initialState,
  reducers: {
    clearTermsState: (state) => {
      state.status = "idle";
      state.terms = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTerms.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllTerms.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.terms = payload.data;
      })
      .addCase(getAllTerms.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearTermsState } = TermsSlice.actions;

export default TermsSlice.reducer;
