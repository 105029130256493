import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../Component/Table";
import { toast } from "react-toastify";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { getAllContacts } from "../../../redux/adminSlice/contactsSlice";
import Loader from "../../../Component/Loader";
import { capitalizeFirstLetter } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

let initialValues = {
  id: "",
  topic: "",
  topicId: "",
  questions: "",
  description: "",
};
const EditQuestion = () => {
  const dispatch = useDispatch();
  const [selectedQue, setSelectedQue] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const { contacts } = useSelector((state) => state.contactsSlice);

  const questionData = contacts.find((item) => item?._id === params.id);
  console.log("contact to edit", questionData);
  useEffect(() => {
    dispatch(getAllContacts());
    setSelectedQue((prev) => {
      let update = JSON.parse(JSON.stringify(prev));
      update.id = questionData?._id;
      update.topic = questionData?.topic;
      update.topicId = questionData?.topicId;
      update.questions = questionData?.questions;
      update.description = questionData?.description;
      return { ...update };
    });
  }, [dispatch]);

  // @ Submit Handler
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let res = await JSON_API["editQuestion"]({ ...selectedQue });
      if (res.isSuccess) {
        dispatch(getAllContacts());
        toast.success("Question updated successfully");
        setSelectedQue(initialValues);
        navigate("/manage-question");
      } else toast.error(res?.message || "Something went wrong");
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className="card p-4">
        <h4
          className="pb-4 font-weight-bold card-title"
          style={{ fontSize: "16px" }}
        >
          Edit Question
        </h4>
        <form onSubmit={submitHandler}>
          <div className="mb-3">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label font-weight-bold"
            >
              Topic
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={selectedQue.topic}
              disabled={true}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="exampleInputPassword1"
              className="form-label font-weight-bold"
            >
              Question
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputPassword1"
              value={selectedQue.questions}
              onChange={(e) =>
                setSelectedQue((prev) => {
                  let update = JSON.parse(JSON.stringify(prev));
                  update.questions = capitalizeFirstLetter(e);
                  return { ...update };
                })
              }
            />
          </div>
          {/* <div className="mb-3">
            <label htmlFor="Faq" className="form-label font-weight-bold">
              Description *
            </label>
            <CKEditor
              className="editor-container"
              editor={ClassicEditor}
              data={questionData.desc}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                // setDescription(data);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </div> */}
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label font-weight-bold">Description</label>
              <textarea
                name="description"
                id="description"
                type="text"
                className="form-control"
                autoComplete="false"
                required={true}
                value={selectedQue.description}
                onChange={(e) =>
                  setSelectedQue((prev) => {
                    let update = JSON.parse(JSON.stringify(prev));
                    update.description = e.target.value;

                    return { ...update };
                  })
                }
              />
            </div>
          </div>
          <button
            type="submit"
            className="mt-2 btn btn-primary  d-table font-weight-bold"
            style={{ fontSize: "14px" }}
          >
            Save Changes
          </button>
        </form>
      </div>
    </>
  );
  //   }
};

export default EditQuestion;
