
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter, apiCallError } from "../../../utils";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { getAllAbouts } from "../../../redux/adminSlice/aboutusSlice";
import Loader from "../../../Component/Loader";

let initialValues = {
  topic: "",
  description: "",
  subTopic: [],
};

const createErrors = {
  topic: "",
  description: "",
  subTopic: "",
  image: "",
};

var imageReg = /[.](gif|jpg|jpeg|tiff|png|webp)$/i;

const EditAboutUs = () => {
  const dispatch = useDispatch();
  const [banner, setbanner] = useState(initialValues);
  const [subtopic, setsubtopic] = useState([
    {
      id: uuidv4(),
      title: "",
      description: "",
      image: "",
    },
  ]);
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [uploadedImg,setuploadedImg]=useState('');
  const navigate = useNavigate();
  const params = useParams();

  const { abouts } = useSelector((state) => state.adminAboutsActionSlice);

  const aboutData = abouts.find((item) => item?._id === params.id);
  useEffect(() => {
    dispatch(getAllAbouts());
    setbanner(aboutData);
    setsubtopic(
      aboutData?.subTopic?.map((item) => {
        return {
          id: uuidv4(),
          title: item?.title,
          description: item?.description,
          image: item?.image,
        };
      })
    );
  }, [dispatch]);

  const uploadImg = async (picture) => {
    let imgData = new FormData();
    imgData.append("image", picture);
    try {
      setImgLoading(true);
      let imgRes = await fileUpload("/admin/aboutUsImage", "POST", imgData);
      if (imgRes && imgRes.url) {
        setImgLoading(false);
        return imgRes;
      } else {
        setImgLoading(false);
        return null;
      }
    } catch (error) {
      setImgLoading(false);
      return null;
    }
  };
  const handleChange = async (event) => {
    event.preventDefault();

    if (event.target.name === "image") {
      if (event.target.files.length === 0) return;
      if (imgLoading) return;

      const i = Array.from(event.target.files)[0];
      const img = await uploadImg(i);
      if (img?.url !== "") {
        setbanner((prev) => {
          let update = JSON.parse(JSON.stringify(prev));
          update.image = img?.url;
          return { ...update };
        });
      } else {
        return toast.error("Error occurred while uploading image, Try Again!");
      }

      return;
    }
    let input;

    if (event.target.name !== "description") {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }

    setbanner({ ...banner, [event.target.name]: input });
  };

  const imageHandle = async (file, index) => {
    if (typeof file === "undefined") return;
    if (imageReg.test(file.type)) {
      setuploadedImg(URL.createObjectURL(file));
      let DATA = new FormData();
      DATA.append("image", file);
      let imgUpRes = await fileUpload("/admin/aboutUsImage", "POST", DATA);
      if (imgUpRes && imgUpRes.status) {
        setsubtopic((prev) => {
          let updated = [...prev];
          updated[index].image = imgUpRes.url;
          return JSON.parse(JSON.stringify(updated));
        });
      } else {
        toast.error(imgUpRes.message);
      }
    }
  };

  const handleValidation = () => {
    const { topic, description } = banner;

    if (topic === "") {
      seterrors({ ...errors, topic: "Topic is required" });

      return false;
    }
    if (description === "") {
      seterrors({ ...errors, description: "Description is required."});

      return false;
    }
    // if (description.length < 50) {
    //   seterrors({
    //     ...errors,
    //     description: "description should atleast be 50 characters",
    //   });

    //   return false;
    // }
    // let sub_topic = true;
    // for (let i = 0; i < subtopic.length; i++) {
    //   // Out of all the participant type input, atleast one has all the field filled up
    //   if (
    //     subtopic[i].title !== "" &&
    //     subtopic[i].description !== "" &&
    //     subtopic[i].image !== ""
    //   ) {
    //     sub_topic = false;
    //   }
    // }
    // if (sub_topic) {
    //   seterrors({
    //     ...createErrors,
    //     subTopic: "Atleast 1 subtopic is required.",
    //   });

    //   return false;
    // }
    // for (let i = 0; i < subtopic.length; i++) {
    //   // Out of all the participant type input, atleast one has all the field filled up
    //   if (subtopic[i].title !== "" && subtopic[i].description !== "") {
    //     sub_topic = false;
    //   }
    // }
    // if (sub_topic) {
    //   seterrors((prev) => {
    //     prev.image.push("Image is required.");

    //     return JSON.parse(JSON.stringify(prev));
    //   });

    //   return false;
    // }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    if (handleValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditAbouts"]({
          id: params.id,
          ...banner,
          subTopic: subtopic,
        });
        if (res.isSuccess) {
          dispatch(getAllAbouts());
          seterrors(createErrors);
          setbanner(initialValues);
          setsubtopic([
            {
              id: uuidv4(),
              title: "",
              description: "",
              image: "",
            },
          ]);
          toast.success("About us info edited successfully!");
          navigate("/manage-aboutUs");
        } else {
          apiCallError(res?.error?.msg);
        }

        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <form
        className=""
        onSubmit={(e) => handleEditSubmit(e)}
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit "About Us" Details</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="topic" className="form-label font-weight-bold">
                    Topic*
                  </label>
                  <input
                    name="topic"
                    id="topic"
                    type="text"
                    value={banner.topic}
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    className="form-label font-weight-bold"
                    htmlFor="description"
                  >
                    Description*
                  </label>
                  <textarea
                    name="description"
                    className="form-control"
                    id="description"
                    aria-describedby="emailHelp"
                    autoComplete="off"
                    onChange={(e) => handleChange(e)}
                    value={banner.description}
                    required
                  />
                </div>
                {errors.description && (
                  <span className="errorMessageText text-danger">
                    {errors.description}
                  </span>
                )}
              </div>
              <label
                htmlFor=""
                className="form-label"
                style={{
                  marginLeft: "15px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Subtopic
              </label>

              {subtopic.map((item, index) => (
                <div
                  className="d-flex"
                  key={item.id}
                  style={{ alignItems: "center" }}
                >
                  <div className="card_add_more col-md-11">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor={`title_${index}`}
                          className="form-label"
                        >
                          Title
                        </label>
                        <input
                          name={`title_${index}`}
                          id={`title_${index}`}
                          type="text"
                          className="form-control"
                          autoComplete="false"
                          value={item.title}
                          onChange={(e) => {
                            setsubtopic((prev) => {
                              prev[index].title = capitalizeFirstLetter(e);

                              return JSON.parse(JSON.stringify(prev));
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor={`desc_${index}`} className="form-label">
                          Description
                        </label>
                        <textarea
                          name={`desc_${index}`}
                          id={`desc_${index}`}
                          className="form-control"
                          autoComplete="false"
                          value={item.description}
                          onChange={(e) => {
                            setsubtopic((prev) => {
                              prev[index].description = e?.target?.value;

                              return JSON.parse(JSON.stringify(prev));
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor={`img_${index}`} className="form-label">
                          Image
                        </label>
                        <input
                          name={`img_${index}`}
                          id={`img_${index}`}
                          type="file"
                          accept="image/*"
                          className="form-control"
                          onChange={(e) => {
                            imageHandle(e?.target?.files[0], index);
                          }}
                        />
                      </div>
                    </div>
                    {uploadedImg.length > 0 ? (
                    <img
                      style={{ height: "100px", width: "100px",marginLeft:'1rem' }}
                      src={uploadedImg}
                      alt="AboutUs"
                    />
                  ) : (
                    <img
                      style={{ height: "100px", width: "100px",marginLeft:'1rem' }}
                      src={item.image}
                      alt="editAboutUs"
                    />
                  )}
                  </div>

                  {subtopic.length - 1 === index ? (
                    <button
                      type="button"
                      className="btn btn-primary ml-auto d-table"
                      onClick={
                        () => {
                          if (item.title.trim() === "") {
                            toast.error("Please fill up title");
                            return;
                          }
                          if (item.description.trim() === "") {
                            toast.error("Please fill up description");
                            return;
                          }
                          if (item.image.trim() === "") {
                            toast.error("Please add image");
                            return;
                          }
                          setsubtopic((prev) => {
                            let updated = [...prev];
                            let data = [
                              ...updated,
                              {
                                id: uuidv4(),
                                title: "",
                                description: "",
                                image: "",
                              },
                            ];
                            return JSON.parse(JSON.stringify(data));
                          });
                        }
                      }
                    >
                      +
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-danger ml-auto d-table"
                      onClick={() => {
                        let update = [...subtopic];
                        update.splice(index, 1);
                        setsubtopic(update);
                      }}
                    >
                      -
                    </button>
                  )}
                </div>
              ))}
              <div>
                {errors.subTopic && (
                  <span className="errorMessageText text-danger">
                    {errors.subTopic}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex m-2">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  navigate("/manage-aboutUs");
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="mt-2 btn btn-primary  d-table"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditAboutUs;
