// Hooks
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";
import {reactLocalStorage} from 'reactjs-localstorage';

// API functions
import { JSON_API } from "../services/api/jsonApiCallWithInterceptor";

// Components
import Slider from "react-slick";

// Assets
import bannerimage from "../images/citynights.8df3ac24685306a68061.jpg";

// Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let regInitValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  cnf_password: "",
  image: "",
};

let createErrors = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  cnf_password: "",
  image: "",
};

export default function Registration() {
  const navigate = useNavigate();
  const [user, setuser] = useState(regInitValues);
  const [errors, seterrors] = useState(createErrors);

  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const handleChange = (event) => {
    event.preventDefault();
    setuser({ ...user, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const { firstName, lastName, email, password, cnf_password } = user;

    if (firstName === "") {
      seterrors({ ...createErrors, firstName: "First name is required." });

      return false;
    }
    if (firstName.length < 2) {
      seterrors({ ...createErrors, firstName: "Please enter a  valid first name." });

      return false;
    }
    if (lastName === "") {
      seterrors({ ...createErrors, lastName: "First name is required." });

      return false;
    }
    if (lastName.length < 2) {
      seterrors({ ...createErrors, lastName: "Please enter a  valid last name." });

      return false;
    }
    if (email === "") {
      seterrors({ ...createErrors, email: "Email is required." });

      return false;
    }
    if (new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}").test(email) === false) {
      seterrors({
        ...createErrors,
        email: "Please provide email in correct format",
      });

      return false;
    }
    if (password === "") {
      seterrors({ ...createErrors, password: "Password is required." });

      return false;
    }
    if (password.length < 8) {
      seterrors({
        ...createErrors,
        password: "Password should be more than 8 characters.",
      });

      return false;
    }
    if (password !== cnf_password) {
      seterrors({
        ...createErrors,
        cnf_password: "Confirmed password does not match password.",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      let res = await JSON_API["adminSignup"](user);

      if (res.isSuccess) {
        setuser(regInitValues);
        toast.success("User successfully registered");
        reactLocalStorage.set("user_email", res.data.data.email);
        reactLocalStorage.set("userType", "Admin");
        reactLocalStorage.set("Authorization", res.data.data.token);
        navigate("/");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <div className="h-100 g-0 row">
        <div className="d-none d-lg-block col-lg-4">
          <div className="slider-light">
            <Slider {...settings}>
              <div
                data-index={0}
                className="slick-slide"
                tabIndex={-1}
                aria-hidden="true"
                style={{
                  outline: "none",
                  width: 455,
                  position: "relative",
                  left: 0,
                  opacity: 0,
                  transition: "opacity 500ms ease 0s, visibility 500ms ease 0s",
                }}
              >
                <div>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate"
                    tabIndex={-1}
                    style={{ width: "100%", display: "inline-block" }}
                  >
                    <div
                      className="slide-img-bg blink waitingForConnection "
                      style={{ backgroundImage: `url('${bannerimage}')` }}
                    />
                    <div className="slider-content">
                      <h3>Perfect Balance</h3>
                      <p>
                        ArchitectUI is like a dream. Some think it's too good to
                        be true! Extensive collection of unified React Boostrap
                        Components and Elements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-index={1}
                className="slick-slide slick-active slick-current"
                tabIndex={-1}
                aria-hidden="false"
                style={{
                  outline: "none",
                  width: 455,
                  position: "relative",
                  left: "-455px",
                  opacity: 1,
                  transition: "opacity 500ms ease 0s, visibility 500ms ease 0s",
                }}
              >
                <div>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark"
                    tabIndex={-1}
                    style={{ width: "100%", display: "inline-block" }}
                  >
                    <div
                      className="slide-img-bg blink waitingForConnection2"
                      style={{ backgroundImage: `url('${bannerimage}')` }}
                    />
                    <div className="slider-content">
                      <h3>Scalable, Modular, Consistent</h3>
                      <p>
                        Easily exclude the components you don't require.
                        Lightweight, consistent Bootstrap based styles across
                        all elements and components
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-index={2}
                className="slick-slide"
                tabIndex={-1}
                aria-hidden="true"
                style={{
                  outline: "none",
                  width: 455,
                  position: "relative",
                  left: "-910px",
                  opacity: 0,
                  transition: "opacity 500ms ease 0s, visibility 500ms ease 0s",
                }}
              >
                <div>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning"
                    tabIndex={-1}
                    style={{ width: "100%", display: "inline-block" }}
                  >
                    <div
                      className="slide-img-bg blink waitingForConnection3 "
                      style={{ backgroundImage: `url('${bannerimage}')` }}
                    />
                    <div className="slider-content">
                      <h3>Complex, but lightweight</h3>
                      <p>
                        We've included a lot of components that cover almost all
                        use cases for any type of application.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
          <div className="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9 rightCntlogin">
            <div className="app-logo" />
            <h4 className="mb-0">
              <span>Create your account.</span>
            </h4>
            <h6 className="mt-3">
              Have an account?{" "}
              <Link to="/login" className="text-primary">
                Login here.
              </Link>
            </h6>
            <div className="divider row" />
            <div>
              <form className="" onSubmit={(e) => handleSubmit(e)}>
                <div
                  className="row row-form-fields"
                  style={{ margin: "0 0.1rem" }}
                >
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        name="firstName"
                        id="firstName"
                        type="text"
                        className="form-control"
                        value={user.firstName}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {errors.firstName && (
                      <span className="errorMessageText text-danger">
                        {errors.firstName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">
                        First Name
                      </label>
                      <input
                        name="lastName"
                        id="lastName"
                        type="text"
                        className="form-control"
                        value={user.lastName}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {errors.lastName && (
                      <span className="errorMessageText text-danger">
                        {errors.lastName}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="exampleEmail" className="form-label">
                      Email
                    </label>
                    <input
                      name="email"
                      id="exampleEmail"
                      placeholder="Email here..."
                      type="text"
                      className="form-control"
                      value={user.email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.email && (
                    <span className="errorMessageText text-danger">
                      {errors.email}
                    </span>
                  )}
                </div>

                <div
                  className="row row-form-fields"
                  style={{ margin: "0 0.1rem" }}
                >
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="examplePassword" className="form-label">
                        Password
                      </label>
                      <input
                        name="password"
                        id="examplePassword"
                        placeholder="Password here..."
                        type="password"
                        className="form-control"
                        value={user.password}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {errors.password && (
                      <span className="errorMessageText text-danger">
                        {errors.password}
                      </span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="examplePassword" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        name="cnf_password"
                        id="cnf_password"
                        placeholder="Confirm password here..."
                        type="password"
                        className="form-control"
                        value={user.cnf_password}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {errors.cnf_password && (
                      <span className="errorMessageText text-danger">
                        {errors.cnf_password}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                      Profile Image
                    </label>
                    <input
                      name="image"
                      id="image"
                      type="file"
                      className="form-control"
                      // onChange={(e) => setimg(e.target.files[0])}
                    />
                  </div>
                </div>
                <div className="divider row" />
                <div className="d-flex align-items-center">
                  <div className="ms-auto">
                    <button className="btn btn-primary btn-lg" type="submit">
                      Register
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
