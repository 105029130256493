import { reactLocalStorage } from "reactjs-localstorage";
// const BASE_URL = "http://34.201.127.230:8025/api/v1/"; // live
// const BASE_URL="http://127.0.0.1:8025/api/v1/"
const BASE_URL="https://api.nowthingstodo.com/api/v1/" // local
//  const ALLOW_ORIGIN = "34.201.127.230:8025"; // live

const ALLOW_ORIGIN="https://api.nowthingstodo.com";

function get(endpoint, params) {
  return requestData(endpoint, params);
}

function post(endpoint, params) {
  return requestData(endpoint, params, "POST");
}

function put(endpoint, params) {
  return requestData(endpoint, params, "PUT");
}

function deletemethod(endpoint, params) {
  return requestData(endpoint, params, "DELETE");
}

async function requestData(url, data = {}, method = "GET") {
  let xhr = new XMLHttpRequest();
  xhr.open(method, BASE_URL + url);
  if (checkingAuth()) xhr.setRequestHeader("authorization", checkingAuth());
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  xhr.setRequestHeader("userType", 'Admin');

  return new Promise((resolve, reject) => {
    if (method === "GET") {
      xhr.send();
    } else {
      xhr.send(JSON.stringify(data));
    }
    xhr.onload = () => {
      let response = JSON.parse(xhr.response);
      if (xhr.status === 200 || xhr.status === 400 || xhr.status === 404) {

        resolve(response);
      }
      else {
        reject(response)
      }
    };
  });
}

async function fileUpload(url, file, callback) {
  var xhr = new XMLHttpRequest();
  xhr.upload.onprogress = function (event) {
    let percent = (event.loaded * 100) / event.total;
    callback(percent);
  };

  xhr.onloadend = function () {
    if (xhr.status === 200) {
      // console.log("Success");
    } else {
      // console.log("error");
    }
  };

  xhr.open("post", BASE_URL + url);
  if (checkingAuth()) xhr.setRequestHeader("Authorization", checkingAuth());
  xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  return new Promise((resolve, reject) => {
    xhr.send(file);
    xhr.onload = () => {
      let response = JSON.parse(xhr.response);
      resolve(response);
    };
  });
}

function checkingAuth() {
  let token = reactLocalStorage.get("Authorization");
  if (token) {
    return token;
  }
  return false;
}

const httpClient = {
  requestData,
  fileUpload,
  get,
  post,
  put,
  deletemethod,
};

export default httpClient;
