import React from 'react'
import { Outlet } from 'react-router-dom';

// Components
import Header from "./header";
import { Sidebar } from "./sidebar";
import Footer from "./footer";

function Layout(props) {
  return (
    <div className="app-container app-theme-white fixed-header fixed-sidebar fixed-footer">
          <Header />
          <div className="app-main d-flex">
            <Sidebar />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <Outlet />
              </div>
            </div>
          </div>
          <Footer />
        </div>
  )
}

export default Layout;