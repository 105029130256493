import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import { getAllVisitedUser } from "../../../redux/adminSlice/visitedUser";
import ViewVisitorDetails from "../../../Component/Modal/ViewVisitorDetails";
import "../../../Component/Modal/Visitormodal.css"
import moment from "moment";

const VisitedUser = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchText, setsearchText] = useState("");
  const { visitedUsers, isLoading } = useSelector((state) => state.VisitedUserSlice);

  useEffect(() => {
    dispatch(getAllVisitedUser());
  }, [dispatch]);

  const openModal = (user) => {
    setSelectedUser(user);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setSelectedUser(null);
  };

  const filterData = () => {
    if (searchText !== "") {
      return visitedUsers.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return visitedUsers;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        { label: "SL", field: "sl", sort: "asc", width: 270 },
        { label: "User Email", field: "email", sort: "asc", width: 150 },
        { label: "Total Visited Count", field: "count", sort: "asc", width: 150 },
        { label: "Action", field: "action", sort: "asc", width: 150 },
      ],
      rows: filterData()?.length > 0
        ? filterData().map((user, idx) => ({
          sl: idx + 1,
          email: <div>{user?.email}</div>,
          count: <div>{user?.countForCartVisit}</div>,
          action: (
            <button
              key={idx}
              className="btn btn-outline-primary"
              onClick={() => openModal(user)} // Pass user data to openModal
            >
              <i className="fa-solid fa-eye" />
            </button>
          ),
        }))
        : [],
    };
  }, [visitedUsers, searchText]);

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle="All Visited Users"
        data={tableData}
        onSearch={(val) => setsearchText(val)}
      />

      <ViewVisitorDetails
        isOpen={modal}
        onClose={closeModal}
      >
        <div>
          <div className="visit_header_main">
            <h5>User Details</h5>
            <hr />
          </div>
          <p>Email: {selectedUser?.email}</p>
          <p>Visited Count: {selectedUser?.countForCartVisit}</p>
          <div>
            {selectedUser?.visitedDateTime?.map((item) => {
              return(
                <div className="date_time_main">
                  <p>Date: {moment(item?.date).format("YYYY-MM-DD")}</p>
                  <p>Time: {item?.time}</p>
                </div>
              )
            })
            }
          </div>
        </div>
      </ViewVisitorDetails>
    </>
  );
};

export default VisitedUser;
