import React from "react";
import "./showEdit.css";
import TextInput from "../Component/TextInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function ShowEditProduct({ tempData, setupdateModal, stuff, iamFrom }) {
  console.log(tempData, iamFrom, "aaaaaaaaaa");

  return (
    <div className="showEdit">
      <div className="innerDiv">
        <span
          onClick={() => {
            setupdateModal(false);
          }}
          style={{ fontSize: "19px", float: "right", cursor: "pointer" }}
        >
          &#10006;
        </span>
        <h1>Updated Data</h1>

        {(() => {
          switch (iamFrom) {
            case "participantType":
              return tempData?.participentType ? (
                <>
                  {" "}
                  {tempData?.participentType.map((item, index) => (
                    <div
                      className="d-flex"
                      key={index}
                      style={{ alignItems: "center" }}
                    >
                      <div className="card_add_more col-md-11">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              htmlFor={`pertype_${index}`}
                              className="form-label"
                            >
                              Type Of Participant
                            </label>
                            <select
                              disabled
                              name={`pertype_${index}`}
                              id={`pertype_${index}`}
                              className="form-control form-select"
                              value={item.pertype}
                            >
                              <option value={item.pertype} key={0}>
                                {item.pertype}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div style={{ backgroundColor: "yellow" }}></div>
                        <div className="col-md-12">
                          <label
                            htmlFor={`age_${index}`}
                            className="form-label"
                          >
                            Age Range
                          </label>
                          <input
                            disabled
                            id={`age_${index}`}
                            name={`age_${index}`}
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={item.age}
                          />
                          <div style={{ backgroundColor: "yellow" }}></div>
                        </div>

                        <div className="col-md-12">
                          <label htmlFor="" className="form-label">
                            Price
                          </label>
                          <input
                            disabled
                            id={`price_per_person_${index}`}
                            name={`price_per_person_${index}`}
                            type="number"
                            min={0}
                            className="form-control"
                            autoComplete="false"
                            value={item.price}
                          />
                          <div style={{ backgroundColor: "yellow" }}></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <h1 className="noDataFound">No Data found</h1>
              );
            case "activitydetails":
              return (
                <>
                  tempData.tourActivity&&
                  {tempData.tourActivity?.map((item, index) => {
                    return (
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <div className="card_add_more col-md-11" key={index}>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor={`tour_term_${index}`}
                                className="form-label"
                              >
                                {/* Topic */}
                                Short main activity description/keywords (Eg:
                                Rome: Colosseum, Palatine Hill and Roman Forum
                                Guided Tour)
                              </label>
                              <input
                                disabled
                                name={`tour_term_${index}`}
                                id={`tour_term_${index}`}
                                type="text"
                                className="form-control"
                                autoComplete="false"
                                value={item.term}
                                // onChange={(e) => {
                                //   settourActivityData((state) => {
                                //     console.log(state);
                                //     state[index].term = e.target.value;

                                //     return JSON.parse(JSON.stringify(state));
                                //   });
                                // }}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="mb-3">
                              <label htmlFor="siteName" className="form-label">
                                {/* Description */}
                                Description (Short description of the main
                                points/sites visited and keywords to attract
                                attention to your product E.g: Visit the
                                Colosseum, the largest amphitheater in the Roman
                                world, the Palatine Hill, the oldest part of the
                                city, and the Roman Forum, the beating heart of
                                ancient Rome, with an expert guide.)
                              </label>
                              <CKEditor
                                className="editor-container"
                                editor={ClassicEditor}
                                data={item.description}
                                onReady={(editor) => {
                                  editor.enableReadOnlyMode("customReadOnly");
                                }}
                                onBlur={(event, editor) => {
                                  console.log("Blur.", editor);
                                }}
                                onFocus={(event, editor) => {
                                  console.log("Focus.", editor);
                                }}
                              />
                            </div>
                            {/* <div style={{ backgroundColor: "yellow" }}>
                                      {activity?.tourActivity?.[0]?.description !==
                                      tempData?.tourActivity?.[0]?.description ? (
                                        <p>
                                          {tempData?.tourActivity?.[0]?.description}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div> */}
                          </div>
                        </div>

                        {/* {tourActivityData.length - 1 == index ? (
                          <button
                            type="button"
                            className="btn btn-primary ml-auto d-table"
                            onClick={() => {
                              settourActivityData((state) => [
                                ...state,
                                { term: "", description: "" },
                              ]);
                            }}
                          >
                            +
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-danger ml-auto d-table"
                            onClick={() => {
                              settourActivityData((state) => {
                                let updated = [...state];
                                updated.splice(index, 1);
                                return updated;
                                // state.splice(index, 1);
                                // return [...state]
                              });
                            }}
                          >
                            -
                          </button>
                        )} */}
                      </div>
                    );
                  })}
                </>
              );

            case "experience":
              return (
                tempData?.information && (
                  <>
                    {tempData?.information?.map((item, index) => {
                      return (
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11" key={index}>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="siteName"
                                  className="form-label"
                                >
                                  {/* Experience Title */}
                                  {item.title === "Highlights"
                                    ? "Add the highlights of this activity one at a time by using the plus box (Keep it short and precise)"
                                    : item.title === "Full description"
                                    ? "Write a full description including everything that is included and will be seen/experience on this activity. Keep the description in correct order as the customer will see/experience this activity. This is your main description/selling point so make it stand out with at least 1000 characters"
                                    : item.title === "Includes"
                                    ? "Describe everything that is included in the tour price (E.g.: Entrance fees , bottle of water , transport. Use the plus button to add one at a time)"
                                    : item.title === "Excludes"
                                    ? "Describe everything that is excluded in the tour price (E.g.: Entrance fees , bottle of water , transport. Use the plus button to add one at a time)"
                                    : " Experience Title"}
                                </label>
                                <input
                                  disabled={
                                    item.title === "Highlights" ||
                                    item.title === "Full description" ||
                                    item.title === "Includes" ||
                                    item.title === "Not suitable for"
                                  }
                                  name={`exp_title_${index}`}
                                  id={`exp_title_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.title}
                                  // onChange={(e) => {
                                  //   setExperienceDetails((state) => {
                                  //     state[index].title = e.target.value;

                                  //     return JSON.parse(JSON.stringify(state));
                                  //   });
                                  // }}
                                />
                              </div>
                              {/* {errors.siteName && (
                            <span className="errorMessageText text-danger">
                              {errors.siteName}
                            </span>
                          )} */}
                            </div>

                            {item.desc.map((it, key) => {
                              return (
                                <div
                                  className="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-md-11" key={key}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="siteName"
                                        className="form-label"
                                        style={{ marginLeft: "18px" }}
                                      >
                                        {/* Experience Details */}
                                        Details{" "}
                                        {item?.title !== "Not suitable for" && (
                                          <span
                                            style={{
                                              fontSize: "20px",
                                              fontWeight: "bold",
                                              color: "red",
                                            }}
                                          >
                                            {" "}
                                            *
                                          </span>
                                        )}
                                      </label>
                                      {/* <textarea
                                        disabled
                                        name={`exp_desc_${index}`}
                                        id={`exp_desc_${index}`}
                                        className="form-control"
                                        autoComplete="false"
                                        value={it}
                                        // onChange={(e) => {
                                        //   setExperienceDetails((state) => {
                                        //     state[index].desc[key] = e.target.value;

                                        //     return JSON.parse(JSON.stringify(state));
                                        //   });
                                        // }}
                                      /> */}
                                      <CKEditor
                                        className="editor-container"
                                        editor={ClassicEditor}
                                        data={it}
                                        onReady={(editor) => {
                                          editor.enableReadOnlyMode(
                                            "customReadOnly"
                                          );
                                        }}
                                        onBlur={(event, editor) => {
                                          console.log("Blur.", editor);
                                        }}
                                        onFocus={(event, editor) => {
                                          console.log("Focus.", editor);
                                        }}
                                      />
                                    </div>
                                    {/* {activity?.information.map((info, ind) => {
                                      if (item?.title === info?.title)
                                        return (
                                          <div
                                            style={{
                                              backgroundColor: "yellow",
                                            }}
                                          >
                                            {item?.desc[0] !==
                                            tempData?.information[ind]
                                              ?.desc[0] ? (
                                              <p>
                                                {
                                                  tempData?.information[ind]
                                                    ?.desc[0]
                                                }
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        );
                                    })} */}
                                  </div>

                                  {/* {item.desc.length - 1 == key ? (
                            <button
                              type="button"
                              className="btn btn-primary ml-auto d-table"
                              onClick={() => {
                                setExperienceDetails((state) => {
                                  state[index].desc.push("");

                                  return JSON.parse(JSON.stringify(state));
                                });
                              }}
                              // onClick={() => {
                              //   setExperienceDetails((state) => [
                              //     ...state[index].desc,
                              //     ""
                              //   ]);
                              // }}
                            >
                              +
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-danger ml-auto d-table"
                              onClick={() => {
                                setExperienceDetails((state) => {
                                  state[index].desc.splice(key, 1);

                                  return JSON.parse(JSON.stringify(state));
                                });
                              }}
                            >
                              -
                            </button>
                          )} */}
                                </div>
                              );
                            })}
                          </div>

                          {/* {experienceDetails.length - 1 == index ? (
                    <button
                      type="button"
                      className="btn btn-primary ml-auto d-table"
                      // onClick={() => {
                      //   setExperienceDetails((state) => {
                      //     state.push({ title: "", desc: [""] });

                      //     return JSON.parse(JSON.stringify(state));
                      //   });
                      // }}
                      onClick={() => {
                        setExperienceDetails((state) => [
                          ...state,
                          { title: "", desc: [""] },
                        ]);
                      }}
                    >
                      +
                    </button>
                  ) : item.title !== "Highlights" &&
                    item.title !== "Full description" &&
                    item.title !== "Includes" &&
                    item.title !== "Not suitable for" ? (
                    <button
                      type="button"
                      className="btn btn-danger ml-auto d-table"
                      // onClick={() => {
                      //   setExperienceDetails((state) => {
                      //     state.splice(index, 1);

                      //     return JSON.parse(JSON.stringify(state));
                      //   });
                      // }}
                      onClick={() => {
                        setExperienceDetails((state) => {
                          let updated = [...state];
                          updated.splice(index, 1);
                          return updated;
                          // state.splice(index, 1);
                          // return [...state]
                        });
                      }}
                    >
                      -
                    </button>
                  ) : (
                    ""
                  )} */}
                        </div>
                      );
                    })}
                  </>
                )
              );
            case "meetingPoint":
              return (
                <>
                  {tempData?.meetingPoint?.map((item, index) => {
                    return (
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <div className="card_add_more col-md-11" key={index}>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label htmlFor="" className="form-label">
                                Meeting Point Description
                              </label>
                              {/* <textarea
                                disabled
                                name={`meeting_point_desc_${index}`}
                                id={`meeting_point_desc_${index}`}
                                className="form-control"
                                autoComplete="false"
                                value={item.desc}
                                // onChange={(e) => {
                                //   setmeetingPointData((state) => {
                                //     state[index].desc = e.target.value;

                                //     return JSON.parse(JSON.stringify(state));
                                //   });
                                // }}
                              /> */}

                              <CKEditor
                                className="editor-container"
                                editor={ClassicEditor}
                                data={item.desc}
                                onReady={(editor) => {
                                  editor.enableReadOnlyMode("customReadOnly");
                                }}
                                onBlur={(event, editor) => {
                                  console.log("Blur.", editor);
                                }}
                                onFocus={(event, editor) => {
                                  console.log("Focus.", editor);
                                }}
                              />
                            </div>
                            {/* <div style={{ backgroundColor: "yellow" }}>
                                          {activity?.meetingPoint?.[0]?.desc !==
                                          tempData?.meetingPoint?.[0]?.desc ? (
                                            <p>{tempData?.meetingPoint?.[0]?.desc}</p>
                                          ) : (
                                            ""
                                          )}
                                        </div> */}
                          </div>

                          <div className="col-md-12">
                            <div className="mb-3">
                              <label htmlFor="siteName" className="form-label">
                                Meeting Point Link
                              </label>
                              <input
                                disabled
                                name={`meeting_point_link_${index}`}
                                id={`meeting_point_link_${index}`}
                                type="text"
                                className="form-control"
                                autoComplete="false"
                                value={item.link}
                                // onChange={(e) => {
                                //   setmeetingPointData((state) => {
                                //     state[index].link = e.target.value;

                                //     return JSON.parse(JSON.stringify(state));
                                //   });
                                // }}
                              />
                            </div>
                            {/* <div style={{ backgroundColor: "yellow" }}>
                                          {activity?.meetingPoint?.[0]?.link !==
                                          tempData?.meetingPoint?.[0]?.link ? (
                                            <p>{tempData?.meetingPoint?.[0]?.link}</p>
                                          ) : (
                                            ""
                                          )}
                                        </div> */}
                          </div>
                        </div>

                        {/* {meetingPointData.length - 1 == index ? (
                              <button
                                type="button"
                                className="btn btn-primary ml-auto d-table"
                                // onClick={() => {
                                //   setmeetingPointData((state) => {
                                //     state.push({ desc: "", link: "" });
          
                                //     return JSON.parse(JSON.stringify(state));
                                //   });
                                // }}
                                onClick={() => {
                                  setmeetingPointData((state) => [
                                    ...state,
                                    { desc: "", link: "" },
                                  ]);
                                }}
                              >
                                +
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-danger ml-auto d-table"
                                // onClick={() => {
                                //   setmeetingPointData((state) => {
                                //     state.splice(index, 1);
          
                                //     return JSON.parse(JSON.stringify(state));
                                //   });
                                // }}
                                onClick={() => {
                                  setmeetingPointData((state) => {
                                    let updated = [...state];
                                    updated.splice(index, 1);
                                    return updated;
                                    // state.splice(index, 1);
                                    // return [...state]
                                  });
                                }}
                              >
                                -
                              </button>
                            )} */}
                      </div>
                    );
                  })}
                </>
              );

            case "importantinfo":
              return (
                tempData?.importentInfo && (
                  <>
                    {tempData?.importentInfo.map((item, index) => {
                      return (
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11" key={index}>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="siteName"
                                  className="form-label"
                                >
                                  {/* Title */}
                                  Important information about your activity
                                </label>
                                <input
                                  disabled
                                  name={`exp_title_${index}`}
                                  id={`exp_title_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.title}
                                  // onChange={(e) => {
                                  //   setImportentInfo((state) => {
                                  //     state[index].title = e.target.value;

                                  //     return JSON.parse(JSON.stringify(state));
                                  //   });
                                  // }}
                                />
                              </div>
                              {/* <div style={{ backgroundColor: "yellow" }}>
                                {activity?.importentInfo?.[0]?.title !==
                                tempData?.importentInfo?.[0]?.title ? (
                                  <p>{tempData?.importentInfo?.[0]?.title}</p>
                                ) : (
                                  ""
                                )}
                              </div> */}
                              {/* {errors.siteName && (
                            <span className="errorMessageText text-danger">
                              {errors.siteName}
                            </span>
                          )} */}
                            </div>

                            {item.description.map((it, key) => {
                              return (
                                <div
                                  className="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-md-11" key={key}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="siteName"
                                        className="form-label ml-3"
                                      >
                                        {/* Description */}
                                        Description (Add important info that the
                                        customer have to be aware of E.g.: Bring
                                        ID , Height restriction etc.. Add one at
                                        a time using the plus button.)
                                      </label>
                                      {/* <div>
                                        <p style={{ fontSize: "14px" }}>
                                          This is the customer’s first
                                          introduction to your activity.
                                          Describe your activity in 2 to 3
                                          sentences including all the main
                                          selling points of the activity (This
                                          is not the main description so keep it
                                          short and aim to attract attention)
                                        </p>
                                      </div> */}
                                      {/* <textarea
                                        disabled
                                        name={`exp_desc_${index}`}
                                        id={`exp_desc_${index}`}
                                        className="form-control"
                                        autoComplete="false"
                                        value={it}
                                        // onChange={(e) => {
                                        //   setImportentInfo((state) => {
                                        //     state[index].description[key] =
                                        //       e.target.value;

                                        //     return JSON.parse(JSON.stringify(state));
                                        //   });
                                        // }}
                                      /> */}

                                      <CKEditor
                                        className="editor-container"
                                        editor={ClassicEditor}
                                        data={it}
                                        onReady={(editor) => {
                                          editor.enableReadOnlyMode(
                                            "customReadOnly"
                                          );
                                        }}
                                        onBlur={(event, editor) => {
                                          console.log("Blur.", editor);
                                        }}
                                        onFocus={(event, editor) => {
                                          console.log("Focus.", editor);
                                        }}
                                      />
                                    </div>
                                    {/* <div style={{ backgroundColor: "yellow" }}>
                                      {activity?.importentInfo?.[0]
                                        ?.description?.[0] !==
                                      tempData?.importentInfo?.[0]
                                        ?.description?.[0] ? (
                                        <p>
                                          {
                                            tempData?.importentInfo?.[0]
                                              ?.description?.[0]
                                          }
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div> */}
                                  </div>

                                  {/* {item.description.length - 1 == key ? (
                            <button
                              type="button"
                              className="btn btn-primary ml-auto d-table"
                              onClick={() => {
                                setImportentInfo((state) => {
                                  state[index].description.push("");

                                  return JSON.parse(JSON.stringify(state));
                                });
                              }}
                            >
                              +
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-danger ml-auto d-table"
                              onClick={() => {
                                setImportentInfo((state) => {
                                  state[index].description.splice(key, 1);

                                  return JSON.parse(JSON.stringify(state));
                                });
                              }}
                            >
                              -
                            </button>
                          )} */}
                                </div>
                              );
                            })}
                          </div>

                          {/* {importentInfo.length - 1 == index ? (
                    <button
                      type="button"
                      className="btn btn-primary ml-auto d-table"
                      // onClick={() => {
                      //   setImportentInfo((state) => {
                      //     state.push({ title: "", description: [""] });

                      //     return JSON.parse(JSON.stringify(state));
                      //   });
                      // }}
                      onClick={() => {
                        setImportentInfo((state) => [
                          ...state,
                          { title: "", description: [""] },
                        ]);
                      }}
                    >
                      +
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-danger ml-auto d-table"
                      // onClick={() => {
                      //   setImportentInfo((state) => {
                      //     state.splice(index, 1);

                      //     return JSON.parse(JSON.stringify(state));
                      //   });
                      // }}
                      onClick={() => {
                        setImportentInfo((state) => {
                          let updated = [...state];
                          updated.splice(index, 1);
                          return updated;
                          // state.splice(index, 1);
                          // return [...state]
                        });
                      }}
                    >
                      -
                    </button>
                  )} */}
                        </div>
                      );
                    })}
                  </>
                )
              );
            case "tourPlaces":
              return (
                tempData?.activityCoordinates && (
                  <>
                    {tempData?.activityCoordinates?.map((item, index) => {
                      console.log("pppppp", item);
                      return (
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11" key={index}>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label htmlFor="" className="form-label">
                                  {/* Location Name */}
                                  Add all locations that will be visited during
                                  this activity (Use the plus button to add them
                                  one at a time)
                                </label>
                                {/* <textarea
                                  disabled
                                  name={`location_name_${index}`}
                                  id={`location_name_${index}`}
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.location}
                                  // onChange={(e) => {
                                  //   settourCoordinates((state) => {
                                  //     state[index].location = e.target.value;

                                  //     return JSON.parse(JSON.stringify(state));
                                  //   });
                                  // }}
                                /> */}

                                <CKEditor
                                  className="editor-container"
                                  editor={ClassicEditor}
                                  data={item.location}
                                  onReady={(editor) => {
                                    editor.enableReadOnlyMode("customReadOnly");
                                  }}
                                  onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                  }}
                                  onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-12 d-none">
                              <div className="mb-3">
                                <label htmlFor="" className="form-label">
                                  Location Longitude
                                </label>
                                <input
                                  disabled
                                  name={`location_longitude_${index}`}
                                  id={`location_longitude_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.lng}
                                  // onChange={(e) => {
                                  //   settourCoordinates((state) => {
                                  //     state[index].lng = e.target.value;

                                  //     return JSON.parse(JSON.stringify(state));
                                  //   });
                                  // }}
                                />
                              </div>
                              {/* <div style={{ backgroundColor: "yellow" }}>
                                {activity?.activityCoordinates?.[0]?.lng !==
                                tempData?.activityCoordinates?.[0]?.lng ? (
                                  <p>
                                    {tempData?.activityCoordinates?.[0]?.lng}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div> */}
                              {/* {errors.siteName && (
                            <span className="errorMessageText text-danger">
                              {errors.siteName}
                            </span>
                          )} */}
                            </div>
                          </div>

                          {/* {tourCoordinates.length - 1 == index ? (
                    <button
                      type="button"
                      className="btn btn-primary ml-auto d-table"
                      // onClick={() => {
                      //   settourCoordinates((state) => {
                      //     state.push({ location: "", lat: "", lng: "" });

                      //     return JSON.parse(JSON.stringify(state));
                      //   });
                      // }}
                      onClick={() => {
                        settourCoordinates((state) => [
                          ...state,
                          { location: "", lat: "", lng: "" },
                        ]);
                      }}
                    >
                      +
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-danger ml-auto d-table"
                      // onClick={() => {
                      //   settourCoordinates((state) => {
                      //     state.splice(index, 1);

                      //     return JSON.parse(JSON.stringify(state));
                      //   });
                      // }}
                      onClick={() => {
                        settourCoordinates((state) => {
                          let updated = [...state];
                          updated.splice(index, 1);
                          return updated;
                        });
                      }}
                    >
                      -
                    </button>
                  )} */}
                        </div>
                      );
                    })}
                  </>
                )
              );

            case "aditionalStaff":
              return (
                <>
                  <div className="card-body">
                    {stuff &&
                      stuff?.map((item) => (
                        <div key={item._id}>
                          <label>
                            {" "}
                            {console.log(
                              "p===",
                              tempData.TourAdditionalStuff.some(
                                (selectedItem) => selectedItem === item._id
                              )
                            )}
                            {<div className="view_btn"></div>}
                            <input
                              type="checkbox"
                              name={item.stuffName}
                              disabled
                              checked={tempData.TourAdditionalStuff.some(
                                (selectedItem) => selectedItem === item._id
                              )}
                              // onChange={(e) => handleCheckboxChange(e, item._id)}
                            />
                            {item.stuffName} - $ {item.stuffprice}
                          </label>
                        </div>
                      ))}
                  </div>
                </>
              );
            case "importantToKnow":
              return (
                tempData?.importantQuestionAboutThisTour && (
                  <>
                    {tempData?.importantQuestionAboutThisTour?.map(
                      (list, i) => {
                        return (
                          <div className="card my-3" key={i}>
                            <div className="card-body row">
                              <div className="col-11">
                                <TextInput
                                  label={
                                    "Provide your customers with the most frequently asked question and answers about your activity. E.g.: Is there a toilet on the bus?"
                                  }
                                  value={list.question}
                                  disabled
                                />
                                <TextInput
                                  label={
                                    "Answer: Yes , a toilet is a available on our busses.(Add as many Q&A as to limit the amount of times our team have to contact you to clear up simple questions saving everyone time.)"
                                  }
                                  value={list.answer}
                                  disabled
                                />
                              </div>
                              <div className="col-1 m-auto">
                                {i !== 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-danger ml-auto d-table"
                                    // onClick={() => {
                                    //   let updated = [...importantQuestion];
                                    //   updated.splice(i, 1);
                                    //   setImportantQuestion(updated);
                                    // }}
                                  >
                                    -
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </>
                )
              );
            default:
              return <h1>No Data Found</h1>;
          }
        })()}

        <button
          className="okBTn"
          onClick={() => {
            setupdateModal(false);
          }}
          style={{ fontSize: "19px", float: "right", cursor: "pointer" }}
        >
          ok
        </button>
      </div>
    </div>
  );
}

export default ShowEditProduct;
