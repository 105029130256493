// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-hot-toast";

// Components
import Loader from "../../../Component/Loader";
import HomeService from "../../../services/api/HomeService";

function AddGiftQuestion() {
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [isLoading, setIsLoading] = useState('')
  const addquestion = (e) => {
    e.preventDefault()
    if (question == "" || answer == "") {
      return toast.error('Fill all Fields')
    }
    setIsLoading(true)
    let data = {
      question: question,
      answer: answer
    }
    HomeService.addgiftquestions(data)
      .then((res) => {
        if (res.status) {
          toast.success('Gift question added successfully')
          setIsLoading(false)
          setQuestion('')
          setAnswer('')
        } else {
          toast.error('something wrong')
          setIsLoading(false)

        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Gift Questions</div>
              <div className="col-md-12">
                <div
                  className="mb-3"
                  style={{ display: "flex", paddingTop: "5px" }}
                >
                  <label
                    htmlFor="question"
                    className="form-label"
                    style={{ margin: "0.5rem 0.5rem" }}
                  >
                    Gift Question
                  </label>
                  <input
                    name="question"
                    id="question"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={question}
                    onChange={(e) => {
                      setQuestion(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="mb-3"
                  style={{ display: "flex", paddingTop: "5px" }}
                >
                  <label
                    htmlFor="answer"
                    className="form-label"
                    style={{ margin: "0.5rem 0.5rem" }}
                  >
                    Gift Answer
                  </label>
                  <textarea
                    name="answer"
                    id="answer"
                    type="number"
                    className="form-control"
                    autoComplete="false"
                    value={answer}
                    onChange={(e) => {
                      setAnswer(e.target.value)
                    }}
                    style={{ height: '200px' }}
                  > </textarea>
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={(e) => addquestion(e)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddGiftQuestion;
