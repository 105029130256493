import React, { useState, useRef, useEffect } from "react";
import HomeService from "../../../services/api/HomeService";
import { toast } from "react-toastify";
import Loader from "../../../Component/Loader";
import { capitalizeFirstLetter } from "../../../utils";
import Select from "react-select";

function AddQuestion() {
  const [topicId, setTopicId] = useState("");
  const inputRef = useRef(null);
  const [questions, setQuestions] = useState("");
  const [desc, setDesc] = useState("");
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllTopics();
  }, []);

  const getAllTopics = () => {
    HomeService.getAllTopics()
      .then((result) => {
        if (result && result.status) {
          setTopics(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handlesubmit = (event) => {
    event.preventDefault();
    if (isLoading) return;

    setIsLoading(true);

    if (topicId === "" || questions === "" || desc === "") {
      return toast.error("Fill all fields");
    }

    let data = {
      questions,
      topicId,
      description: desc,
    };
    HomeService.addQuestion(data)
      .then((res) => {
        if (res && res.status) {
          toast.success("Question added successfully");
          setQuestions("");
          setTopicId("");
          setDesc("");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("Something wrong please try after some time");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <form className="" onSubmit={(e) => handlesubmit(e)}>
        {isLoading && <Loader />}
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="card-title" style={{ fontSize: "16px" }}>
              Create Question
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label
                  htmlFor="countryId"
                  className="form-label font-weight-bold"
                >
                  Topics
                </label>
                {/* <select
                  name="countryId"
                  id="countryId"
                  className="form-control form-select"
                  onChange={e => setTopicId(e.target.value)}
                  value={topicId}>
                  <option value="" disabled={true} selected={true}>
                    Select
                  </option>
                  {topics.map((it, ind) => {
                    return (
                      <option value={it._id} key={ind}>
                        {it.topicName}
                      </option>
                    );
                  })}
                </select> */}
                <Select
                  name="countryId"
                  id="countryId"
                  classNamePrefix="select"
                  ref={inputRef}
                  options={topics.map((item, idx) => ({
                    value: item?._id,
                    label: item?.topicName,
                  }))}
                  onChange={(val) => {
                    setTopicId(val?.value);
                    // console.log('assign to',activity?.assignTo);
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label font-weight-bold">Question</label>
                <input
                  name="Questions"
                  id="Questions"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  placeholder="Enter question"
                  required={true}
                  value={questions}
                  onChange={(e) => setQuestions(capitalizeFirstLetter(e))}
                />
              </div>
            </div>
            {/* <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="Faq" className="form-label font-weight-bold">
                  Description *
                </label>
                <CKEditor
                  className="editor-container"
                  editor={ClassicEditor}
                  data={questionData.desc}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    // setDescription(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label font-weight-bold">Description</label>
                <textarea
                  name="description"
                  id="description"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  required={true}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </div>
            </div>

            <button
              className="mt-2 btn btn-primary ml-auto d-table font-weight-bold"
              type="submit"
              style={{ margin: "5px", fontSize: "14px" }}
            >
              Submit
            </button>
            </div>

          </div>
        </div>
      </form>
    </>
  );
}

export default AddQuestion;
