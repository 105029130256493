// Hooks
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

// Utils
import {
  allowOnlyPositiveInteger,
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
  checkFileDimension,
} from "../../../utils";

// API
import { fileUpload, requestData } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllCities } from "../../../redux/adminSlice/citySlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";
import { getAllActivityTypes } from "../../../redux/adminSlice/activitySlice";
import { getAllSections } from "../../../redux/adminSlice/sectionSlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import useCountries from "../../../Hooks/useCountries";

// var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;

let initialValues = {
  // activityTypeId: "",
  // sectionId: "",
  countryId: "",
  cityName: "",
  picture: "",
  topPriority: 0,
};

let createErrors = {
  // activityTypeId: "",
  // sectionId: "",
  countryId: "",
  cityName: "",
  picture: "",
  topPriority: "",
};
const EditCity = () => {
  // function AddCities() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [city, setcity] = useState(initialValues);
  const [Img, setImg] = useState("");
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [cityEdit, setcityEdit] = useState(initialValues);
  const [uploadedImg, setuploadedImg] = useState("");
  const [imgLoading, setImgLoading] = useState(false);

  const { sections } = useSelector((state) => state.adminSectionActionsSlice);
  const { countries } = useCountries();

  const fetchCityDetail = useCallback(async () => {
    try {
      let response = await requestData(
        `/user/viewSingleCity/${params.id}`,
        "GET"
      );

      if (response.status && response.data.length > 0) {
        let data = response.data[0];
        setcityEdit({
          countryId: data.countryId,
          cityName: data.cityName,
          picture: data.picture,
          topPriority: data.topPriority,
        });
      }
    } catch (error) {}
  }, [params.id]);

  useEffect(() => {
    fetchCityDetail();
  }, [fetchCityDetail]);

  const handleEditChange = (event) => {
    let input;

    if (event.target.name === "topPriority") {
      input = allowOnlyPositiveInteger(event);
    } else {
      input = capitalizeFirstLetter(event);
    }

    setcityEdit({ ...cityEdit, [event.target.name]: input });
  };

  // const handleImg = async (file) => {
  //   if (imageReg.test(file.type)) {
  //     errors.picture = "";
  //     setuploadedImg(URL.createObjectURL(file));
  //   } else {
  //     errors.picture = "File  is not an image";
  //     setuploadedImg("");
  //   }
  // };
  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    if (imgLoading) return;
    const x = Array.from(e.target.files)[0];
    setImg(URL.createObjectURL(x));
    let fileObj = await checkFileDimension(x);
    // console.log(fileObj);
    // if (fileObj?.height > 720) {
    //   console.log("Line 119");
    //   seterrors({
    //     ...errors,
    //     picture: "File height should be less than 720px",
    //   });

    //   return;
    // }
    // if (fileObj?.width > 1280) {
    //   seterrors({
    //     ...errors,
    //     picture: "File width should be less than 1280px",
    //   });

    //   return;
    // }

    seterrors({ ...errors, picture: "" });
    const DATA = new FormData();
    DATA.append("image", x);
    try {
      setImgLoading(true);
      let imgUpRes = await fileUpload("/admin/cityImage", "POST", DATA);

      if (imgUpRes?.status) {
        console.log(imgUpRes.data);
        // setImg(imgUpRes.data);
        setcityEdit((prev) => {
          let updated = { ...prev };
          updated.picture = imgUpRes.data;

          return JSON.parse(JSON.stringify(updated));
        });
      } else {
        toast.error(
          "Something went wrong while uploading image! Please try after some time."
        );
      }
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
      toast.error(
        "Something went wrong while uploading image! Please try after some time."
      );
    }
  };

  // console.log("Edit city", cityEdit);

  const handleEditValidation = () => {
    const { countryId, cityName, topPriority } = cityEdit;

    if (countryId === "") {
      seterrors({ ...errors, countryId: "Country is required" });

      return false;
    }
    if (cityName === "") {
      seterrors({ ...errors, cityName: "City name is required" });

      return false;
    }
    if (topPriority === 0) {
      seterrors({
        ...createErrors,
        topPriority: "Please provide a priority number.",
      });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    if (handleEditValidation()) {
      // if (Img === "") return toast.error("Picture is required");
      let bodyData = { id: `${params.id}`, ...cityEdit };
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditCity"](bodyData);

        if (res.isSuccess) {
          dispatch(getAllCities());
          setModal(false);
          seterrors(createErrors);
          setImg("");
          // setcityEdit(initialValues);
          setuploadedImg("");
          // apiCallSuccess(res.data);
          toast.success("City edited successfully");
          // navigate("/manage-cities");
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
      // } else {
      //   setisLoading(false);
      //   toast.error('Something went wrong!');
      // }
      setisLoading(false);
    }
  };
  // };

  return (
    <>
      {isLoading && <Loader />}
      <form
        className=""
        onSubmit={(e) => handleEditSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit City</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="countryId" className="form-label">
                    Country*
                  </label>
                  <select
                    name="countryId"
                    id="countryId"
                    className="form-control form-select"
                    value={cityEdit.countryId}
                    onChange={(e) => handleEditChange(e)}
                  >
                    <option value="" key={0}>
                      Select
                    </option>
                    {countries?.map((item, idx) => {
                      return (
                        <>
                          <option key={idx + 1} value={`${item?._id}`}>
                            {item?.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                {errors.countryId && (
                  <span className="errorMessageText text-danger">
                    {errors.countryId}
                  </span>
                )}
              </div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="cityName" className="form-label">
                      City Name*
                    </label>
                    <input
                      name="cityName"
                      id="cityName"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={cityEdit.cityName}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.cityName && (
                    <span className="errorMessageText text-danger">
                      {errors.cityName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="picture" className="form-label">
                      Image*
                    </label>
                    <input
                      name="picture"
                      id="picture"
                      type="file"
                      accept={"image/*"}
                      ref={fileInputRef}
                      className="form-control"
                      onChange={imageHandler}
                    />
                  </div>
                  {/* {uploadedImg.length > 0 ? (
                      <img style={{ height: '100px', width: '100px' }} src={uploadedImg} />
                    ) : (
                      <img style={{ height: '100px', width: '100px' }} src={cityEdit.picture} />
                    )}
                    {errors.picture && <span className="errorMessageText text-danger">{errors.picture}</span>} */}
                  <p className="text-primary mt-2">
                    {imgLoading && "Uploading please wait..."}
                  </p>

                  {cityEdit.picture !== "" && (
                    <>
                      <img
                        alt=""
                        style={{
                          height: "70px",
                          width: "120px",
                          borderRadius: "3px",
                        }}
                        src={cityEdit.picture}
                      ></img>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="topPriority" className="form-label">
                    Priority*
                  </label>
                  <input
                    name="topPriority"
                    id="topPriority"
                    type="number"
                    min={0}
                    className="form-control"
                    value={cityEdit?.topPriority}
                    onChange={handleEditChange}
                  />
                </div>
                {errors.topPriority && (
                  <span className="errorMessageText text-danger">
                    {errors.topPriority}
                  </span>
                )}
              </div>
              <div className="d-flex mt-2">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    navigate("/manage-cities");
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="mt-2 btn btn-primary  d-table">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
  //   }
};

export default EditCity;
