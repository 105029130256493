
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { apiCallError } from "../../../utils";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { getAllAbouts } from "../../../redux/adminSlice/aboutusSlice";
import Table from "../../../Component/Table";

const ManageAboutUs = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const { abouts } = useSelector((state) => state.adminAboutsActionSlice);

  useEffect(() => {
    dispatch(getAllAbouts());
  }, [dispatch]);

  // const deleteBanner = async (id) => {
  //   try {
  //     setisLoading(true);
  //     let res = await JSON_API["adminDelteAbout"]({ id: id });
  //     if (res.isSuccess) {
  //       dispatch(getAllAbouts());
  //       setModal(false);
  //       toast.success("About us info deleted successfully!");
  //     } else {
  //       apiCallError(res?.error?.message);
  //     }
  //     setisLoading(false);
  //   } catch (error) {
  //     setisLoading(false);
  //     apiCallError(error.message);
  //   }
  // };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Topic",
        field: "topic",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      abouts?.length > 0
        ? abouts.map((item, idx) => {
            return {
              sl: idx + 1,
              topic: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.topic}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              description: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.description?.length <= 35
                          ? item?.description
                          : item?.description?.slice(0, 35) + "...."}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div key={idx}>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/edit-aboutUs/${item._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  // deleteBanner(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Table
        striped
        tableTitle="All About Us"
        data={tableData}
        modal={modal}
        setModal={setModal}
        renderModalView={renderModalView}
      />
    </>
  );
};

export default ManageAboutUs;
