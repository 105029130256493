import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

import Swal from "sweetalert2";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import useCountries from "../../../Hooks/useCountries";
import { requestData } from "../../../services/api/apiHelpers";

const ManageDayOutInCity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [searchText, setsearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const { getMiniBlogs, miniBlogData, loading } = useCountries();

  useEffect(() => {
    getMiniBlogs();
  }, []);
  useEffect(() => {
    console.log("loading", loading)
setisLoading(loading)
  }, [loading])

  const filterData = () => {
    if (searchText !== "") {
      return miniBlogData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return miniBlogData;
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setisLoading(true);
          let res = await requestData(`/admin/deleteminiBlog/${id}`,"delete");
          // console.log("Res", res);

          if (res.status) {
            // dispatch(getAllTourModules());
            getMiniBlogs()
            setModal(false);
            setisLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
        
          } else {
            setisLoading(false);
          }
        } catch (error) {
          setisLoading(false);
          // apiCallError(error.message, setError);
        }
      }
    });
    console.log("sssss", id);
    return;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 150,
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                title: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-flex flex2">
                        <div className="text-center">{item?.title}</div>
                      </div>
                      <div className="widget-content-left flex2"></div>
                    </div>
                  </div>
                ),
                description: (
                  <div className="widget-content p-0 text-center">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                      <div className="text-center">{item?.description.slice(0,100)}....</div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div>
                    <button
                      title="Mini Blog view"
                      className={
                        "me-2 btn-icon btn-icon-only btn btn-outline-primary"
                      }
                      onClick={() => {
                        navigate(`/edit-dayOutInCity/${item?._id}`);
                      }}
                    >
                      <i class="fa-regular fa fa-eye"></i>
                    </button>
                    <button
                      title="Mini blog delete"
                      className={
                        "me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      }
                      onClick={() => {
                        // navigate(`/edit-dayOutInCity/${item?._id}`);
                        handleDelete(item?._id)
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                  </div>
                ),
              };
            })
          : [],
    };
  }, [miniBlogData, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete merchant</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                // onClick={() => {
                //   // console.log(deleteId);
                //   deleteMerchant(deleteId);
                // }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle="All Day Out In (City)"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
};

export default ManageDayOutInCity;
