import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import ManageCountry from '../View/Admin/Country/ManageCountry';
import Layout from '../Layout';
import Registration from '../Athentication/Registration';
import Login from '../Athentication/Login';
import Profile from '../View/Admin/Profile/Profile';
import Dashboard from '../View/Admin/Dashboard';
import {
  AdminAddSubAdmin,
  ManageSubadmin,
  EditSubAdmin
} from '../View/Admin/SubAdmin';
import {
  AdminAddMerchant,
  AdminManageMerchant,
  AdminEditMerchant,
  AdminManageMerchantAddDrafts,
  EditMerchantAddDraft,
  ManageDetailsChangeRequests,
  ChangeRequestDetailView,
  NewMerchantRequests,
  NewMerchantDetailView,
} from '../View/Admin/Marchent';

import ManageSubMarchent from '../View/Admin/Marchent/ManageSubMarchent';

import {
  AdminAddSubmerchantProfile,
  AdminAddMerchantCompanyDetails,
  AdminManageSubmerchantAccount,
} from '../View/Admin/MerchantCompanyDetails';
import {
  AdminAddActivityType,
  AdminManageActivityType,
  EditActivityType,
  AdminAddActivitySite,
  AdminManageActivitySite,
  EditActivitySite,
  AdminAddActivity,
  AdminManageActivity,
  AdminEditActivity,
  AdminManageActivityDrafts,
  EditActivityDrafts,
  CheckMerchantAdditions,
  ActivityDetailView,
  ActivityChangeRequests,
  ProductChangeDetails,
  AddBookingStatusTag,
  ManageBookingStatusTags,
  EditBookingTag
} from '../View/Admin/Activity';
import { AddDestination, ManageDestinations } from '../View/Admin/ActivitySiteDestination';
import {
  AdminAddParticipants,
  AdminManageParticipants,
  AdminEditParticipants
} from '../View/Admin/ActivityParticipants';
import AdminAddCountry from '../View/Admin/Country/AddCountry';
import AdminAddLanguage from '../View/Admin/Language/AddLanguage';
import ManageLanguage from '../View/Admin/Language/ManageLanguage';
import AdminAddBanner from '../View/Admin/Banner/AddBanner';
import ManageBanner from '../View/Admin/Banner/ManageBanner';
import AdminAddSection from '../View/Admin/Section/AddSection';
import ManageSection from '../View/Admin/Section/ManageSection';
import { AddCategory, ManageCategory, EditCategory } from '../View/Admin/Category';
import { AdminAddCity, ManageCities, AdminEditCity } from '../View/Admin/Cities';
import AddAbout from '../View/Admin/AboutUs/AddAbout';
import ManageAboutUs from '../View/Admin/AboutUs/ManageAboutUs';
import AdminAddPressReleases from '../View/Admin/Press/AddPress';
import ManagePress from '../View/Admin/Press/ManagePress';
import {
  AddBlogType,
  ManageBlogType,
  EditBlogType,
  AddBlogTypeCategory,
  ManageBlogTypeCategories,
  AddBlog,
  ManageBlog,
  BlogDetailedView,
  EditBlog
} from '../View/Admin/Blog';
import AdminAddCareers from '../View/Admin/Careers/AddCareers';
import ManageCareers from '../View/Admin/Careers/ManageCareers';
import AddLegalNotice from '../View/Admin/LegalNotice/AddLegalNotice';
import ManageLegalNotice from '../View/Admin/LegalNotice/ManageLegalNotice';
import AddTermsandCond from '../View/Admin/TermsAndCond/AddTermsandCond';
import AddCookies from '../View/Admin/CookiesMarketing/AddCookies';
import ManageCookies from '../View/Admin/CookiesMarketing/ManageCookies';
import AdminAddPolicy from '../View/Admin/PrivacyPolicy/AddPrivacy';
import ManagePrivacy from '../View/Admin/PrivacyPolicy/ManagePrivacy';
import ManageContact from '../View/Admin/Contact/ManageQuestions';
import {
  AdminAddTourModule,
  AdminManageTourModule,
  AdminEditTourModule
} from '../View/Admin/TourModule';
import AdminAddSpecialOffers from '../View/Admin/Offers/AddSpecialOffers';
import ManageSpecialOffers from '../View/Admin/Offers/ManageSpecialOffers';
import { AdminAddDigitalInfo, AdminManageDigitalInfo } from '../View/Admin/DigitalServiceInfo';
import { AdminAddSitemap, AdminManageSitemap } from '../View/Admin/Sitemap';
import Details from '../Component/Details';
import AddFaqType from '../View/Admin/Faq/AddfaqType';
import ManageFaqType from '../View/Admin/Faq/ManageFaqType';
import AddFaq from '../View/Admin/Faq/AddFaq';
import ManageFaq from '../View/Admin/Faq/ManageFaq';
import AddTopic from '../View/Admin/Contact/AddTopic';
import ManageTopics from '../View/Admin/Contact/ManageTopics';
import AddContactEmail from '../View/Admin/Contact/AddContactEmail';
import ManageTermsandCond from '../View/Admin/TermsAndCond/ManageTermsandCond';
import TermsDetailView from '../View/Admin/TermsAndCond/TermsDetailView';
import {
  AdminAssignActivity,
  AdminManageAssignedActivity,
  EditAssignedActivity
} from '../View/Admin/AssignActivity';
import { AdminAddGift, AdminManageGift } from '../View/Admin/Gift';
import PrivacyDetailView from '../View/Admin/PrivacyPolicy/PrivacyDetailView';
import ManageQuestions from '../View/Admin/Contact/ManageQuestions';
import AddQuestion from '../View/Admin/Contact/AddQuestion';
import AddSubq from '../View/Admin/Contact/AddSubq';
import ManageSubq from '../View/Admin/Contact/ManageSubq';
import MerchantChat from '../View/Admin/Chat/MerchantChat';
import ManageMerchantOffers from '../View/Admin/Offers/ManageMerchantOffers';
import {
  AddActivityAvailability,
  CheckActivityAvailability,
  EditActivityAvailability,
  CancelAvailability,
  ActivityAvailabilityCalendar,
} from '../View/Admin/ActivityAvailability';

import EditCountry from '../View/Admin/Country/EditCountry';
import EditSection from '../View/Admin/Section/EditSection';
import EditLanguage from '../View/Admin/Language/EditLanguage';
import EditBanner from '../View/Admin/Banner/EditBanner';
import EditPress from '../View/Admin/Press/EditPress';
import EditAboutUs from '../View/Admin/AboutUs/EditAboutUs';
import EditCarrier from '../View/Admin/Careers/EditCarrier';
import EditLegalNotice from '../View/Admin/LegalNotice/EditLegalNotice';
import EditPrivacy from '../View/Admin/PrivacyPolicy/EditPrivacy';
import EditCoockies from '../View/Admin/CookiesMarketing/EditCoockies';
import EditTopic from '../View/Admin/Contact/EditTopic';
import EditQuestion from '../View/Admin/Contact/EditQuestion';
import EditSubq from '../View/Admin/Contact/EditSubq';
import EditOffer from '../View/Admin/Offers/EditOffer';
import EditTerm from '../View/Admin/TermsAndCond/EditTerm';
import EditInfo from '../View/Admin/DigitalServiceInfo/EditInfo';
import EditSiteMap from '../View/Admin/Sitemap/EditSiteMap';
import EditGift from '../View/Admin/Gift/EditGift';
import {
  VeiwBookings,
  BookingDetailedView,
  MerchantInvoices,
  OrderInvoice
} from '../View/Admin/Bookings';
import AddGiftQuestion from '../View/Admin/Gift/AddGiftQuestions';
import ManageGiftQuestion from '../View/Admin/Gift/ManageGiftQuestions';
import EditGiftQuestions from '../View/Admin/Gift/EditGiftQuestion';
import EditDistination from '../View/Admin/ActivitySiteDestination/EditDistination';
import { AddCommission, ManageCommission, EditCommission } from "../View/Admin/MerchantCommission";

import ViewReviewAndRating from "../View/Admin/ReviewAndRating/ViewReviewAndRating";
import ViewMerchantReviewAndRating from "../View/Admin/ReviewAndRating/ViewMerchantReviewAndRating";
import MerchantRejectProducts from '../View/Admin/Activity/MerchantRejectProducts';
import ViewMerchants from '../View/Admin/Marchent/ViewMerchants';
import RejectedMerchants from '../View/Admin/Marchent/RejectedMerchants';
import ManageAvailability from '../View/Admin/ActivityAvailability/ManageAvailability';
import AddMail from '../View/Admin/Mail/AddMail';
import ManageMail from '../View/Admin/Mail/ManageMail';
import AddDayOutInCity from '../View/Admin/dayOutInCity/AddDayOutInCity';
import ManageDayOutInCity from '../View/Admin/dayOutInCity/ManageDayOutInCity';
import EditDayOutInCity from '../View/Admin/dayOutInCity/EditDayOutInCity';
import ManageAdditionalStuff from '../View/Admin/AdditionalStuff/ManageAdditionalStuff';
import ManageCoupon from '../View/Admin/coupon/ManageCoupon';
import Viewallcontuct from '../View/Admin/Contact/Viewallcontuct';
import AddThirdPartyPlatform from '../View/Admin/ThirdPartyPlatform/AddThirdPartyPlatform';
import ManageThirsPartyplatform from '../View/Admin/ThirdPartyPlatform/ManageThirsPartyplatform';
import AddTravelAgency from '../View/Admin/OnlineTravelAgency/AddTravelAgency';
import ManageTravelAgency from '../View/Admin/OnlineTravelAgency/ManageTravelAgency';
import VisitedUser from '../View/Admin/VisitedUser/VisitedUser';



function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.pathname || '/';
  const [loggedIn, setloggedIn] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem('Authorization') &&
      localStorage.getItem('userType')
    ) {
      if (from === '/') {
        navigate('/');
      } else {
        navigate(from, { replace: true });
      }
      setloggedIn(true);
    } else {
      navigate('/login');
    }
  }, [loggedIn]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/add-admin" element={<AdminAddSubAdmin />} />
        <Route path="/manage-admin" element={<ManageSubadmin />} />
        <Route path="/edit-admin/:id" element={<EditSubAdmin />} />
        <Route path="/add-merchant" element={<AdminAddMerchant />} />
        <Route path="/manage-merchant/:merchantType?" element={<AdminManageMerchant />} />
        <Route path="/submerchant-list/:merchantid?" element={<ManageSubMarchent />} />
        <Route path="/edit-merchant/:id" element={<AdminEditMerchant />} />
        <Route path='/reject-merchant' element={<RejectedMerchants />}/>
        <Route path='/view_merchant/:id' element={<ViewMerchants />} />
        <Route path="/add-merchant-company-details" element={<AdminAddMerchantCompanyDetails />} />
        <Route path="/add-submerchant" element={<AdminAddSubmerchantProfile />} />
        <Route path="/manage-submerchant" element={<AdminManageSubmerchantAccount />} />
        <Route path="/add-activity-type" element={<AdminAddActivityType />} />
        <Route path="/manage-activity-type" element={<AdminManageActivityType />} />
        <Route path='/edit-activity-type/:id' element={<EditActivityType />} />
        <Route path="/add-activity-site" element={<AdminAddActivitySite />} />
        <Route path="/manage-activity-site" element={<AdminManageActivitySite />} />
        <Route path="/edit-activity-site/:id" element={<EditActivitySite />} />
        <Route path="/add-site-destination" element={<AddDestination />} />
        <Route path="/manage-site-destinations" element={<ManageDestinations />} />
        <Route path="/edit-site-destinations/:id" element={<EditDistination />} />

        <Route path="/add-country" element={<AdminAddCountry />} />
        <Route path="/manage-country" element={<ManageCountry />} />
        <Route path="/edit-country/:id" element={<EditCountry />} />
        <Route path="/add-language" element={<AdminAddLanguage />} />
        <Route path="/manage-language" element={<ManageLanguage />} />
        <Route path="/add-banner" element={<AdminAddBanner />} />
        <Route path="/manage-banner" element={<ManageBanner />} />
        <Route path="/add-sections" element={<AdminAddSection />} />
        <Route path="/manage-sections" element={<ManageSection />} />
        <Route path="/add-categories" element={<AddCategory />} />
        <Route path="/manage-categories" element={<ManageCategory />} />
        <Route path="/edit-categories/:id" element={<EditCategory />} />
        <Route path="/add-tour-module" element={<AdminAddTourModule />} />
        <Route path="/manage-tour-module" element={<AdminManageTourModule />} />
        <Route path='/edit-tour-module/:id' element={<AdminEditTourModule />} />
        <Route path="/add-city" element={<AdminAddCity />} />
        <Route path="/manage-cities" element={<ManageCities />} />
        <Route path='/edit-city/:id' element={<AdminEditCity />} />
        <Route path="/add-aboutUs" element={<AddAbout />} />
        <Route path="/manage-aboutUs" element={<ManageAboutUs />} />
        <Route path="/add-press" element={<AdminAddPressReleases />} />
        <Route path="/manage-press" element={<ManagePress />} />
        <Route path="/add-blog-type" element={<AddBlogType />} />
        <Route path="/manage-blog-types" element={<ManageBlogType />} />
        <Route path="/add-blog-type-category" element={<AddBlogTypeCategory />} />
        <Route path="/manage-blog-type-category" element={<ManageBlogTypeCategories />} />
        <Route path="/add-blogs" element={<AddBlog />} />
        <Route path="/manage-blogs" element={<ManageBlog />} />
        <Route path='/blog-detailed-view/:id' element={<BlogDetailedView />} />
        <Route path="/add-careers" element={<AdminAddCareers />} />
        <Route path="/manage-careers" element={<ManageCareers />} />
        <Route path="/add-legal-notices" element={<AddLegalNotice />} />
        <Route path="/manage-legal-notices" element={<ManageLegalNotice />} />
        <Route path="/add-terms-conditions" element={<AddTermsandCond />} />
        <Route path="/manage-terms-conditions" element={<ManageTermsandCond />} />
        <Route path='/terms-conditions/:id' element={<TermsDetailView />} />
        <Route path="/add-cookies-marketing" element={<AddCookies />} />
        <Route path="/manage-cookies-marketing" element={<ManageCookies />} />
        <Route path="/add-privacy-policy" element={<AdminAddPolicy />} />
        <Route path="/manage-privacy-policy" element={<ManagePrivacy />} />
        <Route path="/privacy-details/:id" element={<PrivacyDetailView />} />
        <Route path="/manage-contacts" element={<ManageContact />} />
        <Route path="/add-special-offers" element={<AdminAddSpecialOffers />} />
        <Route path="/manage-special-offers" element={<ManageSpecialOffers />} />
        <Route path="/manage-merchant-offers" element={<ManageMerchantOffers />} />
        <Route path="/add-activity" element={<AdminAddActivity />} />
        <Route path="/manage-activity" element={<AdminManageActivity />} />
        <Route path="/edit-activity/:id" element={<AdminEditActivity />} />
        <Route path="/add-participants" element={<AdminAddParticipants />} />
        <Route path="/manage-participants" element={<AdminManageParticipants />} />
        <Route path="/edit-participant-type/:id" element={<AdminEditParticipants />} />
        <Route path="/details" element={<Details />} />
        <Route path="/add-digital-info" element={<AdminAddDigitalInfo />} />
        <Route path="/manage-digital-info" element={<AdminManageDigitalInfo />} />
        <Route path="/add-sitemap" element={<AdminAddSitemap />} />
        <Route path="/manage-sitemap" element={<AdminManageSitemap />} />
        <Route path="/add-faq-type" element={<AddFaqType />} />
        <Route path="/manage-faq-type" element={<ManageFaqType />} />
        <Route path="/add-faq" element={<AddFaq />} />
        <Route path="/manage-faq" element={<ManageFaq />} />
        <Route path="/add-topic" element={<AddTopic />} />
        <Route path="/manage-topic" element={<ManageTopics />} />
        <Route path='/add-contact-email' element={<AddContactEmail />} />
        <Route path="/add-subtopic" element={<AddQuestion />} />
        <Route path="/manage-subtopic" element={<ManageQuestions />} />
        <Route path="/add-subquestion" element={<AddSubq />} />
        <Route path="/manage-subquestion" element={<ManageSubq />} />
        <Route path="/assign-activity" element={<AdminAssignActivity />} />
        <Route path="/manage-assigned-activity" element={<AdminManageAssignedActivity />} />
        <Route path="/edit-assigned-activity/:id" element={<EditAssignedActivity />} />
        <Route path="/add-gift" element={<AdminAddGift />} />
        <Route path="/manage-gift" element={<AdminManageGift />} />
        <Route path="/gift-questions" element={<AddGiftQuestion />} />
        <Route path="/manage-gift-questions" element={<ManageGiftQuestion />} />
        <Route path="/manage-activity-drafts" element={<AdminManageActivityDrafts />} />
        <Route path="/edit-activity-drafts/:id" element={<EditActivityDrafts />} />
        <Route path="/manage-merchant-drafts" element={<AdminManageMerchantAddDrafts />} />
        <Route path='/viewAllContuctIssue' element={<Viewallcontuct/>}/>
        <Route
          path="/edit-merchant-drafts/:id"
          element={<EditMerchantAddDraft />}
        />
        <Route path="/merchant-details-change-requests" element={<ManageDetailsChangeRequests />} />
        <Route path="/change-request-detail-view/:id" element={<ChangeRequestDetailView />} />
        <Route path="/merchants-chat" element={<MerchantChat />} />
        <Route path="/check-merchant-additions" element={<CheckMerchantAdditions />} />
        <Route path="/check-merchant-reject-product" element={<MerchantRejectProducts />} />
        <Route path="/new-activity-details/:id" element={<ActivityDetailView />} />
        <Route path='/product-change-requests' element={<ActivityChangeRequests />} />
        <Route path='/product-change-details/:id' element={<ProductChangeDetails />} />
        <Route path="/new-merchant-request-list" element={<NewMerchantRequests />} />
        <Route path="/new-merchant-detail-view/:id" element={<NewMerchantDetailView />} />
        <Route path="/add-activity-availability/:id" element={<AddActivityAvailability />} />
        <Route path="/check-activity-availability" element={<CheckActivityAvailability />} />
        <Route path="/activity-availability/:activityId" element={<ActivityAvailabilityCalendar />} /> 
        <Route path="/ManageAvailability/:id" element={<ManageAvailability/>} />

        <Route path="/edit-activity-availability/:acti_id/:slot_id" element={<EditActivityAvailability />} />
        <Route path='/cancel-activity-availability' element={<CancelAvailability />} />
        <Route path="/edit-language/:id" element={<EditLanguage />} />
        <Route path="/edit-section/:id" element={<EditSection />} />
        <Route path="/edit-banner/:id" element={<EditBanner />} />
        <Route path="/edit-press/:id" element={<EditPress />} />
        <Route path='/edit-blog-type/:id' element={<EditBlogType />} />
        <Route path="/edit-blog/:id" element={<EditBlog />} />
        <Route path="/edit-aboutUs/:id" element={<EditAboutUs />} />
        <Route path="/edit-carrier/:id" element={<EditCarrier />} />
        <Route path="/edit-legal-notice/:id" element={<EditLegalNotice />} />
        <Route path='/edit-privacy-policy/:id' element={<EditPrivacy />} />
        <Route path="/edit-cookies/:id" element={<EditCoockies />} />
        <Route path="/edit-topic/:id" element={<EditTopic />} />
        <Route path="/edit-question/:id" element={<EditQuestion />} />
        <Route path="/edit-sub-question/:id" element={<EditSubq />} />
        <Route path="/edit-special-offer/:id" element={<EditOffer />} />
        <Route path="/edit-term-conditions/:id" element={<EditTerm />} />
        <Route path="/edit-digital-info" element={<EditInfo />} />
        <Route path="/edit-sidemap/:id" element={<EditSiteMap />} />
        <Route path="/edit-gift/:id" element={<EditGift />} />
        <Route path="/edit-gift-questions/:id" element={<EditGiftQuestions />} />
        <Route path="/view-bookings" element={<VeiwBookings />} />
        <Route path='/booking-details/:id' element={<BookingDetailedView />} />
        <Route path="/merchant-invoices" element={<MerchantInvoices />} />
        <Route path="/order-invoice" element={<OrderInvoice />} />
        <Route path='/add-bookings-status-tag' element={<AddBookingStatusTag />} />
        <Route path='/manageAdditionalStuff' element={<ManageAdditionalStuff />} />
        <Route
          path='/manage-bookings-status-tags'
          element={<ManageBookingStatusTags />}
        />
        <Route path='/edit-booking-status-tag/:id' element={<EditBookingTag />} />
        <Route path='/add-commission' element={<AddCommission />} />
        <Route path='/manage-commission' element={<ManageCommission />} />
        <Route path='/edit-commission/:id' element={<EditCommission />} />

        {/* <Route path="/reviewHome" element={<ReviewHome />} />    */}
        <Route path="/viewReviewAndRating/:id" element={<ViewReviewAndRating />} />
        <Route path="/merchant/reviewAndRating/:id" element={<ViewMerchantReviewAndRating />} />

        <Route path='/add-mail/:id?' element={<AddMail />} />
        <Route path='/manage-mail' element={<ManageMail />} />

        <Route path='/add-dayOutInCity' element={<AddDayOutInCity />} />
        <Route path='/edit-dayOutInCity/:id' element={<EditDayOutInCity />} />
        <Route path='/manage-dayOutInCity' element={<ManageDayOutInCity />} />
        <Route path='/manage-coupon' element={<ManageCoupon />} />

        <Route path='/add_trird_party_platform' element={<AddThirdPartyPlatform />} />
        <Route path='/add_trird_party_platform/:id' element={<AddThirdPartyPlatform />} />
        <Route path='/manage_trird_party_platform' element={<ManageThirsPartyplatform />} />

        <Route path='/add_travel_agency' element={<AddTravelAgency />} />
        <Route path='/add_travel_agency/:id' element={<AddTravelAgency />} />
        <Route path='/manage_travel_agency' element={<ManageTravelAgency />} />

        <Route path='/visited-user' element={<VisitedUser />} />

      </Route>
      <Route path="/register" element={<Registration />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default AppRoutes;
