// Hooks
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { capitalizeFirstLetter, apiCallError } from "../../../utils";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import Table from "../../../Component/Table";
import HomeService from "../../../services/api/HomeService";
import Loader from "../../../Component/Loader";
import { useNavigate } from "react-router-dom";

const topicInitial = {
  id: "",
  topicName: "",
};

function ManageTopics() {
  const [selectedTopic, setSelectedTopic] = useState(topicInitial);
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [allContactTopic, setAllContactTopic] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAllContactTopic();
  }, []);

  // Redux stores

  const getAllContactTopic = () => {
    HomeService.getAllContactTopic()
      .then((result) => {
        console.log("contact topic", result);
        if (result && result.status) {
          setAllContactTopic(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const editHandler = async () => {
    if (isLoading) return;
    setisLoading(true);

    try {
      const res = await JSON_API["editTopic"]({ ...selectedTopic });
      if (res.isSuccess) {
        setSelectedTopic({ ...topicInitial });
        setModal(false);
        setmodalToggleView(null);
        getAllContactTopic();
        toast.success("Topic updated successfully");
      } else {
        apiCallError(res?.error?.message, setError);
        toast.error(res?.message);
      }
    } catch (error) {
      toast.error(error?.message);
      apiCallError(error?.message, setError);
    }

    setisLoading(false);
  };

  const deleteHandler = async (id) => {
    if (isLoading) return;
    setisLoading(true);

    try {
      const res = await JSON_API["deleteTopic"]({ id });
      if (res.isSuccess) {
        setModal(false);
        setmodalToggleView(null);
        getAllContactTopic();
        toast.success("Topic deleted successfully");
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }

    setisLoading(false);
  };
  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Contact Topic",
        field: "Faqtype",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      allContactTopic?.length > 0
        ? allContactTopic.map((item, idx) => {
          console.log("bal item", item);
          return {
            sl: idx + 1,
            Faqtype: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div className="widget-heading" style={{ opacity: 1 }}>
                      {item.topicName}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            action: (
              <div>
                <button
                  className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                  onClick={() => {
                    setModal(true);
                    setmodalToggleView(1);
                    setdeleteId(item._id);
                  }}
                >
                  <i className="fa-solid fa-trash-can" />
                </button>
                <button
                  className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                  onClick={() => {
                    // setModal(true);
                    // setmodalToggleView(0);
                    // setSelectedTopic({
                    //   id: item._id,
                    //   topicName: item.topicName,
                    // });
                    navigate(`/edit-topic/${item._id}`)
                  }}
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </button>
              </div>
            ),
          };
        })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            {isLoading && <Loader />}
            <div className="card p-4">
              <h4
                className="text-primary pb-4 font-weight-bold"
                style={{ fontSize: "16px" }}
              >
                Edit Topic
              </h4>
              <div className="form-group">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="font-weight-bold"
                >
                  Topic
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  onChange={(e) =>
                    setSelectedTopic((prev) => {
                      let update = JSON.parse(JSON.stringify(prev));
                      update.topicName = capitalizeFirstLetter(e);
                      return { ...update };
                    })
                  }
                  value={selectedTopic.topicName}
                />
              </div>
              <button
                type="submit"
                onClick={editHandler}
                className="btn btn-primary mb-2 font-weight-bold"
                style={{ fontSize: "14px" }}
              >
                Save Changes
              </button>
            </div>
          </>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Topic</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteHandler(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="Manage Topics"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
}

export default ManageTopics;
