// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utilities
import { apiCallError } from "../../../utils/common-utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllAssignedActivity } from "../../../redux/adminSlice/assignActivitySlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

function ManageAssignedActivities() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);

  // Redux stores
  const { assigned_activities } = useSelector(
    (state) => state.adminAssignActivitySlice
  );

  useEffect(() => {
    dispatch(getAllAssignedActivity());
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const deleteCategory = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["adminDeleteAssignedActivity"]({ id: id });

      if (res.isSuccess) {
        dispatch(getAllAssignedActivity());
        setModal(false);
        setisLoading(false);
        toast.success("Assigned activities to merchant deleted.");
      } else {
        setisLoading(false);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message, setError);
    }
  };

  const filterData = () => {
    if (searchText !== "") {
      return assigned_activities.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return assigned_activities;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Merchant",
          field: "merchant",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                merchant: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.assignDetails?.firstName
                            ?.charAt(0)
                            ?.toUpperCase() +
                            item?.assignDetails?.firstName?.slice(1) +
                            " " +
                            item?.assignDetails?.lastName
                              ?.charAt(0)
                              ?.toUpperCase() +
                            item?.assignDetails?.lastName?.slice(1)}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(0);
                        setdeleteId(assigned_activities[idx]._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        navigate(`/edit-assigned-activity/${item?._id}`);
                      }}
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                  </div>
                ),
              };
            })
          : [],
    };
  }, [assigned_activities, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Category</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCategory(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Assigned Activities"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
}

export default ManageAssignedActivities;
