// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utils
import { capitalizeFirstLetter2 } from '../../../utils/common-utils';

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { requestData } from "../../../services/api/apiHelpers";

// Redux actions
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

import "./styles.css";

export default function ManageSubMarchent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [allMerchantData, setallMerchantData] = useState([]);
  const [subMerchantData, setSubMerchantData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const params = useParams();
  //console.log('-: merchantid :-',params.merchantid);

  // Redux stores
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);

  let userdata  = localStorage.getItem('userdata');
  userdata  = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType     = userdata?.userType;


  const submerchantlist = async () => {
    const res = await requestData(`/admin/viewSubMarchentList/${params.merchantid}`, "GET");
    setSubMerchantData(res.data);
  }

  useEffect(() => {
    dispatch(getAllMerchants());
    setallMerchantData(merchants.filter((item) => item?.isApproval === true));

    submerchantlist();
  }, []);


  const openModal = () => {
    setModal(true);
  };

  const changeStatus = async (id, idx) => {
    try {
      let res = await JSON_API["adminChangeMerchantStatus"]({ id: id });

      if (res.isSuccess) {
        dispatch(getAllMerchants());
      } else {
        console.log("Update status change res: ", res);
      }
    } catch (error) {
      console.log("Failed to change status due to ", error.message);
    }
  };

  const deleteMerchant = async (id) => {
    // console.log("here", id);
    try {
      setisLoading(true);
      // let res = await JSON_API["adminDeleteMerchant"]({ id: id });
      let res = await requestData(`/admin/deleteMarchent/${id}`, "PUT");
      console.log("Res", res);

      // if (res.isSuccess) {
      if (res && res.status) {
        dispatch(getAllMerchants());
        setModal(false);
        setisLoading(false);
        MerchantApiCallSuccess(res.data);
      } else {
        setisLoading(false);
        MerchantApiCallError(res?.error?.msg);
      }
    } catch (error) {
      setisLoading(false);
      MerchantApiCallError(error.message);
    }
  };

  const MerchantApiCallSuccess = (data) => {
    // setkey(Math.random());
    toast.success("Action successful!");
  };

  const MerchantApiCallError = (errorMsg) => {
    toast.error(errorMsg);
    setError(errorMsg);
  };

  const filterData = () => {
    if (searchText !== "") {
      return subMerchantData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return subMerchantData;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Logo",
        //   field: "company_logo",
        //   sort: "asc",
        //   width: 150,
        // },
        // {
        //   label: "Company",
        //   field: "company_name",
        //   sort: "asc",
        //   width: 150,
        // },
        {
          label: "Sub-Merchant Name",
          field: "merchant_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 200,
        },
        {
          label: "Position",
          field: "merchant_code",
          sort: "asc",
          width: 150,
        },
        {
          label: "Mobile",
          field: "mobileno",
          sort: "asc",
          width: 100,
        },
        {
          label: "Permission",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
      filterData()?.length > 0
          ? filterData().map((item, idx) => {

            let str = '';
            Object.keys(item.tabPermission[0]).forEach(key => {
              if(item.tabPermission[0][key]){
                str += key+', ';
              }
            })



              return {
                sl: idx + 1,
                company_logo: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      {/* <div className="widget-content-left flex2">
                          <img
                            style={{
                              borderRadius: "3px",
                              height: "80px",
                              width: "80px",
                            }}
                            src={
                              item?.logo
                                ? item?.logo
                                : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                            }
                            alt="Avatar"
                          />
                      </div> */}
                    </div>
                  </div>
                ),
                company_name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                          {capitalizeFirstLetter2(item?.position)}
                      </div>
                    </div>
                  </div>
                ),
                merchant_name:(
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                          {capitalizeFirstLetter2(item?.firstName +' '+item?.lastName)}
                      </div>
                    </div>
                  </div>
                ),
                email: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        {item.email}
                      </div>
                    </div>
                  </div>
                  ),
                merchant_code: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        {item.position ? item.position : null}
                      </div>
                    </div>
                  </div>
                  ),
                  mobileno: (
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left flex2">
                          {item.mobile}
                        </div>
                      </div>
                    </div>
                ),
                action:(
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        
                      </div>
                    </div>
                  </div>
                ),
                action_other: (
                  <div>
                    {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('delete'))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(0);
                        // console.log("Delete id", allMerchantData[idx]?._id);
                        setdeleteId(allMerchantData[idx]?._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    )}

                    {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('edit'))) && (

                    <>
                      <button
                        className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                        onClick={() => {
                          navigate(`/edit-merchant/${item?._id}`);
                        }}
                      >
                        <i class="fa-regular fa-pen-to-square"></i>
                      </button>

                    </>
                    )}
                  </div>
                ),
              };
            })
          : [],
    };
  }, [subMerchantData, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete merchant</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  // console.log(deleteId);
                  deleteMerchant(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };


  let merchantDetails = [];
  allMerchantData.forEach((x,y)=> {
     if(params.merchantid == x._id){
      merchantDetails = x;
     }
     else{
       return null;
     }
  });

  return (
    <>
      {isLoading && <Loader />}
      <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Merchant Details</div>
              <table style={{width:"100%", padding:"10px", backgroundColor:"#FFF", margin:"2px 0 20px"}}>
        <tr>
          <td><b>Logo</b></td>
          <td><b>Company</b></td>
          <td><b>Merchant</b></td>
          <td><b>Email</b></td>
          <td><b>Merchant Code</b></td>
          <td><b>Status</b></td>
        </tr>
        <tr>
          <td><img src={merchantDetails.logo} style={{width:"40px"}} /></td>
          <td>{merchantDetails.companyName}</td>
          <td>{merchantDetails.firstName}</td>
          <td>{merchantDetails.email}</td>
          <td>{merchantDetails.marchentCode}</td>
          <td>Status</td>
        </tr>
      </table>
            </div>
          </div>
        </div>
      
      <Table
        striped
        tableTitle="Sub-Merchants"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
  // }
}
