// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Utils
import { apiCallError } from "../../../utils";

// Helpers
import { toast } from "react-toastify";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllBlogs } from "../../../redux/adminSlice/blogSlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import { Link } from "react-router-dom";

const ManageBlog = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);

  const { blogs } = useSelector((state) => state.blogsSlice);

  const openModal = () => {
    setModal(true);
  };

  const deletePress = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["adminDeleteBlog"]({ id: id });
      if (res.isSuccess) {
        dispatch(getAllBlogs());
        setModal(false);
        toast.success("Blog deleted successfully.");
      } else {
        apiCallError(res?.error?.message, setError);
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 100,
        height: 100,
      },
      {
        label: "Author Image",
        field: "image",
        sort: "asc",
        width: 100,
      },
      {
        label: "Author Name",
        field: "authorName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      blogs?.length > 0
        ? blogs.map((item, idx) => {
            return {
              sl: idx + 1,
              title: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item.title}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              image: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <img
                      src={`${item.image}`}
                      alt=""
                      className="img-fluid"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
              ),
              authorName: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item?.authorName}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setdeleteId(blogs[idx]?._id);
                      
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/edit-blog/${item._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                  <Link to={`/blog-detailed-view/${item._id}`}>
                    <button className="me-2 btn-icon btn-icon-only btn btn-outline-primary">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </button>
                  </Link>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deletePress(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Blogs"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
  //   }
};

export default ManageBlog;
