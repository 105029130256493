// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import {
  capitalizeFirstLetter,
  apiCallError,
} from "../../../utils";
import { handleBannerImg } from "../../../utils/image-utils";

// Helpers
import { toast } from "react-toastify";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllBanners } from "../../../redux/adminSlice/bannerSlice";
import {
  getAllActivityTypes,
  getAllActivities,
} from "../../../redux/adminSlice/activitySlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

import Loader from "../../../Component/Loader";
import { Player } from "video-react";

import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";

let initialValues = {
  bannerTitle: "",
  bannerDesc: "",
  activityTypeId: "",
  activityId: "",
  activityTitle: "",
  countryId: "",
  bannerImg: "",
  bannerVdo: "",
};

const createErrors = {
  bannerTitle: "",
  bannerDesc: "",
  activityTypeId: "",
  activityId: "",
  countryId: "",
  bannerImg: "",
  bannerVdo: "",
};
const EditBanner = () => {
  const dispatch = useDispatch();
  const imgInputRef = useRef(null);
  const vidInputRef = useRef(null);
  const [activityList, setactivityList] = useState([]);
  const [banner, setbanner] = useState(initialValues);
  const [uploadedImg, setuploadedImg] = useState("");
  const [uploadedVid, setuploadedVid] = useState("");
  const [bannerImg, setbannerImg] = useState(null);
  const [bannerVid, setbannerVid] = useState(null);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();


  const { banners } = useSelector((state) => state.adminBannerSlice);
  const { activity_types, activities } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const { countres } = useSelector((state) => state.countrySlice);

  const bannerData = banners?.find((item) => item?._id === params?.id);

  const activitiesForTheType = activities?.filter(
    (item) => item?.activityTypesId === bannerData?.activityTypeId
  );

  useEffect(() => {
    dispatch(getAllBanners());
    dispatch(getAllActivityTypes());
    dispatch(getAllActivities());
    dispatch(getAllcountres());
    setbanner({
      bannerTitle: bannerData?.bannerTitle,
      bannerDesc: bannerData?.bannerDesc,
      activityTypeId: bannerData?.activityTypeId,
      activityId: bannerData?.activityId,
      countryId: bannerData?.countryId,
      bannerImg: bannerData?.bannerImg,
      bannerVdo: bannerData?.bannerVdo,
    });
    setactivityList(activitiesForTheType);
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    let input;

    if (event.target.name !== "bannerDesc") {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }
    setbanner({ ...banner, [event.target.name]: input });
  };

  const getActivitiesForType = (typeId) => {
    let data = activities?.filter((item) => item?.activityTypesId === typeId);

    setactivityList(data);
  };

  const handleVideo = (file) => {
    setuploadedVid(URL.createObjectURL(file));
  };

  const handleValidation = () => {
    const { bannerTitle, bannerDesc, countryId, activityTypeId, activityId } =
      banner;

    if (bannerTitle === "") {
      seterrors({ ...errors, bannerTitle: "Banner name is required" });

      return false;
    }
    if (bannerDesc === "") {
      seterrors({ ...errors, bannerDesc: "Banner description is required" });

      return false;
    }
    if (bannerDesc.length < 5) {
      seterrors({
        ...errors,
        bannerDesc: "Banner description should atleast be 5 characters",
      });
      return false;
    }
    if (activityTypeId === "") {
      seterrors({ ...errors, activityTypeId: "Activity is required" });

      return false;
    }
    if (activityId === "") {
      seterrors({ ...errors, activityId: "An activity is required" });

      return false;
    }
    if (countryId === "") {
      seterrors({ ...errors, countryId: "Country is required" });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    const DATA1 = new FormData();
    const DATA2 = new FormData();

    if (bannerVid === null && bannerImg === null) {
      if (handleValidation()) {
        let bodyData = { id: params.id, ...banner };
        try {
          setisLoading(true);
          let res = await JSON_API["adminEditBanner"](bodyData);

          if (res.isSuccess) {
            dispatch(getAllBanners());
            setbanner(initialValues);
            if (imgInputRef.current) {
              imgInputRef.current.value = "";
            }
            setuploadedImg("");
            if (vidInputRef.current) {
              vidInputRef.current.value = "";
            }
            setuploadedVid("");
            seterrors(createErrors);
            setisLoading(false);
            toast.success("Banner edited successfully!");
            navigate("/manage-banner");
          } else {
            setisLoading(false);
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      }
    } else if (bannerImg !== null && bannerVid === null) {
      DATA1.append("image", bannerImg);
      let imgUpRes = await fileUpload(
        "/admin/upload-bannerImage",
        "POST",
        DATA1
      );

      if (imgUpRes.status && handleValidation()) {
        let bodyData = { id: params.id, ...banner, bannerImg: imgUpRes.data };
        try {
          setisLoading(true);
          let res = await JSON_API["adminEditBanner"](bodyData);

          if (res.isSuccess) {
            dispatch(getAllBanners());
            setbanner(initialValues);
            if (imgInputRef.current) {
              imgInputRef.current.value = "";
            }
            setuploadedImg("");
            if (vidInputRef.current) {
              vidInputRef.current.value = "";
            }
            setuploadedVid("");
            seterrors(createErrors);
            setisLoading(false);
            toast.success("Banner edited successfully!");
            navigate("/manage-banner");
          } else {
            setisLoading(false);
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      } else {
        toast.error("Failed to upload image due to ", imgUpRes.message + ".");
      }
      // }
    } else if (bannerImg === null && bannerVid !== null) {
      if (
        bannerVid.type === "video/x-flv" ||
        bannerVid.type === "video/mp4" ||
        bannerVid.type === "application/x-mpegURL" ||
        bannerVid.type === "video/MP2T" ||
        bannerVid.type === "video/3gpp" ||
        bannerVid.type === "video/quicktime" ||
        bannerVid.type === "video/x-msvideo" ||
        bannerVid.type === "video/x-ms-wmv"
      ) {
        DATA2.append("video", bannerVid);
        let vidUpRes = await fileUpload(
          "/admin/upload-bannervideo",
          "POST",
          DATA2
        );
        if (vidUpRes.status) {
          setbanner((prev) => ({ ...prev, bannerVdo: vidUpRes.data }));
        } else {
          toast.error("Failed to upload video due to ", vidUpRes.message);
        }
      } else {
        toast.error("File uploaded for is not a video for banner.");
      }
      if (handleValidation()) {
        let bodyData = { id: params.id, ...banner };
        try {
          setisLoading(true);
          let res = await JSON_API["adminEditBanner"](bodyData);

          if (res.isSuccess) {
            dispatch(getAllBanners());
            setbanner(initialValues);
            if (imgInputRef.current) {
              imgInputRef.current.value = "";
            }
            setuploadedImg("");
            if (vidInputRef.current) {
              vidInputRef.current.value = "";
            }
            setuploadedVid("");
            seterrors(createErrors);
            setisLoading(false);
            toast.success("Banner edited successfully!");
            navigate("/manage-banner");
          } else {
            setisLoading(false);
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      }
    } else if (bannerImg !== null && bannerVid !== null) {
      DATA1.append("image", bannerImg);
      let imgUpRes = await fileUpload(
        "/admin/upload-bannerImage",
        "POST",
        DATA1
      );
      DATA2.append("video", bannerVid);
      let vidUpRes = await fileUpload(
        "/admin/upload-bannervideo",
        "POST",
        DATA2
      );
      if (imgUpRes.status && vidUpRes.status) {
        setbanner((prev) => ({
          ...prev,
          bannerImg: imgUpRes.data,
          bannerVdo: imgUpRes.data,
        }));
        if (handleValidation()) {
          let bodyData = { id: params.id, ...banner };
          try {
            setisLoading(true);
            let res = await JSON_API["adminEditBanner"](bodyData);

            if (res.isSuccess) {
              dispatch(getAllBanners());
              setbanner(initialValues);
              if (imgInputRef.current) {
                imgInputRef.current.value = "";
              }
              setuploadedImg("");
              if (vidInputRef.current) {
                vidInputRef.current.value = "";
              }
              setuploadedVid("");
              seterrors(createErrors);
              setisLoading(false);
              toast.success("Banner edited successfully!");
              navigate("/manage-banner");
            } else {
              setisLoading(false);
              apiCallError(res?.error?.message, setError);
            }
          } catch (error) {
            setisLoading(false);
            apiCallError(error.message, setError);
          }
        }
      } else {
        toast.error(
          "Failed to upload image and video due to ",
          imgUpRes.message + ", " + vidUpRes.message + " respectively."
        );
      }
      // }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="card-title">Edit Banner</div>
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="bannerTitle" className="form-label">
                  Title*
                </label>
                <input
                  name="bannerTitle"
                  id="bannerTitle"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  value={banner.bannerTitle}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {errors.bannerTitle && (
                <span className="errorMessageText text-danger">
                  {errors.bannerTitle}
                </span>
              )}
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="bannerDesc" className="form-label">
                  Description*
                </label>
                <textarea
                  name="bannerDesc"
                  id="bannerDesc"
                  className="form-control"
                  autoComplete="false"
                  value={banner.bannerDesc}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {errors.bannerDesc && (
                <span className="errorMessageText text-danger">
                  {errors.bannerDesc}
                </span>
              )}
            </div>
            <div className="row" style={{ margin: "0.1rem" }}>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="activityTypeId" className="form-label">
                    Activity Type*
                  </label>
                  <select
                    name="activityTypeId"
                    id="activityTypeId"
                    className="form-control form-select"
                    value={banner.activityTypeId}
                    onChange={(e) => {
                      getActivitiesForType(e.target.value);
                      handleChange(e);
                    }}
                  >
                    <option value="" key={0}>
                      Select
                    </option>
                    {activity_types?.map((item, idx) => {
                      return (
                        <>
                          <option key={idx + 1} value={`${item?._id}`}>
                            {item?.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                {errors.activityTypeId && (
                  <span className="errorMessageText text-danger">
                    {errors.activityTypeId}
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="activityId" className="form-label">
                    Activity*
                  </label>
                  <select
                    name="activityId"
                    id="activityId"
                    className="form-control form-select"
                    value={banner.activityId}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="" key={0}>
                      Select
                    </option>
                    {activityList?.map((item, idx) => {
                      return (
                        <>
                          <option key={idx + 1} value={`${item?._id}`}>
                            {item?.activityTitle}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                {errors.activityId && (
                  <span className="errorMessageText text-danger">
                    {errors.activityId}
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="countryId" className="form-label">
                    Country*
                  </label>
                  <select
                    name="countryId"
                    id="countryId"
                    className="form-control form-select"
                    value={banner.countryId}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="" key={0}>
                      Select
                    </option>
                    {countres?.map((item, idx) => {
                      return (
                        <>
                          <option key={idx + 1} value={`${item?._id}`}>
                            {item?.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                {errors.countryId && (
                  <span className="errorMessageText text-danger">
                    {errors.countryId}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="bannerImg" className="form-label">
                    Banner Image
                  </label>
                  <input
                    name="bannerImg"
                    id="bannerImg"
                    type="file"
                    ref={imgInputRef}
                    accept="image/*"
                    className="form-control"
                    onChange={(e) => {
                      setbannerImg(e.target.files[0]);
                      handleBannerImg(
                        e.target.files[0],
                        setuploadedImg,
                        errors,
                        seterrors,
                        "bannerImg"
                      );
                    }}
                  />
                </div>
                {uploadedImg.length > 0 ? (
                  <img
                    style={{ height: "100px", width: "100px" }}
                    src={uploadedImg}
                    alt="bannerImg"
                  />
                ) : (
                  <img
                    style={{ height: "100px", width: "100px" }}
                    src={banner.bannerImg}
                    alt="bannerImg"
                  />
                )}
                {errors.bannerImg && (
                  <span className="errorMessageText text-danger">
                    {errors.bannerImg}
                  </span>
                )}
              </div>
              <div className="col-md-6 d-none">
                <div className="mb-3">
                  <label htmlFor="bannerVdo" className="form-label">
                    Banner Video
                  </label>
                  <input
                    name="bannerVdo"
                    id="bannerVdo"
                    type="file"
                    accept="video/*"
                    ref={vidInputRef}
                    className="form-control"
                    onChange={(e) => {
                      setbannerVid(e.target.files[0]);
                      handleVideo(e.target.files[0]);
                    }}
                  />
                </div>
                {uploadedVid.length > 0 ? (
                  <div style={{ height: "100px", width: "240px" }}>
                    <Player
                      playsInline
                      src={uploadedVid}
                      fluid={false}
                      width={180}
                      height={120}
                    />
                  </div>
                ) : (
                  <div style={{ height: "100px", width: "240px" }}>
                    <Player
                      playsInline
                      src={banner.bannerVdo}
                      fluid={false}
                      width={180}
                      height={120}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                navigate("/manage-banner");
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleEditSubmit}
              className="mt-2 btn btn-primary  d-table"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBanner;
