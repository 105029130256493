// Hooks
import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";




// Components
import Loader from "../../../Component/Loader";
import { ApiHelperFunction } from "../../../services/api/apiHelpers";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

function BookingDetailedView() {
  const params = useParams();
  const id = params?.id;

  console.log("3434343", id)
  const navigate = useNavigate();


  const [isLoading, setisLoading] = useState(false);
  // Redux stores

  const [detailData, setdetailsData] = useState(null)

  const fetchSingleBookings = async () => {

    let response = await ApiHelperFunction({
      urlPath: `/admin/view-singleking-details/${params?.id}`,
      method: 'GET',
    });
    console.log(response?.data.data[0], "ooooo")
    if (response && response.status) {

      setdetailsData(response?.data.data[0])
    }

  }

  useEffect(() => {
    fetchSingleBookings()

  }, [params?.id]);

  const handleCancelBooking = async () => {




    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure ?",
       text: "You can't be Revert This Booking",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
    try {
      let res = await ApiHelperFunction({
        urlPath: `/admin/delete_booking/${params?.id}`,
        method: "POST"
      });

      if (res && res?.status) {
        swalWithBootstrapButtons.fire({
          title: "Canceled!",
          // text: "Your booking Hash been Deleted",
          icon: "success"
        });
     
        fetchSingleBookings();
        // toast.success(res?.message || "Booking is Cancelled");
        navigate("/view-bookings")
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  } else {


  }
    });

  }


  return (
    <>
      {isLoading && <Loader />}
      <div className="info-details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex">
              <div className="obser-top">
                <p>{`Booking Details` /**${detailData?.merchantName} :*/}</p>
              </div>
              {/* <div
                className="obser-top"
                style={{
                  marginLeft: "auto",
                }}
              >
                <Link to="/view-bookings" className="btn btn-secondary btn-lg">
                  Back
                </Link>
              </div> */}
            </div>
          </div>

          <div className="">
            <div className="des-detail">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Activity Name</span>
                  <h6>
                    {detailData?.activity[0]?.activityTitle
                      ?.charAt(0)
                      ?.toUpperCase() +
                      detailData?.activity[0]?.activityTitle.slice(1)}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Booking Date</span>
                  <h6>{detailData?.bookingDate}</h6>
                </div>
                <div className="DetailsData">
                  <span>Participants</span>
                  <h6>
                    {detailData?.participentType?.map((item, index) => {
                      const capitalizedItem = item?.pertype?.charAt(0)?.toUpperCase() + item?.pertype?.slice(1)?.toLowerCase();
                      const isLastIndex = index === detailData.participentType.length - 1;
                      return isLastIndex ? capitalizedItem : capitalizedItem + ', ';
                    }).join('')
                    }
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Payment Mode</span>
                  <h6 className="">{detailData?.paymentMode}</h6>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Activity Owner</span>
                  <h6>{detailData?.activity[0]?.addedBY}</h6>
                </div>
                <div className="DetailsData">
                  <span>Booking Time</span>
                  <h6>{detailData?.bookingTime}</h6>
                </div>
                <div className="DetailsData">
                  <span>Total Number of Persons</span>
                  <div>
                    {" "}
                    <h6>{detailData?.totalTourPerson}</h6>
                  </div>
                </div>
                <div className="DetailsData">
                  <span>Booking Reference ID</span>
                  <div>
                    {" "}
                    <h6>{detailData?.alpfaNueID}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="col-md-6 col-6">
              <div className="column">
                <div
                  className="employeeDetails"
                  style={{ padding: 20, display: "flex" }}
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex">
              <div className="obser-top">
                <p>{`User Details` /**${detailData?.merchantName} :*/}</p>
              </div>
            </div>
          </div>

          <div className="">
            <div className="des-detail">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>User Name</span>
                  <h6>
                    {detailData?.userDetails?.firstName
                      .charAt(0)
                      ?.toUpperCase() +
                      detailData?.userDetails?.firstName?.slice(1) +
                      " " +
                      detailData?.userDetails?.lastName
                        .charAt(0)
                        ?.toUpperCase() +
                      detailData?.userDetails?.lastName?.slice(1)}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Email</span>
                  <h6>{detailData?.userDetails?.email}</h6>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Mobile</span>
                  <h6>{detailData?.userDetails?.mobileNo}</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="col-md-6 col-6">
              <div className="column">
                <div
                  className="employeeDetails"
                  style={{ padding: 20, display: "flex" }}
                >
                  <div className="DetailsData mr-5">
                    <span>Activity Image</span>
                    <div
                      className="d-flex task_img"
                      style={{ width: "55rem", overflowX: "auto" }}
                    >
                      {detailData?.activity[0]?.image?.map((item, idx) => (
                        <img
                          key={idx}
                          src={item}
                          className="img-fluid mr-5"
                          alt=""
                          style={{ height: "6rem", width: "9rem" }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="obser-top mb-8"
        style={{
          float: "right",
        }}
      >
        <Link to="" className="btn btn-success btn-2xl" onClick={() => handleCancelBooking()}>
          Cancel
        </Link>
      </div>
    </>
  );
}

export default BookingDetailedView;
