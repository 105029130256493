import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
    status: "idle",
    visitedUsers: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
  };

  export const getAllVisitedUser = createAsyncThunk(
    "get_all_visited_user",
    async () => {
      let response = await ApiHelperFunction({
        urlPath: "/admin/get-all-visited-user",
        method: "GET",
      });

      console.log("visited_user", response)
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Can't get data. Something went wrong");
      }
    }
  );
  

  export const VisitedUserSlice = createSlice({
    name: "visited_user_actions",
    initialState,
    reducers: {
      clearState: (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAllVisitedUser.pending, (state) => {
          state.status = "loading";
          state.isLoading = true;
        })
        .addCase(getAllVisitedUser.fulfilled, (state, { payload }) => {
          state.status = "success";
          state.isSuccess = true;
          state.isLoading = false;
          state.visitedUsers = payload.data;
        })
        .addCase(getAllVisitedUser.rejected, (state) => {
          state.status = "failed";
          state.isLoading = false;
          state.isError = true;
          state.isSuccess = false;
        });
    },
  });
  
  export const { clearState } = VisitedUserSlice.actions;
  
  export default VisitedUserSlice.reducer;