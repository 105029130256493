import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Redux actions
// import { getProfileDetails } from '../../../redux/authSlice/adminProfileSlice';
// import { getAllSubadmins } from '../../../redux/adminSlice/subadminActionsSlice';

// Components
import Layout from "../../../Layout";
import Dashboard from "./Dashboard";
import Loader from "../../../Component/Loader";

// New Product newMerchantActivity, merchantActivityChange
import { newMerchantActivity } from "../../../redux/adminSlice/activitySlice";
import { merchantActivityChange } from "../../../redux/adminSlice/activitySlice";

function Index() {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const location = useLocation();
  // let from = location.pathname || "/";
  // const [loggedIn, setloggedIn] = useState(false);
  // const [isLoading, setisLoading] = useState(false);

  // useEffect(() => {
  // if (
  //   !localStorage.getItem("Authorization") &&
  //   !localStorage.getItem("userType")
  //   // reactLocalStorage.get("Authorization") &&
  //   // reactLocalStorage.get("userType")
  // ) {
  //   setisLoading(true);
  // }
  // else {
  //   dispatch(getProfileDetails());
  //   dispatch(getAllSubadmins());
  //   setisLoading(false);
  // }
  // }, []);

  // New Product
  // const dispatch = useDispatch();
  // if (
  //   localStorage.getItem("Authorization") &&
  //   localStorage.getItem("userType")
  // ) {
  //   dispatch(newMerchantActivity());
  //   dispatch(merchantActivityChange());
  // }

  return <Dashboard />;
}

export default Index;
