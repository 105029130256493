// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


// Utilities
import { apiCallSuccess, apiCallError } from "../../../../utils/common-utils";

// API functions
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllBlogTypes } from "../../../../redux/adminSlice/blogSlice";

// Components
import Table from "../../../../Component/Table";
import Loader from "../../../../Component/Loader";

const ManageCategory = () => {
  // function AddCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);

  // Component did mount
  useEffect(() => {
    dispatch(getAllBlogTypes());
  }, []);

  const { blog_types } = useSelector((state) => state.blogsSlice);

  const openModal = () => {
    setModal(true);
  };

  const deleteCategory = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["deleteBlogType"]({ id: id });

      if (res.isSuccess) {
        dispatch(getAllBlogTypes());
        setModal(false);
        setisLoading(false);
        apiCallSuccess(res?.data);
      } else {
        setisLoading(false);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      blog_types?.length > 0
        ? blog_types.map((item, idx) => {
            return {
              sl: idx + 1,
              name: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div
                      className="widget-content-left flex2"
                      style={{ textAlign: "center" }}
                    >
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item?.name}
                      </div>
                    </div>
                  </div>
                </div>
              ),

              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setdeleteId(item?._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/edit-blog-type/${item?._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Category</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCategory(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle="All categories"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
  //   }
};

export default ManageCategory;
