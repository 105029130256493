import React, { useEffect, useState } from 'react';
import { requestData } from "../../../services/api/apiHelpers";
import Loader from "../../../Component/Loader";
import { Button, IconButton, } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import RemoveIcon from '@mui/icons-material/Remove';
import Form from 'react-bootstrap/Form';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams, NavLink } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "./CalenderModel.css"

function ManageAvailability() {
    const [isLoading, setisLoading] = useState(false);
    const { id } = useParams();
    const [updatedid, setupdatedid] = useState("")
    const [updatedtileslot, setupdatedtileslot] = useState("")
    const [meditrDate, setmeditrDate] = useState("")
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [selmonweekvalue, setselmonweekvalue] = useState("Week")
    const [changes, setChanges] = useState('')
    const currentDate = new Date();
    const [currentDateState, setCurrentDateState] = useState(formatDate(currentDate));
    const [endDateState, setendDateState] = useState(null)
    const { activities } = useSelector(
        (state) => state.adminActivityActionSlice
    );
    const currentActivityData = activities?.find((item) => item?._id === id);
    const activityend_date = formatDate(new Date(currentActivityData.endDate));

    const [endDate, setEndDate] = useState(activityend_date);
    const inputDate = currentDateState;
    const seventhDay = endDateState;

    useEffect(() => {
        if (selmonweekvalue == "Month") {
            findthirthyDate()
        } else {
            findSeventhDate()
        }
    }, [selmonweekvalue])

    const findSeventhDate = () => {
        const date = new Date(currentDateState);
        date.setDate(date.getDate() + 6);
        setCurrentDateState(formatDate(currentDate))
        setendDateState(formatDate(date));
    }

    const findthirthyDate = () => {
        if (!currentDateState) {
            return { startDate: null, endDate: null };
        }
        const [year, month] = currentDateState.split('-').map(Number);
        if (isNaN(year) || isNaN(month)) {
            return { startDate: null, endDate: null };
        }
        const startDate = new Date(year, month - 1, 1);
        const endDate = new Date(year, month, 0);
        setendDateState(formatDate(endDate))
        setCurrentDateState(formatDate(startDate))
        return { startDate, endDate };
    }

    const viewAllAvailability = async () => {
        if (!endDateState) {
            setisLoading(true)
            return;
        }

        try {
            let res = await requestData(
                `/admin/view-all-availability`,
                "POST", { activityDetailsId: currentActivityData.activityDetailsId, inputDate, seventhDay }
            );
            if (res.status) {
                const dftData = res?.data
                dftData.sort((a, b) => new Date(a.tourDate) - new Date(b.tourDate))
                const dataslottime = {};

                dftData.forEach(item => {
                    const { _id, remeningUser, tourDate, time, status, addedById } = item;
                    if (!dataslottime[time]) {
                        dataslottime[time] = [];
                    }
                    dataslottime[time].push({ _id, remeningUser, tourDate, time, copyremainuser: remeningUser, status, addedById });
                });
                setupdatedtileslot(dataslottime);
                setisLoading(false)
            } else {
                setisLoading(true)
            }
        } catch (error) {
            toast.error(error.message)
        }
    };


    useEffect(() => {
        if (inputDate && seventhDay) {
            viewAllAvailability()
        }
        viewAllAvailability()
    }, [inputDate, seventhDay])

    function formatDatenew(inputDate) {
        const date = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    }

    const [remainuseredited, setremainuseredited] = useState(" ")
    const handleChange = (e) => {
        setremainuseredited(e.target.value)
    };

    const incrementbyone = (item, i) => {
        let slotdata = updatedtileslot[item.time]?.find(slot => slot?._id === item?._id)
        slotdata.copyremainuser = slotdata.copyremainuser + 1
        let updatedSlot = updatedtileslot[item.time]
        updatedSlot[i] = slotdata
        setupdatedtileslot((prev) => ({ ...prev, [item.time]: updatedSlot }))
        setChanges(updatedSlot)

    };
    const decrementbyone = (item, i) => {
        let slotdata = updatedtileslot[item.time]?.find(slot => slot._id === item._id)
        slotdata.copyremainuser = slotdata.copyremainuser - 1
        let updatedSlot = updatedtileslot[item.time]
        updatedSlot[i] = slotdata
        setupdatedtileslot((prev) => ({ ...prev, [item.time]: updatedSlot }))
        setChanges(updatedSlot)
    };



    const deleteavailabilityoneday = async (id) => {
        setisLoading(true)
        try {
            let res = await requestData(
                `/admin/edit-oneday-availability_zero`,
                "POST", { id, remeningUser: 0 }
            );
            if (res.status) {
                viewAllAvailability()
                setisLoading(false)
                toast.success(" Availability Zero  Successfully");

            } else {
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    const editavailabilityoneday = async () => {
        setisLoading(true)
        try {
            let res = await requestData(
                `/admin/edit-only-one`,
                "POST", { id: updatedid, remainingUsers: remainuseredited }
            );
            if (res.status) {
                viewAllAvailability()
                setisLoading(false)
                toast.success(" Availability edited  Successfully");
                setShow(false)

            } else {
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    const toggle = (itemst) => {
        setupdatedid(itemst._id)
        setmeditrDate(itemst.tourDate)
        setShow(true)
    }
    const updateavailabilityoneday = async () => {
        setisLoading(true)
        let copyslotdata = JSON.parse(JSON.stringify(changes))
        const updatedData = copyslotdata.map((slot) => ({ ...slot, remeningUser: slot.copyremainuser }))
        try {
            let res = await requestData(
                `/admin/edit-oneday-availability`,
                "POST", { slotArray: updatedData }
            );
            if (res.status) {
                setisLoading(false)
                viewAllAvailability()
                toast.success("Update Remaining seat Successfully");

            } else {

            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    const handleNextWeek = (btntype) => {
        const nextWeek = new Date(currentDateState);
        nextWeek.setDate(nextWeek.getDate() + 7);
        const end = new Date(nextWeek);
        end.setDate(end.getDate() + 6)
        if (nextWeek <= new Date(endDate)) {
            setCurrentDateState(formatDate(nextWeek));
            setendDateState(formatDate(end))
        } else {
            toast.success(" there is no slot Availability");
        }
    };

    const handlePrevWeek = () => {
        const prevWeek = new Date(currentDateState);
        prevWeek.setDate(prevWeek.getDate() - 1);
        const end = new Date(prevWeek);
        end.setDate(end.getDate() - 6)
        if (prevWeek >= new Date(formatDate(currentDate))) {
            setCurrentDateState(formatDate(end));
            setendDateState(formatDate(prevWeek));
        } else {
            toast.error(" can,t see expire Availability ");
        }
    };

    const handleNextMonth = () => {
        const nextMonth = new Date(currentDateState);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        const lmnextMonth = formatDate(nextMonth)
        if (nextMonth <= new Date(endDate)) {
            if (!lmnextMonth) {
                return { startDate: null, endDate: null };
            }
            const [year, month] = lmnextMonth.split('-').map(Number);
            if (isNaN(year) || isNaN(month)) {
                return { startDate: null, endDate: null };
            }
            const startDate = new Date(year, month - 1, 1);
            const endDate = new Date(year, month, 0);
            setendDateState(formatDate(endDate))
            setCurrentDateState(formatDate(startDate))
            return { startDate, endDate };

        }

    };

    const getMonthYearFromDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return null;
        }
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const monthString = month < 10 ? `0${month}` : `${month}`;
        return `${monthString}-${year}`;
    }


    const handlePrevMonth = () => {
        const prevMonth = new Date(currentDateState);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        const lmprevMonth = formatDate(prevMonth)
        const x = getMonthYearFromDate(prevMonth)
        const y = getMonthYearFromDate(new Date(formatDate(currentDate)))
        if (y <= x) {
            if (!lmprevMonth) {
                return { startDate: null, endDate: null };
            }
            const [year, month] = lmprevMonth.split('-').map(Number);
            if (isNaN(year) || isNaN(month)) {
                return { startDate: null, endDate: null };
            }
            const startDate = new Date(year, month - 1, 1);
            const endDate = new Date(year, month, 0);
            setendDateState(formatDate(endDate))
            setCurrentDateState(formatDate(startDate))
            return { startDate, endDate };
        }
        else {
            toast.error(" can,t see expire Availability ");
        }

    };
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const hasChanges = Object.values(changes).some((val) => val !== 0);
    const handleChangeselect = (e) => {
        setselmonweekvalue(e.target.value);
    };


    const deactivateTimeSlot = async (time) => {
        setisLoading(true)
        try {
            let res = await requestData(
                `/admin/deActivateSlotAvailability`,
                "POST", { slotStatus: false, slotTime: time, activityDetailsId: currentActivityData.activityDetailsId }
            );
            if (res.status) {

                viewAllAvailability()
                setisLoading(false)
                toast.success("  slot Deactivated Successfully");
            } else {
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    const activateTimeSlot = async (time) => {
        setisLoading(true)
        try {
            let res = await requestData(
                `/admin/deActivateSlotAvailability`,
                "POST", { slotStatus: true, slotTime: time, activityDetailsId: currentActivityData.activityDetailsId }
            );
            if (res.status) {
                viewAllAvailability()
                setisLoading(false)
                toast.success(" Slot Activated Successfully");
            } else {
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
    return (
        <>
            {isLoading && <Loader />}
            <div className='tour_name_ad'> <h1> <b> Activity Title : </b> {currentActivityData.activityTitle}</h1> </div>
            <span>  <Button variant="contained">
                <NavLink to={`/add-activity-availability/${currentActivityData._id}`} className="metismenu-link" > Add Availability  </NavLink>
            </Button></span>


            <hr />
            <div className='headercalender'>

                <div className='selmonweek'>

                    <Form.Select aria-label="Default select example" size='sm' onChange={handleChangeselect}  >
                        <option value="Week">Week</option>
                        <option value="Month">Month</option>
                    </Form.Select>
                </div>
                {selmonweekvalue == "Month" ? <>   <IconButton className='iconbtn' onClick={() => handlePrevMonth("month")}  ><ArrowBackIosNewIcon sx={{ fontSize: '15px' }} /> <span className='monthweek'> Month</span> </IconButton>
                    <b>Date : </b> <span> {formatDatenew(currentDateState)}</span>  <b> To </b><span> {formatDatenew(endDateState)}</span>
                    <IconButton onClick={() => handleNextMonth("month")}>  <span className='monthweek'> Month</span> <ArrowForwardIosIcon sx={{ fontSize: '15px' }} /> </IconButton>   </> : <>      <IconButton onClick={() => handlePrevWeek("week")} ><ArrowBackIosNewIcon sx={{ fontSize: '15px' }} /> <span className='monthweek'> week</span> </IconButton>
                    <b>Date :</b> <span> {formatDatenew(currentDateState)}</span>   <b> To </b> <span> {formatDatenew(endDateState)}</span>
                    <IconButton onClick={() => handleNextWeek("week")}> <span className='monthweek'>Week </span>   <ArrowForwardIosIcon sx={{ fontSize: '15px' }} /> </IconButton>  </>}
            </div>
            <hr />
            {Object.keys(updatedtileslot)?.length > 0 ? Object.keys(updatedtileslot)?.map((time) => (
                <div key={time}>

                    <Accordion className='m-1' defaultActiveKey={['0']} alwaysOpen >
                        <Accordion.Item eventKey={time} >
                            <Accordion.Header>
                                <div className='slot_time_ss'>
                                    <Button className='m-2 slot_button_kk' variant="contained" >{time}</Button>
                                    <Button className='m-2 slot_button_kkr' variant="contained" onClick={() => deactivateTimeSlot(time)} > Deactivate TimeSlot</Button>
                                    <Button className='m-2 slot_button_kkaa' variant="contained" onClick={() => activateTimeSlot(time)} > Activate TimeSlot </Button>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='sdfrghj'>
                                    {updatedtileslot[time]?.length > 0 && updatedtileslot[time]?.map((itemst, index) => (

                                        <div key={itemst._id} className={itemst.tourDate < formatDate(new Date()) ? " paginationkms red" : "paginationkms"}>
                                            <div className={itemst.status ? "top_one_db" : "top_one_db deactive"}>
                                                <span > <b>  {formatDatenew(itemst.tourDate)}</b> </span>
                                                <IconButton onClick={() => { deleteavailabilityoneday(itemst._id) }}>
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </div>
                                            <div className={itemst.tourDate < formatDate(new Date()) ? " top_tow_bdf" : "top_tow_bd"}>
                                                <IconButton onClick={() => { decrementbyone(itemst, index) }} disabled={itemst.copyremainuser > 1 ? false : true} >
                                                    <RemoveIcon />
                                                </IconButton>

                                                <span onClick={() => toggle(itemst)}> {itemst.copyremainuser} </span>
                                                <IconButton onClick={() => { incrementbyone(itemst, index) }} >
                                                    <AddIcon />
                                                </IconButton>
                                            </div>


                                            <div className='top_three_db'>
                                                <span><b>{itemst.time}</b> </span>
                                                <Button className={itemst.remeningUser > 0 ? "remain_btn" : "remain_btn_red"} variant="contained"  > {itemst.remeningUser} </Button>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>




                </div>
            )) : <span className='metismenu-link-k-s'>  <Button variant="contained">
                <NavLink to={`/add-activity-availability/${currentActivityData._id}`} className="metismenu-link-k" > There is no  Availability added for that Date , Want to add <b> Click</b>  </NavLink>
            </Button></span>}

            <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
                <Button variant="contained" className={!hasChanges ? 'disabledSaveAndCont' : "saveAndContinue"} onClick={updateavailabilityoneday} disabled={!hasChanges} >
                    Save & continue
                </Button>
            </div>
            <div>
                <Dialog onClose={handleClose} open={show}>
                    <DialogTitle> <b> {meditrDate}</b></DialogTitle>
                    <div className='modalbtn_tpm'>
                        <input
                            type="number"
                            value={remainuseredited}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <Button variant='contained' onClick={editavailabilityoneday} className='edtbtn m-2'> update</Button>
                </Dialog>
            </div>
            <div>
            </div>
        </>
    )
}

export default ManageAvailability