// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utilities
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";
import useImgUploader from "../../../Hooks/use-img-uploader";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllActivityTypes } from "../../../redux/adminSlice/activitySlice";

// Components
import Loader from "../../../Component/Loader";

const initialValues = {
  activityTypeId: "",
  subType: "",
  title: "",
  image: "",
};

const createErrors = {
  activityTypeId: "",
  subType: "",
  title: "",
};

const subtypes = ["activities", "sights", "cities"];

const imgUpApiConfig = {
  url: "/admin/uploadActivitySiteImage",
  propertyName: "data",
};

function AddSection() {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [section, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isSubtypeSite, setisSubtypeSite] = useState(false);
  const { Img, setImg, imageHandler, imgComp, imgLoading } =
    useImgUploader(imgUpApiConfig);
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { activity_types } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  // console.log("activity_types", activity_types);

  // Component did mount
  useEffect(() => {
    dispatch(getAllActivityTypes());
  }, []);

  const handleChange = (event) => {
    // console.log(event.target.name, event.target.value);
    let input;

    if (
      event.target.name === "activityTypeId" ||
      event.target.name === "subType"
    ) {
      input = event.target.value;
    } else {
      input = capitalizeFirstLetter(event);
    }

    setsection({ ...section, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { title, activityTypeId, subType } = section;

    if (title === "") {
      seterrors({ ...createErrors, title: "Section title is required" });

      return false;
    }
    if (activityTypeId === "") {
      seterrors({
        ...createErrors,
        activityTypeId: "Activity type is required",
      });

      return false;
    }

    if (subType === "") {
      seterrors({ ...createErrors, subType: "Section subtype is required" });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (imgLoading) return;
    console.log("submitting", section);
    if (handleValidation()) {
      let bodyData = {
        ...section,
        image: Img !== "" ? Img : null,
      };
      try {
        let res = await JSON_API["adminAddSection"](bodyData);

        if (res.isSuccess) {
          seterrors(createErrors);
          setsection(initialValues);
          setImg("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setisLoading(false);
          // apiCallSuccess(res.data);
          toast.success("Section added successfully.");
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">

            <div className="card-body">
            <div className="card-title">Add Section</div>

            <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Section Title*
                </label>
                <input
                  name="title"
                  id="title"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  value={section.title}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {errors.title && (
                <span className="errorMessageText text-danger">
                  {errors.title}
                </span>
              )}
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="activityTypeId" className="form-label">
                  Choose Activity Type*
                </label>
                <select
                  name="activityTypeId"
                  id="activityTypeId"
                  className="form-control form-select"
                  value={section.activityTypeId}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" key={0}>
                    Select
                  </option>
                  {activity_types?.map((item, idx) => {
                    return (
                      <>
                        <option key={idx + 1} value={`${item?._id}`}>
                          {item?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              {errors.activityTypeId && (
                <span className="errorMessageText text-danger">
                  {errors.activityTypeId}
                </span>
              )}
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="subType" className="form-label">
                  Choose Activity Subtype*
                </label>
                <select
                  name="subType"
                  id="subType"
                  className="form-control form-select"
                  value={section.subType}
                  onChange={(e) => {
                    if (e.target.value === "sights") {
                      setisSubtypeSite(true);
                    } else {
                      setisSubtypeSite(false);
                    }
                    handleChange(e);
                  }}
                >
                  <option value="" key={0}>
                    Select
                  </option>
                  {subtypes?.map((item, idx) => {
                    return (
                      <>
                        <option key={idx + 1} value={`${item}`}>
                          {item.charAt(0).toUpperCase() + item.slice(1)}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              {errors.subType && (
                <span className="errorMessageText text-danger">
                  {errors.subType}
                </span>
              )}
            </div>
            </div>


            {isSubtypeSite && (
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Image
                  </label>
                  <input
                    name="image"
                    id="image"
                    type="file"
                    accept={"image/*"}
                    ref={fileInputRef}
                    className="form-control"
                    onChange={imageHandler}
                  />
                </div>
                {imgComp}
              </div>
            )}

            <button
              className="mt-2 btn btn-primary ml-auto d-table ml-2"
              type="submit"
              style={{ margin: "10px" }}
            >
              Submit
            </button>
              </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddSection;
