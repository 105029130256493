// Hooks
import React, { useState, useEffect, useCallback } from "react";

import { ApiHelperFunction } from "../../../../services/api/apiHelpers";
import toast from "react-hot-toast";

// Components
// import { ApiHelperFunction } from "../../../services/api/apiHelpers";

function SingleModel({ singleid, setopenModel, allbookingdata }) {
  const [detailData, setdetailsData] = useState(null);
  const [fromdata, setfromdata] = useState({ amount: "", remarke: "" });
  console.log(fromdata, "oppppp", detailData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "amount") {
      if (e.target.value > detailData.amount) {
        toast.error("You Can not refund more than booking amount");
        return;
      }
    }
    setfromdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchSingleBookings = useCallback(async () => {
    let response = await ApiHelperFunction({
      urlPath: `/admin/view-singleking-details/${singleid}`,
      method: "GET",
    });

    if (response && response.status) {
      console.log(response?.data.data[0], "kk5566");
      setdetailsData(response?.data.data[0]);
    }
  }, [singleid]);

  const refundPrice = useCallback(async () => {
    if (!fromdata?.amount) {
      toast.error("please select amount");
      return;
    }
    if (!fromdata?.remarke) {
      toast.error("please select remarks");
      return;
    }

    const data = {
      amount: fromdata.amount * 100,
      remarks: fromdata.remarke,
    };
    let response = await ApiHelperFunction({
      urlPath: `/admin/order-refund/${singleid}`,
      method: "POST",
      data: data,
    });
    console.log("resp34343onse", response);
    if (response && response?.data?.status) {
      setopenModel(false);
      toast.success("refund Requested sucsessfuly");
      allbookingdata();
      setfromdata({ amount: "", remarke: "" });
    } else {
      setopenModel(false);
      // toast.error("already applied for request")
      toast.error(response?.response?.data?.message);
    }
  }, [singleid, fromdata.amount, fromdata.remarke]);

  useEffect(() => {
    fetchSingleBookings();
  }, [singleid, fetchSingleBookings]);

  return (
    <div
      style={{
        position: "fixed",
        top: "10px",
        left: 0,
        right: 0,
        bottom: 0,
        width: "800px",
        height: "800px",
        zIndex: 9,
        margin: "auto",
        backgroundColor: "#d5d5d5",
      }}
    >
      <div className="info-detailssss ">
        <div className="info-details ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="obser-top">
                  <p>{`Booking Details` /**${detailData?.merchantName} :*/}</p>
                </div>
                <div
                  className="obser-top"
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                    fontSize: "30px",
                  }}
                  onClick={() => {
                    setopenModel(false);
                  }}
                >
                  x
                </div>
              </div>
            </div>

            <div className="">
              <div className="des-detailnew">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="DetailsData">
                    <span>Activity Name</span>
                    <h6>
                      {detailData?.activity[0]?.activityTitle
                        ?.charAt(0)
                        ?.toUpperCase() +
                        detailData?.activity[0]?.activityTitle.slice(1)}
                    </h6>
                  </div>
                  <div className="DetailsData">
                    <span>Booking Date</span>
                    <h6>{detailData?.bookingDate}</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Participants</span>
                    <h6>
                      {detailData?.participentType
                        ?.map((item, index) => {
                          const capitalizedItem =
                            item?.pertype?.charAt(0)?.toUpperCase() +
                            item?.pertype?.slice(1)?.toLowerCase();
                          const isLastIndex =
                            index === detailData.participentType.length - 1;
                          return isLastIndex
                            ? capitalizedItem
                            : capitalizedItem + ", ";
                        })
                        .join("")}
                    </h6>
                  </div>
                  <div className="DetailsData">
                    <span>Payment Mode</span>
                    <h6 className="">{detailData?.paymentMode}</h6>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="DetailsData">
                    <span>Activity Owner</span>
                    <h6>{detailData?.activity[0]?.addedBY}</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Booking Time</span>
                    <h6>{detailData?.bookingTime}</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Total Number of Persons</span>
                    <div>
                      {" "}
                      <h6>{detailData?.totalTourPerson}</h6>
                    </div>
                  </div>
                  <div className="DetailsData">
                    <span>Booking Reference ID</span>
                    <div>
                      {" "}
                      <h6>{detailData?.alpfaNueID}</h6>
                    </div>
                  </div>

                  <div className="DetailsData">
                    <span>Boking Amount</span>
                    <div>
                      <h6>
                        {detailData?.amount}{" "}
                        {detailData?.activity[0].currency.symbol}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="col-md-6 col-6">
                <div className="column">
                  <div
                    className="employeeDetails"
                    style={{ padding: 20, display: "flex" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="info-details" style={{ marginTop: "-87px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 d-flex">
                <div className="obser-top">
                  <p>{`User Details` /**${detailData?.merchantName} :*/}</p>
                </div>
              </div>
            </div>

            <div className="">
              <div className="des-detail">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="DetailsData">
                    <span>User Name</span>
                    <h6>
                      {detailData?.userDetails?.firstName
                        .charAt(0)
                        ?.toUpperCase() +
                        detailData?.userDetails?.firstName?.slice(1) +
                        " " +
                        detailData?.userDetails?.lastName
                          .charAt(0)
                          ?.toUpperCase() +
                        detailData?.userDetails?.lastName?.slice(1)}
                    </h6>
                  </div>
                  <div className="DetailsData">
                    <span>Email</span>
                    <h6>{detailData?.userDetails?.email}</h6>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="DetailsData">
                    <span>Mobile</span>
                    <h6>{detailData?.userDetails?.mobileNo}</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="col-md-6 col-6">
                <div className="column">
                  <div className="employeeDetails" style={{ display: "flex" }}>
                    <div className="DetailsData ">
                      <span>Activity Image</span>
                      <div
                        className="d-flex task_img"
                        style={{ width: "55rem", overflowX: "auto" }}
                      >
                        {detailData?.activity[0]?.image?.map((item, idx) => (
                          <img
                            key={idx}
                            src={item}
                            className="img-fluid mr-1"
                            alt=""
                            style={{ height: "3rem", width: "3rem" }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <form>
                <div
                  class="form-group"
                  style={{
                    width: "300px",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <label for="exampleInputEmail1">Refunded Amount</label>
                  <input
                    type="number"
                    name="amount"
                    value={fromdata.amount}
                    onChange={handleChange}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div
                  class="form-group"
                  style={{
                    width: "300px",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <label for="exampleInputPassword1">Remark</label>
                  <input
                    type="textarea"
                    name="remarke"
                    value={fromdata.remarke}
                    onChange={handleChange}
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>

                <button
                  style={{ display: "inline-block", marginLeft: "10px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    refundPrice();
                  }}
                  class="btn btn-primary"
                >
                  Refund
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleModel;
