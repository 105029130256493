// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utils
import {
  allowOnlyPositiveInteger,
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
  checkFileDimension,
} from "../../../utils";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllCities } from "../../../redux/adminSlice/citySlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";
import { getAllActivityTypes } from "../../../redux/adminSlice/activitySlice";
import { getAllSections } from "../../../redux/adminSlice/sectionSlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import Select from "react-select";

var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;

let initialValues = {
  // activityTypeId: "",
  // sectionId: "",
  countryId: "",
  countryName: "",
  cityName: "",
  picture: "",
  topPriority: 0,
};

let createErrors = {
  // activityTypeId: "",
  // sectionId: "",
  countryId: "",
  cityName: "",
  picture: "",
  topPriority: "",
};

function AddCities() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [city, setcity] = useState(initialValues);
  const [Img, setImg] = useState("");
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  // Redux stores
  const { countres } = useSelector((state) => state.countrySlice);

  // Component did mount
  useEffect(() => {
    dispatch(getAllCities());
    dispatch(getAllcountres());
    dispatch(getAllActivityTypes());
    dispatch(getAllSections());
  }, [dispatch]);
  // const ShortedCountry = countres?.sort((a, b) => a.name.localeCompare(b.name));
  // console.log('shorted countries',)
  const handleChange = (event) => {
    let input;

    if (event.target.name === "topPriority") {
      input = allowOnlyPositiveInteger(event);
    } else {
      input = capitalizeFirstLetter(event);
    }
    // console.log(event.target.name, event.target.value);
    setcity({ ...city, [event.target.name]: input });
  };
  const shortedCitys = (cityArr)=>{
    cityArr.sort((a, b) => {
      const wordA = a.name.toLowerCase();
      const wordB = b.name.toLowerCase();

      if (wordA < wordB) {
        return -1;
      } else if (wordA > wordB) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  const handleValidation = () => {
    const { countryId, cityName, topPriority } = city;

    if (countryId === "") {
      seterrors({ ...createErrors, countryId: "Country is required" });

      return false;
    }
    if (cityName === "") {
      seterrors({ ...createErrors, cityName: "City name is required" });

      return false;
    }
    if (topPriority === 0) {
      seterrors({
        ...createErrors,
        topPriority: "Please provide a priority number.",
      });

      return false;
    }

    return true;
  };

  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    if (imgLoading) return;
    const x = Array.from(e.target.files)[0];
    setImg(URL.createObjectURL(x));
    let fileObj = await checkFileDimension(x);
    // console.log(fileObj);
    if (fileObj?.height > 720) {
      seterrors({
        ...errors,
        picture: "File height should be less than 720px",
      });

      return;
    }
    if (fileObj?.width > 1280) {
      seterrors({
        ...errors,
        picture: "File width should be less than 1280px",
      });

      return;
    }

    seterrors({ ...errors, picture: "" });
    const DATA = new FormData();
    DATA.append("image", x);
    try {
      setImgLoading(true);
      let imgUpRes = await fileUpload("/admin/cityImage", "POST", DATA);
      console.log("city image up", imgUpRes);

      if (imgUpRes?.status) {
        setcity((prev) => {
          let updated = { ...prev };
          updated.picture = imgUpRes.data;

          return JSON.parse(JSON.stringify(updated));
        });
      } else {
        toast.error(
          "Something went wrong while uploading image! Please try after some time."
        );
      }
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
      toast.error(
        "Something went wrong while uploading image! Please try after some time."
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("city", city);
    if (handleValidation()) {
      if (imgLoading) return;
      if (isLoading) return;

      if (Img === "") {
        toast.error("Picture is required");
      } else {
        try {
          setisLoading(true);
          let res = await JSON_API["adminAddCity"]({
            ...city,
            topPriority: Number(city.topPriority),
            // picture: imgUpRes.data,
          });

          if (res.isSuccess) {
            dispatch(getAllCities());
            seterrors(createErrors);
            setImg("");
            setcity(initialValues);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }

            // apiCallSuccess(res.data);
            toast.success("City added successfully!");
          } else {
            apiCallError(res?.error?.message, setError);
          }
          setisLoading(false);
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add City</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="countryId" className="form-label">
                    Country*
                  </label>
                  {/* <select
                    name="countryId"
                    id="countryId"
                    className="form-control form-select"
                    value={city.countryId}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="" key={0}>
                      Select
                    </option>
                    {countres?.map((item, idx) => {
                      return (
                        <>
                          <option key={idx + 1} value={`${item?._id}`}>
                            {item?.name}
                          </option>
                        </>
                      );
                    })}
                  </select> */}
                  <Select
                    name="countryId"
                    id="countryId"
                    classNamePrefix="select"
                    ref={inputRef}
                    options={countres?.map((item, idx) => ({
                      value: item?._id,
                      label: item?.name,
                    }))}
                    value={{
                      value: city.countryId,
                      label: city.countryName,
                    }}
                    // isMulti
                    onChange={(val) => {
                      console.log("value", val);
                      setcity((prev) => {
                        console.log(prev);
                        return {
                          ...prev,
                          countryId: val?.value,
                          countryName: val?.label,
                        };
                      });
                    }}
                  />
                </div>
                {errors.countryId && (
                  <span className="errorMessageText text-danger">
                    {errors.countryId}
                  </span>
                )}
              </div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="cityName" className="form-label">
                      City Name*
                    </label>
                    <input
                      name="cityName"
                      id="cityName"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={city.cityName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.cityName && (
                    <span className="errorMessageText text-danger">
                      {errors.cityName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="picture" className="form-label">
                      Image*
                    </label>
                    <input
                      name="picture"
                      id="picture"
                      type="file"
                      accept={"image/*"}
                      ref={fileInputRef}
                      className="form-control"
                      // onClick={() => setuploadedImg('')}
                      // onChange={e => {
                      //   setImg(e.target.files[0]);
                      //   handleImg(e.target.files[0], setuploadedImg, errors, seterrors, 'picture');
                      // }}
                      onChange={imageHandler}
                    />
                  </div>
                  {/* {uploadedImg.length > 0 && (
                    <img alt="" style={{ height: '100px', width: '100px' }} src={uploadedImg} />
                  )}
                  {errors.picture && <span className="errorMessageText text-danger">{errors.picture}</span>} */}
                  <p className="text-primary mt-2">
                    {imgLoading && "Uploading please wait..."}
                  </p>
                  {Img !== "" && (
                    <>
                      <img
                        alt=""
                        style={{
                          height: "70px",
                          width: "120px",
                          borderRadius: "3px",
                        }}
                        src={Img}
                      ></img>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="topPriority" className="form-label">
                    Priority*
                  </label>
                  <input
                    name="topPriority"
                    id="topPriority"
                    type="number"
                    min={0}
                    className="form-control"
                    value={city?.topPriority}
                    onChange={handleChange}
                  />
                </div>
                {errors.topPriority && (
                  <span className="errorMessageText text-danger">
                    {errors.topPriority}
                  </span>
                )}
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* <Table
          striped
          tableTitle="All Cities"
          data={tableData}
          modal={modal}
          setModal={setModal}
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
        /> */}
    </>
  );
}

export default AddCities;
