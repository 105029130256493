// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Helpers
// import * as Yup from "yup";
import { toast } from "react-toastify";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSubadmins } from "../../../redux/adminSlice/subadminActionsSlice";

// Components
import Table from "../../../Component/Table";
// import { Formik } from "formik";
import Loader from "../../../Component/Loader";

function ManageSubadmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [subadminData, setsubadminData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [statusLoaders, setStatusLoaders] = useState([]);
  // Redux stores
  const { subadmins } = useSelector((state) => state.adminSubadminActionSlice);
  // const { isLogdedIn } = useSelector((state) => state.adminProfileSlice);
  // console.log("subadmins", subadmins);

  // Component did mount
  useEffect(() => {
    window.scrollTo(0, 0);
    // if (isLogdedIn) {
    dispatch(getAllSubadmins());
    setsubadminData(subadmins);
    setStatusLoaders(
      subadmins.map((item) => {
        return { loader: false };
      })
    );
    // }
  }, []);
  // Component did update
  useEffect(() => {
    setsubadminData(subadmins);
  }, [subadmins]);

  const openModal = () => {
    setModal(true);
  };

  const changeStatus = async (id, idx) => {
    try {
      setStatusLoaders((prev) => {
        let update = JSON.parse(JSON.stringify(prev));
        update[idx].loader = true;
        return [...update];
      });
      let res = await JSON_API["adminChangeStatus"]({ id: id });

      if (res.isSuccess) {
        dispatch(getAllSubadmins());
      } else {
        console.log("Update status response ", res);
      }
    } catch (error) {
      console.log("Failed to change status due to ", error.message);
    }
    setStatusLoaders((prev) => {
      let update = JSON.parse(JSON.stringify(prev));
      update[idx].loader = false;
      return [...update];
    });
  };

  const deleteSubadminInfo = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["adminDeleteSubadmin"]({ id: id });

      if (res.isSuccess) {
        dispatch(getAllSubadmins());
        setModal(false);
        setisLoading(false);
        // SubadminApiCallSuccess(res.data);
        toast.success("Deleted Successfully");
      } else {
        setisLoading(false);
        SubadminApiCallError(res?.error?.msg);
      }
    } catch (error) {
      setisLoading(false);
      SubadminApiCallError(error.message);
    }
  };

  const SubadminApiCallError = (errorMsg) => {
    toast.error("Something went wrong.");
    setError(errorMsg);
  };

  const filterData = () => {
    if (searchText !== "") {
      return subadminData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return subadminData;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "desc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "desc",
          width: 100,
        },
        {
          label: "Access Priviledege",
          field: "accessPriviledege",
          sort: "desc",
          width: 100,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 200,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData().length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                accessPriviledege:(
                  item?.assignAccess.toString()
                ),
                name: (
                  <div className="widget-content p-0">
                    <div
                      className="widget-content-wrapper"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <div className="widget-content-left me-3">
                        <div className="widget-content-left">
                          <img
                            style={{
                              borderRadius: "3px",
                              height: "80px",
                              width: "80px",
                            }}
                            src={
                              item?.image
                                ? item?.image
                                : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                            }
                            alt="Avatar"
                          />
                        </div>
                      </div>
                      <div className="widget-content-left flex2">
                        <div className="zwidget-heading" style={{ opacity: 1 }}>
                          {item?.firstName + " " + item?.lastName}
                        </div>
                        {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                      </div>
                    </div>
                  </div>
                ),

                email: item.email,
                status: (
                  // <button
                  //   className={`badge ${
                  //     item?.status ? "bg-success" : "bg-danger"
                  //   } border-0`}
                  //   onClick={() => changeStatus(item?._id)}
                  // >
                  //   {item?.status ? "Active" : "Deactivated"}
                  // </button>
                  <div>
                    {!statusLoaders[idx]?.loader ? (
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          // id="flexSwitchCheckChecked"
                          checked={item.status}
                          onChange={() => changeStatus(item._id, idx)}
                        />
                      </div>
                    ) : (
                      <div
                        className="spinner-grow spinner-grow-sm text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>{" "}
                      </div>
                    )}
                  </div>
                ),
                action: (
                  <div>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(0);
                        setdeleteId(subadminData[idx]?._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        navigate(`/edit-admin/${item?._id}`);
                      }}
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                  </div>
                ),
              };
            })
          : [],
    };
  }, [subadminData, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do you want to delete this ?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteSubadminInfo(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle={"All Admins"}
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
}

export default ManageSubadmin;
