import { useMemo, useState } from 'react';
import { cn, dayNames } from '../lib/utils';
import {
  add,
  addDays,
  addHours,
  eachDayOfInterval,
  eachMinuteOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isBefore,
  isEqual,
  isSameMonth,
  isThisMonth,
  isToday,
  parse,
  parseISO,
  set,
  startOfDay,
  startOfToday,
  startOfWeek,
  startOfYesterday,
} from 'date-fns';
import { CheckCircle2, ChevronLeft, ChevronRight } from 'lucide-react';
import { getOnlyFullDate } from '../utils/date-functions';

const reservations = [
  addHours(startOfToday(), 5).toString(),
  addHours(startOfToday(), 6).toString(),
  addHours(startOfToday(), 7).toString(),
  addHours(startOfToday(), 8).toString(),
  addHours(startOfToday(), 9).toString(),
  addDays(new Date(addHours(startOfToday(), 4)), 3).toString(),
]

export default function Calendar({
  dateData,
  onDateClick,
  onDateHover,
  nextMonthClick,
  prevMonthClick
}) {
  const [calendarTouched, setCalendarTouched] = useState(false);

  let today = startOfToday();
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let [selectedDay, setSelectedDay] = useState(today);
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
  let days = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(firstDayCurrentMonth, { weekStartsOn: 1 }),
        end: endOfWeek(endOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 }),
      }),
    [firstDayCurrentMonth]
  );

  function prevMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
    prevMonthClick({
      month: new Date(format(firstDayNextMonth, "yyyy-MM-dd")).getMonth() + 1,
      year: new Date(format(firstDayNextMonth, "yyyy-MM-dd")).getFullYear()
    })
  }
  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
    nextMonthClick({
      month: new Date(format(firstDayNextMonth, "yyyy-MM-dd")).getMonth() + 1,
      year: new Date(format(firstDayNextMonth, "yyyy-MM-dd")).getFullYear()
    })
  }

  // const freeTimes = useMemo(() => {
  //   const StartOfToday = startOfDay(selectedDay);
  //   const endOfToday = endOfDay(selectedDay);
  //   const startHour = set(StartOfToday, { hours: 1 });
  //   const endHour = set(endOfToday, { hours: 17, minutes: 45 });
  //   let hoursInDay = eachMinuteOfInterval(
  //     {
  //       start: startHour,
  //       end: endHour,
  //     },
  //     { step: 15 }
  //   );

  //   // filter the available hours
  //   let freeTimes = hoursInDay.filter(
  //     (hour) => !reservations.includes(parseISO(hour.toISOString()).toString())
  //   )

  //   return (freeTimes)

  // }, [selectedDay])

  // calculate the number of available times for each day in this month
  useMemo(() => {
    let thisMonthTimesLength = []
    let thisMonthTimesEachDay = []
    days.forEach((day, dayIdx) => {
      // get times

      const StartOfToday = startOfDay(day)
      console.log("StartOfToday", StartOfToday);
      const endOfToday = endOfDay(day)
      console.log("endOfToday", endOfToday);
      // change your working hours here
      const startHour = set(StartOfToday, { hours: 1 });
      const endHour = set(endOfToday, { hours: 17, minutes: 45 });
      let hoursInDay = eachMinuteOfInterval(
        {
          start: startHour,
          end: endHour,
        },
        { step: 15 }
      );
      // filter the available hours
      let freeTimes = hoursInDay.filter(
        (hour) =>
          !reservations.includes(parseISO(hour.toISOString()).toString())
      )
      thisMonthTimesLength.push(freeTimes.length)
      thisMonthTimesEachDay.push(freeTimes)
    })

    return {
      thisMonthTimesLength,
      thisMonthTimesEachDay,
    };

  }, [currentMonth]);

  return (
    <div className="flex flex-col justify-center items-center gap-2 bg-stone-50">
     
      <div className="flex flex-col h-[450px] w-[380px]">
       
        <div className="grid grid-cols-3 myCalender">
          <button type="button" onClick={prevMonth} disabled={isThisMonth(new Date(currentMonth))}>
            <ChevronLeft
              size={20}
              aria-hidden="true"
              className={cn(isThisMonth(new Date(currentMonth)) && 'text-gray-300')}
            />
          </button>
          <h2 className="font-semibold text-orange-950 justify-center flex" style={{ marginBottom: "0" }}>
            {format(firstDayCurrentMonth, ' MMMM yyyy')}
          </h2>
          <button type="button" className="flex justify-end" onClick={nextMonth}>
            <ChevronRight size={20} aria-hidden="true" />
          </button>
        </div>
        <div>
          <div className="grid grid-cols-7 mt-4">
            {dayNames.map((day, i) => {
              return (
                <div
                  key={i}
                  className={cn(
                    "flex justify-center items-center text-sm text-blue-500 w-full py-2",
                    {
                      "text-orange-400 bg-orange-50 rounded-t-lg":
                        day === "Sun" || day === "Sat",
                    }
                  )}
                >
                  {day}
                </div>
              )
            })}
          </div>

          <div className="grid grid-cols-7 text-sm">
            {days.map((day, dayIdx) => {

              let dayAvailability = dateData?.find(item => item?._id === getOnlyFullDate(day.toString()))

              console.log("daysAvailability", dayAvailability);
              return (
                <div
                  key={day.toString()}
                  className={cn(
                    dayIdx === 0 && colStartClasses[getDay(day) - 1],
                    "h-14 justify-center flex items-center",
                    (getDay(day) === 0 || getDay(day) === 6) &&
                    "bg-orange-50 rounded-lg"
                  )}

                  onMouseOver={() => onDateHover(getOnlyFullDate(day.toString()))}
                  onClick={() => onDateClick(getOnlyFullDate(day.toString()))}
                >
                  <button
                    onClick={() => {
                      setCalendarTouched(true);
                      setSelectedDay(day);
                    }}
                    className={cn(
                      "w-12 h-12 flex flex-col p-2 justify-center items-center rounded-xl gap-0 group bg-gray-50 relative group",
                      typeof dayAvailability?.remainingUsers === 'undefined'
                        ? "bg-gray-300" : dayAvailability?.remainingUsers !== 0
                          ? "bg-green-500" : "bg-red-600",
                      isEqual(day, selectedDay) &&
                      "bg-orange-100 text-slate-900 text-lg",
                      isEqual(today, day) && "text-blue-900 bg-blue-50",
                      isBefore(day, today) &&
                      "text-red-800 bg-red-50 cursor-not-allowed",
                      isEqual(today, day) && "text-blue-900 bg-blue-50",
                      isBefore(day, today) && "cursor-not-allowed",
                      isEqual(day, selectedDay) &&
                      isToday(day) &&
                      "bg-blue-200",
                      !isEqual(day, selectedDay) &&
                      !isToday(day) &&
                      !isSameMonth(day, firstDayCurrentMonth) &&
                      "text-gray-400",
                      !isEqual(day, selectedDay) &&
                      !isToday(day) &&
                      isSameMonth(day, firstDayCurrentMonth) &&
                      "text-gray-900"
                    )}
                    disabled={isBefore(day, today)}>
                    {isAfter(day, startOfYesterday()) && (
                      <span className="hidden group-hover:flex absolute top-0 -translate-x-.5 -translate-y-4 z-10 text-[11px] bg-slate-900 text-slate-100 px-1 rounded-md gap-1">
                        <span>{dayAvailability ?
                          `${dayAvailability.remainingUsers} Available`
                          : "No slots available"}
                        </span>
                      </span>
                    )}

                    <time
                      dateTime={format(day, 'yyyy-MM-dd')}
                      className={cn(
                        "group-hover:text-lg",
                        (isEqual(day, selectedDay) || isToday(day)) &&
                        "font-semibold"
                      )}
                    >
                      {format(day, "d")}
                    </time>

                    <CheckCircle2
                      className={cn(
                        'hidden',
                        isEqual(day, selectedDay) &&
                        "absolute block top-0 right-0 h-[18px] w-[18px] translate-x-1 -translate-y-1 text-orange-900",
                        isEqual(day, today) && "text-blue-900"
                      )}
                    />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={cn(`hidden`, calendarTouched && 'block')}>
        <span className="flex items-center w-full justify-center gap-1">
          <span>
            Select your reservation time for
            <span className="text-orange-950 font-semibold pl-1">{format(selectedDay, 'dd MMMM yyyy').toString()}</span>
          </span>
        </span>
      </div>
    </div>
  );
}

let colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7'];
