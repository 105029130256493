// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import { useNavigate } from "react-router-dom";
import { getAllGiftsquestions } from "../../../redux/adminSlice/giftQuestionSlice";

const ManageGiftQuestion = () => {
  // function AddLanguage() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [newPrice, setnewPrice] = useState("");
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();

  // Component did mount
  useEffect(() => {
    dispatch(getAllGiftsquestions());
  }, []);

  // Redux stores
  const { giftquestions } = useSelector((state) => state.GiftQuestionsSlice);
  console.log("giftquestions", giftquestions);

  const openModal = () => {
    setModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    if (newPrice === "") {
      toast.error("Please add a price in digits");
    } else {
      try {
        let res = await JSON_API["adminEditGiftqusstion"]({
          id: giftquestions[itemIndex]._id,
          price: newPrice,
        });

        if (res.isSuccess) {
          dispatch(getAllGiftsquestions());
          setModal(false);
          setnewPrice("");
          apiCallSuccess(res?.data);
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
    }
  };

  const deleteLanguage = async (id) => {
    try {
      let res = await JSON_API["adminDeleteGiftqusstion"]({ id: id });
      // console.log("Res", res);

      if (res.isSuccess) {
        dispatch(getAllGiftsquestions());
        setModal(false);
        apiCallSuccess(res.data);
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Gift Questions",
        field: "question",
        sort: "asc",
        width: 150,
      },
      {
        label: "Gift Answer",
        field: "answer",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      giftquestions?.length > 0
        ? giftquestions.map((item, idx) => {
            console.log('cholche',item)
          return {
            sl: idx + 1,
            question: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    
                      {item?.question}
                    
                  </div>
                </div>
              </div>
            ),
            answer: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    
                      {item?.answer}
                  </div>
                </div>
              </div>
            ),
            action: (
              <div>
                <button
                  className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                  onClick={() => {
                    openModal();
                    setmodalToggleView(1);
                    setdeleteId(giftquestions[idx]._id);
                  }}
                >
                  <i className="fa-solid fa-trash-can" />
                </button>
                <button
                  className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                  onClick={() => {
                    // openModal();
                    // setmodalToggleView(0);
                    // setitemIndex(idx);
                    // setnewPrice(gifts[idx]?.price);
                    navigate(`/edit-gift-questions/${item._id}`)
                  }}
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </button>
              </div>
            ),
          };
        })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Change Price</h5>
            </div>
            <form className="">
              <div className="modal-body">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="price"
                      className="form-label"
                      style={{ margin: "0.5rem 0.5rem" }}
                    >
                      Price
                    </label>
                    <input
                      name="price"
                      id="price"
                      type="number"
                      className="form-control"
                      autoComplete="false"
                      value={newPrice}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = "";
                        }
                        setnewPrice(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setModal(false);
                    // setnewLanguage("");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={(e) => handleEditSubmit(e)}
                >
                  Edit
                </button>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteLanguage(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Table
      striped
      tableTitle="All Gift Questions & Answers"
      data={tableData}
      modal={modal}
      setModal={setModal}
      modalToggleView={modalToggleView}
      renderModalView={renderModalView}
    />
  );
};

export default ManageGiftQuestion;
