// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import {
  capitalizeFirstLetter,
  apiCallError,
} from "../../../utils/common-utils";
import { handleBannerImg } from "../../../utils/image-utils";

// Helpers
import { toast } from "react-toastify";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import {
  getAllActivityTypes,
  getAllActivities,
} from "../../../redux/adminSlice/activitySlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

// Components
import Loader from "../../../Component/Loader";
import { Player } from "video-react";
import Select from "react-select";

let initialValues = {
  bannerTitle: "",
  bannerDesc: "",
  activityTypeId: "",
  activityId: "",
  activityName: "",
  countryId: "",
  bannerImg: "",
  bannerVdo: "",
  countryName: "",
};

const createErrors = {
  bannerTitle: "",
  bannerDesc: "",
  activityTypeId: "",
  activityId: "",
  countryId: "",
  bannerImg: "",
  bannerVdo: "",
};

function AddBanner() {
  const dispatch = useDispatch();
  const imgInputRef = useRef(null);
  const inputRef = useRef(null);
  const vidInputRef = useRef(null);
  const [activityList, setactivityList] = useState([]);
  const [banner, setbanner] = useState(initialValues);
  const [uploadedImg, setuploadedImg] = useState("");
  const [uploadedVid, setuploadedVid] = useState("");
  const [bannerImg, setbannerImg] = useState(null);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);

  // Redux stores
  const { activity_types, activities } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const { countres } = useSelector((state) => state.countrySlice);


  useEffect(() => {
    dispatch(getAllActivityTypes());
    dispatch(getAllActivities());
    dispatch(getAllcountres());
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    let input;

    if (event.target.name !== "bannerDesc") {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }
    setbanner({ ...banner, [event.target.name]: input });
  };

  const getActivitiesForType = (typeId) => {
    let data = activities?.filter((item) => item?.activityTypesId === typeId);

    setactivityList(data);
  };

  const handleValidation = () => {
    const { bannerTitle, bannerDesc, countryId, activityTypeId, activityId } =
      banner;

    if (bannerTitle === "") {
      seterrors({ ...errors, bannerTitle: "Banner name is required" });

      return false;
    }
    if (bannerDesc === "") {
      seterrors({ ...errors, bannerDesc: "Banner description is required" });

      return false;
    }
    if (bannerDesc.length < 5) {
      seterrors({
        ...errors,
        bannerDesc: "Banner description should atleast be 5 characters",
      });
      return false;
    }
    if (activityTypeId === "") {
      seterrors({ ...errors, activityTypeId: "Activity type is required" });

      return false;
    }
    if (activityId === "") {
      seterrors({ ...errors, activityId: "An activity is required" });

      return false;
    }
    if (countryId === "") {
      seterrors({ ...errors, countryId: "Country is required" });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      if (bannerImg === null) {
        toast.error("Both banner image  is required.");
      } else {
        setisLoading(true);
        const DATA1 = new FormData();
        DATA1.append("image", bannerImg);
        let imgUpRes = await fileUpload(
          "/admin/upload-bannerImage",
          "POST",
          DATA1
        );
        if (imgUpRes.status) {
            try {
              let res = await JSON_API["adminAddBanner"]({
                ...banner,
                bannerImg: imgUpRes.data,
                bannerVdo: '',
              });

              if (res.isSuccess) {
                seterrors(createErrors);
                setbanner(initialValues);
                if (imgInputRef.current && vidInputRef.current) {
                  imgInputRef.current.value = "";
                  vidInputRef.current.value = "";
                }
                setuploadedImg("");
                setisLoading(false);
                toast.success("Banner added successfully!");
              } else {
                setisLoading(false);
                apiCallError(res?.error?.message, setError);
              }
            } catch (error) {
              setisLoading(false);
              apiCallError(error.message, setError);
            }
        } else {
          setisLoading(false);
          apiCallError(imgUpRes?.msg, setError);
        }
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Banner</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="bannerTitle" className="form-label">
                    Title*
                  </label>
                  <input
                    name="bannerTitle"
                    id="bannerTitle"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={banner.bannerTitle}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.bannerTitle && (
                  <span className="errorMessageText text-danger">
                    {errors.bannerTitle}
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="bannerDesc" className="form-label">
                    Description*
                  </label>
                  <textarea
                    name="bannerDesc"
                    id="bannerDesc"
                    className="form-control"
                    autoComplete="false"
                    value={banner.bannerDesc}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.bannerDesc && (
                  <span className="errorMessageText text-danger">
                    {errors.bannerDesc}
                  </span>
                )}
              </div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="activityTypeId" className="form-label">
                      Activity Type*
                    </label>
                    <select
                      name="activityTypeId"
                      id="activityTypeId"
                      className="form-control form-select"
                      onChange={(e) => {
                        getActivitiesForType(e.target.value);
                        handleChange(e);
                      }}
                    >
                      <option value="" key={0}>
                        Select
                      </option>
                      {activity_types?.map((item, idx) => {
                        return (
                          <>
                            <option key={idx + 1} value={`${item?._id}`}>
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  {errors.activityTypeId && (
                    <span className="errorMessageText text-danger">
                      {errors.activityTypeId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="activityId" className="form-label">
                      Activity*
                    </label>
                    <Select
                      name="activityId"
                      id="activityId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={activityList.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      onChange={(val) => {
                        setbanner((prev) => {
                          return {
                            ...prev,
                            activityId: val?.value,
                            activityName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.activityId && (
                    <span className="errorMessageText text-danger">
                      {errors.activityId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="countryId" className="form-label">
                      Country*
                    </label>
                    <Select
                      name="countryId"
                      id="countryId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={countres.map((item, idx) => ({
                        value: item?._id,
                        label: item?.name,
                      }))}
                      onChange={(val) => {
                        setbanner((prev) => {
                          return {
                            ...prev,
                            countryId: val?.value,
                            countryName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.countryId && (
                    <span className="errorMessageText text-danger">
                      {errors.countryId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="bannerImg" className="form-label">
                      Banner Image*
                    </label>
                    <input
                      name="bannerImg"
                      id="bannerImg"
                      type="file"
                      accept="image/*"
                      ref={imgInputRef}
                      className="form-control"
                      onChange={(e) => {
                        setbannerImg(e.target.files[0]);
                        handleBannerImg(
                          e.target.files[0],
                          setuploadedImg,
                          errors,
                          seterrors,
                          "bannerImg"
                        );
                      }}
                    />
                  </div>
                  {uploadedImg.length > 0 && (
                    <img
                      style={{ height: "100px", width: "100px" }}
                      src={uploadedImg}
                      alt="bannerImg"
                    />
                  )}
                  {errors.bannerImg && (
                    <span className="errorMessageText text-danger">
                      {errors.bannerImg}
                    </span>
                  )}
                </div>
                <div className="col-md-6 d-none">
                  <div className="mb-3">
                    <label htmlFor="bannerVdo" className="form-label">
                      Banner Video
                    </label>
                    <input
                      name="bannerVdo"
                      id="bannerVdo"
                      type="file"
                      accept="video/*"
                      ref={vidInputRef}
                      className="form-control"
                      onChange={(e) => {
                        setuploadedVid(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </div>
                  {uploadedVid.length > 0 && (
                    <div style={{ height: "100px", width: "240px" }}>
                      <Player
                        playsInline
                        src={uploadedVid}
                        fluid={true}
                        width={180}
                        height={120}
                      />
                    </div>
                  )}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={() => {}}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddBanner;
