
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
    apiKey: "AIzaSyAu305P7AVHqMyFzdSGlUXP68vzII-gEiI",
    authDomain: "things-to-doo.firebaseapp.com",
    databaseURL: "https://things-to-doo-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "things-to-doo",
    storageBucket: "things-to-doo.appspot.com",
    messagingSenderId: "467616240907",
    appId: "1:467616240907:web:3cf4581b224d29f9843e0e",
    measurementId: "G-1WC5FXRHK2"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export { db }