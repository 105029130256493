// Hooks
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSubadmins } from '../../../redux/adminSlice/subadminActionsSlice';

// Components
import Table from "../../../Component/Table";
import { Formik } from "formik";
import Loader from "../../../Component/Loader";
import HomeService from "../../../services/api/HomeService";
import Notification from "../../../utils/NotificationAlert";
import { getAllVisitedUser } from "../../../redux/adminSlice/visitedUser";

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [key, setkey] = useState(Math.random());
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [editObject, seteditObject] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [totalcount, setTotalCount] = useState({})
  // Redux stores
  const { subadmins } = useSelector((state) => state.adminSubadminActionSlice);
  const { visitedUsers } = useSelector((state) => state.VisitedUserSlice);

  console.log("visitedUsers", visitedUsers.length)
  
  useEffect(() => {
    dispatch(getAllVisitedUser());
  }, []);

  useEffect(() => {
  
    if (
      !localStorage.getItem("Authorization") &&
      !localStorage.getItem("userType")
      // reactLocalStorage.get("Authorization") &&
      // reactLocalStorage.get("userType")
    ) {
      setisLoading(true);
    } else {
      // dispatch(getProfileDetails());
      // dispatch(getAllSubadmins());
      getAllcount()
      setisLoading(false);
    }
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const editSubadminInfo = async (values, resetFormFunc) => {
    let bodyData = { id: `${subadmins[itemIndex]._id}`, ...values };
    // console.log(bodyData);

    try {
      let res = await JSON_API["editSubadminInfo"](bodyData);

      if (res.isSuccess) {
        dispatch(getAllSubadmins());
        setModal(false);
        seteditObject(null);
        resetFormFunc(editObject);
        SubadminApiCallSuccess(res.data);
      } else {
        SubadminApiCallError(res?.response?.data?.error?.msg);
      }
    } catch (error) {
      SubadminApiCallError(error.message);
    }
  };

  const SubadminApiCallSuccess = (data) => {
    setkey(Math.random());
    toast.success("Action successful!");
  };

  const SubadminApiCallError = (errorMsg) => {
    toast.error("Something went wrong.");
    setError(errorMsg);
  };

  const getAllcount = () =>{
    HomeService.getAllcounts()
    .then((res)=>{
      if (res && res.status) {
        setTotalCount(res.data)
      }
    })
    .catch((err)=>{
      console.log('error',err)
    })
  }


  return (
    <>
      {isLoading && <Loader />}
      <div className="">
        <div className="">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-danger">
                        {totalcount?.TotalSubAdmin?totalcount?.TotalSubAdmin:'0'}
                        </div>
                      </div>
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">
                        Total Admins
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card-shadow-success mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-success">
                          {totalcount?.TotalMerchant?totalcount?.TotalMerchant:'0'}
                        </div>
                      </div>
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">Total Merchants</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card-shadow-warning mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-warning">
                        {totalcount?.TotalUser?totalcount?.TotalUser:'0'}
                        </div>
                      </div>
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">Total Users</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card-shadow-info mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-info">
                        {!totalcount?.TotalActivity?'0':totalcount?.TotalActivity}
                        </div>
                      </div>
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">Total Activities</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card-shadow-info mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-info">
                        {visitedUsers && visitedUsers.length}
                        </div>
                      </div>
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">Total Visited User</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
                <Notification />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
