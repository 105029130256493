// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Utils
import {
    capitalizeFirstLetter,
    apiCallError,
} from "../../../utils/common-utils";

// Helpers
import { toast } from "react-toastify";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllPressReleases } from "../../../redux/adminSlice/pressSlice";

// Components
import Loader from "../../../Component/Loader";

let initialValues = {
    topic: "",
    subTopic: "",
    description: "",
};

let createErrors = {
    topic: "",
    subTopic: "",
    description: "",
};

const EditPress = () => {
    // function AddPress() {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [press, setpress] = useState(initialValues);
    const [Img, setImg] = useState(null);
    const [error, setError] = useState("");
    const [errors, seterrors] = useState(createErrors);
    const [modal, setModal] = useState(false);
    const [modalToggleView, setmodalToggleView] = useState(null);
    const [pressEdit, setpressEdit] = useState(initialValues);
    const [itemIndex, setitemIndex] = useState("");
    const [deleteId, setdeleteId] = useState("");
    const [imgLoading, setImgLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    // Redux stores
    const { press_releases } = useSelector((state) => state.pressSlice);
    console.log("press_releases", press_releases);

    const openModal = () => {
        setModal(true);
    };

    const handleChange = (event) => {
        event.preventDefault();
        console.log(event.target.name, event.target.value);
        setpress({ ...press, [event.target.name]: event.target.value });
    };

    const pressData = press_releases.find((item) => item?._id === params.id);
    useEffect(() => {
        dispatch(getAllPressReleases());
        setpressEdit({
            ...pressEdit,
            topic: pressData.topic,
            subTopic: pressData.subTopic,
            description: pressData.description,
        });
        setImg(pressData?.image);
    }, []);


    const handleEditChange = (event) => {
        event.preventDefault();

        let input;

        if (event.target.name !== "description") {
            input = capitalizeFirstLetter(event);
        } else {
            input = event.target.value;
        }
        setpressEdit({ ...pressEdit, [event.target.name]: input });
    };

    const handleEditValidation = () => {
        const { topic, subTopic, description } = pressEdit;

        if (topic === "") {
            seterrors({ ...errors, topic: "Topic is required" });

            return false;
        }
        if (subTopic === "") {
            seterrors({ ...errors, subTopic: "Subtopic is required" });

            return false;
        }
        if (description === "") {
            seterrors({ ...errors, description: "Description is required" });

            return false;
        }
        // if (description.length < 50) {
        //   seterrors({
        //     ...errors,
        //     bannerDesc: "Description should atleast be 50 characters",
        //   });

        //   return false;
        // }

        return true;
    };

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        // console.log("Edit submit");
        if (imgLoading) return;
        if (submitting) return;
        let bodyData = { id: `${params.id}`, ...pressEdit };

        // console.log(image);
        if (handleEditValidation()) {
            if (Img === "") return toast.error("Picture is required");
            try {
                setSubmitting(true);

                let res = await JSON_API["adminEditPress"]({
                    ...bodyData,
                    image: Img,
                });

                if (res.isSuccess) {
                    dispatch(getAllPressReleases());
                    setModal(false);
                    seterrors(createErrors);
                    setImg("");
                    setpressEdit(initialValues);
                    // apiCallSuccess(res.data);
                    toast.success("Press release edited successfully.");
                    navigate('/manage-press')
                } else {
                    apiCallError(res?.error?.message, setError);
                }
            } catch (error) {
                apiCallError(error.message, setError);
            }
            // } else {
            //   toast.error('Something went wrong!');
            // }
            setSubmitting(false);
        }
        // } else {
        //   toast.error('Picture is not a .png or .jpg type');
        // }
        // }
    };

    const imageHandler = async (e) => {
        if (e.target.files.length === 0) return;
        if (imgLoading) return;
        const x = Array.from(e.target.files)[0];
        setImg(URL.createObjectURL(x));
        const DATA = new FormData();
        DATA.append("image", e.target.files[0]);
        try {
            setImgLoading(true);
            let imgUpRes = await fileUpload("/admin/pressImage", "POST", DATA);
            if (imgUpRes?.status) {
                setImg(imgUpRes?.url);
            } else {
                toast.error(
                    "Something went wrong while uploading image! Please try after some time."
                );
            }
        } catch (error) {
            toast.error(
                "Something went wrong while uploading image! Please try after some time."
            );
        }
        setImgLoading(false);
    };


    return (
        <>
            {submitting && <Loader />}
            <form
                className=""
                onSubmit={(e) => handleEditSubmit(e)}
                encType="multipart/form-data"
            >
                <div component="div" className="TabsAnimation appear-done enter-done">
                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            <div className="card-title">Edit Press Release</div>
                            <div className="row" style={{ margin: "0.1rem" }}>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="topic" className="form-label">
                                            Topic*
                                        </label>
                                        <input
                                            name="topic"
                                            id="topic"
                                            type="text"
                                            className="form-control"
                                            autoComplete="false"
                                            value={pressEdit.topic}
                                            onChange={(e) => handleEditChange(e)}
                                        />
                                    </div>
                                    {errors.topic && (
                                        <span className="errorMessageText text-danger">
                                            {errors.topic}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="subTopic" className="form-label">
                                            Subtopic*
                                        </label>
                                        <input
                                            name="subTopic"
                                            id="subTopic"
                                            type="text"
                                            className="form-control"
                                            autoComplete="false"
                                            value={pressEdit.subTopic}
                                            onChange={(e) => handleEditChange(e)}
                                        />
                                    </div>
                                    {errors.subTopic && (
                                        <span className="errorMessageText text-danger">
                                            {errors.subTopic}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">
                                        Description*
                                    </label>
                                    <textarea
                                        name="description"
                                        id="description"
                                        className="form-control"
                                        autoComplete="false"
                                        value={pressEdit.description}
                                        onChange={(e) => handleEditChange(e)}
                                    />
                                </div>
                                {errors.bannerTitle && (
                                    <span className="errorMessageText text-danger">
                                        {errors.bannerTitle}
                                    </span>
                                )}
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="picture" className="form-label">
                                        Image*
                                    </label>
                                    <input
                                        name="picture"
                                        id="picture"
                                        type="file"
                                        ref={fileInputRef}
                                        className="form-control"
                                        onChange={imageHandler}
                                        accept="image/*"
                                    />
                                </div>
                                <p className="text-primary mt-2">
                                    {imgLoading && "Uploading please wait..."}
                                </p>
                                {Img !== "" && (
                                    <img
                                        alt=""
                                        style={{ height: "100px", width: "100px" }}
                                        src={Img}
                                    ></img>
                                )}
                            </div>
                        </div>
                        <div className="d-flex m-2">
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                    // setModal(false);
                                    navigate('/manage-press')
                                }}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary  d-table">
                                Edit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
    //   }
};

export default EditPress;
