import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux actions
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

// APIs
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Utils
import {
  apiCallError,
} from "../../../utils";

// Helpers
import { toast } from "react-toastify";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";

import "./styles.css";

let initialValues = {
  commissionType: "global",
  commissionPercentage: 0,
  merchantID: "",
  merchantName: "",
  countryWisePercentage: [],
};

let createErrors = {
  commissionType: "",
  commissionPercentage: "",
  merchantID: "",
  merchantName: "",
  countryWiseCommission: "",
};

const commision_types = ["global", "individualLevel", "countryWise"];

const AddCommission = () => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [commission, setcommission] = useState(initialValues);
  const [countryCommission, setcountryCommission] = useState([
    {
      countryID: "",
      countryName: "",
      commissionPercentage: 0,
    },
  ]);
  const [errors, seterrors] = useState(createErrors);
  const [error, setError] = useState("");

  // Redux stores
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const { countres } = useSelector((state) => state.countrySlice);
  // console.log("merchants", merchants);

  useEffect(() => {
    dispatch(getAllMerchants());
    dispatch(getAllcountres());
  }, []);

  const handleChange = (event) => {
    // console.log(event.target.name, event.target.value);
    setcommission({ ...commission, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const { merchantID, commissionType, commissionPercentage } = commission;
    let countryWiseCommission = countryCommission;

    console.log(' -: commission :- ', commission)


    if (merchantID == "") {
      console.log(' -: commission :- 456')
      seterrors({ ...createErrors, merchantID: "Please provide a merchant" });

      return false;
    }

    if (commissionType != "countryWise" && Number(commissionPercentage) == 0) {
      console.log(' -: commission :- 789')
      seterrors({
        ...createErrors,
        commissionPercentage: "Please add a commission rate.",
      });

      return false;
    }

    if (commissionType == "countryWise" && 
      countryWiseCommission.filter(
        (item) => item.countryID.length > 0 && item.commissionPercentage != 0
      ).length == 0
    ) {
      console.log(' -: commission :- 741')
      seterrors({
        ...createErrors,
        countryWiseCommission: "Please enter both country and commission",
      });

      return false;
    }


    console.log(' -: commission :- 1233')

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (handleValidation()) {
      console.log("Submit");
      setisLoading(true);
      let bodyData;

      if (commission.commissionType == "countryWise") {
        bodyData = { ...commission, countryWisePercentage: countryCommission };
        delete bodyData.commissionPercentage;
      } else {
        bodyData = commission;
      }

      try {
        let res = await JSON_API["adminAddMerchantCommission"](bodyData);

        if (res.isSuccess) {
          seterrors(createErrors);
          setcommission(initialValues);
          toast.success("Commission has been added for the merchant.");
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (err) {
        apiCallError(err.message, setError);
      }

      setisLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Commission</div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="merchantID"
                    >
                      Merchant*
                    </label>
                    <Select
                      isSearchable={true}
                      name="merchantID"
                      id="merchantID"
                      classNamePrefix="select"
                      options={merchants?.map((item) => {
                        return {
                          value: item?._id,
                          label:
                            item?.firstName?.charAt(0).toUpperCase() +
                            item?.firstName?.slice(1) +
                            " " +
                            item?.lastName?.charAt(0).toUpperCase() +
                            item?.lastName?.slice(1),
                        }
                      })}
                      value={{
                        value: commission.merchantID,
                        label: commission.merchantName,
                      }}
                      // isMulti
                      onChange={(val) => {
                        // console.log("merchantId", val.value);
                        setcommission((prev) => {
                          let update = JSON.parse(JSON.stringify(prev));
                          update.merchantID = val.value;
                          update.merchantName = val.label;

                          return update;
                        });
                      }}
                    />
                  </div>
                  {errors.merchantID && (
                    <span className="errorMessageText text-danger">
                      {errors.merchantID}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="commissionType"
                    >
                      Commision Type
                    </label>
                    <select
                      name="commissionType"
                      id="commissionType"
                      className="form-control form-select"
                      value={commission.commissionType}
                      onChange={(e) => handleChange(e)}
                    >
                      {commision_types.map((item, idx) => (
                        <option key={idx + 1} value={item}>
                          {item
                            .split(/(?=[A-Z])/)[0]
                            .charAt(0)
                            .toUpperCase() +
                            item.split(/(?=[A-Z])/)[0].slice(1)}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.commissionType && (
                    <span className="errorMessageText text-danger">
                      {errors.commissionType}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  {commission.commissionType === "countryWise" ? (
                    <>
                      <label
                        htmlFor=""
                        className="form-label"
                        style={{
                          marginLeft: "15px",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Country And Percentage*
                      </label>
                      <div className="field-add-btn">
                        <button
                          className="btn btn-primary mb-3"
                          type="button"
                          onClick={() => {
                            let lastEle =
                              countryCommission[countryCommission.length - 1];

                            if (lastEle.countryID.trim() === "") {
                              toast.error("Please add a country.");
                              return;
                            }
                            if (Number(lastEle.commissionPercentage) == 0) {
                              toast.error("Please add commission");
                              return;
                            }

                            setcountryCommission((prev) => [
                              ...prev,
                              { countryID: "", commissionPercentage: 0 },
                            ]);
                          }}
                        >
                          +
                        </button>
                      </div>

                      {countryCommission.map((item, index) => (
                        <div
                          className="d-flex"
                          key={index}
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11">
                            
                              <div className="mb-3">
                                <label
                                  htmlFor={`time_${index}`}
                                  className="form-label font-weight-bold"
                                >
                                  Country*
                                </label>
                                <Select
                                  isSearchable={true}
                                  name={`country_${index}`}
                                  id={`country_${index}`}
                                  classNamePrefix="select"
                                  options={countres?.map((ele) => ({
                                    value: ele?._id,
                                    label: ele?.name,
                                  }))}
                                  value={{
                                    value: item.countryID,
                                    label: item.countryName,
                                  }}
                                  // isMulti
                                  onChange={(val) => {
                                    setcountryCommission((state) => {
                                      let update = JSON.parse(
                                        JSON.stringify(state)
                                      );
                                      update[index].countryID = val.value;
                                      update[index].countryName = val.label;

                                      return update;
                                    });
                                  }}
                                />
                              </div>
                            
                            
                              <div className="mb-3">
                                <label
                                  htmlFor={`rate_${index}`}
                                  className="form-label font-weight-bold"
                                >
                                  Commission Rate % *
                                </label>
                                <input
                                  type="number"
                                  name={`rate_${index}`}
                                  id={`rate_${index}`}
                                  className="form-control"
                                  value={item.commissionPercentage}
                                  onChange={(e) =>
                                    setcountryCommission((state) => {
                                      let update = JSON.parse(
                                        JSON.stringify(state)
                                      );
                                      update[index].commissionPercentage =
                                        e.target.value;

                                      return update;
                                    })
                                  }
                                />
                              </div>
                            
                          </div>

                          {index !== 0 && (
                            <button
                              type="button"
                              className="btn btn-danger ml-auto d-table"
                              onClick={() =>
                                setcountryCommission((state) => {
                                  let updated = [...state];
                                  updated.splice(index, 1);
                                  return updated;
                                })
                              }
                            >
                              -
                            </button>
                          )}
                        </div>
                      ))}
                      {errors.countryWiseCommission && (
                        <span className="errorMessageText text-danger">
                          {errors.countryWiseCommission}
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="mb-3">
                        <label
                          htmlFor="commissionPercentage"
                          className="form-label font-weight-bold"
                        >
                          Commission Rate % *
                        </label>
                        <input
                          type="number"
                          name="commissionPercentage"
                          id="commissionPercentage"
                          className="form-control"
                          value={commission.commissionPercentage}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.commissionPercentage && (
                        <span className="errorMessageText text-danger">
                          {errors.commissionPercentage}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddCommission;
