// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import {
  capitalizeFirstLetter,
  apiCallError,
} from "../../../utils";

// Helpers
import { toast } from "react-toastify";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllPressReleases } from "../../../redux/adminSlice/pressSlice";

// Components
import Loader from "../../../Component/Loader";

let initialValues = {
  topic: "",
  subTopic: "",
  description: "",
};

let createErrors = {
  topic: "",
  subTopic: "",
  description: "",
};

function AddPress() {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [press, setpress] = useState(initialValues);
  const [Img, setImg] = useState("");
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [imgLoading, setImgLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input;

    if (event.target.name !== 'description') {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }
    setpress({ ...press, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { topic, subTopic, description } = press;

    if (topic === "") {
      seterrors({ ...errors, topic: "Topic is required" });

      return false;
    }
    if (subTopic === "") {
      seterrors({ ...errors, subTopic: "Subtopic is required" });

      return false;
    }
    if (description === "") {
      seterrors({ ...errors, description: "Description is required" });

      return false;
    }
    // if (description.length < 50) {
    //   seterrors({
    //     ...errors,
    //     description: "Description should atleast be 50 characters",
    //   });

    //   return false;
    // }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (imgLoading) return;
    if (submitting) return;
    if (handleValidation()) {
      if (Img === "") {
        toast.error("Picture is required");
      } else {
        // if (Img.type === 'image/png' || Img.type === 'image/jpeg') {
        // console.log(Img);
        // const DATA = new FormData();
        // DATA.append('image', Img);
        // setisLoading(true);
        // let imgUpRes = await fileUpload('/admin/pressImage', 'POST', DATA);
        // console.log(imgUpRes);
        // if (imgUpRes.status) {
        try {
          setSubmitting(true);
          let res = await JSON_API["adminAddPressRelease"]({
            ...press,
            // image: imgUpRes.url,
            image: Img,
          });

          if (res.isSuccess) {
            dispatch(getAllPressReleases());
            seterrors(createErrors);
            setImg("");
            setpress(initialValues);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            // apiCallSuccess(res.data);
            toast.success("Press Release added successfully")
          } else {
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          apiCallError(error.message, setError);
        }
        // } else {
        //   apiCallError(imgUpRes?.error?.message, setError);
        // }
        // } else {
        //   apiCallError('Picture should be a .png or .jpg type', setError);
        // }
      }
      setSubmitting(false);
    }
  };

  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    const x = Array.from(e.target.files)[0];
    setImg(URL.createObjectURL(x));
    const DATA = new FormData();
    DATA.append("image", e.target.files[0]);
    try {
      setImgLoading(true);
      let imgUpRes = await fileUpload("/admin/pressImage", "POST", DATA);
      if (imgUpRes?.status) {
        setImg(imgUpRes?.url);
      } else {
        toast.error(
          "Something went wrong while uploading image! Please try after some time."
        );
      }
    } catch (error) {
      toast.error(
        "Something went wrong while uploading image! Please try after some time."
      );
    }
    setImgLoading(false);
  };

  return (
    <>
      {submitting && <Loader />}

      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Press Release</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="topic" className="form-label">
                      Topic*
                    </label>
                    <input
                      name="topic"
                      id="topic"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={press.topic}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.topic && (
                    <span className="errorMessageText text-danger">
                      {errors.topic}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="subTopic" className="form-label">
                      Subtopic*
                    </label>
                    <input
                      name="subTopic"
                      id="subTopic"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={press.subTopic}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.subTopic && (
                    <span className="errorMessageText text-danger">
                      {errors.subTopic}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Description*
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    // placeholder="Your first name, e.g. Walter"
                    className="form-control"
                    autoComplete="false"
                    value={press.description}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.description && (
                  <span className="errorMessageText text-danger">
                    {errors.description}
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="picture" className="form-label">
                    Image*
                  </label>
                  <input
                    name="picture"
                    id="picture"
                    type="file"
                    ref={fileInputRef}
                    className="form-control"
                    onChange={imageHandler}
                    accept="image/*"
                  />
                </div>
                <p className="text-primary mt-2">
                  {imgLoading && "Uploading please wait..."}
                </p>
                {Img !== "" && (
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px" }}
                    src={Img}
                  ></img>
                )}
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddPress;
