export var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;

export const handleBannerImg = async (file, stateManager, errors, errorStateManager, errorField = "") => {
    if (imageReg.test(file.type)) {
        let fileObj = await checkFileDimension(file);
        if (fileObj?.height > 2*720) {
            errorStateManager({
                ...errors,
                [errorField]: "File height should be less than 1440px",
            });
            return
        }
        if (fileObj?.width > 2*1280) {
            errorStateManager({
                ...errors,
                [errorField]: "File width should be less than 2560px",
            });
            return
        }
        errorStateManager({ ...errors, [errorField]: "" })
        stateManager(URL.createObjectURL(file));
    } else {
        errorStateManager({ ...errors, [errorField]: "File  is not an image" })
        stateManager("");
    }
};

export const handleImg = async (file, stateManager, errors, errorStateManager, errorField = "") => {
    if (imageReg.test(file.type)) {
        let fileObj = await checkFileDimension(file);
        if (fileObj?.height > 720) {
            errorStateManager({
                ...errors,
                [errorField]: "File height should be less than 720px",
            });
            return
        }
        if (fileObj?.width > 1280) {
            errorStateManager({
                ...errors,
                [errorField]: "File width should be less than 1280px",
            });
            return
        }
        errorStateManager({ ...errors, [errorField]: "" })
        stateManager(URL.createObjectURL(file));
    } else {
        errorStateManager({ ...errors, [errorField]: "File  is not an image" })
        stateManager("");
    }
};

export const checkFileDimension = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        let fileObj = {}
        reader.onload = function (e) {
            const img = new Image();
            img.onload = function () {
                let width = img.naturalWidth;
                let height = img.naturalHeight;
                fileObj.width = width;
                fileObj.height = height;
                resolve(fileObj);
            };
            img.src = e.target.result;

        };
        reader.readAsDataURL(file);
    })
};