import { useState, useRef } from "react";
import Layout from "../../../Layout";
import { fileUpload } from "../../../services/api/apiHelpers";
import { toast } from "react-toastify";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { CountryDropdown } from "react-country-region-selector";

const INITIAL = {
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  mobile: "",
  directorName: "",
  individualName: "",
  dob: "",
  streetAddress: "",
  postalCode: "",
  city: "",
  state: "",
  country: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  compamyRegistrationImage: "",
};
const AddCompanyDetails = () => {
  const [enteredData, setEnteredData] = useState({ ...INITIAL });
  const [isLoading, setIsLoading] = useState({
    logo: { status: false, text: "" },
    compamyRegistrationImage: { status: false, text: "" },
  });

  //~ Refs for resetting img fields
  const logoRef = useRef();
  const compamyRegistrationImageRef = useRef();

  // ~ Upload img fn
  const uploadImg = async (picture, picName) => {
    let imgData = new FormData();
    imgData.append("image", picture);
    try {
      setIsLoading((oldState) => {
        oldState[picName].status = true;
        return { ...oldState };
      });
      let imgRes = await fileUpload("/merchant/upload-image", "POST", imgData);
      if (imgRes && imgRes.url) {
        setIsLoading((oldState) => {
          oldState[picName].status = false;
          return { ...oldState };
        });
        return imgRes;
      } else {
        setIsLoading((oldState) => {
          oldState[picName].status = false;
          return { ...oldState };
        });
        return null;
      }
    } catch (error) {
      setIsLoading((oldState) => {
        oldState[picName].status = false;
        return { ...oldState };
      });
      return null;
    }
  };

  // @ Register Change
  const changeHandler = async (e) => {
    if (
      e.target.name === "compamyRegistrationImage" ||
      e.target.name === "logo"
    ) {
      if (e.target.files.length === 0) return;
      if (isLoading[e.target.name].status) return;

      setEnteredData((oldState) => {
        oldState[e.target.name] = URL.createObjectURL(
          Array.from(e.target?.files)?.[0]
        );
        return { ...oldState };
      });
      const img = await uploadImg(
        Array.from(e.target?.files)?.[0],
        e.target.name
      );

      if (img?.url) {
        setEnteredData((oldState) => {
          oldState[e.target.name] = img.url;
          return { ...oldState };
        });
      } else {
        toast.error(`${e.target.name} upload failed`);
        console.log("IMAGE UPLOAD FAILED", e.target.name);
      }

      return;
    }
    setEnteredData((oldState) => {
      oldState[e.target.name] = e.target.value;
      return { ...oldState };
    });
  };

  //@ Submit Handler
  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      isLoading.logo.status ||
      isLoading.compamyRegistrationImage.status ||
      Object.values(enteredData).some((item) => item === "")
    )
      return;
    console.log("SEND", enteredData);

    try {
      let response = await JSON_API["addCompanyDetails"]({
        ...enteredData,
      });
      console.log("COMPANY RESPONSE", response);
      if (response?.isSuccess) {
        toast.success(response?.data?.message || "Company added successfully");
        setEnteredData({ ...INITIAL });
        compamyRegistrationImageRef.current.value = "";
        logoRef.current.value = "";
      } else {
        toast.error(response?.error?.message);
      }
    } catch (error) {
      console.log("COMPANY ADDITION ERROR", error);
      toast.error(error?.message);
    }
  };

  return (
    <div className="main-card mb-3 p-4 card">
      <h4 className="text-primary">Company Details</h4>
      <form autoComplete="off" onSubmit={submitHandler}>
        <br />

        <div className="row">
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="legalStatus">
              Legal Status
            </label>
            <select
              className="form-select"
              id="legalStatus"
              name="legalStatus"
              onChange={changeHandler}
              value={enteredData.legalStatus}
            >
              <option value="" disabled={true} selected={true}>
                Select legal status
              </option>
              <option value="registered_company">Registered Company</option>
              <option value="individual_traders">Individual Trader</option>
            </select>
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="companyName">
              Company Name
            </label>
            <input
              type="text"
              name="companyName"
              className="form-control"
              id="companyName"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.companyName}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="legalCompanyName">
              Legal Company Name
            </label>
            <input
              type="text"
              name="legalCompanyName"
              className="form-control"
              id="legalCompanyName"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.legalCompanyName}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="mobile">
              Mobile Number
            </label>
            <input
              type="number"
              name="mobile"
              className="form-control"
              id="mobile"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.mobile}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="directorName">
              Director Name
            </label>
            <input
              type="text"
              name="directorName"
              className="form-control"
              id="directorName"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.directorName}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="individualName">
              Individual Name
            </label>
            <input
              type="text"
              name="individualName"
              className="form-control"
              id="individualName"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.individualName}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="dob">
              Date of Birth
            </label>
            <input
              type="date"
              name="dob"
              className="form-control"
              id="dob"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.dob}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="streetAddress">
              Street Address
            </label>
            <input
              type="text"
              name="streetAddress"
              className="form-control"
              id="streetAddress"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.streetAddress}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="postalCode">
              Postal Code
            </label>
            <input
              type="number"
              name="postalCode"
              className="form-control"
              id="postalCode"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.postalCode}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="city">
              City
            </label>
            <input
              type="text"
              name="city"
              className="form-control"
              id="city"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.city}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="state">
              State
            </label>
            <input
              type="text"
              name="state"
              className="form-control"
              id="state"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.state}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="country">
              Country
            </label>
            <CountryDropdown
              className="form-select"
              value={enteredData.country}
              onChange={(val) => {
                setEnteredData((oldState) => {
                  oldState.country = val;
                  return { ...oldState };
                });
              }}
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="licenseNumber">
              License Number
            </label>
            <input
              type="text"
              name="licenseNumber"
              className="form-control"
              id="licenseNumber"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.licenseNumber}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="registrationNumber">
              Registration Number
            </label>
            <input
              type="text"
              name="registrationNumber"
              className="form-control"
              id="registrationNumber"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.registrationNumber}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="description">
              Description
            </label>
            <input
              type="text"
              name="description"
              className="form-control"
              id="description"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.description}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="socialLink">
              Social Link
            </label>
            <input
              type="text"
              name="socialLink"
              className="form-control"
              id="socialLink"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.socialLink}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="logo">
              Add Logo
            </label>
            <input
              type="file"
              name="logo"
              className="form-control"
              id="logo"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              required
              accept="image/*"
              ref={logoRef}
            />
            <p className="text-primary mt-2">
              {isLoading.logo.status && "Uploading please wait..."}
            </p>
            {enteredData.logo !== "" && (
              <img
                style={{ borderRadius: "5px" }}
                width="120px"
                height="120px"
                src={enteredData.logo}
                alt="logo"
              />
            )}
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="insurancePolicy">
              Insurance Policy
            </label>
            <input
              type="text"
              name="insurancePolicy"
              className="form-control"
              id="insurancePolicy"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={enteredData.insurancePolicy}
              required
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label
              className="font-weight-bold"
              htmlFor="compamyRegistrationImage"
            >
              Company Registration Certificate
            </label>
            <input
              type="file"
              name="compamyRegistrationImage"
              className="form-control"
              id="compamyRegistrationImage"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              required
              accept="image/*"
              ref={compamyRegistrationImageRef}
            />
            <p className="text-primary mt-2">
              {isLoading.compamyRegistrationImage.status &&
                "Uploading please wait..."}
            </p>
            {enteredData.compamyRegistrationImage !== "" && (
              <img
                style={{ borderRadius: "5px" }}
                width="120px"
                height="120px"
                src={enteredData.compamyRegistrationImage}
                alt="compamyRegistrationImage"
              />
            )}
          </div>
          <div
            className="form-group col-md-12 col-12"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <button
              className="btn btn-primary btn-lg font-weight-bold"
              disabled={
                isLoading.logo.status ||
                isLoading.compamyRegistrationImage.status ||
                Object.values(enteredData).some((item) => item === "")
                  ? true
                  : false
              }
            >
              SUBMIT
            </button>

            <button
              className="btn btn-primary btn-lg font-weight-bold bg-dark"
              style={{ marginLeft: "10px" }}
              onClick={(e) => {
                e.preventDefault();
                setEnteredData({ ...INITIAL });
                compamyRegistrationImageRef.current.value = "";
                logoRef.current.value = "";
                console.log(enteredData);
              }}
              disabled={
                Object.values(enteredData).some((item) => item !== "")
                  ? false
                  : true
              }
            >
              CANCEL
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCompanyDetails;
