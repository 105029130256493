import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../Component/Table";
import { toast } from "react-toastify";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { getAllContacts } from "../../../redux/adminSlice/contactsSlice";
import Loader from "../../../Component/Loader";
import { capitalizeFirstLetter } from "../../../utils";
import { useNavigate } from "react-router-dom";

let initialValues = {
  id: "",
  topic: "",
  topicId: "",
  questions: "",
};
const Viewallcontuct = () => {
  const dispatch = useDispatch();
  const [selectedQue, setSelectedQue] = useState({ ...initialValues });
  const [deleteId, setDeleteId] = useState("");
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  // Redux stores
    const [contuctissues, setcontuctissues] = useState([]);
 console.log(contuctissues,"kjkjkjkjs")

  const getAllviewcontuctIssues=async()=>{
    let res = await JSON_API["getAllcontuctIssues"]({});
    console.log("dfdfdfdf", res)
    if(res && res.isSuccess){
      setcontuctissues(res.data.data)

    }

  }
  useEffect(() => {
    // dispatch(getAllContacts());
    getAllviewcontuctIssues()
  }, []);




  // @ Submit Handler
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let res = await JSON_API["editQuestion"]({ ...selectedQue });
      if (res.isSuccess) {
        dispatch(getAllContacts());
        toast.success("Question updated successfully");
        setSelectedQue({ ...initialValues });
        setModal(false);
      } else toast.error(res?.message || "Something went wrong");
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
    setIsLoading(false);
  };

  // ~ Delete Handler
  const deleteHandler = async (id) => {
    setIsLoading(true);
    try {
      let res = await JSON_API["deleteQuestion"]({ id });
      if (res.isSuccess) {
        dispatch(getAllContacts());
        toast.success("Question deleted successfully");
        setModal(false);
      } else toast.error(res?.message || "Something went wrong");
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
    setIsLoading(false);
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 100,
      },

      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Remarks",
        field: "remark",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "asc",
      //   width: 150,
      // },
    ],
    rows:
 contuctissues?.map((item, idx) => {     
            return {
              sl: idx + 1,
              email: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 0.75, textAlign: "center" }}
                      >
                        {item?.email}
                      </div>
                    </div>
                  </div>
                </div>
              ),

              name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 0.75, textAlign: "center" }}
                      >
                        {item?.name}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              remark: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 0.75, textAlign: "center" }}
                      >
                        {item?.remarks}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              // action: (
              //   <div>
              //     <button
              //       className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
              //       onClick={() => {
              //         setDeleteId(item?._id);
              //         setmodalToggleView(1);
              //         setModal(true);
              //       }}
              //     >
              //       <i className="fa-solid fa-trash-can" />
              //     </button>
              //     <button
              //       className="me-2 btn-icon btn-icon-only btn btn-outline-success"
              //       onClick={() => {
              //         // setSelectedQue(prev => {
              //         //   let update = JSON.parse(JSON.stringify(prev));
              //         //   update.id = item?._id;
              //         //   update.topic = item?.topic;
              //         //   update.topicId = item?.topicId;
              //         //   update.questions = item?.questions;
              //         //   return { ...update };
              //         // });
              //         // setmodalToggleView(0);
              //         // setModal(true);
              //         navigate(`/edit-question/${item._id}`);
              //       }}
              //     >
              //       <i class="fa-regular fa-pen-to-square"></i>
              //     </button>
              //   </div>
              // ),
            };
          })
     
  };


  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle={"Manage Questions"}
        data={tableData}
        modal={modal}
        isSearchable={false}
        setModal={setModal}
        // modalToggleView={modalToggleView}
        // renderModalView={renderModalView}
      />
    </>
  );
  //   }
};

export default Viewallcontuct;
