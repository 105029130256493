// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utilities
import { apiCallError } from "../../../utils/common-utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSitemaps } from "../../../redux/adminSlice/sitemapSlice";

// Components
import Table from "../../../Component/Table";
import { useNavigate } from "react-router-dom";

function ManageInfo() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();
  // Redux stores
  const { sitemaps } = useSelector((state) => state.sitemapSlice);
  console.log("sitemaps", sitemaps);

  // Component did mount
  useEffect(() => {
    dispatch(getAllSitemaps());
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const deleteCategory = async (id) => {
    try {
      let res = await JSON_API["adminDeleteSitemap"]({ id: id });
      // console.log("Res", res);

      if (res.isSuccess) {
        dispatch(getAllSitemaps());
        setModal(false);
        // apiCallSuccess(res.data);
        toast.success("Deleted Successfully");
      } else {
        // ActivityApiCallError(res?.error?.message);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Continent",
        field: "continent",
        sort: "asc",
        width: 150,
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      sitemaps?.length > 0
        ? sitemaps.map((item, idx) => {
            return {
              sl: 1,
              continent: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.continent.charAt(0).toUpperCase() +
                          item?.continent.slice(1)}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              country: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.countryName.charAt(0).toUpperCase() +
                          item?.countryName.slice(1)}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      // console.log("Delete id", activity_types[idx]._id);
                      setdeleteId(sitemaps[idx]._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      // openModal();
                      // setmodalToggleView(0);
                      // setitemIndex(idx);
                      // setCategoryEdit({
                      //   ...categoryEdit,
                      //   continent: sitemaps[idx]?.continent,
                      //   countryId: sitemaps[idx]?.countryId,
                      // });
                      navigate(`/edit-sidemap/${item._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Category</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCategory(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Table
      striped
      tableTitle="All Sitemaps"
      data={tableData}
      modal={modal}
      setModal={setModal}
      modalToggleView={modalToggleView}
      renderModalView={renderModalView}
    />
  );
}

export default ManageInfo;
