import React from 'react';
import "./Visitormodal.css"

const ViewVisitorDetails = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal-overlay visit_modal_overlay"
    >
      <div className="modal-content visit_content"
      >
        <div className='visit_close_main'>
          <button
            className="btn-close visit_close"
            onClick={onClose}
          >
          </button>
        </div>

        <div className="visited_main_modal">
          {children || "This is the modal content"} {/* Render the content passed via children */}
        </div>
      </div>
    </div>
  );
};

export default ViewVisitorDetails;
