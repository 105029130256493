import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { capitalizeFirstLetter2 } from "../../../utils/common-utils";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { requestData } from "../../../services/api/apiHelpers";
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

import "./styles.css";

export default function ManageMarchent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [allMerchantData, setallMerchantData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const merchantType = params?.merchantType;
  let tableTitle = "All Merchant";
  if (merchantType == "new") {
    tableTitle = "All New Merchant";
  }
  let userdata = localStorage.getItem("userdata");
  userdata = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType = userdata?.userType;

  useEffect(() => {
    dispatch(getAllMerchants());
    setallMerchantData(
      merchants.filter((item) => {
        return;
      })
    );
  }, []);

  useEffect(() => {
    if (merchants?.length > 0) {
      setallMerchantData(
        merchants.filter((item) => {
          if (merchantType == "new") {
            if (item?.newSupplier && !item?.saveAsDraft) {
              return true;
            } else {
              return false;
            }
          } else {
            if (item?.isApproval) {
              return true;
            } else {
              return false;
            }
          }
        })
      );
    }
  }, [merchants, merchantType]);

  const openModal = () => {
    setModal(true);
  };

  const changeStatus = async (id, idx) => {
    try {
      let res = await JSON_API["adminChangeMerchantStatus"]({ id: id });

      if (res.isSuccess) {
        dispatch(getAllMerchants());
      } else {
        // console.log("Update status change res: ", res);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteMerchant = async (id) => {
    try {
      setisLoading(true);
      let res = await requestData(`/admin/deleteMarchent/${id}`, "PUT");
      if (res && res.status) {
        dispatch(getAllMerchants());
        setModal(false);
        setisLoading(false);
        toast.success(res?.data?.message)
      } else {
        setModal(false);
        setisLoading(false);
        toast.error(res?.message)
      }
    } catch (error) {
      setisLoading(false);
      toast.error(error.message);
    }
  };

  const filterData = () => {
    if (searchText !== "") {
      return allMerchantData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return allMerchantData;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Logo",
          field: "company_logo",
          sort: "asc",
          width: 150,
        },
        {
          label: "Company",
          field: "company_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Merchant",
          field: "merchant_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 200,
        },
        {
          label: "Contact No.",
          field: "ContactNo",
          sort: "asc",
          width: 200,
        },
        {
          label: "Merchant Code",
          field: "merchant_code",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
            return {
              sl: idx + 1,
              company_logo: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left me-3">
                    </div>
                    <div className="widget-content-left flex2">
                      <img
                        style={{
                          borderRadius: "3px",
                          height: "80px",
                          width: "80px",
                        }}
                        src={
                          item?.logo
                            ? item?.logo
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                        }
                        alt="Avatar"
                      />
                    </div>
                  </div>
                </div>
              ),
              company_name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      {item?.companyName}
                    </div>
                  </div>
                </div>
              ),
              merchant_name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      {capitalizeFirstLetter2(
                        item?.firstName + " " + item?.lastName
                      )}
                    </div>
                  </div>
                </div>
              ),
              ContactNo: (
                <div>
                  {item.phonecode}-{item.mobile},<br />
                  {item.phonecodecompany}-{item.companyMobile}
                </div>
              ),
              email: <div>{item.email}</div>,
              merchant_code: item.marchentCode ? item.marchentCode : null,
              status: (
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={item.status}
                    onChange={() => changeStatus(item._id, idx)}
                  />
                </div>
              ),
              action: (
                <div>
                  {(userType == "admin" ||
                    (userType == "subadmin" &&
                      assignAccess.includes("delete"))) && (
                      <button
                        title="Delete Merchant"
                        className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                        onClick={() => {
                          openModal();
                          setmodalToggleView(0);
                          setdeleteId(allMerchantData[idx]?._id);
                        }}
                      >
                        <i className="fa-solid fa-trash-can" />
                      </button>
                    )}

                  {(userType == "admin" ||
                    (userType == "subadmin" &&
                      assignAccess.includes("edit"))) && (
                      <>
                        <button
                          title="Edit Merchant"
                          className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                          onClick={() => {
                            navigate(`/edit-merchant/${item?._id}`);
                          }}
                        >
                          <i class="fa-regular fa-pen-to-square"></i>
                        </button>

                        <button
                          className={
                            merchantType == "new"
                              ? "d-none"
                              : "me-2 btn-icon btn-icon-only btn btn-outline-success"
                          }
                          onClick={() => {
                            navigate(`/submerchant-list/${item?._id}`);
                          }}
                        >
                          <i class="fa fa-user-circle"></i>
                        </button>

                        <button
                          title="Merchant Review"
                          className={
                            merchantType == "new"
                              ? "d-none"
                              : "me-2 btn-icon btn-icon-only btn btn-outline-primary"
                          }
                          onClick={() => {
                            navigate(`/merchant/reviewAndRating/${item?._id}`);
                          }}
                        >
                          <i class="fa-regular fa fa-eye"></i>
                        </button>

                        <button
                          title="Merchant Review"
                          className={
                            merchantType == "new"
                              ? "me-2 btn-icon btn-icon-only btn btn-outline-primary"
                              : "d-none"
                          }
                          onClick={() => {
                            navigate(`/view_merchant/${item?._id}`);
                          }}
                        >
                          <i class="fa-regular fa fa-eye"></i>
                        </button>
                      </>
                    )}
                </div>
              ),
            };
          })
          : [],
    };
  }, [allMerchantData, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete merchant</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteMerchant(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle={tableTitle}
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
}
