
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, apiCallError } from "../../../utils";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { getAllAbouts } from "../../../redux/adminSlice/aboutusSlice";
import Loader from "../../../Component/Loader";

let initialValues = {
  topic: "",
  description: "",
  subTopic: [],
};

const createErrors = {
  topic: "",
  description: "",
  subTopic: "",
  image: [],
};

var imageReg = /[.](gif|jpg|jpeg|tiff|png|webp)$/i;

function AddAbout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [banner, setbanner] = useState(initialValues);
  const [subtopic, setsubtopic] = useState([
    {
      id: uuidv4(),
      title: "",
      description: "",
      image: "",
    },
  ]);
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const[uploadedImg,setUploadedImg]=useState('');

  useEffect(() => {
    dispatch(getAllAbouts());
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    let input;

    if (event.target.name !== "description") {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }

    setbanner({ ...banner, [event.target.name]: input });
  };

  const imageHandle = async (file, index) => {
    if (typeof file === "undefined") return;
    if (imageReg.test(file.type)) {
      setUploadedImg(URL.createObjectURL(file));
      let DATA = new FormData();
      DATA.append("image", file);
      let imgUpRes = await fileUpload("/admin/aboutUsImage", "POST", DATA);
      if (imgUpRes && imgUpRes.status) {
        setsubtopic((prev) => {
          let updated = [...prev];
          updated[index].image = imgUpRes.url;

          return JSON.parse(JSON.stringify(updated));
        });
      } else {
        toast.error(imgUpRes.message);
      }
    }
  };

  const handleValidation = () => {
    const { topic, description } = banner;

    if (topic === "") {
      seterrors({ ...errors, topic: "Topic is required" });

      return false;
    }
    if (description === "") {
      seterrors({ ...errors, description: "Description is required."});

      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let res;

    if (handleValidation()) {
      let data = {
        ...banner,
        subTopic: subtopic,
      };

      try {
        if (editEnable) {
          res = await JSON_API["adminEditAbouts"]({
            // id: abouts[itemIndex]._id,
            ...data,
          });
        } else {
          setisLoading(true);
          res = await JSON_API["adminAddAbout"](data);
        }

        if (res.isSuccess) {
          dispatch(getAllAbouts());
          seterrors(createErrors);
          setbanner(initialValues);
          setsubtopic([
            {
              id: uuidv4(),
              title: "",
              description: "",
              image: "",
            },
          ]);
          toast.success("About Us added successfully!");
          navigate("/manage-aboutUs");
        } else {
          apiCallError(res?.error?.message);
        }

        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add About Us</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor="topic"
                    className="form-label font-weight-bold"
                  >
                    Topic*
                  </label>
                  <input
                    name="topic"
                    id="topic"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={banner.topic}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.topic && (
                  <span className="errorMessageText text-danger">
                    {errors.topic}
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    className="form-label font-weight-bold"
                    htmlFor="description"
                  >
                    Description*
                  </label>
                  <textarea
                    name="description"
                    className="form-control"
                    id="description"
                    aria-describedby="emailHelp"
                    autoComplete="off"
                    onChange={(e) => handleChange(e)}
                    value={banner.description}
                    required
                  />
                </div>
                {errors.description && (
                  <span className="errorMessageText text-danger">
                    {errors.description}
                  </span>
                )}
              </div>
              <label
                htmlFor=""
                className="form-label"
                style={{
                  marginLeft: "15px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Subtopic
              </label>

              {subtopic.map((item, index) => {
                return (
                  <div
                    className="d-flex"
                    key={item.id}
                    style={{ alignItems: "center" }}
                  >
                    <div className="card_add_more col-md-11">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor={`title_${index}`}
                            className="form-label"
                          >
                            Title
                          </label>
                          <input
                            name={`title_${index}`}
                            id={`title_${index}`}
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={item.title}
                            onChange={(e) => {
                              setsubtopic((prev) => {
                                prev[index].title = capitalizeFirstLetter(e);

                                return JSON.parse(JSON.stringify(prev));
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor={`desc_${index}`}
                            className="form-label"
                          >
                            Description
                          </label>
                          <textarea
                            name={`desc_${index}`}
                            id={`desc_${index}`}
                            className="form-control"
                            autoComplete="false"
                            value={item.description}
                            onChange={(e) => {
                              setsubtopic((prev) => {
                                prev[index].description = e?.target?.value;

                                return JSON.parse(JSON.stringify(prev));
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Image</label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={(e) => {
                              imageHandle(e?.target?.files[0], index);
                            }}
                          />
                        </div>
                        {uploadedImg.length > 0 && (
                    <img
                      style={{ height: "100px", width: "100px"}}
                      src={uploadedImg}
                      alt="aboutUs"
                    />
                  )}
                      </div>
                    </div>

                    {subtopic.length - 1 === index ? (
                      <button
                        type="button"
                        className="btn btn-primary ml-auto d-table"
                        onClick={
                          () => {
                            if (item.title.trim() === "") {
                              toast.error("Please fill up title");
                              return;
                            }
                            if (item.description.trim() === "") {
                              toast.error("Please fill up description");
                              return;
                            }
                            if (item.image.trim() === "") {
                              toast.error("Please add image");
                              return;
                            }
                            setsubtopic((prev) => {
                              let updated = [...prev];
                              let data = [
                                ...updated,
                                {
                                  id: uuidv4(),
                                  title: "",
                                  description: "",
                                  image: "",
                                },
                              ];
                              return JSON.parse(JSON.stringify(data));
                            });
                          }
                        }
                      >
                        +
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger ml-auto d-table"
                        onClick={() => {
                          let update = [...subtopic];
                          update.splice(index, 1);
                          setsubtopic(update);
                        }}
                      >
                        -
                      </button>
                    )}
                  </div>
                );
              })}
              <div>
                {errors.subTopic && (
                  <span className="errorMessageText text-danger">
                    {errors.subTopic}
                  </span>
                )}
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={() => {}}
              >
                {/* {editEnable ? "Update" : "Submit"} */}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddAbout;
