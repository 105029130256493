// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utils
import { capitalizeFirstLetter, apiCallError } from "../../../utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllLanguages } from "../../../redux/adminSlice/languageSlice";

// Components
import Loader from "../../../Component/Loader";

//import css module

function AddLanguage() {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [language, setlanguage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("languageCode", language);
    if (language === "") {
      toast.error("Please add a language");
    } else {
      // console.log(language);
      try {
        setisLoading(true);
        let res = await JSON_API["adminAddLanguage"]({ name: language });
        // console.log("this response", res);

        if (res.isSuccess) {
          dispatch(getAllLanguages());
          setlanguage("");
          // apiCallSuccess(res.data);
          toast.success("Language added successfully!");
        } else {
          apiCallError(res?.error?.message, setError);
        }
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };
  // console.log("edit info", newLanguage);

  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Language</div>
              <div className="col-md-12">
                <div
                  className="mb-3"
                  style={{ display: "flex", paddingTop: "5px" }}
                >
                  <label
                    htmlFor="language"
                    className="form-label"
                    style={{ margin: "0.5rem 0.5rem" }}
                  >
                    Language
                  </label>
                  {/* <ReactLanguageSelect
                  className="border border-2 rounded"
                  names={'both'}
                  // languages={["English", "French", "German", "Italian", "Spanish"]}
                  onSelect={(lang) => setlanguage(lang)}
                  placeholder="Select Language"
                  searchable={true}
                  searchPlaceholder="Search for a language"
                /> */}
                  <input
                    name="language"
                    id="language"
                    // placeholder="Your first name, e.g. Walter"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={language}
                    onChange={(e) => setlanguage(capitalizeFirstLetter(e))}
                  />
                  {/* {errors.name && (
                            <span className="errorMessageText">{errors.name}</span>
                            )} */}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={(e) => handleSubmit(e)}
                // style={{ margin: "5px auto" }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddLanguage;
