// Hooks
import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Redux actions
import { getAllNewMerchantRequests } from "../../../redux/adminSlice/merchantActionsSlice";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Components
import Loader from "../../../Component/Loader";

function NewMerchantDetailView() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  // console.log("request id", params.id);
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { new_merchant_requests } = useSelector(
    (state) => state.adminMerchantActionSlice
  );
  console.log("new_merchant_requests", new_merchant_requests);

  useEffect(() => {
    dispatch(getAllNewMerchantRequests());
  }, []);

  const detailData = new_merchant_requests?.find(
    (item) => item?._id === params.id
  );
  console.log("detailData", detailData);

  const reject = async (id) => {
    // let bodyData = { rejectedStatus: true, isApproval: false };
    try {
      setisLoading(true);
      let res = await JSON_API["merchantApproval"]({
        id: id,
        rejectedStatus: false,
      });

      if (res.isSuccess) {
        setisLoading(false);
        toast.success("Merchant request rejected.");
        navigate("/new-merchant-request-list");
      } else {
        setisLoading(false);
        toast.error(res?.error?.message);
        // navigate("/merchant-details-change-requests");
      }
    } catch (error) {
      setisLoading(false);
      console.log("Failed to reject due to ", error.message);
    }
  };

  const approveDetails = async (id) => {
    // let bodyData = { rejectedStatus: false, isApproval: true };
    try {
      setisLoading(true);
      let res = await JSON_API["merchantApproval"]({
        id: id,
        isApproval: true,
      });

      if (res.isSuccess) {
        setisLoading(false);
        toast.success("Merchant have been approved.");
        navigate("/new-merchant-request-list");
      } else {
        setisLoading(false);
        toast.error(res?.error?.message);
        // navigate("/merchant-details-change-requests");
      }
    } catch (error) {
      setisLoading(false);
      console.log("Failed to approve due to ", error.message);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="info-details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex">
              <div className="obser-top">
                <p>{
                  `New Merchant Details` /**${detailData?.merchantName} :*/
                }</p>
              </div>
              {/* <div className="obser-top" style={{display: 'flex', float: 'right'}}>
              <button className="btn btn-secondary btn-lg">Back</button>
            </div> */}
            </div>
          </div>

          <div className="row mt-3">
            <div className="des-detail">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Merchant Name</span>
                  <h6>
                    {detailData?.firstName?.charAt(0)?.toUpperCase() +
                      detailData?.firstName?.slice(1) +
                      " " +
                      detailData?.lastName?.charAt(0)?.toUpperCase() +
                      detailData?.lastName?.slice(1)}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Merchant Email</span>
                  <h6>{detailData?.email}</h6>
                </div>
                <div className="DetailsData">
                  <span>Merchant Mobile</span>
                  <h6 className="">{detailData?.mobile}</h6>
                </div>
                {/* <div className="DetailsData">
                  <span>Commission From Sales</span>
                  <h6 className="">{detailData?.commisionPercentage + "%"}</h6>
                </div> */}
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Merchant City</span>
                  <h6 className="">
                    {detailData?.city?.charAt(0)?.toUpperCase() +
                      detailData?.city?.slice(1)}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Merchant Country</span>
                  <h6 className="">
                    {detailData?.country?.charAt(0)?.toUpperCase() +
                      detailData?.country?.slice(1)}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Merchant Zip</span>
                  <h6 className="">{detailData?.zip ? detailData?.zip : ""}</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <div className="col-md-6 col-6">
              <div className="column">
                <div
                  className="employeeDetails"
                  style={{ padding: 20, display: "flex" }}
                >
                  <div className="DetailsData">
                    <span>Profile Image</span>
                    <div className="task_img">
                      <img
                        src={detailData?.image}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="des-detail">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Merchant Bank Account Number</span>
                  <h6>{detailData?.marBankAccNo}</h6>
                </div>
                <div className="DetailsData">
                  <span>Merchant Bank Name</span>
                  <h6>{detailData?.marBankName}</h6>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Merchant Bank IFS Code</span>
                  <h6>{detailData?.marBankIfscCode}</h6>
                </div>
                <div className="DetailsData">
                  <span>Merchant Account Holder Name</span>
                  <h6>{detailData?.marAccHolderName}</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="des-detail">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Merchant Code</span>
                  <h6>
                    {detailData?.marchentCode
                      ? detailData?.marchentCode
                      : "Not Available"}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Company Name</span>
                  <h6>
                    {detailData?.companyName?.charAt(0)?.toUpperCase() +
                      detailData?.companyName?.slice(1)}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Company Legal Name</span>
                  <h6>
                    {detailData?.legalCompanyName?.charAt(0)?.toUpperCase() +
                      detailData?.legalCompanyName?.slice(1)}
                  </h6>
                </div>
                {detailData?.legalStatus === "individual_traders" ? (
                  <>
                    <div className="DetailsData">
                      <span>Trader Name</span>
                      <h6 className="">
                        {detailData?.individualName
                          ?.split(" ")
                          .map((item) => {
                            return (
                              item?.charAt(0).toUpperCase() + item?.slice(1)
                            );
                          })
                          .join(" ")}
                      </h6>
                    </div>
                    <div className="DetailsData">
                      <span>Trader DOB</span>
                      <h6 className="">{detailData?.companyDob}</h6>
                    </div>
                  </>
                ) : (
                  <div className="DetailsData">
                    <span>Director Name</span>
                    <h6>
                      {detailData?.directorName?.charAt(0)?.toUpperCase() +
                        detailData?.directorName?.slice(1)}
                    </h6>
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Company Legal Status</span>
                  <h6>
                    {detailData?.legalStatus === "individual_traders"
                      ? "Individual Trader"
                      : "Registered Company"}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Company Address</span>
                  <h6 className="" style={{ wordWrap: "break-word" }}>
                    {detailData?.companyStreetAddress
                      ?.charAt(0)
                      ?.toUpperCase() +
                      detailData?.companyStreetAddress?.slice(1) +
                      ", " +
                      detailData?.compCity?.charAt(0)?.toUpperCase() +
                      detailData?.compCity?.slice(1) +
                      ", " +
                      detailData?.compCountry?.charAt(0)?.toUpperCase() +
                      detailData?.compCountry?.slice(1) +
                      ", " +
                      detailData?.postalCode}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Company License</span>
                  <h6 className="">{detailData?.licenseNumber}</h6>
                </div>
                <div className="DetailsData">
                  <span>Company Registration</span>
                  <h6>{detailData?.registrationNumber}</h6>
                </div>
                <div className="DetailsData">
                  <span>Social Media Link</span>
                  <h6>
                    <a href={detailData?.socialLink}>
                      {detailData?.socialLink}
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="col-md-9 col-9">
              <div className="column">
                <div
                  className="employeeDetails"
                  style={{ padding: 20, display: "flex" }}
                >
                  <div className="DetailsData mr-5">
                    <span>Company Logo</span>
                    <div className="task_img">
                      <img
                        src={detailData?.logo}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="DetailsData mr-5">
                    <span>Company Insurance</span>
                    <div className="task_img">
                      <img
                        src={detailData?.insurancePolicy}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="DetailsData">
                    <span>Registration Certificate</span>
                    <div className="task_img">
                      <img
                        src={detailData?.compamyRegistrationImage}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {!detailData?.rejectedStatus && detailData?.isApproval ? (
            <div className="btn btn-success">This is approved.</div>
          ) : detailData?.rejectedStatus && !detailData?.isApproval ? (
            <div className="btn btn-danger">This is rejected</div>
          ) : !detailData?.rejectedStatus && !detailData?.isApproval ? (
            <div
              className="mb-5"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                className="mt-2 btn btn-danger d-table"
                onClick={() => reject(detailData?._id)}
                style={{ marginRight: "5px" }}
              >
                Reject
              </button>
              <button
                className="mt-2 btn btn-primary d-table"
                onClick={() => approveDetails(detailData?._id)}
              >
                Approve
              </button>
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </>
  );
}

export default NewMerchantDetailView;
