// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";

// Helpers
import { toast } from "react-toastify";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllBlogs } from "../../../redux/adminSlice/blogSlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import { getAllTerms } from "../../../redux/adminSlice/termsAndConSlice";
import { getAllCookies } from "../../../redux/adminSlice/cookiesSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

let initialValues = {
  title: "",
  description: "",
};

let createErrors = {
  title: "",
  description: "",
};

function AddCookies() {


  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [content, setcontent] = useState("");

  const [press, setpress] = useState(initialValues);
  const [Img, setImg] = useState(null);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [pressEdit, setpressEdit] = useState(initialValues);
  const [itemIndex, setitemIndex] = useState("");
  const [deleteId, setdeleteId] = useState("");
  // Redux stores
  const { cookies } = useSelector((state) => state.cookiesMarketingSlice);
  // Component did mount
  useEffect(() => {
    dispatch(getAllCookies());
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input;

    if (event.target.name !== "description") {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }
    
    setpress({ ...press, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { title, subTopic, description } = press;

    if (title === "") {
      seterrors({ ...errors, title: "title is required" });

      return false;
    }

    if (description === "") {
      seterrors({ ...errors, description: "Description is required" });

      return false;
    }
    if (description.length < 50) {
      seterrors({
        ...errors,
        description: "Description should atleast be 50 characters",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
 
    const inpdata={description:content}
      try {
        let res = await JSON_API["adminAddCookiesMarketing"](inpdata);

        if (res.isSuccess) {
          dispatch(getAllCookies());
          seterrors(createErrors);
          setImg(null);
          setpress(initialValues);

          setisLoading(false);
          toast.success(' Cookie Marketing added successfully ');
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
    
  };

  const handleEditChange = (event) => {
    event.preventDefault();
    setpressEdit({ ...pressEdit, [event.target.name]: event.target.value });
  };

  const handleEditValidation = () => {
    const { title, description } = pressEdit;

    if (title === "") {
      seterrors({ ...errors, title: "title is required" });

      return false;
    }

    if (description === "") {
      seterrors({ ...errors, description: "Description is required" });

      return false;
    }
    if (description.length < 50) {
      seterrors({
        ...errors,
        bannerDesc: "Description should atleast be 50 characters",
      });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    // console.log("Edit submit");
    let bodyData = { id: `${cookies[itemIndex]._id}`, ...pressEdit };

    if (handleEditValidation()) {
      try {
        let res = await JSON_API["adminEditCookiesMarketing"](bodyData);

        if (res.isSuccess) {
          dispatch(getAllCookies());
          setModal(false);
          seterrors(createErrors);
          setpressEdit(initialValues);
          apiCallSuccess(res.data);
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
    }
  };

  const deletePress = async (id) => {
    try {
      let res = await JSON_API["adminDeleteCookiesMarketing"]({ id: id });
      // console.log("Res", res);

      if (res.isSuccess) {
        dispatch(getAllCookies());
        setModal(false);
        apiCallSuccess(res.data);
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      //   {
      //     label: "Picture",
      //     field: "picture",
      //     sort: "asc",
      //     width: 100,
      //     height: 100,
      //   },
      {
        label: "title",
        field: "title",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      cookies?.length > 0
        ? cookies.map((item, idx) => {
            return {
              sl: idx + 1,
              //   picture: (
              //     <div className="widget-content p-0" key={idx}>
              //       <div className="widget-content-wrapper">
              //         <img src={`${item.image}`} alt="" className="img-fluid" />
              //       </div>
              //     </div>
              //   ),
              title: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item.title}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),

              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(1);
                      setdeleteId(cookies[idx]?._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setitemIndex(idx);
                      setpressEdit({
                        ...pressEdit,
                        title: cookies[idx].title,
                        subTopic: cookies[idx].subTopic,
                        description: cookies[idx].description,
                      });
                      // console.log("edit", editObject);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Cookies Marketing</h5>
            </div>
            <form
              className=""
              onSubmit={(e) => handleEditSubmit(e)}
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <div className="row" style={{ margin: "0.1rem" }}>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="title" className="form-label">
                        title*
                      </label>
                      <input
                        name="title"
                        id="title"
                        // placeholder="Your first name, e.g. Walter"
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        value={pressEdit.title}
                        onChange={(e) => handleEditChange(e)}
                      />
                    </div>
                    {errors.title && (
                      <span className="errorMessageText text-danger">
                        {errors.title}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description*
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={pressEdit.description}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.bannerTitle && (
                    <span className="errorMessageText text-danger">
                      {errors.bannerTitle}
                    </span>
                  )}
                </div>
                {/* <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="picture" className="form-label">
                      Picture*
                    </label>
                    <input
                      name="picture"
                      id="picture"
                      type="file"
                      ref={fileInputRef}
                      className="form-control"
                      onChange={(e) => setImg(e.target.files[0])}
                    />
                  </div>
                </div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Edit
                </button>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deletePress(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Cookies Marketing</div>
          <div>
          <div>        <CKEditor
                    className="editor-container"
                    editor={ClassicEditor}
                    data={content}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                      setcontent(data);
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  /></div>
          </div>
              {/* <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="picture" className="form-label">
                      Picture*
                    </label>
                    <input
                      name="picture"
                      id="picture"
                      type="file"
                      ref={fileInputRef}
                      className="form-control"
                      onChange={(e) => setImg(e.target.files[0])}
                    />
                  </div>
                </div> */}

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* <Table
          striped
          tableTitle="All Cookies Marketing"
          data={tableData}
          modal={modal}
          setModal={setModal}
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
        /> */}
    </>
  );
}

export default AddCookies;
