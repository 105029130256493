// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utilities
import { capitalizeFirstLetter, apiCallError } from "../../../utils";

// API functions
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";
import { getCitiesOfACountry } from "../../../redux/adminSlice/citySlice";
import { getAllTourModules } from "../../../redux/adminSlice/tourModuleSlice";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";

const initialValues = {
  countryId: "",
  countryName: "",
  cityId: "",
  cityName: "",
  name: "",
  image: "",
};

const createErrors = {
  countryId: "",
  cityId: "",
  name: "",
  image: "",
  //   activityTypeId: "",
};

function EditTourModule() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  // const [imgIsLoading, setimgIsLoading] = useState(false);
  // const [uploadedImg, setuploadedImg] = useState('');
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [categoryEdit, setCategoryEdit] = useState(initialValues);
  const [isLoading, setisLoading] = useState(false);
  const [Img, setImg] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  // Redux stores
  const { tour_modules } = useSelector((state) => state.tourModuleSlice);
  const { countres } = useSelector((state) => state.countrySlice);
  const { cities_of_a_country } = useSelector((state) => state.citySlice);
  // console.log("tour_modules", tour_modules);
  const tourModuleData = tour_modules.find((item) => item?._id === params.id);
  // console.log("tourModuleData", tourModuleData);
  // Component did mount
  useEffect(() => {
    dispatch(getAllcountres());
    dispatch(getCitiesOfACountry(tourModuleData?.countryId))
    dispatch(getAllTourModules());
    setCategoryEdit({
      ...categoryEdit,
      name: tourModuleData?.name,
      image: tourModuleData?.image,
      countryId: tourModuleData?.countryId,
      countryName: tourModuleData?.city[0]?.country?.name,
      cityId: tourModuleData?.cityId,
      cityName: tourModuleData?.city[0]?.cityName,
    });
    setImg(tourModuleData?.image);
  }, [dispatch]);

  const handleEditChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input = capitalizeFirstLetter(event);
    setCategoryEdit({
      ...categoryEdit,
      [event.target.name]: input,
    });
  };

  // var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;

  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    if (imgLoading) return;
    const x = Array.from(e.target.files)[0];
    setImg(URL.createObjectURL(x));
    const DATA = new FormData();
    DATA.append("image", e.target.files[0]);
    try {
      setImgLoading(true);
      let imgUpRes = await fileUpload("/admin/tourImage", "POST", DATA);
      if (imgUpRes?.status) {
        setImg(imgUpRes?.url);
      } else {
        toast.error(
          "Something went wrong while uploading image! Please try after some time."
        );
      }
    } catch (error) {
      toast.error(
        "Something went wrong while uploading image! Please try after some time."
      );
    }
    setImgLoading(false);
  };

  // const handleImage = async file => {
  //   if (imageReg.test(file.type)) {
  //     setuploadedImg(URL.createObjectURL(file));
  //     let data = new FormData();
  //     data.append('image', file);
  //     setimgIsLoading(true);
  //     let imgUpRes = await fileUpload('/admin/tourImage', 'POST', data);
  //     console.log('res', imgUpRes);
  //     if (imgUpRes && imgUpRes.status) {
  //       setimgIsLoading(false);
  //       setuploadedImg('');
  //       setCategoryEdit(prev => ({ ...prev, image: imgUpRes.url }));
  //     } else {
  //       toast.error(imgUpRes.message);
  //     }
  //   } else {
  //     toast.error('Please Upload Valid Image');
  //   }
  // };

  const handleEditValidation = () => {
    const { countryId, cityId, name } = categoryEdit;

    if (countryId === "") {
      seterrors({ ...errors, countryId: "Country is required" });

      return false;
    }
    if (cityId === "") {
      seterrors({ ...errors, cityId: "City is required" });

      return false;
    }
    if (name === "") {
      seterrors({ ...createErrors, name: "Continent is required" });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    console.log("Edit submit", categoryEdit);
    if (isLoading) return;
    if (imgLoading) return;

    if (handleEditValidation()) {
      if (Img === "") return toast.error("Pictured is required");
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditTourModule"]({
          id: params.id,
          ...categoryEdit,
          image: Img,
        });

        if (res.isSuccess) {
          dispatch(getAllTourModules());
          seterrors(createErrors);
          setCategoryEdit(initialValues);
          // apiCallSuccess(res.data);
          toast.success("Tour module edited successfully!");
          setImg("");
          navigate("/manage-tour-module");
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
      setisLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="card-title">Edit Tour Module</div>
            <form
              className=""
              onSubmit={(e) => handleEditSubmit(e)}
              encType="multipart/form-data"
            >
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="countryId"
                      className="form-label font-weight-bold"
                    >
                      Country*
                    </label>
                    <Select
                      name="countryId"
                      id="countryId"
                      classNamePrefix="select"
                      options={countres?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.name,
                      }))}
                      // isMulti
                      value={{
                        value: categoryEdit.countryId,
                        label: categoryEdit.countryName,
                      }}
                      onChange={(val) => {
                        setCategoryEdit((prev) => {
                          return {
                            ...prev,
                            countryId: val?.value,
                            countryName: val?.label,
                            cityId: "",
                            cityName: "",
                          };
                        });
                        dispatch(getCitiesOfACountry(val?.value));
                      }}
                    />
                  </div>
                  {/* {errors.countryId && (
                    <span className="errorMessageText text-danger">
                      {errors.countryId}
                    </span>
                  )} */}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="cityId"
                      className="form-label font-weight-bold"
                    >
                      City*
                    </label>
                    <Select
                      name="cityId"
                      id="cityId"
                      classNamePrefix="select"
                      options={cities_of_a_country?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.cityName,
                      }))}
                      // isMulti
                      value={{
                        value: categoryEdit.cityId,
                        label: categoryEdit.cityName,
                      }}
                      onChange={(val) => {
                        setCategoryEdit((prev) => {
                          return {
                            ...prev,
                            cityId: val?.value,
                            cityName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.cityId && (
                    <span className="errorMessageText text-danger">
                      {errors.cityId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Module Name*
                    </label>
                    <input
                      name="name"
                      id="name"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={categoryEdit.name}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.name && (
                    <span className="errorMessageText text-danger">
                      {errors.name}
                    </span>
                  )}
                </div>
                <div className="col-md-6" id="img_input_block">
                  <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                      Picture*
                    </label>
                    <input
                      name="image"
                      id="image"
                      type="file"
                      accept={"image/*"}
                      ref={fileInputRef}
                      className="form-control"
                      onChange={imageHandler}
                    />
                  </div>
                  {/* {imgIsLoading && <p className="text-primary">Uploading. Please wait...</p>}
                  {uploadedImg.length > 0 ? (
                    <img style={{ height: '100px', width: '100px' }} src={uploadedImg} />
                  ) : (
                    <img style={{ height: '100px', width: '100px' }} src={categoryEdit.image} />
                  )}
                  {errors.image && <span className="errorMessageText text-danger">{errors.image}</span>} */}
                  <p className="text-primary mt-2">
                    {imgLoading && "Uploading please wait..."}
                  </p>
                  {Img !== "" && (
                    <>
                      <img
                        alt=""
                        style={{
                          height: "70px",
                          width: "120px",
                          borderRadius: "3px",
                        }}
                        src={Img}
                      ></img>
                    </>
                  )}
                </div>
              </div>
              <div
                className="d-flex m-2"
                style={{ justifyContent: "flex-end" }}
              >
                <button
                  type="button"
                  className="mt-2 btn btn-link"
                  onClick={() => {
                    setCategoryEdit(initialValues);
                    navigate("/manage-tour-module");
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="mt-2 btn btn-primary d-table">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditTourModule;
