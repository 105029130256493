// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Helpers
// import * as Yup from "yup";
import { toast } from "react-toastify";
import { Country, State, City } from "country-state-city";

// Utils
import { capitalizeFirstLetter, getOnlyFullDate } from "../../../utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { fileUpload, requestData } from "../../../services/api/apiHelpers";

// Redux actions
import { getAllLanguages } from "../../../redux/adminSlice/languageSlice";
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import { ApiHelperFunction } from "../../../services/api/apiHelpers";

// Components
// import { Formik } from "formik";
import DatePicker from "react-date-picker";
import Loader from "../../../Component/Loader";
import Select from "react-select";
import PhoneInput from "../../../Component/PhoneInput";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./styles.css";
import "react-phone-number-input/style.css";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Modal from "../../../Component/Modal/Modal";
import ViewMerchantModal from "../../../Component/Modal/ViewMerchantModal";

let initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  langId: "",
  mobile: "",
  city: "",
  country: "",
  zip: "",
  image: "",
  commisionPercentage: "",
  payeeType: "",
  accountType: "",
  marBankName: "",
  bankCode: "",
  branchName: "",
  branchCode: "",
  marBankAccNo: "",
  marBankIfscCode: "",
  marAccHolderName: "",
  bankAcctCurrency: "",
  swiftCode: "",
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  companyMobile: "",
  directorName: "",
  individualName: "",
  companyDob: "",
  companyStreetAddress: "",
  postalCode: "",
  compCity: "",
  compState: "",
  compStateCode: "",
  compCountry: "",
  compCountryCode: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  compamyRegistrationImage: "",
  saveAsDraft: false,
};

let createErrors = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  langId: "",
  mobile: "",
  city: "",
  country: "",
  zip: "",
  image: "",
  commisionPercentage: "",
  payeeType: "",
  accountType: "",
  marBankAccNo: "",
  marBankIfscCode: "",
  marBankName: "",
  marAccHolderName: "",
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  companyMobile: "",
  directorName: "",
  individualName: "",
  companyDob: "",
  companyStreetAddress: "",
  postalCode: "",
  compCity: "",
  compState: "",
  compCountry: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  compamyRegistrationImage: "",
};

var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;
var emailReg =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var bankAccountRegex = /^[A-Za-z0-9]{10}$/;
var IFSCRegex = /^(?:[A-Za-z]{4}[0][A-Za-z0-9]{6}|)$/;

const worldCities = require("../../../constants/worldcities.json");

let currencies = [];
for (const [key, value] of Object.entries(
  require("../../../constants/currencies.json")
)) {
  currencies.push(value);
}

const ViewMerchants = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [allMerchantData, setallMerchantData] = useState([]);
  const [editObject, seteditObject] = useState(initialValues);
  const [editPassword, seteditPassword] = useState("");
  const [citiesForCountry, setcitiesForCountry] = useState([]);
  const [zipDropdownOne, setzipDropdownOne] = useState(false);
  const [phonecode, setPhoneCode] = useState(""); // This state value is not getting concatenated with mobile
  const [zipDropdownTwo, setzipDropdownTwo] = useState(false);
  const [zipcodesForCity, setzipcodesForCity] = useState([]);
  const [zipForCompCity, setzipForCompCity] = useState([]);
  const [phonecodecompany, setPhoneCodeCompany] = useState(""); // This state value is not getting concatenated with compMobile
  const imgInputRef = useRef(null);
  const [errors, seterrors] = useState(createErrors);
  // const [checkedAccess, setcheckedAccess] = useState(accessPrivileges);
  // const [allAccessChecked, setallAccessChecked] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [uploadedImg, setuploadedImg] = useState("");
  const [uploadedLogo, setuploadedLogo] = useState("");
  const [upInsurancePolicy, setupInsurancePolicy] = useState("");
  const [uploadedcompRegCert, setuploadedcompRegCert] = useState("");
  const [approvalStatus, setApprovalStatus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [rejectModal, setRejectModal] = useState(false);
  const [modal, setmodal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(0);
  const [activityStatus, setactivityStatus] = useState({ remarks: "" });

  // Redux stores
  const { languages } = useSelector((state) => state.languageSlice);
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  // const { isLogdedIn } = useSelector((state) => state.adminProfileSlice);

  console.log("approvalStatus", approvalStatus);

  const merchantData = merchants.find((item) => item?._id === params.id);
  // Component did mount
  useEffect(() => {
    // if (isLogdedIn) {
    dispatch(getAllLanguages());
    dispatch(getAllMerchants());
    setallMerchantData(merchants.filter((item) => item?.isApproval === true));
    setuploadedImg("");
    setuploadedLogo("");
    setuploadedcompRegCert("");
    let {
      _id,
      isApproval,
      rejectedStatus,
      saveAsDraft,
      status,
      subMrchent,
      //passwordCrypto,
      ...rest
    } = merchantData;
    // console.log("rest", rest);
    seteditObject((prev) => {
      let update = JSON.parse(JSON.stringify(prev));

      setPhoneCode(update.phonecode);
      setPhoneCodeCompany(update.phonecodecompany);
      // console.log("-----HERE-----", update.phonecode, update.phonecodecompany);

      return { ...update, ...rest };
    });
    getZipcodesForCity(rest?.city);
    //seteditPassword(passwordCrypto);
    getZipForCompCity(rest?.compCity);
    // }
    newMerchantData();
  }, []);

  const newMerchantData = async () => {
    const singleMerchantData = await ApiHelperFunction({
      urlPath: "/admin/single-merchant-profile/" + params.id,
      method: "GET",
    });

    seteditPassword(singleMerchantData["data"]["data"]["password"]);
  };

  const getCityAgainstCountry = (countryName) => {
    console.log(countryName);
    setcitiesForCountry(
      worldCities.filter((item) => item.country === countryName)
    );
  };

  const getZipcodesForCity = async (cityName) => {
    console.log(cityName);
    let res = await requestData("/admin/view-zipcode", "POST", { cityName });

    if (res && res.status) {
      setzipcodesForCity(res.data);
      return res.data;
    }
  };
  const getZipForCompCity = async (cityName) => {
    console.log(cityName);
    let res = await requestData("/admin/view-zipcode", "POST", { cityName });

    if (res && res.status) {
      setzipForCompCity(res.data);
      return res.data;
    }
  };

  const handleImage = async (event, errors) => {
    console.log(event.target.name);
    if (imageReg.test(event.target.files[0].type)) {
      if (event.target.files[0].size <= 2560000) {
        errors[event.target.name] = "";

        if (event.target.name === "image") {
          setuploadedImg(URL.createObjectURL(event.target.files[0]));
        } else if (event.target.name === "logo") {
          setuploadedLogo(URL.createObjectURL(event.target.files[0]));
        } else if (event.target.name === "insurancePolicy") {
          setupInsurancePolicy(URL.createObjectURL(event.target.files[0]));
        } else if (event.target.name === "compamyRegistrationImage") {
          setuploadedcompRegCert(URL.createObjectURL(event.target.files[0]));
        }

        const DATA = new FormData();
        DATA.append("image", event.target.files[0]);
        let imgUpRes = await fileUpload("/admin/uploadMerimage", "POST", DATA);
        console.log("res", imgUpRes);
        if (imgUpRes && imgUpRes.status) {
          seteditObject((prev) => ({
            ...prev,
            [event.target.name]: imgUpRes.url,
          }));
        } else {
          toast.error(imgUpRes.message);
        }
      } else {
        errors[event.target.name] = "Image should not exceed more the 2.5MB";
        if (event.target.name === "image") {
          setuploadedImg("");
        } else if (event.target.name === "logo") {
          setuploadedLogo("");
        } else if (event.target.name === "insurancePolicy") {
          setupInsurancePolicy("");
        } else {
          setuploadedcompRegCert("");
        }
      }
    } else {
      errors[event.target.name] = "File is not an image";
      if (event.target.name === "image") {
        setuploadedImg("");
      } else if (event.target.name === "logo") {
        setuploadedLogo("");
      } else if (event.target.name === "insurancePolicy") {
        setupInsurancePolicy("");
      } else {
        setuploadedcompRegCert("");
      }
    }
  };

  const handleDateChange = (date) => {
    // console.log("date", getOnlyFullDate(date));
    seteditObject({
      ...editObject,
      companyDob: getOnlyFullDate(date),
    });
  };

  const handleChange = (event) => {
    // console.log(event?.target?.name, event?.target?.value);
    if (
      event?.target?.name === "mobile" ||
      event?.target?.name === "zip" ||
      event?.target?.name === "commisionPercentage" ||
      event?.target?.name === "companyMobile" ||
      event?.target?.name === "postalCode"
    ) {
      if (event.target.value < 0) {
        event.target.value = "";
      }
      let inputValue = event.target.value.replace(/\D+/g, "");
      seteditObject({ ...editObject, [event.target.name]: inputValue });
    }

    let textInput;
    if (
      event.target.name === "email" ||
      event.target.name === "payeeType" ||
      event.target.name === "accountType" ||
      event.target.name === "bankCode" ||
      event.target.name === "branchCode" ||
      event.target.name === "bankAcctCurrency" ||
      event.target.name === "marBankAccNo" ||
      event.target.name === "marBankIfscCode" ||
      event.target.name === "swiftCode" ||
      event.target.name === "legalStatus" ||
      event.target.name === "licenseNumber" ||
      event.target.name === "registrationNumber" ||
      event.target.name === "description" ||
      event.target.name === "socialLink"
    ) {
      textInput = event.target.value;
    } else {
      textInput = capitalizeFirstLetter(event);
    }

    seteditObject({
      ...editObject,
      [event?.target?.name]: textInput,
    });
  };
  //scroll to a Div
  const scrollToErrorElement = (id) => {
    const errorElement = document.getElementById(id); // Replace with your actual element ID
    if (errorElement) {
      errorElement.scrollIntoView({
        // or 'auto' for instant scrolling
        block: "center", // or 'end' or 'center'
      });
    }
  };
  // console.log("-: valid :-", bankAccountRegex.test(editObject?.marBankName));

  const handleValidation = () => {
    const {
      firstName,
      lastName,
      email,
      langId,
      mobile,
      commisionPercentage,
      country,
      city,
      zip,
      payeeType,
      accountType,
      marBankAccNo,
      marBankIfscCode,
      marBankName,
      marAccHolderName,
      legalStatus,
      companyName,
      legalCompanyName,
      companyMobile,
      directorName,
      individualName,
      companyDob,
      companyStreetAddress,
      postalCode,
      compCountry,
      compCity,
      licenseNumber,
      registrationNumber,
      description,
      socialLink,
      newSupplier,
    } = editObject;

    if (country === "") {
      setSelectedIndex(0);
      seterrors({
        ...createErrors,
        country: "Merchant country name is required.",
      });

      return false;
    }
    if (city === "") {
      setSelectedIndex(0);
      scrollToErrorElement("city");

      seterrors({
        ...createErrors,
        city: "Merchant city name is required.",
      });

      return false;
    }
    if (zip === "") {
      setSelectedIndex(0);
      scrollToErrorElement("zip");
      seterrors({
        ...createErrors,
        zip: "Merchant address zip is required.",
      });

      return false;
    }
    if (country === "India" && isNaN(zip)) {
      setSelectedIndex(0);
      scrollToErrorElement("zip");
      seterrors({
        ...createErrors,
        zip: "Please enter only numerics",
      });

      return false;
    }
    if (companyName === "") {
      setSelectedIndex(0);
      scrollToErrorElement("companyName");
      seterrors({
        ...createErrors,
        companyName: "Company name is required.",
      });

      return false;
    }
    if (email === "") {
      scrollToErrorElement("email");
      seterrors({ ...createErrors, email: "Email is required." });
      setSelectedIndex(0);
      return false;
    }
    if (!emailReg.test(email)) {
      scrollToErrorElement("email");
      seterrors({
        ...createErrors,
        email: "Please enter correct email format.",
      });

      return false;
    }
    if (editPassword.length < 8) {
      scrollToErrorElement("password");
      seterrors({
        ...createErrors,
        password: "Password should be minimum 8 characters",
      });
      setSelectedIndex(0);
      return false;
    }
    if (firstName === "") {
      scrollToErrorElement("firstName");
      seterrors({
        ...createErrors,
        firstName: "First name is required.",
      });
      setSelectedIndex(0);
      return false;
    }

    if (lastName === "") {
      scrollToErrorElement("lastName");
      seterrors({
        ...createErrors,
        lastName: "Last name is required.",
      });
      setSelectedIndex(0);
      return false;
    }
    if (langId === "") {
      scrollToErrorElement("langId");
      seterrors({
        ...createErrors,
        langId: "Language is required.",
      });
      setSelectedIndex(0);
      return false;
    }
    if (mobile === "") {
      scrollToErrorElement("mobile");

      seterrors({
        ...createErrors,
        mobile: "Merchant mobile number is required.",
      });
      setSelectedIndex(0);
      return false;
    }
    // if (mobile.length < 10) {
    //   scrollToErrorElement("mobile");

    //   seterrors({
    //     ...createErrors,
    //     mobile: "Please enter atleast 10 digit mobile number.",
    //   });

    //   return false;
    // }
    if (mobile.length > 15) {
      scrollToErrorElement("mobile");

      seterrors({
        ...createErrors,
        mobile: "Please enter atmost 15 digit mobile number.",
      });
      setSelectedIndex(0);
      return false;
    }
    if (isNaN(mobile)) {
      scrollToErrorElement("mobile");

      seterrors({
        ...createErrors,
        mobile: "Please enter only numerics",
      });
      setSelectedIndex(0);
      return false;
    }
    // if (
    //   mobile.charAt(0) !== 9 ||
    //   mobile.charAt(0) !== 8 ||
    //   mobile.charAt(0) !== 7 ||
    //   mobile.charAt(0) !== 6
    // ) {
    //   seterrors({
    //     ...createErrors,
    //     mobile: "Please enter a valid mobile number",
    //   });

    //   return false;
    // }
    if (commisionPercentage === "") {
      setSelectedIndex(0);
      scrollToErrorElement("commisionPercentage");

      seterrors({
        ...createErrors,
        commisionPercentage: "Commission percentage is required.",
      });

      return false;
    }
    if (isNaN(commisionPercentage)) {
      setSelectedIndex(0);
      scrollToErrorElement("commisionPercentage");

      seterrors({
        ...createErrors,
        commisionPercentage: "Please enter only numerics",
      });

      return false;
    }

    if (!approvalStatus) {
      setSelectedIndex(0);
      toast.error("Merchant Abroad is required");
      return false;
    }
    if (legalStatus === "") {
      setSelectedIndex(2);
      scrollToErrorElement("legalStatus");

      seterrors({
        ...createErrors,
        legalStatus: "Company legal status is required.",
      });

      return false;
    }
    if (legalCompanyName === "") {
      setSelectedIndex(2);
      scrollToErrorElement("legalCompanyName");

      seterrors({
        ...createErrors,
        legalCompanyName: "Company legal name is required.",
      });

      return false;
    }
    // if (companyDob === "") {
    //   seterrors({
    //     ...createErrors,
    //     companyDob: "Company formation date is required.",
    //   });

    //   return false;
    // }
    // if (directorName === "") {
    //   seterrors({
    //     ...createErrors,
    //     directorName: "Company director name is required.",
    //   });

    //   return false;
    // }
    if (companyStreetAddress === "") {
      setSelectedIndex(2);
      scrollToErrorElement("companyStreetAddress");

      seterrors({
        ...createErrors,
        companyStreetAddress: "Company address street is required.",
      });

      return false;
    }
    if (compCountry === "") {
      setSelectedIndex(2);
      seterrors({
        ...createErrors,
        compCountry: "Company country name is required.",
      });

      return false;
    }
    if (compCity === "") {
      setSelectedIndex(2);
      scrollToErrorElement("compCity");
      seterrors({
        ...createErrors,
        compCity: "Company city name is required.",
      });

      return false;
    }
    if (postalCode === "") {
      setSelectedIndex(2);
      scrollToErrorElement("postalCode");
      seterrors({
        ...createErrors,
        postalCode: "Company address zip is required.",
      });

      return false;
    }
    if (compCountry == "India" && isNaN(postalCode)) {
      setSelectedIndex(2);
      scrollToErrorElement("postalCode");

      seterrors({
        ...createErrors,
        postalCode: "Please enter only numerics",
      });

      return false;
    }
    if (licenseNumber === "") {
      setSelectedIndex(2);
      scrollToErrorElement("licenseNumber");

      seterrors({
        ...createErrors,
        licenseNumber: "Company license is required.",
      });

      return false;
    }
    if (registrationNumber === "") {
      setSelectedIndex(2);
      scrollToErrorElement("registrationNumber");

      seterrors({
        ...createErrors,
        registrationNumber: "Company registration is required.",
      });

      return false;
    }
    // if (description === "") {
    //   scrollToErrorElement('description');

    //   seterrors({
    //     ...createErrors,
    //     description: "Company business description is required.",
    //   });

    //   return false;
    // }
    // if (socialLink === "") {
    //   scrollToErrorElement("socialLink");

    //   seterrors({
    //     ...createErrors,
    //     socialLink: "Company's one social media link is required.",
    //   });

    //   return false;
    // }
    // if (individualName === "") {
    //   seterrors({
    //     ...createErrors,
    //     individualName: "Company contact person name is required.",
    //   });

    //   return false;
    // }
    if (companyMobile === "") {
      setSelectedIndex(2);
      scrollToErrorElement("companyMobile");
      seterrors({
        ...createErrors,
        companyMobile: "Contact person mobile number is required.",
      });

      return false;
    }
    // if (companyMobile.length < 10) {
    //   seterrors({
    //     ...createErrors,
    //     companyMobile: "Please enter atleast 10 digit mobile number.",
    //   });

    //   return false;
    // }
    if (companyMobile.length > 15) {
      setSelectedIndex(2);
      seterrors({
        ...createErrors,
        companyMobile: "Please enter atmost 15 digit mobile number.",
      });

      return false;
    }
    if (isNaN(companyMobile)) {
      setSelectedIndex(2);
      scrollToErrorElement("companyMobile");
      seterrors({
        ...createErrors,
        companyMobile: "Please enter only numerics",
      });

      return false;
    }
    // if (
    //   companyMobile.charAt(0) !== 9 ||
    //   companyMobile.charAt(0) !== 8 ||
    //   companyMobile.charAt(0) !== 7 ||
    //   companyMobile.charAt(0) !== 6
    // ) {
    //   seterrors({
    //     ...createErrors,
    //     companyMobile: "Please enter a valid mobile number",
    //   });

    //   return false;
    // }

    return true;
  };

  const handleApproved = async (event) => {
    event.preventDefault();

    if (handleValidation()) {
      let bodyData = {
        ...editObject,
        approvalStatus: approvalStatus,
        phonecode: phonecode,
        phonecodecompany: phonecodecompany,
        password: editPassword,
        id: `${params.id}`,

        approvalStatus: true,
        isReject: false,
        remarks: activityStatus?.remarks,
      };

      // console.log(
      //   "Edit Object : ",
      //   editObject.phonecode,
      //   editObject.phonecodecompany
      // );
      // console.log(
      //   "Body Data : ",
      //   bodyData.phonecode,
      //   bodyData.phonecodecompany
      // );

      //return false;

      try {
        setisLoading(true);
        let res = await JSON_API["adminEditMerchant"](bodyData);
        // console.log(res);
        let cityZipCheck = await getZipcodesForCity(editObject.city);
        // console.log("cityZipCheck", cityZipCheck);
        if (
          cityZipCheck?.filter((item) => item?.zipCode === editObject.zip)
            ?.length === 0
        ) {
          let res2 = await JSON_API["addCityZipCode"]({
            cityName: editObject.city,
            countryName: editObject.country,
            zipCode: editObject.zip,
          });
        }
        let compCityCheck = await getZipForCompCity(editObject.compCity);
        console.log("compCityCheck", compCityCheck);
        if (
          compCityCheck?.filter(
            (item) => item?.zipCode === editObject.postalCode
          )?.length === 0
        ) {
          let res3 = await JSON_API["addCityZipCode"]({
            cityName: editObject.compCity,
            countryName: editObject.compCountry,
            zipCode: editObject.postalCode,
          });
        }

        if (res.isSuccess) {
          dispatch(getAllMerchants());
          if (imgInputRef.current) {
            imgInputRef.current.value = "";
          }
          setuploadedImg("");
          setuploadedLogo("");
          setuploadedcompRegCert("");
          seteditObject(initialValues);
          seterrors(createErrors);
          setisLoading(false);
          MerchantApiCallSuccess(res.data);
          navigate("/manage-merchant");
        } else {
          setisLoading(false);
          MerchantApiCallError(res?.error?.message);
        }
      } catch (error) {
        setisLoading(false);
        MerchantApiCallError(error.message);
      }
    }
    // else {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: "smooth",
    //   });
    // }
  };

  const rejectSubmission = async (event) => {
    if (activityStatus.remarks === "") {
      seterrors({ ...errors, remarks: "A remark is required." });
    } else {
      let bodyData = {
        ...editObject,
        approvalStatus: approvalStatus,
        phonecode: phonecode,
        phonecodecompany: phonecodecompany,
        password: editPassword,
        id: `${params.id}`,

        approvalStatus: false,
        isReject: true,
        remarks: activityStatus?.remarks,
      };

      try {
        setisLoading(true);
        let res = await requestData(
          `/admin/update-merchant-profile/${params.id}`,
          "PUT",
          {
            activityDetailsId: `${params.id}`,
            ...bodyData,
            rejectedStatus: true,
            updatedStatus: true,
            isApproval: false,
            visibleStatus: false,
          }
        );

        if (res && res.status) {
          setactivityStatus({ remarks: "" });
          setisLoading(false);
          setmodal(false);
          navigate("/check-merchant-additions");
        } else {
          setisLoading(false);
          toast.error(res?.error?.message);
        }
      } catch (error) {
        console.log("Failed to reject due to ", error.message);
      }
    }
  };

  const MerchantApiCallSuccess = (data) => {
    // setkey(Math.random());
    toast.success("Edited Successfully!");
  };

  const MerchantApiCallError = (errorMsg) => {
    toast.error(errorMsg);
    setError(errorMsg);
  };

  const openModal = () => {
    setmodal(true);
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content" style={{ width: "400px" }}>
            <div className="modal-header">
              <h5 className="modal-title">APPROVAL REMARKS</h5>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks*
                  </label>
                  <textarea
                    name="remarks"
                    id="remarks"
                    // placeholder="Your first name, e.g. Walter"
                    className="form-control"
                    autoComplete="false"
                    value={activityStatus.remarks}
                    // onChange={(e) => handleChange(e)}
                    onChange={(e) => setactivityStatus(e.target.value)}
                  />
                </div>
                {errors.remarks && (
                  <span className="errorMessageText text-danger">
                    {errors.remarks}
                  </span>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setmodal(false);
                }}
              >
                CLOSE
              </button>
              <button
                type="submit"
                className="btn btn-link btn-primary"
                onClick={(e) => {
                  handleApproved(e);
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="modal-content" style={{ width: "400px" }}>
            <div className="modal-header">
              <h5 className="modal-title">REJECTION REMARKS</h5>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks*
                  </label>
                  <textarea
                    name="remarks"
                    id="remarks"
                    // placeholder="Your first name, e.g. Walter"
                    className="form-control"
                    autoComplete="false"
                    value={activityStatus.remarks}
                    // onChange={(e) => handleChange(e)}
                    onChange={(e) => setactivityStatus(e.target.value)}
                  />
                </div>
                {errors.remarks && (
                  <span className="errorMessageText text-danger">
                    {errors.remarks}
                  </span>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setmodal(false);
                }}
              >
                CLOSE
              </button>
              <button
                type="submit"
                className="btn btn-link btn-warning"
                onClick={(e) => {
                  rejectSubmission(e);
                }}
              >
                REJECT
              </button>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {modal && (
        <Modal
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
          closeModal={setmodal}
        />
      )}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">View Merchant</div>

              <Tabs
                selectedIndex={selectedIndex}
                onSelect={(index) => setSelectedIndex(index)}
              >
                <TabList>
                  <Tab id="merchantDetails">Merchant Details</Tab>
                  <Tab id="bankDetails">Bank Details</Tab>
                  <Tab id="companyDetails">Company Details</Tab>
                </TabList>
                <TabPanel>
                  <div className="row" style={{ padding: "1rem" }}>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="country">
                          Country{" "}
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        {/* <CountryDropdown
                        className="form-select"
                        value={country || editObject?.country}
                        onChange={(val) => {
                          getCityAgainstCountry(val);
                          seteditObject({ ...editObject, city: "", zip: "" }); // to clear previos city name on change
                          setcountry(val);
                        }}
                      /> */}
                        <Select
                          isSearchable={true}
                          isDisabled={true}
                          name="country"
                          id="country"
                          classNamePrefix="select"
                          // ref={inputRef}
                          options={Country?.getAllCountries()?.map(
                            (item, idx) => ({
                              value: item.name,
                              label: item?.name,
                            })
                          )}
                          value={{
                            value: editObject?.country,
                            label: editObject?.country,
                          }}
                          // isMulti
                          onChange={(val) => {
                            // console.log(val);
                            getCityAgainstCountry(val?.value);
                            seteditObject((prev) => {
                              // console.log(prev);
                              return {
                                ...prev,
                                country: val?.value,
                                city: "",
                                zip: "",
                              };
                            });
                          }}
                        />
                      </div>
                      {errors.country && (
                        <span className="errorMessageText text-danger">
                          {errors.country}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="city" className="form-label">
                          City
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        {/* <input
                        name="city"
                        id="city"
                        type="text"
                        className="form-control"
                        value={merchant.city}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      /> */}
                        <Select
                          name="city"
                          id="city"
                          isDisabled={true}
                          classNamePrefix="select"
                          // ref={inputRef}
                          options={citiesForCountry?.map((item, idx) => ({
                            value: item?.city,
                            label: item?.city,
                          }))}
                          value={{
                            value: editObject?.city,
                            label: editObject?.city,
                          }}
                          // isMulti
                          onChange={(val) => {
                            getZipcodesForCity(val?.value);
                            seteditObject((prev) => {
                              // console.log(prev);
                              return {
                                ...prev,
                                city: val?.value,
                                zip: "",
                              };
                            });
                            // console.log('cityid',activity.cityId);
                          }}
                        />
                      </div>
                      {errors.city && (
                        <span className="errorMessageText text-danger">
                          {errors.city}
                        </span>
                      )}
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label htmlFor="zip" className="form-label">
                          Zip
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="zip"
                          id="zip"
                          type="text"
                          className="form-control"
                          value={editObject.zip}
                          onChange={(e) => handleChange(e)}
                          onClick={() => setzipDropdownOne(true)}
                          required={true}
                          disabled
                        />

                        {zipDropdownOne && (
                          <Select
                            name="zip"
                            id="zip_select"
                            isDisabled={true}
                            classNamePrefix="select"
                            // ref={inputRef}
                            options={zipcodesForCity?.map((item, idx) => ({
                              value: item?.zipCode,
                              label: item?.zipCode,
                            }))}
                            value={{
                              value: editObject?.zip,
                              label: editObject?.zip,
                            }}
                            // isMulti
                            onChange={(val) => {
                              seteditObject((prev) => {
                                // console.log(prev);
                                return {
                                  ...prev,
                                  zip: val?.value,
                                };
                              });
                            }}
                          />
                        )}
                      </div>
                      {errors.zip && (
                        <span className="errorMessageText text-danger">
                          {errors.zip}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="companyName">
                          Company Name
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="companyName"
                          className="form-control"
                          id="companyName"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          value={editObject.companyName}
                          required
                          disabled
                        />
                      </div>
                      {errors.companyName && (
                        <span className="errorMessageText text-danger">
                          {errors.companyName}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="marchentCode">
                          Merchant Code
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="marchentCode"
                          className="form-control"
                          id="marchentCode"
                          autoComplete="off"
                          value={editObject.marchentCode}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="email"
                          id="email"
                          placeholder="E.g.,waltersmith@gmail.com"
                          type="email"
                          className="form-control"
                          value={editObject.email}
                          onChange={(e) => handleChange(e)}
                          required={true}
                          disabled
                        />
                      </div>
                      {errors.email && (
                        <span className="errorMessageText text-danger">
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="password"
                          id="password"
                          type="text"
                          className="form-control"
                          value={editPassword}
                          onChange={(e) => seteditPassword(e.target.value)}
                          required={true}
                          disabled
                        />
                      </div>
                      {errors.password && (
                        <span className="errorMessageText text-danger">
                          {errors.password}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">
                          First Name
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="firstName"
                          id="firstName"
                          placeholder="Your first name, e.g. Walter"
                          type="text"
                          className="form-control"
                          value={editObject.firstName}
                          onChange={(e) => handleChange(e)}
                          required={true}
                          disabled
                        />
                      </div>
                      {errors.firstName && (
                        <span className="errorMessageText text-danger">
                          {errors.firstName}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="lastName"
                          id="lastName"
                          placeholder="Your first name, e.g. Smith"
                          type="text"
                          className="form-control"
                          value={editObject.lastName}
                          onChange={(e) => handleChange(e)}
                          required={true}
                          disabled
                        />
                      </div>
                      {errors.lastName && (
                        <span className="errorMessageText text-danger">
                          {errors.lastName}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="langId" className="form-label">
                          Language
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <select
                          name="langId"
                          id="langId"
                          className="form-control form-select"
                          value={editObject.langId}
                          onChange={(e) => handleChange(e)}
                          required={true}
                          disabled
                        >
                          <option value="" key={0}>
                            Select
                          </option>
                          {languages.map((item, idx) => {
                            return (
                              <>
                                <option key={idx + 1} value={`${item?._id}`}>
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      {errors.langId && (
                        <span className="errorMessageText text-danger">
                          {errors.langId}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="mobile" className="form-label">
                          Mobile
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <div className="d-flex">
                          {/* <PhoneInput
                            international
                            className="col-md-3"
                            defaultCountry="IE"
                            readonly="true"
                            value={editObject?.phonecode}
                            onChange={(e) => {
                              console.log("-: Phone Code :-", e);
                              editObject.phonecode = e;
                              setPhoneCode(e);
                            }}
                          />
                          <input
                            name="mobile"
                            id="mobile"
                            placeholder="E.g. 9477280560"
                            type="text"
                            className="form-control"
                            value={editObject.mobile}
                            onChange={(e) => handleChange(e)}
                            required={true}
                            disabled
                          /> */}

                          <div className="form-control">
                            <PhoneInput
                              value={editObject.mobile}
                              onChange={(e) => {
                                seteditObject((prev) => ({
                                  ...prev,
                                  mobile: e,
                                }));
                              }}
                              countryCode={editObject.phonecode}
                              onCountryChange={(val) =>
                                seteditObject((prev) => ({
                                  ...prev,
                                  phonecode: val,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {errors.mobile && (
                        <span className="errorMessageText text-danger">
                          {errors.mobile}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="commisionPercentage"
                          className="form-label"
                        >
                          Commission Percentage
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="commisionPercentage"
                          id="commisionPercentage"
                          type="number"
                          value={editObject.commisionPercentage}
                          // ref={imgInputRef}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          required={true}
                          min={1}
                        />
                        {errors.commisionPercentage && (
                          <span className="errorMessageText text-danger">
                            {errors.commisionPercentage}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 d-none">
                      <div className="mb-3">
                        <label htmlFor="image" className="form-label">
                          Image
                        </label>
                        <input
                          name="image"
                          id="image"
                          type="file"
                          ref={imgInputRef}
                          className="form-control"
                          onChange={(e) => {
                            handleImage(e, errors);
                          }}
                          required={true}
                          accept="image/*"
                          disabled
                        />
                        {uploadedImg.length > 0 ? (
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={uploadedImg}
                          />
                        ) : (
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={editObject.image}
                          />
                        )}
                        {errors.image && (
                          <span className="errorMessageText text-danger">
                            {errors.image}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row" style={{ padding: "1rem" }}>
                    <div className="col-md-12">
                      <div className="col-md-6">
                        <div className="mb-3 d-flex" style={{ gap: "1rem" }}>
                          <label
                            htmlFor="payeeType"
                            className="form-label"
                            style={{
                              marginLeft: "15px",
                              // fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Payee Type
                          </label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="payeeType"
                              id="payee_type_1"
                              onChange={(e) => handleChange(e)}
                              value="person"
                              checked={editObject.payeeType === "person"}
                              disabled
                            />
                            <label
                              className="form-check-label"
                              htmlFor="payee_type_1"
                            >
                              Person
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="payeeType"
                              id="payee_type_2"
                              onChange={(e) => handleChange(e)}
                              value="organization"
                              checked={editObject.payeeType === "organization"}
                              disabled
                            />
                            <label
                              className="form-check-label"
                              htmlFor="payee_type_2"
                            >
                              Organization
                            </label>
                          </div>
                        </div>
                        {errors.payeeType && (
                          <span className="errorMessageText text-danger">
                            {errors.payeeType}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-6">
                        <div className="mb-3 d-flex" style={{ gap: "1rem" }}>
                          <label
                            htmlFor="accountType"
                            className="form-label"
                            style={{
                              marginLeft: "15px",
                              // fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Account Type
                          </label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="accountType"
                              id={`account_type_1`}
                              onChange={(e) => handleChange(e)}
                              value="current"
                              checked={editObject.accountType === "current"}
                              disabled
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`account_type_1`}
                            >
                              Current
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="accountType"
                              id="account_type_2"
                              onChange={(e) => handleChange(e)}
                              value="savings"
                              checked={editObject.accountType === "savings"}
                              disabled
                            />
                            <label
                              className="form-check-label"
                              htmlFor="account_type_2"
                            >
                              Savings
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="accountType"
                              id="account_type_3"
                              onChange={(e) => handleChange(e)}
                              value="others"
                              checked={editObject.accountType === "others"}
                              disabled
                            />
                            <label
                              className="form-check-label"
                              htmlFor="account_type_3"
                            >
                              Others
                            </label>
                          </div>
                        </div>
                        {errors.accountType && (
                          <span className="errorMessageText text-danger">
                            {errors.accountType}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="marBankName" className="form-label">
                          Bank Name
                        </label>
                        <input
                          name="marBankName"
                          id="marBankName"
                          type="text"
                          value={editObject.marBankName}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          required={true}
                          disabled
                        />
                        {errors.marBankName && (
                          <span className="errorMessageText text-danger">
                            {errors.marBankName}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="bankCode" className="form-label">
                          Bank Code
                        </label>
                        <input
                          name="bankCode"
                          id="bankCode"
                          type="text"
                          value={editObject.bankCode}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="branchName" className="form-label">
                          Branch Name
                        </label>
                        <input
                          name="branchName"
                          id="branchName"
                          type="text"
                          value={editObject.branchName}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="branchCode" className="form-label">
                          Branch Code
                        </label>
                        <input
                          name="branchCode"
                          id="branchCode"
                          type="text"
                          value={editObject.branchCode}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="marAccHolderName"
                          className="form-label"
                        >
                          Account Holder Name
                        </label>
                        <input
                          name="marAccHolderName"
                          id="marAccHolderName"
                          type="text"
                          value={editObject.marAccHolderName}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          required={true}
                          disabled
                        />
                        {errors.marAccHolderName && (
                          <span className="errorMessageText text-danger">
                            {errors.marAccHolderName}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="marBankAccNo" className="form-label">
                          Bank Account Number
                        </label>
                        <input
                          name="marBankAccNo"
                          id="marBankAccNo"
                          type="text"
                          value={editObject.marBankAccNo}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          required={true}
                          disabled
                        />
                      </div>
                      {errors.marBankAccNo && (
                        <span className="errorMessageText text-danger">
                          {errors.marBankAccNo}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6  d-none">
                      <div className="mb-3">
                        <label htmlFor="marBankIfscCode" className="form-label">
                          Bank IFS Code
                        </label>
                        <input
                          name="marBankIfscCode"
                          id="marBankIfscCode"
                          type="text"
                          value={editObject.marBankIfscCode}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          disabled
                        />
                      </div>
                      {errors.marBankIfscCode && (
                        <span className="errorMessageText text-danger">
                          {errors.marBankIfscCode}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="bankAcctCurrency"
                          className="form-label"
                        >
                          Bank Account Currency
                        </label>
                        <select
                          name="bankAcctCurrency"
                          id="bankAcctCurrency"
                          className="form-control form-select"
                          value={editObject.bankAcctCurrency}
                          onChange={(e) => handleChange(e)}
                          disabled
                        >
                          <option value="" key={0}>
                            Select
                          </option>
                          {currencies?.map((item, idx) => {
                            return (
                              <>
                                <option
                                  key={idx + 1}
                                  // value={JSON.stringify({
                                  //   name: item?.name,
                                  //   abbreviation: item?.code,
                                  //   symbol: item?.symbol_native,
                                  // })} // JSON.stringify to set the data as string for validation
                                  value={item?.name}
                                >
                                  {item?.name + " (" + item?.code + ")"}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="swiftCode" className="form-label">
                          Swift Code/BIC
                        </label>
                        <input
                          name="swiftCode"
                          id="swiftCode"
                          type="text"
                          value={editObject.swiftCode}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row" style={{ padding: "1rem" }}>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="legalStatus"
                        >
                          Legal Status
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <select
                          className="form-select"
                          id="legalStatus"
                          name="legalStatus"
                          onChange={(e) => handleChange(e)}
                          value={editObject.legalStatus}
                          disabled
                        >
                          <option value="" disabled={true} selected={true}>
                            Select legal status
                          </option>
                          <option value="registered_company">
                            Registered Company
                          </option>
                          <option value="individual_traders">
                            Individual Trader
                          </option>
                        </select>
                      </div>
                      {errors.legalStatus && (
                        <span className="errorMessageText text-danger">
                          {errors.legalStatus}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="legalCompanyName"
                        >
                          Legal Company Name
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="legalCompanyName"
                          className="form-control"
                          id="legalCompanyName"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          value={editObject.legalCompanyName}
                          required
                          disabled
                        />
                      </div>
                      {errors.legalCompanyName && (
                        <span className="errorMessageText text-danger">
                          {errors.legalCompanyName}
                        </span>
                      )}
                    </div>
                    {editObject.legalStatus === "registered_company" ? (
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            className="font-weight-bold"
                            htmlFor="directorName"
                          >
                            Director Name
                          </label>
                          <input
                            type="text"
                            name="directorName"
                            className="form-control"
                            id="directorName"
                            aria-describedby="emailHelp"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                            value={editObject.directorName}
                            required
                            disabled
                          />
                        </div>
                        {errors.directorName && (
                          <span className="errorMessageText text-danger">
                            {errors.directorName}
                          </span>
                        )}
                      </div>
                    ) : editObject.legalStatus === "individual_traders" ? (
                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="individualName"
                            >
                              Trader Name
                            </label>
                            <input
                              type="text"
                              name="individualName"
                              className="form-control"
                              id="individualName"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                              value={editObject.individualName}
                              required
                              disabled
                            />
                          </div>
                          {errors.individualName && (
                            <span className="errorMessageText text-danger">
                              {errors.individualName}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="companyDob"
                            >
                              Date Of Birth
                            </label>
                            <DatePicker
                              calendarIcon={null}
                              className="form-control"
                              clearIcon={null}
                              value={editObject.companyDob}
                              format="dd/MM/y"
                              // closeCalendar  // on 'select'
                              shouldCloseCalendar={({ reason }) =>
                                reason === "select" ||
                                reason === "outsideAction"
                              }
                              onChange={handleDateChange}
                            />
                          </div>
                          {errors.companyDob && (
                            <span className="errorMessageText text-danger">
                              {errors.companyDob}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row" style={{ padding: "1rem" }}>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="compCountry"
                        >
                          Company Country
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <Select
                          name="compCountry"
                          id="compCountry"
                          isDisabled={true}
                          classNamePrefix="select"
                          // ref={inputRef}
                          options={Country?.getAllCountries()?.map(
                            (item, idx) => ({
                              value: item?.name,
                              label: item?.name,
                            })
                          )}
                          value={{
                            value: editObject?.compCountry,
                            label: editObject?.compCountry,
                          }}
                          // isMulti
                          onChange={(val) => {
                            // console.log(val);
                            seteditObject((prev) => {
                              // console.log(prev);
                              getCityAgainstCountry(val?.value);
                              return {
                                ...prev,
                                compCountry: val?.value,
                                compCity: "",
                                postalCode: "",
                              };
                            });

                            // console.log('cityid',activity.cityId);
                          }}
                        />
                      </div>
                      {errors.compCountry && (
                        <span className="errorMessageText text-danger">
                          {errors.compCountry}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="font-weight-bold" htmlFor="compCity">
                          Company City
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <Select
                          name="compCity"
                          id="compCity"
                          isDisabled={true}
                          classNamePrefix="select"
                          // ref={inputRef}
                          options={citiesForCountry?.map((item, idx) => ({
                            value: item?.city,
                            label: item?.city,
                          }))}
                          value={{
                            value: editObject?.compCity,
                            label: editObject?.compCity,
                          }}
                          // isMulti
                          onChange={(val) => {
                            getZipForCompCity(val?.value);
                            seteditObject((prev) => {
                              // console.log(prev);
                              return {
                                ...prev,
                                compCity: val?.value,
                                postalCode: "",
                              };
                            });
                            // console.log('cityid',activity.cityId);
                          }}
                        />
                      </div>
                      {errors.compCity && (
                        <span className="errorMessageText text-danger">
                          {errors.compCity}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="companyStreetAddress"
                        >
                          Company Street Address
                        </label>
                        <input
                          type="text"
                          name="companyStreetAddress"
                          className="form-control"
                          id="companyStreetAddress"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          value={editObject.companyStreetAddress}
                          required
                          disabled
                        />
                      </div>
                      {errors.companyStreetAddress && (
                        <span className="errorMessageText text-danger">
                          {errors.companyStreetAddress}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="postalCode"
                        >
                          Company Zip
                        </label>
                        <input
                          type="text"
                          name="postalCode"
                          className="form-control"
                          id="postalCode"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          min={0}
                          onChange={(e) => handleChange(e)}
                          onClick={() => setzipDropdownTwo(true)}
                          value={editObject.postalCode}
                          required
                          disabled
                        />

                        {zipDropdownTwo && (
                          <Select
                            name="postalCode"
                            id="comp_zip_select"
                            isDisabled={true}
                            classNamePrefix="select"
                            // ref={inputRef}
                            options={zipForCompCity?.map((item, idx) => ({
                              value: item?.zipCode,
                              label: item?.zipCode,
                            }))}
                            value={{
                              value: editObject?.postalCode,
                              label: editObject?.postalCode,
                            }}
                            // isMulti
                            onChange={(val) => {
                              seteditObject((prev) => {
                                // console.log(prev);
                                return {
                                  ...prev,
                                  postalCode: val?.value,
                                };
                              });
                            }}
                          />
                        )}
                      </div>
                      {errors.postalCode && (
                        <span className="errorMessageText text-danger">
                          {errors.postalCode}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="companyMobile"
                        >
                          Company Mobile Number
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <div className="d-flex">
                          {/* <PhoneInput
                            international
                            className="col-md-3"
                            defaultCountry="IE"
                            readonly="true"
                            value={editObject?.phonecodecompany}
                            //onChange={setPhoneCodeCompany}
                            onChange={(e) => {
                              console.log("-: Company Phone Code :-", e);
                              editObject.phonecodecompany = e;
                              setPhoneCodeCompany(e);
                            }}
                          />
                          <input
                            type="text"
                            name="companyMobile"
                            className="form-control"
                            id="companyMobile"
                            aria-describedby="emailHelp"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                            value={editObject.companyMobile}
                            required
                            disabled
                          /> */}

                          <div className="form-control">
                            <PhoneInput
                              value={editObject.companyMobile}
                              onChange={(e) => {
                                seteditObject((prev) => ({
                                  ...prev,
                                  companyMobile: e,
                                }));
                              }}
                              countryCode={editObject.phonecodecompany}
                              onCountryChange={(val) =>
                                seteditObject((prev) => ({
                                  ...prev,
                                  phonecodecompany: val,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {errors.companyMobile && (
                        <span className="errorMessageText text-danger">
                          {errors.companyMobile}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="licenseNumber"
                        >
                          License Number
                        </label>
                        <input
                          type="text"
                          name="licenseNumber"
                          className="form-control"
                          id="licenseNumber"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          value={editObject.licenseNumber}
                          required
                          disabled
                        />
                      </div>
                      {errors.licenseNumber && (
                        <span className="errorMessageText text-danger">
                          {errors.licenseNumber}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="registrationNumber"
                        >
                          Registration Number
                        </label>
                        <input
                          type="text"
                          name="registrationNumber"
                          className="form-control"
                          id="registrationNumber"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          value={editObject.registrationNumber}
                          required
                          disabled
                        />
                      </div>
                      {errors.registrationNumber && (
                        <span className="errorMessageText text-danger">
                          {errors.registrationNumber}
                        </span>
                      )}
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="description"
                        >
                          Description
                        </label>
                        <textarea
                          name="description"
                          className="form-control"
                          id="description"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          value={editObject.description}
                          required
                          disabled
                        />
                      </div>
                      {errors.description && (
                        <span className="errorMessageText text-danger">
                          {errors.description}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="socialLink"
                        >
                          Social Link
                        </label>
                        <input
                          type="text"
                          name="socialLink"
                          className="form-control"
                          id="socialLink"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          value={editObject.socialLink}
                          required
                          disabled
                        />
                      </div>
                      {errors.socialLink && (
                        <span className="errorMessageText text-danger">
                          {errors.socialLink}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="font-weight-bold" htmlFor="logo">
                        Company Logo
                      </label>
                      <input
                        name="logo"
                        id="logo"
                        type="file"
                        ref={imgInputRef}
                        className="form-control"
                        onChange={(e) => {
                          handleImage(e, errors);
                        }}
                        required
                        accept="image/*"
                        disabled
                      />
                      {uploadedLogo.length > 0 ? (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={uploadedLogo}
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={editObject.logo}
                        />
                      )}
                      {errors.logo && (
                        <span className="errorMessageText text-danger">
                          {errors.logo}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="insurancePolicy"
                        >
                          Insurance Policy
                        </label>
                        <input
                          type="file"
                          name="insurancePolicy"
                          id="insurancePolicy"
                          ref={imgInputRef}
                          className="form-control"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          onChange={(e) => {
                            handleImage(e, errors);
                          }}
                          required
                          accept="image/*"
                          disabled
                        />
                      </div>
                      {upInsurancePolicy.length > 0 ? (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={upInsurancePolicy}
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={editObject.insurancePolicy}
                        />
                      )}
                      {errors.insurancePolicy && (
                        <span className="errorMessageText text-danger">
                          {errors.insurancePolicy}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        className="font-weight-bold"
                        htmlFor="compamyRegistrationImage"
                      >
                        Company Registration Certificate
                      </label>
                      <input
                        name="compamyRegistrationImage"
                        id="compamyRegistrationImage"
                        type="file"
                        ref={imgInputRef}
                        className="form-control"
                        onChange={(e) => {
                          handleImage(e, errors);
                        }}
                        required
                        accept="image/*"
                        disabled
                      />
                      {uploadedcompRegCert.length > 0 ? (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={uploadedcompRegCert}
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={editObject.compamyRegistrationImage}
                        />
                      )}
                      {errors.compamyRegistrationImage && (
                        <span className="errorMessageText text-danger">
                          {errors.compamyRegistrationImage}
                        </span>
                      )}
                    </div>
                  </div>
                </TabPanel>
              </Tabs>

              <div className="row" style={{ padding: "1rem" }}>
                <div
                  className={
                    editObject?.newSupplier && editObject?.newSupplier == true
                      ? "d-flex"
                      : "d-none"
                  }
                  style={{ justifyContent: "flex-start" }}
                >
                  <input
                    type="checkbox"
                    name="isApprovalStatus"
                    value="true"
                    onClick={(e) => {
                      console.log(e.target.checked);
                      setApprovalStatus(e.target.checked);
                      //handleChange(e)
                    }}
                  />{" "}
                  &nbsp; Merchant abroad ?
                </div>
                <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                  {!approvalStatus && (
                    <button
                      type="button"
                      className="mt-2 btn btn-danger d-table"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(1);
                      }}
                    >
                      Reject
                    </button>
                  )}

                  <button
                    type="button"
                    className="mt-2 btn btn-primary d-table ml-4"
                    // onClick={(e) => handleApproved(e)}
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                    }}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* {rejectModal && ( */}
      {/* <ViewMerchantModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="container">
          <form>
            <div className="row">
              <label for="usr" style={{ fontSize: "12px", fontWeight: "bold" }}>
                Reject Reason
              </label>
              <textarea type="text" className="form-control" id="usr" />
            </div>
          </form>
        </div>
      </ViewMerchantModal> */}
      {/* )} */}
    </>
  );
};

export default ViewMerchants;
