// Hooks
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { capitalizeFirstLetter, apiCallError } from "../../../utils";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import Table from "../../../Component/Table";
import HomeService from "../../../services/api/HomeService";
import Loader from "../../../Component/Loader";
import { useNavigate, useParams } from "react-router-dom";

const topicInitial = {
  id: "",
  topicName: "",
};

function EditTopic() {
  const [selectedTopic, setSelectedTopic] = useState(topicInitial);
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [allContactTopic, setAllContactTopic] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  // Redux stores

  const getAllContactTopic = () => {
    HomeService.getAllContactTopic()
      .then((result) => {
        console.log("contact topic", result);
        if (result && result.status) {
          setAllContactTopic(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getAllContactTopic();
  }, []);

  useEffect(() => {
    if (allContactTopic.length) {
      const topicData = allContactTopic.find((item) => item?._id === params.id);
      setSelectedTopic({
        id: topicData._id,
        topicName: topicData.topicName,
      });
    }
  }, [allContactTopic]);

  const editHandler = async () => {
    if (isLoading) return;
    setisLoading(true);

    try {
      const res = await JSON_API["editTopic"]({ ...selectedTopic });
      if (res.isSuccess) {
        setSelectedTopic({ ...topicInitial });
        setModal(false);
        setmodalToggleView(null);
        getAllContactTopic();
        toast.success("Topic updated successfully");
        navigate("/manage-topic");
      } else {
        apiCallError(res?.error?.message, setError);
        toast.error(res?.message);
      }
    } catch (error) {
      toast.error(error?.message);
      apiCallError(error?.message, setError);
    }

    setisLoading(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <form action="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Topic</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="font-weight-bold"
                    >
                      Topic
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      onChange={(e) =>
                        setSelectedTopic((prev) => {
                          let update = JSON.parse(JSON.stringify(prev));
                          update.topicName = capitalizeFirstLetter(e);
                          return { ...update };
                        })
                      }
                      value={selectedTopic.topicName}
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                onClick={editHandler}
                className="mt-2 btn btn-primary d-table ml-auto font-weight-bold"
                style={{ fontSize: "14px" }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditTopic;
