// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";
import { checkFileDimension } from "../../../utils/image-utils";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllCities } from "../../../redux/adminSlice/citySlice";
import { getallDestination } from "../../../redux/adminSlice/activitySlice";

import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import HomeService from "../../../services/api/HomeService";


let initialValues = {
  countryId: "",
  cityName: "",
  picture: "",
};

let createErrors = {
  countryId: "",
  cityName: "",
  picture: "",
};
const ManageDestinations = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [Img, setImg] = useState("");
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [cityEdit, setcityEdit] = useState(initialValues);
  const [uploadedImg, setuploadedImg] = useState("");
  const [itemIndex, setitemIndex] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const { all_destination } = useSelector((state) => state.adminActivityActionSlice);
  const { cities } = useSelector((state) => state.citySlice);
  const { countres } = useSelector((state) => state.countrySlice);

  useEffect(() => {
    dispatch(getallDestination())
  }, [dispatch]);

  let userdata  = localStorage.getItem('userdata');
  userdata  = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType     = userdata?.userType;

  const handleEditChange = (event) => {
    event.preventDefault();
    setcityEdit({ ...cityEdit, [event.target.name]: event.target.value });
  };
  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    if (imgLoading) return;
    const x = Array.from(e.target.files)[0];
    setImg(URL.createObjectURL(x));
    let fileObj = await checkFileDimension(x);
    if (fileObj?.height > 720) {
      seterrors({
        ...errors,
        picture: "File height should be less than 720px",
      });

      return;
    }
    if (fileObj?.width > 1280) {
      seterrors({
        ...errors,
        picture: "File width should be less than 1280px",
      });

      return;
    }

    seterrors({ ...errors, picture: "" });
    const DATA = new FormData();
    DATA.append("image", e.target.files[0]);
    try {
      setImgLoading(true);
      let imgUpRes = await fileUpload(
        "/admin/upload-bannerImage",
        "POST",
        DATA
      );
      if (imgUpRes?.status) {
        setImg(imgUpRes?.data);
      } else {
        toast.error(
          "Something went wrong while uploading image! Please try after some time."
        );
      }
    } catch (error) {
      toast.error(
        "Something went wrong while uploading image! Please try after some time."
      );
    }
    setImgLoading(false);
  };

  const handleEditValidation = () => {
    const { countryId, cityName } = cityEdit;

    if (countryId === "") {
      seterrors({ ...errors, countryId: "Country is required" });
      return false;
    }
    if (cityName === "") {
      seterrors({ ...errors, cityName: "City name is required" });
      return false;
    }
    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    let bodyData = { id: `${cities[itemIndex]._id}`, ...cityEdit };
    if (handleEditValidation()) {
      if (Img === "") return toast.error("Picture is required");
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditCity"]({
          ...bodyData,
          picture: Img,
        });

        if (res.isSuccess) {
          dispatch(getAllCities());
          setModal(false);
          seterrors(createErrors);
          setImg("");
          setcityEdit(initialValues);
          setuploadedImg("");

          apiCallSuccess(res.data);
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
      setisLoading(false);
    }
  };

  const deleteDestinationcontent = async (id) => {
    HomeService.deleteDestination(id)
    .then((res)=>{
      if (res && res.status) {
        toast.success('Deleted Successfully')
        dispatch(getallDestination())
      }else{
        toast.error('Something Wrong')
      }
    })
    .catch((err)=>{
      toast.error(err.message)
    })
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity Site",
        field: "activitySite",
        width: 100,
      },
      {
        label: "Destination",
        field: "destination",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
        height: 100,
      },
    ],
    rows:
      all_destination?.length > 0
        ? all_destination.map((item, idx) => {
            return {
              sl: idx + 1,
              activitySite:(
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div
                      className="zwidget-content-left flex2"
                      style={{ textAlign: "center" }}
                    >
                      <div className="zwidget-heading" style={{ opacity: 1 }}>
                        {item?.sitedata[0]?.siteName}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              destination: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div
                      className="zwidget-content-left flex2"
                      style={{ textAlign: "center" }}
                    >
                      <div className="zwidget-heading" style={{ opacity: 1 }}>
                        {item?.name}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  {(userType === "admin" || (userType ==="subadmin" && assignAccess.includes('delete'))) && (

                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      deleteDestinationcontent(item._id)
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  )}
                  
                  {(userType ==="admin" || (userType ==="subadmin" && assignAccess.includes('edit'))) && (
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/edit-site-destinations/${item._id}`)
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                  )}
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Site Destination</h5>
            </div>
            <form
              className=""
              onSubmit={(e) => handleEditSubmit(e)}
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="countryId" className="form-label">
                      Country*
                    </label>
                    <select
                      name="countryId"
                      id="countryId"
                      className="form-control form-select"
                      value={cityEdit.countryId}
                      onChange={(e) => handleEditChange(e)}
                    >
                      <option value="" key={0}>
                        Select
                      </option>
                      {countres?.map((item, idx) => {
                        return (
                          <>
                            <option key={idx + 1} value={`${item?._id}`}>
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  {errors.countryId && (
                    <span className="errorMessageText text-danger">
                      {errors.countryId}
                    </span>
                  )}
                </div>
                <div className="row" style={{ margin: "0.1rem" }}>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="cityName" className="form-label">
                        City Name*
                      </label>
                      <input
                        name="cityName"
                        id="cityName"
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        value={cityEdit.cityName}
                        onChange={(e) => handleEditChange(e)}
                      />
                    </div>
                    {errors.cityName && (
                      <span className="errorMessageText text-danger">
                        {errors.cityName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="picture" className="form-label">
                        Picture*
                      </label>
                      <input
                        name="picture"
                        id="picture"
                        type="file"
                        accept={"image/*"}
                        ref={fileInputRef}
                        className="form-control"
                        onChange={imageHandler}
                      />
                    </div>
                    <p className="text-primary mt-2">
                      {imgLoading && "Uploading please wait..."}
                    </p>

                    {Img !== "" && (
                      <>
                        <img
                          alt=""
                          style={{
                            height: "70px",
                            width: "120px",
                            borderRadius: "3px",
                          }}
                          src={Img}
                        ></img>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Edit
                </button>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete City</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Site Destinations"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
  //   }
};

export default ManageDestinations;
