import React, { useState } from 'react';
import { toast } from 'react-toastify';
import HomeService from '../../../services/api/HomeService';
import Loader from '../../../Component/Loader';
import { capitalizeFirstLetter } from "../../../utils";

function AddTopic() {
  const [topicName, setTopicName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    if (topicName === '') {
      return toast.error('Please Enter Contact Topic');
    }

    setIsLoading(true);

    HomeService.addcontacttype({ topicName })
      .then(res => {
        if (res && res.status) {
          toast.success('Topic added successfully');
          setTopicName('');
        } else {
          toast.error(res.error);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={handleSubmit}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body font-weight-bold card-title" style={{ fontSize: '16px' }}>
              Create Topic
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label font-weight-bold">Topic</label>
                <input
                  name="Contact Topic"
                  type="text"
                  required={true}
                  className="form-control"
                  value={topicName}
                  placeholder="Enter topic here..."
                  onChange={e => setTopicName(capitalizeFirstLetter(e))}
                />
              </div>
            </div>
            <button
              className="mt-2 btn btn-primary ml-auto d-table font-weight-bold"
              type="submit"
              style={{ margin: '5px', fontSize: '14px' }}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddTopic;
