// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utils
import { capitalizeFirstLetter2 } from "../../../utils/common-utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { requestData } from "../../../services/api/apiHelpers";

// Redux actions
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

import "./styles.css";

const RejectedMerchants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [allMerchantData, setallMerchantData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);

  // Redux stores
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const merchantType = params?.merchantType;

  let userdata = localStorage.getItem("userdata");
  userdata = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType = userdata?.userType;

  console.log("merchants", merchants);
  // Component did mount
  useEffect(() => {
    dispatch(getAllMerchants());
  }, []);

  // Component did update
  //   useEffect(() => {
  //     if (merchants?.length > 0) {
  //       // setisLoading(false);
  //       setallMerchantData(
  //         merchants.filter((item) => {
  //           console.log("sssss", item);
  //           if (merchantType == "new") {
  //             if (item?.newSupplier && !item?.saveAsDraftMerchant) {
  //               return true;
  //             } else {
  //               return false;
  //             }
  //           } else {
  //             if (item?.isApproval) {
  //               return true;
  //             } else {
  //               return false;
  //             }
  //           }
  //         })
  //       );
  //     }
  //   }, [merchants, merchantType]);

  useEffect(() => {
    if (merchants?.length > 0) {
      setallMerchantData(
        merchants.filter(
          (item) => item?.rejectedStatus === true && item?.isApproval === false
        )
      );
    }
  }, [merchants, merchantType]);

  console.log("allMerchantData", allMerchantData);

  const openModal = () => {
    setModal(true);
  };

  const changeStatus = async (id, idx) => {
    try {
      let res = await JSON_API["adminChangeMerchantStatus"]({ id: id });

      if (res.isSuccess) {
        dispatch(getAllMerchants());
      } else {
        console.log("Update status change res: ", res);
      }
    } catch (error) {
      console.log("Failed to change status due to ", error.message);
    }
  };

  const deleteMerchant = async (id) => {
    // console.log("here", id);
    try {
      setisLoading(true);
      // let res = await JSON_API["adminDeleteMerchant"]({ id: id });
      let res = await requestData(`/admin/deleteMarchent/${id}`, "PUT");
      console.log("Res", res);

      // if (res.isSuccess) {
      if (res && res.status) {
        dispatch(getAllMerchants());
        setModal(false);
        setisLoading(false);
        MerchantApiCallSuccess(res.data);
      } else {
        setisLoading(false);
        MerchantApiCallError(res?.error?.msg);
      }
    } catch (error) {
      setisLoading(false);
      MerchantApiCallError(error.message);
    }
  };

  const MerchantApiCallSuccess = (data) => {
    // setkey(Math.random());
    toast.success("Action successful!");
  };

  const MerchantApiCallError = (errorMsg) => {
    toast.error(errorMsg);
    setError(errorMsg);
  };

  const filterData = () => {
    if (searchText !== "") {
      return allMerchantData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return allMerchantData;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Logo",
          field: "company_logo",
          sort: "asc",
          width: 150,
        },
        {
          label: "Company",
          field: "company_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Merchant",
          field: "merchant_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 200,
        },
        {
          label: "Contact No.",
          field: "ContactNo",
          sort: "asc",
          width: 200,
        },
        {
          label: "Merchant Code",
          field: "merchant_code",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                company_logo: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left me-3">
                        {/* <div className="widget-content-left">
                          </div> */}
                      </div>
                      <div className="widget-content-left flex2">
                        <img
                          style={{
                            borderRadius: "3px",
                            height: "80px",
                            width: "80px",
                          }}
                          src={
                            item?.logo
                              ? item?.logo
                              : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                          }
                          alt="Avatar"
                        />
                      </div>
                    </div>
                  </div>
                ),
                company_name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        {item?.companyName}
                      </div>
                    </div>
                  </div>
                ),
                merchant_name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        {capitalizeFirstLetter2(
                          item?.firstName + " " + item?.lastName
                        )}
                      </div>
                    </div>
                  </div>
                ),
                ContactNo: (
                  <div>
                    {item.phonecode}-{item.mobile},<br />
                    {item.phonecodecompany}-{item.companyMobile}
                  </div>
                ),
                email: <div>{item.email}</div>,
                merchant_code: item.marchentCode ? item.marchentCode : null,
                status: (
                  // <button
                  //   className={`badge ${
                  //     item?.status ? "bg-success" : "bg-danger"
                  //   } border-0`}
                  // >
                  //   {item?.status ? "Active" : "Deactivated"}
                  // </button>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      // id="flexSwitchCheckChecked"
                      checked={item.status}
                      onChange={() => changeStatus(item._id, idx)}
                    />
                    {/* <label class="form-check-label" for="flexSwitchCheckChecked">
                        Checked switch checkbox input
                      </label> */}
                  </div>
                ),
                action: (
                  <div>
                

                    {(userType == "admin" ||
                      (userType == "subadmin" &&
                        assignAccess.includes("edit"))) && (
                      <>
                        <button
                          title="Edit Merchant"
                          className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                          onClick={() => {
                            navigate(`/edit-merchant/${item?._id}`);
                          }}
                        >
                          <i class="fa-regular fa-pen-to-square"></i>
                        </button>

                   
                      </>
                    )}
                  </div>
                ),
              };
            })
          : [],
    };
  }, [allMerchantData, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete merchant</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  // console.log(deleteId);
                  deleteMerchant(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle={"Rejected Merchants"}
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
};

export default RejectedMerchants;
