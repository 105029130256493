import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from 'react-router-dom';

// Utils
import {
    apiCallError,
  } from "../../../utils";

// Helpers
import { toast } from "react-hot-toast";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

const ManageCommission = () => {
    const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [commissions, setcommissions] = useState([]);
  const [commissionslist, setcommissionslist] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");

  let userdata  = localStorage.getItem('userdata');
  userdata  = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType     = userdata?.userType;



  useEffect(() => {
    getCommissionsList();
    getCommissions();
  }, []);

  const getCommissionsList = async () => {
    let res = await JSON_API["adminManageCommissionList"]();

    if (res.isSuccess) {
      setcommissions(res.data.data);
    }
  };



  const getCommissions = async () => {
    let res = await JSON_API["adminManageCommissionList"]();

    if (res.isSuccess) {
      setcommissionslist(res.data.data);
    }
  };


  const deleteMerchantCommission = async (id) => {
    setisLoading(true);

    try {
      let res = await JSON_API["deleteMerchantCommission"]({ id });

      if (res.isSuccess) {
        toast.success("Merchant commission deleted successfully.");
        getCommissions();
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
        apiCallError(error?.message, setError);
    }

    setisLoading(false);
  };

  const filterData = () => {
    return commissionslist;


    if (searchText !== "") {
      return commissions.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return commissions;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Merchant Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Commission Type",
          field: "commissionType",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div
                        className="widget-content-left flex2"
                        style={{ textAlign: "center" }}
                      >
                        <div  style={{ opacity: 1 }}>
                          {item?.marchantDetails[0]?.fullName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),

                commissionType: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.commissionType
                            ?.split(/(?=[A-Z])/)[0]
                            .charAt(0)
                            .toUpperCase() +
                            item?.commissionType
                              ?.split(/(?=[A-Z])/)[0]
                              .slice(1)}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div>
                    {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('delete'))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        setModal(true);
                        setmodalToggleView(0);

                        setdeleteId(item?._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    )}
                    {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('edit'))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        item?.commissionType == 'countryWise' ? navigate(`/edit-commission/${item?._id}`) : alert('Work in progress.. Please check later');
                      }}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </button>
                    )}
                  </div>
                ),
              };
            })
          : [],
    };
  }, [commissions, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete section</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteMerchantCommission(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Merchant Commissions"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
};

export default ManageCommission;
