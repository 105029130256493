import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiHelperFunction } from '../../services/api/apiHelpers';

let initialState = {
  status: 'idle',
  cities: [],
  cities_of_a_country: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllCities = createAsyncThunk('get_all_cities', async () => {
  let response = await ApiHelperFunction({
    urlPath: '/admin/viewCity',
    method: 'GET',
  });
  if (response.status === 200) {

    return response.data;
  } else {
    console.log("Can't get data. Something went wrong");
  }
});

export const getCitiesOfACountry = createAsyncThunk('get_cities_of_a_country', async (countryId) => {
  let response = await ApiHelperFunction({
    urlPath: `/admin/viewCountryAgainstCity/${countryId}`,
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data;
  } else {
    console.log("Can't get data. Something went wrong");
  }
});

export const CitySlice = createSlice({
  name: 'city_actions',
  initialState,
  reducers: {
    clearCityState: state => {
      state.status = 'idle';
      state.cities = [];
      state.cities_of_a_country = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllCities.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllCities.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        console.log('payload citis', payload);
        let payloaddata = payload?.data ;
        let filterdata =  payloaddata?.sort((a, b) => {
          const wordA = a.cityName?.toLowerCase();
          const wordB = b.cityName?.toLowerCase();
          if (wordA < wordB) {
            return -1;
        } else if (wordA > wordB) {
            return 1;
        } else {
            return 0;
        }
        });
        console.log('filterdata',filterdata,payloaddata)
        state.cities = filterdata;
      })
      .addCase(getAllCities.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getCitiesOfACountry.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getCitiesOfACountry.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.isLoading = false;
        state.cities_of_a_country = payload?.data;
      })
      .addCase(getCitiesOfACountry.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      });
  },
});

export const { clearCityState } = CitySlice.actions;

export default CitySlice.reducer;
