import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiCallError } from "../../../../utils/common-utils";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";
import { getAllActivitySites } from "../../../../redux/adminSlice/activitySlice";
import Loader from "../../../../Component/Loader";
import useImgUploader from "../../../../Hooks/use-img-uploader";
import { capitalizeFirstLetter } from "../../../../utils";
import { getAllcountres } from "../../../../redux/adminSlice/countrySlice";
import Select from "react-select";
import { getCitiesOfACountry } from "../../../../redux/adminSlice/citySlice";

let initialValues = {
  countryId: "",
  countryName: "",
  cityId: "",
  cityName: "",
  siteName: "",
  image: "",
  priority: 0,
};

let createErrors = {
  countryId: "",
  cityId: "",
  siteName: "",
  image: "",
  priority: "",
};

const config = {
  url: "/admin/uploadActivitySiteImage",
  propertyName: "data",
};

function EditActivitySite() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [cityEdit, setcityEdit] = useState(initialValues);
  const { Img, setImg, imageHandler, imgComp, imgLoading } =
    useImgUploader(config);
  const { activity_sites } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const { countres } = useSelector((state) => state.countrySlice);
  const { cities_of_a_country } = useSelector((state) => state.citySlice);
  const activitySiteData = activity_sites.find(
    (item) => item?._id === params.id
  );
  useEffect(() => {
    dispatch(getAllcountres());
    dispatch(getAllActivitySites());
    setcityEdit((state) => {
      let updated = { ...state };
      updated.countryId = activitySiteData?.countryId;
      updated.countryName = activitySiteData?.country;
      updated.cityId = activitySiteData?.cityId;
      updated.cityName = activitySiteData?.cityName;
      updated.siteName = activitySiteData?.siteName;
      updated.image = activitySiteData?.image;
      updated.priority = activitySiteData?.priority;

      return JSON.parse(JSON.stringify(updated));
    });
    setImg(activitySiteData?.image);
  }, [activitySiteData?.countryId, activitySiteData?.country, activitySiteData?.cityId, activitySiteData?.cityName, activitySiteData?.siteName, activitySiteData?.image, activitySiteData?.priority, dispatch, setImg]);

  const handleEditChange = (event) => {
    event.preventDefault();
    let input = capitalizeFirstLetter(event);
    setcityEdit({ ...cityEdit, [event.target.name]: input });
  };

  const handleEditValidation = () => {
    const { countryId, cityId, siteName } = cityEdit;

    if (countryId === "") {
      seterrors({ ...errors, countryId: "Country is required" });

      return false;
    }
    if (cityId === "") {
      seterrors({ ...errors, cityId: "City is required" });

      return false;
    }
    if (siteName === "") {
      seterrors({ ...errors, siteName: "Banner name is required" });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    if (isLoading || imgLoading) return;

    let bodyData = { id: `${params.id}`, ...cityEdit };

    if (handleEditValidation()) {
      if (Img === "") return toast.error("Picture is required");
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditActivitySite"]({
          ...bodyData,
          image: Img,
        });

        if (res.isSuccess) {
          dispatch(getAllActivitySites());
          seterrors(createErrors);
          setImg("");
          setcityEdit(initialValues);
          setisLoading(false);
          toast.success("Activity site edited successfully");
          navigate("/manage-activity-site");
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleEditSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Update Activity Site</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="countryId"
                      className="form-label font-weight-bold"
                    >
                      Country*
                    </label>
                    {/* <select
                      name="countryId"
                      id="countryId"
                      className="form-control form-select"
                      value={cityEdit.countryId}
                      onChange={(e) => {
                        dispatch(getCitiesOfACountry(e.target.value));
                        handleEditChange(e);
                      }}
                    >
                      <option value="" key={0}>
                        Select
                      </option>
                      {countres?.map((item, idx) => {
                        return (
                          <>
                            <option key={idx + 1} value={`${item?._id}`}>
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                    </select> */}
                    <Select
                      name="countryId"
                      id="countryId"
                      classNamePrefix="select"
                      options={countres?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.name,
                      }))}
                      // isMulti
                      value={{
                        value: cityEdit.countryId,
                        label: cityEdit.countryName,
                      }}
                      onChange={(val) => {
                        setcityEdit((prev) => {
                          return {
                            ...prev,
                            countryId: val?.value,
                            countryName: val?.label,
                            cityId: "",
                            cityName: ""
                          };
                        });
                        dispatch(getCitiesOfACountry(val?.value));
                      }}
                    />
                  </div>
                  {errors.countryId && (
                    <span className="errorMessageText text-danger">
                      {errors.countryId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="cityId"
                      className="form-label font-weight-bold"
                    >
                      City*
                    </label>
                    <Select
                      name="cityId"
                      id="cityId"
                      classNamePrefix="select"
                      options={cities_of_a_country?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.cityName,
                      }))}
                      // isMulti
                      value={{
                        value: cityEdit.cityId,
                        label: cityEdit.cityName,
                      }}
                      onChange={(val) => {
                        setcityEdit((prev) => {
                          return {
                            ...prev,
                            cityId: val?.value,
                            cityName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.cityId && (
                    <span className="errorMessageText text-danger">
                      {errors.cityId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="siteName"
                      className="form-label font-weight-bold"
                    >
                      Site Name*
                    </label>
                    <input
                      name="siteName"
                      id="siteName"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={cityEdit.siteName}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.siteName && (
                    <span className="errorMessageText text-danger">
                      {errors.siteName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="picture"
                      className="form-label font-weight-bold"
                    >
                      Image*
                    </label>
                    <input
                      name="picture"
                      id="picture"
                      type="file"
                      accept={"image/*"}
                      ref={fileInputRef}
                      className="form-control"
                      onChange={imageHandler}
                    />
                  </div>
                  {imgComp}
                </div>
              </div>

              <button
                type="button"
                className="mt-2 btn btn-link"
                onClick={() => {
                  navigate("/manage-activity-site");
                }}
              >
                Cancel
              </button>
              <button type="submit" className="mt-2 btn btn-primary">
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditActivitySite;
