// Hooks
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Helpers
import { toast } from 'react-toastify';

// API functions
import { JSON_API } from '../services/api/jsonApiCallWithInterceptor';

// Redux actions
import { loginState, setToken } from '../redux/authSlice/adminProfileSlice';

// Components
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

// Assets
import logo from '../assets/utils/images/logo-inverse2.png';

// Styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

let regInitValues = {
  email: '',
  password: '',
};

let createErrors = {
  captcha: '',
};

export default function Login() {
  const dispatch = useDispatch();
  const [user, setuser] = useState(regInitValues);
  const [captchaVal, setcaptchaVal] = useState('');
  const [errors, seterrors] = useState(createErrors);

  useEffect(() => {
    loadCaptchaEnginge(6, '#000', '#FFF');
  }, []);

  const handleChange = event => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    setuser({ ...user, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const { email, password } = user;

    if (password === '') {
      toast.error('Password is required.');

      return false;
    }
    if (email === '') {
      toast.error('Email is required.');

      return false;
    }
    if (validateCaptcha(captchaVal) !== true) {
      seterrors({ ...errors, captcha: "Captch doesn't match..." });
      //return false;
    }

    return true;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    // console.log("user data", captchaVal);
    if (handleValidation()) {
      let res = await JSON_API['adminLogin'](user);
      // console.log(res.data.data.token);

      if (res.isSuccess) {
        if (res.data.status) {
          dispatch(loginState());
          dispatch(setToken(res.data.data.token));
          localStorage.setItem('userType', 'Admin');
          // reactLocalStorage.set("userType", "Admin");
          localStorage.setItem('Authorization', res.data.data.token);
          // reactLocalStorage.set("Authorization", res.data.data.token);
          setuser(regInitValues);
          toast.success('Logged In Successfully');
          setTimeout(() => {
            window.location.href = '/';
          }, 1500);
          // navigate("/"); // , { state: profile_data }
        } else {
          toast.error(res.data.message);
        }
      } else {
        toast.error('Email or Password not exist');
      }
    }
  };

  return (
    <>
      <div className="h-100 g-0 row">
        <div className="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-12">
          <div className="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9 rightCntlogin">
            <div className="app-logo">
              <img src={logo} className="img-fluid" alt="logo" style={{ width: '100px', height: '70px' }} />
            </div>
            <h4 className="mb-0">
              <div>Welcome,</div>
              <span>Please sign in to your account.</span>
            </h4>
            {/* <h6 className="mt-3">
              No account?{" "}
              <a href="https://colorlib.com/" className="text-primary"></a>
              <Link to="/register" className="text-primary">
                Sign up now
              </Link>
            </h6> */}
            <div className="divider row" />
            <div>
              <form className="" onSubmit={e => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="exampleEmail" className="form-label">
                        Email
                      </label>
                      <input
                        name="email"
                        id="exampleEmail"
                        placeholder="Email here..."
                        type="email"
                        className="form-control"
                        // value={user.email}
                        onChange={e => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="examplePassword" className="form-label">
                        Password
                      </label>
                      <input
                        name="password"
                        id="examplePassword"
                        placeholder="Password here..."
                        type="password"
                        className="form-control"
                        // value={user.password}
                        onChange={e => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <LoadCanvasTemplate />
                      <input
                        type="text"
                        name="user_captcha_input"
                        id="user_captcha_input"
                        onChange={e => setcaptchaVal(e.target.value)}
                        className="captcha-input"
                        style={{ borderRadius: '3px', border: '1px solid #ced4da', height: '2rem' }}
                      />
                      {errors.captcha && <span className="errorMessageText text-danger">{errors.captcha}</span>}
                    </div>
                  </div>

                  
                </div>
                {/* <div className="col-md-12">
                  <LoadCanvasTemplate />
                </div> */}
                {/* <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleEmail" className="form-label">
                                                Enter Captcha
                                            </label>
                                            <input
                                                name=""
                                                id=""
                                                placeholder=""
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="examplePassword" className="form-label">
                                         Captcha Code
                                            </label>
                                     <div style={{backgroundColor:"#474eb847"}}><img src={captcha} style={{margin:"0 auto", display:"table", height:"36px"}}/></div> 
                                        </div>
                                    </div>
                                </div> */}
                {/* <div className="form-check">
                                    <input
                                        name="check"
                                        id="exampleCheck"
                                        type="checkbox"
                                        className="form-check-input"
                                    />
                                    <label
                                        htmlFor="exampleCheck"
                                        className="form-check-label form-label"
                                    >
                                        Keep me logged in
                                    </label>
                                </div> */}
                <div className="divider row" />
                <div className="d-flex align-items-center">
                  <div className="ms-auto">
                    {/* <a href="https://colorlib.com/" className="btn-lg btn btn-link">
                                            Recover Password
                                        </a>{" "} */}
                    <button className="btn btn-primary btn-lg" type="submit">
                      Login to Dashboard
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
