// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import {
  capitalizeFirstLetter,
  apiCallError,
} from "../../../utils";

// Helpers
import { toast } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Redux actions
import {
  getAllBlogTypeCategories,
} from "../../../redux/adminSlice/blogSlice";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";

let initialValues = {
  categoryId: "",
  categoryName: "",
  authorImage: "",
  authorName: "",
  title: "",
  image: "",
  description: "",
};

let createErrors = {
  categoryId: "",
  authorImage: "",
  authorName: "",
  title: "",
  image: "",
  description: "",
};

export default function AddBlog() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [blog, setblog] = useState(initialValues);
  const [errors, seterrors] = useState(createErrors);
  const [error, setError] = useState("");
  const [upAuthorImg, setupAuthorImg] = useState("");
  const [upBlogImg, setupBlogImg] = useState("");
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { blog_type_categories } = useSelector(
    (state) => state.blogsSlice
  );

  // Component did mount
  useEffect(() => {
    dispatch(getAllBlogTypeCategories());
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event) => {
    // console.log(event.target.name, event.target.value);
    let input;

    if (
      event.target.name !== "categoryId" ||
      event.target.name !== "description"
    ) {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }
    setblog({ ...blog, [event.target.name]: input });
  };

  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    // if (imgLoading) return;
    const x = Array.from(e.target.files)[0];

    if (e.target.name === "authorImage") {
      setupAuthorImg(URL.createObjectURL(x));
    }
    if (e.target.name === "image") {
      setupBlogImg(URL.createObjectURL(x));
    }

    const DATA = new FormData();
    DATA.append("image", e.target.files[0]);

    try {
      // setImgLoading(true);
      let imgUpRes = await fileUpload("/admin/blogImage", "POST", DATA);
      console.log("imgUpRes", imgUpRes);
      if (imgUpRes?.status) {
        /**  upAuthorImg, upBlogImg are used for validation */
        if (e.target.name === "authorImage") {
          setupAuthorImg(imgUpRes?.url);
        }
        if (e.target.name === "image") {
          setupBlogImg(imgUpRes?.url);
        }
        /** ---------------------------------------------- */
        setblog({ ...blog, [e.target.name]: imgUpRes?.url });
      } else {
        toast.error(
          "Something went wrong while uploading image! Please try after some time."
        );
      }
    } catch (error) {
      toast.error(
        "Something went wrong while uploading image! Please try after some time."
      );
    }
    // setImgLoading(false);
  };

  const handleValidation = () => {
    const { categoryId, authorImage, authorName, title, image, description } =
      blog;

    if (categoryId === "") {
      seterrors({ ...createErrors, categoryId: "Category is required." });

      return false;
    }
    if (authorName === "") {
      seterrors({ ...createErrors, authorName: "Author name is required." });

      return false;
    }
    if (authorImage === "") {
      seterrors({ ...createErrors, authorImage: "Author image is required." });

      return false;
    }
    if (title === "") {
      seterrors({ ...createErrors, title: "Blog title is required." });

      return false;
    }
    if (image === "") {
      seterrors({ ...createErrors, image: "Blog image is required." });

      return false;
    }
    if (description === "") {
      seterrors({ ...createErrors, description: "Blog content is required." });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (handleValidation()) {
      console.log("body data", blog);
      try {
        setisLoading(true);
        let res = await JSON_API["adminAddBlog"](blog);

        if (res.isSuccess) {
          setblog(initialValues);
          seterrors(createErrors);
          setupAuthorImg("");
          setupBlogImg("");
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          toast.success("Blog added successfully!");
        } else {
          apiCallError(res?.error?.message, setError);
        }

        setisLoading(false);
      } catch (error) {
        setisLoading(true);
        console.log("Failed to add privacy policy info due to ", error.message);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form action="" onSubmit={handleSubmit}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Blog</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="categoryId"
                      className="form-label font-weight-bold"
                    >
                      Category*
                    </label>
                    <Select
                      name="categoryId"
                      id="categoryId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={blog_type_categories?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.name,
                      }))}
                      value={{
                        value: blog.categoryId,
                        label: blog.categoryName,
                      }}
                      // isMulti
                      onChange={(val) => {
                        // console.log("value", val);
                        setblog((prev) => {
                          // console.log(prev);
                          return {
                            ...prev,
                            categoryId: val?.value,
                            categoryName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.categoryId && (
                    <span className="errorMessageText text-danger">
                      {errors.categoryId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="authorName"
                      className="form-label font-weight-bold"
                    >
                      Author Name*
                    </label>
                    <input
                      name="authorName"
                      id="authorName"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={blog.authorName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.authorName && (
                    <span className="errorMessageText text-danger">
                      {errors.authorName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="authorImage"
                      className="form-label font-weight-bold"
                    >
                      Author Image*
                    </label>
                    <input
                      name="authorImage"
                      id="authorImage"
                      type="file"
                      accept={"image/*"}
                      ref={inputRef}
                      className="form-control"
                      // onClick={() => setuploadedImg('')}
                      // onChange={e => {
                      //   setImg(e.target.files[0]);
                      //   handleImg(e.target.files[0], setuploadedImg, errors, seterrors, 'picture');
                      // }}
                      onChange={imageHandler}
                    />
                  </div>
                  {/* <p className="text-primary mt-2">
                    {authorImgLoading && "Uploading please wait..."}
                  </p> */}
                  {upAuthorImg !== "" && (
                    <>
                      <img
                        alt=""
                        style={{
                          height: "70px",
                          width: "120px",
                          borderRadius: "3px",
                        }}
                        src={upAuthorImg}
                      ></img>
                    </>
                  )}
                  {errors.authorImage && (
                    <span className="errorMessageText text-danger">
                      {errors.authorImage}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="title"
                      className="form-label font-weight-bold"
                    >
                      Blog Title*
                    </label>
                    <input
                      name="title"
                      id="title"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={blog.title}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.title && (
                    <span className="errorMessageText text-danger">
                      {errors.title}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="image"
                      className="form-label font-weight-bold"
                    >
                      Blog Image*
                    </label>
                    <input
                      name="image"
                      id="image"
                      type="file"
                      accept={"image/*"}
                      ref={inputRef}
                      className="form-control"
                      // onClick={() => setuploadedImg('')}
                      // onChange={e => {
                      //   setImg(e.target.files[0]);
                      //   handleImg(e.target.files[0], setuploadedImg, errors, seterrors, 'picture');
                      // }}
                      onChange={imageHandler}
                    />
                  </div>
                  {/* <p className="text-primary mt-2">
                    {imgLoading && "Uploading please wait..."}
                  </p> */}
                  {upBlogImg !== "" && (
                    <>
                      <img
                        alt=""
                        style={{
                          height: "70px",
                          width: "120px",
                          borderRadius: "3px",
                        }}
                        src={upBlogImg}
                      ></img>
                    </>
                  )}
                  {errors.image && (
                    <span className="errorMessageText text-danger">
                      {errors.image}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="Faq"
                      className="form-label font-weight-bold"
                    >
                     Description *
                    </label>
                    <CKEditor
                      className="editor-container"
                      editor={ClassicEditor}
                      data={blog?.description}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ event, editor, data });
                        setblog((state) => {
                          return {
                            ...state,
                            description: data,
                          };
                        });
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  </div>
                  {errors.description && (
                    <span className="errorMessageText text-danger">
                      {errors.description}
                    </span>
                  )}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                style={{ margin: "5px" }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
