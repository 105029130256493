// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utils
import { capitalizeFirstLetter, apiCallError } from "../../../utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllLanguages } from "../../../redux/adminSlice/languageSlice";

// Components
import Loader from "../../../Component/Loader";

//import css module

const EditLanguage = () => {
  // function AddLanguage() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [newLanguage, setnewLanguage] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  // Redux stores
  const { languages } = useSelector((state) => state.languageSlice);
  // console.log("languages", languages);

  // Component did
  const languageData = languages.find((item) => item?._id === params.id);

  useEffect(() => {
    dispatch(getAllLanguages());
    setnewLanguage(languageData.name);
  }, []);

  // console.log("edit info", newLanguage);

  const handleEditSubmit = async (event) => {
    if (newLanguage === "") {
      toast.error("Please Enter langauge");
    } else {
      try {
        setisLoading(true);
        let res = await JSON_API["editLanguage"]({
          id: params.id,
          name: newLanguage,
        });
        if (res.isSuccess) {
          dispatch(getAllLanguages());
          setnewLanguage("");
          // apiCallSuccess(res.data);
          toast.success("Language edited successfully!");
          navigate("/manage-language");
        } else {
          apiCallError(res?.error?.message, setError);
        }
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main-card mb-3 card">
        <div className="card-body">
          <div className="card-title">Edit Language</div>
          <div className="col-md-12">
            <div className="mb-3">
              <label
                htmlFor="language"
                className="form-label"
                style={{ margin: "0.5rem 0.5rem" }}
              >
                Language*
              </label>
              {/* <ReactLanguageSelect
                            placeholder="Select Language"
                            searchable={true}
                            searchPlaceholder="Search for a language"
                            names={"both"}
                            selectedSize={14}
                            optionsSize={14}
                          /> */}
              <input
                name="language"
                id="language"
                // placeholder="Your first name, e.g. Walter"
                type="text"
                className="form-control"
                autoComplete="false"
                value={newLanguage}
                onChange={(e) => setnewLanguage(capitalizeFirstLetter(e))}
              />
              {/* {errors.name && (
                      <span className="errorMessageText">{errors.name}</span>
                    )} */}
            </div>
          </div>
        </div>
        <div className="d-flex m-2">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              // setModal(false);
              // setnewLanguage("");
              navigate("/manage-language");
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="mt-2 btn btn-primary  d-table ml-2"
            onClick={(e) => handleEditSubmit(e)}
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default EditLanguage;
