// Hooks
import React, { useState } from "react";

// Helpers
import { toast } from "react-toastify";

// Utilities
import {
  capitalizeFirstLetter,
  apiCallError,
} from "../../../../utils";

// API functions
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";

// Components
import Loader from "../../../../Component/Loader";

const initialValues = {
  name: "",
};

const createErrors = {
  name: "",
  //   activityTypeId: "",
};

function AddCategory() {
  const [category, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    let input = capitalizeFirstLetter(event);
    setsection({ ...category, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { name } = category;

    if (name === "") {
      seterrors({ ...errors, name: "Category name is required" });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["addBlogType"](category);

        if (res.isSuccess) {
          seterrors(createErrors);
          setsection(initialValues);
          setisLoading(false);
        toast.success('Blog Type added successfully');
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Blog Type</div>
              {/* <div className="row"> */}
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Type*
                  </label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={category.name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.name && (
                  <span className="errorMessageText text-danger">
                    {errors.name}
                  </span>
                )}
              </div>
              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                style={{ margin: "5px" }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddCategory;
