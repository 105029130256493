// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utilities
import { capitalizeFirstLetter, apiCallError } from "../../../utils";

// API functions
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllDigitalInfos } from "../../../redux/adminSlice/digitalInfoSlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const initialValues = {
  topic: "",
  desc: "",
};

const createErrors = {
  topic: "",
  desc: "",
  //   activityTypeId: "",
};

function EditInfo() {
  const dispatch = useDispatch();
  const [content, setcontent] = useState("");

  const [category, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [categoryEdit, setCategoryEdit] = useState(initialValues);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  // Component did mount

  // Redux stores
  const { digital_infos } = useSelector((state) => state.digitalInfoSlice);
  console.log("digital infos", digital_infos);

  useEffect(() => {
    dispatch(getAllDigitalInfos());
    setitemIndex(digital_infos._id);
    setcontent(digital_infos?.desc)
    // setCategoryEdit({
    //   ...categoryEdit,
    //   topic: digital_infos?.topic,
    //   desc: digital_infos?.desc,
    // });
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const handleEditChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input;

    if (event.target.name === "desc") {
      input = event.target.value;
    } else {
      input = capitalizeFirstLetter(event);
    }

    setCategoryEdit({
      ...categoryEdit,
      [event.target.name]: input,
    });
  };

  const handleEditValidation = () => {
    const { topic, desc } = categoryEdit;

    if (topic === "") {
      seterrors({ ...createErrors, topic: "Topic name is required" });

      return false;
    }
    if (desc === "") {
      seterrors({ ...createErrors, desc: "Topic description is required" });

      return false;
    }
    if (desc.length < 50) {
      seterrors({
        ...createErrors,
        desc: "Topic description should be atleast 50 characters.",
      });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    console.log("Edit submit", categoryEdit);
    const editdata={desc:content}

    // if (handleEditValidation()) {
      try {
        let res = await JSON_API["adminEditDigitalInfo"]({
          id: digital_infos._id,
          editdata
        });
        setisLoading(true);

        if (res.isSuccess) {
          dispatch(getAllDigitalInfos());
          setModal(false);
          seterrors(createErrors);
          setCategoryEdit(initialValues);
          setisLoading(false);
          // apiCallSuccess(res.data);
          toast.success("Digital information updated successfully.");
          navigate("/manage-digital-info");
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
    // }
  };

  const deleteCategory = async (id) => {
    try {
      let res = await JSON_API["adminDelteCategory"]({ id: id });
      // console.log("Res", res);
      setisLoading(true);

      if (res.isSuccess) {
        dispatch(getAllDigitalInfos());
        setModal(false);
        // ActivityApiCallSuccess(res.data);
      } else {
        // ActivityApiCallError(res?.error?.message);
      }
    } catch (error) {
      //   ActivityApiCallError(error.message);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Topic",
        field: "topic",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        sl: 1,
        topic: (
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left flex2">
                <div className="widget-heading" style={{ opacity: 1 }}>
                  {digital_infos?.topic}
                </div>
                {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
              </div>
            </div>
          </div>
        ),

        action: (
          <div>
            {/* <button
              className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
              onClick={() => {
                openModal();
                setmodalToggleView(1);
                // console.log("Delete id", activity_types[idx]._id);
                setdeleteId(digital_infos._id);
              }}
            >
              <i className="fa-solid fa-trash-can" />
            </button> */}
            <button
              className="me-2 btn-icon btn-icon-only btn btn-outline-success"
              onClick={() => {
                openModal();
                setmodalToggleView(0);
                setitemIndex(digital_infos._id);
                // console.log("edit", activity_types[idx]?.name);
                setCategoryEdit({
                  ...categoryEdit,
                  topic: digital_infos?.topic,
                  desc: digital_infos?.desc,
                });
              }}
            >
              <i class="fa-regular fa-pen-to-square"></i>
            </button>
          </div>
        ),
      },
    ],
  };

  return (
    <>
      {isLoading && <Loader />}

      <form
        className=""
        onSubmit={(e) => handleEditSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-title">Edit Digital Information</div>
            <div>        <CKEditor
                    className="editor-container"
                    editor={ClassicEditor}
                    data={content}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                      setcontent(data);
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  /></div>
            <div className="d-flex m-2">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  // setModal(false);
                  // setCategoryEdit(initialValues);
                  navigate("/manage-digital-info");
                }}
              >
                Cancel
              </button>
              <button type="submit" className="mt-2 btn btn-primary  d-table">
                Edit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditInfo;
