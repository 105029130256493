import React, { useState, useEffect, useMemo } from "react";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import { requestData } from "../../../services/api/apiHelpers";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

function ManageParticipants() {
  const [isLoading, setisLoading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [modalToggleView, setmodalToggleView] = useState(1);
  const [parTypes, setparTypes] = useState([]);
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getParTypes();
  }, []);

  const openModal = () => {
    setmodal(true);
  };

  const getParTypes = async () => {
    let res = await requestData("/admin/view-participant-type", "GET");

    if (res && res.status) {
      setparTypes(res?.data);
    }
  };

  const deleteParticipantType = async (id) => {
    let res = await requestData(
      `/admin/delete-participant-type/${id}`,
      "DELETE"
    );

    if (res && res.status) {
      toast.success("Deleted successfully");
      getParTypes();
    } else {
      toast.error(res?.error?.message);
    }
    setmodal(false);
  };

  const filterData = () => {
    if (searchText !== "") {
      return parTypes.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return parTypes;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Age Range",
          field: "ageRange",
          sort: "asc",
          width: 150,
        },

        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div
                        className="widget-content-left flex2"
                        style={{ textAlign: "center" }}
                      >
                        {item?.type?.charAt(0).toUpperCase() +
                          item?.type?.slice(1)}
                      </div>
                    </div>
                  </div>
                ),
                ageRange: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div
                        className="widget-content-left flex2"
                        style={{ textAlign: "center" }}
                      >
                        {item?.ageRange}
                      </div>
                    </div>
                  </div>
                ),

                action: (
                  <div>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(0);
                        setdeleteId(item?._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        navigate(`/edit-participant-type/${item?._id}`);
                      }}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </button>
                  </div>
                ),
              };
            })
          : [],
    };
  }, [parTypes, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Category</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteParticipantType(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Participant Types"
        data={tableData}
        modal={modal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
}

export default ManageParticipants;
