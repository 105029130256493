// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  apiCallError,
} from "../../../utils";

// Redux actions
import { getAllActivitySites, getallDestination } from "../../../redux/adminSlice/activitySlice";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import HomeService from "../../../services/api/HomeService";

let initialValues = {
  name: "",
  siteId: "",
  siteName: "",
  topPriority: 0,
};

let createErrors = {
  name: "",
  siteId: "",
  topPriority: "",
};

function EditDistination() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [destination, setDestination] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const params = useParams();
  const { all_destination } = useSelector((state) => state.adminActivityActionSlice);
  const destinationData = all_destination.find((it)=>it._id === params.id);
  const { activity_sites } = useSelector((state) => state.adminActivityActionSlice);
  const siteData = activity_sites.find(it => it._id === destinationData?.siteId)
  useEffect(() => {
    dispatch(getallDestination());
    dispatch(getAllActivitySites()); 
    setDestination((state)=>{
        let updated = { ...state };
        state.name = destinationData?.name;
        state.siteId = destinationData?.siteId;
        state.siteName = siteData?.siteName;
        state.topPriority = destinationData?.topPriority;
        return JSON.parse(JSON.stringify(updated));
    });
  }, []);

  const handleChange = (event) => {
    let input;

    if (event.target.name === "siteId") {
      input = event.target.value;
    } else if (event.target.name === "topPriority") {
      input = Number(event.target.value);
    } else {
      input = capitalizeFirstLetter(event);
    }
    setDestination({ ...destination, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { name, siteId, topPriority } = destination;

    if (siteId === "") {
      seterrors({ ...createErrors, siteId: "Destination site is required" });

      return false;
    }
    if (name === "") {
      seterrors({ ...createErrors, name: "Destination name is required" });

      return false;
    }
    if (topPriority === 0) {
      seterrors({ ...createErrors, topPriority: "Please provide a priority number."});

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      if (isLoading) return;
      try {
        setisLoading(true);
        HomeService.updateDestination(params.id,destination)
        .then((res)=>{
          if (res.status) {
            seterrors(createErrors);
            toast.success("Site Destination edited Successfully!");
          } else {
            apiCallError(res?.error?.message, setError);
          }
        })
        .catch((err)=>{
          toast.error(err.message)
        })

        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Site Destination</div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="siteId" className="form-label">
                      Activity Site*
                    </label>
                    <Select
                      name="siteId"
                      id="siteId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={activity_sites.map((item, idx) => ({
                        value: item?._id,
                        label: item?.siteName,
                      }))}
                      value={{
                        value: destination?.siteId,
                        label: destination?.siteName,
                      }}
                      // isMulti
                      onChange={(val) => {
                        setDestination((prev) => {
                          return {
                            ...prev,
                            siteId: val?.value,
                            siteName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.siteId && (
                    <span className="errorMessageText text-danger">
                      {errors.siteId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Destination Name*
                    </label>
                    <input
                      name="name"
                      id="name"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={destination.name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.name && (
                    <span className="errorMessageText text-danger">
                      {errors.name}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="topPriority" className="form-label">
                      Priority*
                    </label>
                    <input
                      name="topPriority"
                      id="topPriority"
                      type="number"
                      min={0}
                      className="form-control"
                      value={destination.topPriority}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.topPriority && (
                    <span className="errorMessageText text-danger">
                      {errors.topPriority}
                    </span>
                  )}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditDistination;
