// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utilities
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSections } from "../../../redux/adminSlice/sectionSlice";
import { getAllActivityTypes } from "../../../redux/adminSlice/activitySlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

const ManageSection = () => {
  // function AddSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [searchText, setsearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { sections }        = useSelector((state) => state.adminSectionActionsSlice);
  const { activity_types }  = useSelector((state) => state.adminActivityActionSlice);
  console.log("sections", sections);
  const [sectionList, SetsectionList] = useState([]);


  let userdata  = localStorage.getItem('userdata');
  userdata  = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType     = userdata?.userType;


  // Component did mount
  useEffect(() => {
    dispatch(getAllActivityTypes());
    dispatch(getAllSections());
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const deleteSection = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["adminDeleteSection"]({ id: id });
      // console.log("Res", res);

      if (res.isSuccess) {
        dispatch(getAllSections());
        setModal(false);
        setisLoading(false);
        // apiCallSuccess(res.data);
        toast.success("Section deleted successfully.");
      } else {
        setisLoading(false);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message, setError);
    }
  };

  const filterData = () => {

    if (searchText !== "") {
      return sections.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return sections;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Activity Type",
          field: "activityType",
          sort: "asc",
          width: 150,
        },
        {
          label: "Activity Subtype",
          field: "subtype",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div
                        className="widget-content-center flex2"
                      >
                        
                          {item?.title}
                        
                      </div>
                    </div>
                  </div>
                ),
                activityType: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        
                          {item?.activityTypes?.name}
                        
                      </div>
                    </div>
                  </div>
                ),
                subtype: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        
                          {item?.subType?.charAt(0)?.toUpperCase() +
                            item?.subType?.slice(1)}
                        
                      </div>
                    </div>
                  </div>
                ),
                status: (
                  <button
                    className={`badge ${
                      item?.active ? "bg-success" : "bg-danger"
                    } border-0`}
                  >
                    {item?.active ? "Active" : "Deactivated"}
                  </button>
                ),
                img:(
                  <img src={item?.image} style={{width:225}} />
                ),
                action: (
                  <div>
                    {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('delete'))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(0);

                        setdeleteId(sections[idx]._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    )}

                    {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('edit'))) && (

                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        navigate(`/edit-section/${item?._id}`);
                      }}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </button>
                    )}
                  </div>
                ),
              };
            })
          : [],
    };
  }, [sections, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete section</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteSection(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Sections"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
};

export default ManageSection;
