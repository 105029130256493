// Hooks
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// API function
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { fileUpload } from "../../../services/api/apiHelpers";

// Redux actions
import { getProfileDetails } from "../../../redux/authSlice/adminProfileSlice";

// Components
import Loader from "../../../Component/Loader";
// import Table from "../../../Component/Table";

let createErrors = {
  email: "",
  image: "",
};

function Profile() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false);
  // const { profile_data } = useSelector((state) => state.adminProfileSlice);
  const profile_data = location.state;
  // console.log("profileData", location.state);
  let regInitValues = {
    firstName: profile_data?.firstName,
    lastName: profile_data?.lastName,
    email: profile_data?.email,
    image: profile_data?.image,
  };
  const [user, setuser] = useState(regInitValues);
  const [img, setimg] = useState(null);
  const [errors, seterrors] = useState(createErrors);
  const [inputReadOnlyStatus, setinputReadOnlyStatus] = useState(true);
  const [imgDisableStatus, setimgDisableStatus] = useState(true);

  // useEffect(() => {
  //   dispatch(getProfileDetails());
  // }, []);

  const handleChange = (event) => {
    // console.log(event.target.name, event.target.value);
    setuser({ ...user, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const { email } = user;

    if (email === "") {
      seterrors({ ...createErrors, email: "Email is required." });

      return false;
    }
    if (new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}").test(email) === false) {
      seterrors({
        ...createErrors,
        email: "Please provide email in correct format",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("user data", user);
    if (handleValidation()) {
      let data = { ...user };
      let res;

      if (img == null) {
        setisLoading(true);
        res = await JSON_API["adminEditSelfProfile"](data);
        console.log(res);
        if (res.isSuccess) {
          dispatch(getProfileDetails());
          // setuser(regInitValues);
          setinputReadOnlyStatus(true);
          setimgDisableStatus(true);
          toast.success("User information successfully edited.");
        } else {
          toast.error("Something went wrong");
        }
        setisLoading(false);
      } else {
        if (
          data.image?.type === "image/png" ||
          data.image?.type === "image/jpeg"
        ) {
          console.log(data.image);
          setisLoading(true);
          const DATA = new FormData();
          DATA.append("image", data?.image);
          let imgUpRes = await fileUpload("/admin/updateImage", "POST", DATA);
          console.log(imgUpRes, "alala");
          if (imgUpRes.status) {
            data.image = imgUpRes.url;

            res = await JSON_API["adminEditSelfProfile"](data);
            console.log(res);

            if (res.isSuccess) {
              dispatch(getProfileDetails());
              // setuser(regInitValues);
              setinputReadOnlyStatus(true);
              setimgDisableStatus(true);
              toast.success("User information successfully edited.");
            } else {
              toast.error("Something went wrong");
            }
          } else {
            toast.error("Something went wrong. Please try again.");
          }
          setisLoading(false);
        } else {
          toast.error("Image should be a .png or .jpg type");
          return;
        }
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {/* <form className=""> */}
      <div className="card-title">Edit Profile</div>
      <div className="row row-form-fields" style={{ margin: "0 0.1rem" }}>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label">
              First Name
            </label>
            <input
              name="firstName"
              id="firstName"
              type="text"
              className="form-control"
              readOnly={inputReadOnlyStatus}
              value={user.firstName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errors.firstName && (
            <span className="errorMessageText text-danger">
              {errors.firstName}
            </span>
          )}
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="lastName" className="form-label">
              Last Name
            </label>
            <input
              name="lastName"
              id="lastName"
              type="text"
              className="form-control"
              readOnly={inputReadOnlyStatus}
              value={user.lastName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errors.lastName && (
            <span className="errorMessageText text-danger">
              {errors.lastName}
            </span>
          )}
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="exampleEmail" className="form-label">
              Email
            </label>
            <input
              name="email"
              id="exampleEmail"
              placeholder="Email here..."
              type="text"
              className="form-control"
              readOnly={true}
              value={user.email}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errors.email && (
            <span className="errorMessageText text-danger">{errors.email}</span>
          )}
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="image" className="form-label">
              Profile Image
            </label>
            <input
              name="image"
              id="image"
              type="file"
              className="form-control"
              disabled={imgDisableStatus}
              onChange={(e) => {
                setimg(e.target.files[0]);
                setuser((prev) => {
                  return { ...prev, image: e.target.files[0] };
                });
              }}
            />
          </div>
          {profile_data?.image && typeof profile_data?.image == "string" && (
            <img
              style={{ height: "100px", width: "100px" }}
              alt=""
              src={profile_data.image}
            ></img>
          )}
        </div>
      </div>
      x
      <div className="divider row" />
      <div className="d-flex align-items-center">
        <div className="ms-auto">
          {inputReadOnlyStatus === false ? (
            <>
              <button
                class="btn btn-primary btn-lg font-weight-bold bg-dark"
                onClick={() => {
                  setinputReadOnlyStatus(true);
                  setimgDisableStatus(true);
                }}
              >
                Cancel
              </button>
              <button
                id="editButton"
                className="btn btn-primary btn-lg"
                onClick={(e) => handleSubmit(e)}
                style={{ marginLeft: "10px" }}
              >
                Edit
              </button>
            </>
          ) : (
            <button
              className="btn btn-primary btn-lg"
              onClick={() => {
                setinputReadOnlyStatus(false);
                setimgDisableStatus(false);
              }}
            >
              Enable Edit
            </button>
          )}
        </div>
      </div>
      {/* </form> */}
    </>
  );
}

export default Profile;
