import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { requestData } from "../../../services/api/apiHelpers";

import { BASE_URL, API_ENDPOINT_PATH } from "../../../constants/config";
import { toast } from "react-toastify";

const ViewReviewAndRating = () => {
  console.log("---ViewReviewAndRating-------");
  const params = useParams();
  let navigate = useNavigate();
  const [activityDetails, setActivityDetails] = useState([]);
  console.log("dfdfdf", activityDetails);
  const [productDetails, setProductDetails] = useState([]);

  const [reviewRating, setReviewRating] = useState([]); // useSelector((state) => state.reviewRatingSlice);

  const getReviewData = async () => {
    let res = await requestData(`/admin/view-review-rating`, "GET");

    if (res?.status && res?.data) {
      //console.clear();
      let result = res.data.filter((val) => val._id == params.id);
      if (result.length) {
        setActivityDetails(result[0]);
      } else {
        alert("Activity Not Found!");
        navigate("/manage-activity");
      }

      console.log(result);
    }
  };

  const getProductData = async () => {
    let res = await requestData(`viewActivityDetails/bookable_products`, "GET");

    if (res?.status && res?.data) {
      let result = res.data.filter((val) => val._id == params.id);
      console.log("-: setProductDetails :-", result);
      if (result.length) {
        setProductDetails(result[0]);
      } else {
        alert("Activity Not Found!");
        navigate("/manage-activity");
      }

      console.log(result);
    }
  };

  console.log("-: activityDetails :-", activityDetails);

  useEffect(() => {
    console.log(" -: Id :- ", params.id);
    getProductData();
    getReviewData();
  }, []);

  const changeStatus = async (id, status) => {
    const data = {
      status: status,
    };
   
    const res = await requestData(
      `/admin/review-status-change/${id}`,
      "PUT",
      data
    );
    if (res && res.status) {
      toast.success("Review status change successfully");
      getReviewData();
    }
    else{
      toast.error("Review status change failed")
    }
  };

  const reviewDelete = async (id) => {
    console.log("dsdasdfwefdwe", id);
    const res = await requestData(`/admin/review-status-change`, "DELETE", id);
    if(res && res.status){
      toast.success("Review Deleted Successfully")
    }
    else{
      toast.error("Review Deleted failed")
    }
  };

  return (
    <div component="div" className="TabsAnimation appear-done enter-done">
      <div className="main-card mb-3 card">
        <div className="card-body">
          <div className="card-title">Activity Review</div>
          <div class="row mt-3">
            <div class="col-lg-4 col-md-4 col-12 d-none">
              <div class="DetailsData">
                <span>Merchant Name</span>
                <h6>NA</h6>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="DetailsData">
                <span>Activity Name</span>
                <h6>{activityDetails?.activityName}</h6>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="DetailsData">
                <span>Overall rating</span>
                <h6 class="">{activityDetails?.reviewRating}</h6>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12 d-none">
              <div class="DetailsData">
                <span>Activity Category</span>
                <h6 class="">NA</h6>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12 d-none">
              <div class="DetailsData">
                <span>Activity Type</span>
                <h6 class="">NA</h6>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12 d-none">
              <div class="DetailsData">
                <span>Activity Country</span>
                <h6 class="">NA</h6>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col mainInnerRatingPart">
              <div class="reviewSecondPart">
                <p class="reviewSmallHeads" style={{ fontWeight: "bold" }}>
                  Review summary : <br /> <hr />
                </p>
                <div className="heightscrl">
                  {activityDetails?.activityReview?.length === 0 ? (
                    <div
                      style={{
                        height: "30px",
                        width: "100%",
                        backgroundColor: "#7ad97a",
                        textAlign: "center",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>
                        There is no review for this activity
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <table
                    className="table table-striped dataTable"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Submitted By</th>
                        <th>Submitted Comment</th>
                        <th>Submitted Date</th>
                        <th>Overall rating</th>
                        <th>Submitted Ratting</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityDetails?.activityReview?.length > 0 &&
                        activityDetails?.activityReview?.map((item) => {
                          console.log("item", item);
                          return (
                            <tr>
                              <td>{item.submittedBy}</td>
                              <td>{item.submittedComment}</td>
                              <td>
                                {moment(item.submittedDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>{item.submittedRating}</td>
                              <td>
                                <div style={{ width: "100%" }}>
                                  <div class="reviewProgressBarPartParent">
                                    <div class="reviewProgressBarPart">
                                      <p class="revSumText">
                                        Guide / Experience : {item?.guide}/5
                                      </p>
                                      <div class="progress myProgress">
                                        <div
                                          class="progress-bar progressBarPart"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              (item?.guide / 5) * 100
                                            }%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div class="reviewProgressBarPart">
                                      <p class="revSumText">
                                        Organization : {item?.organization}/5
                                      </p>
                                      <div class="progress myProgress">
                                        <div
                                          class="progress-bar progressBarPart"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              (item?.organization / 5) * 100
                                            }%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div class="reviewProgressBarPart">
                                      <p class="revSumText">
                                        Service : {item?.service}/5
                                      </p>
                                      <div class="progress myProgress">
                                        <div
                                          class="progress-bar progressBarPart"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              (item?.service / 5) * 100
                                            }%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div class="reviewProgressBarPart">
                                      <p class="revSumText">
                                        Value For Money : {item?.valueForMoney}
                                        /5
                                      </p>
                                      <div class="progress myProgress">
                                        <div
                                          class="progress-bar progressBarPart"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              (item?.valueForMoney / 5) * 100
                                            }%`,
                                          }}
                                        ></div>
                                      </div>
                                      <p class="revRightRate"></p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {item?.isActive ? (
                                  <>
                                    <button
                                      className="btn btn-success mt-35px"
                                      onClick={() =>
                                        changeStatus(item?._id, false)
                                      }
                                    >
                                      Active
                                    </button>
                                    <p style={{marginTop: "3px"}}>click to Inactive</p>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-danger"
                                      onClick={() =>
                                        changeStatus(item?._id, true)
                                      }
                                    >
                                      Inactive
                                    </button>
                                    <p style={{marginTop: "3px"}}>click to active</p>
                                  </>
                                )}
                              </td>
                              <td>
                                <button
                                  title="Delete Activity"
                                  className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                                  onClick={() => {
                                    // openModal();
                                    // setmodalToggleView(0);
                                    // setdeleteId(item?._id);
                                    reviewDelete(item?._id)
                                  }}
                                >
                                  <i className="fa-solid fa-trash-can" />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewReviewAndRating;
