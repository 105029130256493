// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Helpers
import * as Yup from "yup";
import { toast } from "react-toastify";

// Utils
import { capitalizeFirstLetter, checkFileDimension } from "../../../utils";

// API functions
import {
  ApiHelperFunction,
  fileUpload,
} from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSubadmins } from "../../../redux/adminSlice/subadminActionsSlice";

// Components
import Layout from "../../../Layout";
import { Formik } from "formik";
import Loader from "../../../Component/Loader";

import "./subadmin_section_style.css";
import { update } from "firebase/database";
import Resizer from "react-image-file-resizer";

let createErrors = {
  firstName: "",
  lastName: "",
  image: "",
  email: "",
  password: "",
  assignAccess: "",
};

let initialValues = {
  firstName: "",
  lastName: "",
  image: "",
  email: "",
  password: "",
  assignAccess: "",
};

let accessPrivileges = [
  { id: 1, name: "view", checked: false },
  { id: 2, name: "create", checked: false },
  { id: 3, name: "edit", checked: false },
  { id: 4, name: "delete", checked: false },
];

var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;
var emailReg =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function AdminAddSubAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imgInputRef = useRef(null);
  const [key, setkey] = useState(Math.random());
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [subadmin, setsubadmin] = useState(initialValues);
  const [img, setimg] = useState("");
  const [imgRatioData, setimgRatioData] = useState({});
  const [checkedAccess, setcheckedAccess] = useState(accessPrivileges);
  const [allAccessChecked, setallAccessChecked] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [uploadedImg, setuploadedImg] = useState("");
  const [checkboxData, setCheckboxData] = useState([]);
  const [emailSugg, setEmailSugg] = useState(null);
  const [filterEmails, setFilterEmails] = useState([]);

  const handleChange = (event) => {
    if (event.target.name === "email") {
      setsubadmin({ ...subadmin, email: event.target.value });
      if (event.target.value != "") {
        let emails = filterEmail(event.target.value);
        if(!typeof emails === "undefined"){
          setFilterEmails(emails.slice(0, 5));
        }
      }
    } else {
      let input;
      if (event.target.name === "password" || event.target.name === "email") {
        input = event.target.value;
      } else {
        input = capitalizeFirstLetter(event);
      }
      // console.log(event.target.name, event.target.value);
      setsubadmin({ ...subadmin, [event.target.name]: input });
    }
  };

  const handleCheckedChange = (event, index) => {
    let accesses = checkedAccess.map((item, idx) => {
      if (index === idx) {
        // console.log(item);
        return {
          ...item,
          checked: !item.checked,
        };
      }

      return item;
    });

    let flag = true;
    for (let i = 0; i < accesses?.length; i++) {
      if (!accesses[i].checked) {
        flag = false;
      }
    }

    if (flag) {
      setallAccessChecked(true);
    } else {
      setallAccessChecked(false);
    }
    setcheckedAccess(accesses);
  };

  // console.log("emailSugg", emailSugg);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

    const handleImageNew = async (file) => {
      if (typeof file === "undefined") return;
      if (imageReg.test(file.type)) {
        setuploadedImg(URL.createObjectURL(file));
        let fileObj = await checkFileDimension(file);
        // console.log(fileObj);
        if (fileObj?.height > 720) {
          seterrors({
            ...errors,
            image: "File height should be less than 720px",
          });
  
          return;
        }
        if (fileObj?.width > 1280) {
          seterrors({
            ...errors,
            image: "File width should be less than 1280px",
          });
  
          return;
        }
  
        seterrors({ ...errors, image: "" });
  
        let DATA = new FormData();
        DATA.append("image", file);
        let imgUpRes = await fileUpload("/admin/uploadSubImage", "POST", DATA);
        console.log("res", imgUpRes);
        if (imgUpRes && imgUpRes.status) {
          setsubadmin((prev) => ({ ...prev, image: imgUpRes.url }));
          
        } else {
          toast.error(imgUpRes.message);
        }
      } else {
        seterrors({ ...errors, image: "File is not an image" });
        setuploadedImg("");
      }
    };

    
  const handleImage = async (file) => {
    const image = await resizeFile(file);
    console.log("image", image);
    if (typeof file === "undefined") return;
    if (imageReg.test(file.type)) {
      setuploadedImg(URL.createObjectURL(file));

      // let fileObj = await checkFileDimension(file);
      // console.log(fileObj);
      // if (fileObj?.height > 720) {
      //   seterrors({
      //     ...errors,
      //     image: "File height should be less than 720px",
      //   });

      //   return;
      // }
      // if (fileObj?.width > 1280) {
      //   seterrors({
      //     ...errors,
      //     image: "File width should be less than 1280px",
      //   });

      //   return;
      // }

      seterrors({ ...errors, image: "" });
      let DATA = new FormData();
      DATA.append("image", image);
      let imgUpRes = await fileUpload("/admin/uploadSubImage", "POST", DATA);
      console.log("res", imgUpRes);
      if (imgUpRes && imgUpRes.status) {
        setsubadmin((prev) => ({ ...prev, image: imgUpRes.url }));
      } else {
        toast.error(imgUpRes.message);
      }
    } else {
      seterrors({ ...errors, image: "File is not an image" });
      setuploadedImg("");
    }
  };

  // const subadminInfoValidationSchema = Yup.object().shape({
  //   firstName: Yup.string().required("First name is required."),
  //   lastName: Yup.string().required("Last name is required."),
  //   email: Yup.string()
  //     .required("Email is required.")
  //     .matches(
  //       /^[a-z0-9]+@[a-z]+.[a-z]{2,3}$/,
  //       "Please enter correct email format."
  //     ),
  //   password: Yup.string()
  //     .required("Password is required.")
  //     .min(8, "Password must be at least 8 characters"),
  //   assignAccess: Yup.array().min(1, "Atleast 1 priviledge is required"),
  //   profileImg: Yup.mixed()
  //     .test("fileType", "File should be a .png or .jpg", (value) => {
  //       // console.log("Yup file value", value);
  //       if (!value) return true; // attachment is optional
  //       return value?.type === "image/jpeg" || value?.type === "image/png";
  //     })
  //     .test("fileSize", "Image should not exceed more the 2.5MB", (value) => {
  //       if (!value) return true; // attachment is optional
  //       return value?.size <= 2560000;
  //     }),
  // });

  const handleValidation = async () => {
    const { firstName, lastName, email, password } = subadmin;

    let response = await ApiHelperFunction({
      urlPath: '/admin/checkEmail',
      method: 'POST',
      data: {email:email},
    });
  
    if(!response?.data?.status){
      toast.error(response?.data?.message);
      return false;
    }

    if (firstName === "") {
      seterrors({
        ...createErrors,
        firstName: "First name is required.",
      });

      return false;
    }
    if (lastName === "") {
      seterrors({
        ...createErrors,
        lastName: "Last name is required.",
      });

      return false;
    }
    if (email === "") {
      seterrors({ ...createErrors, email: "Email is required." });

      return false;
    }
    if (!emailReg.test(email)) {
      seterrors({
        ...createErrors,
        email: "Please enter correct email format.",
      });

      return false;
    }
    if (password === "") {
      seterrors({
        ...createErrors,
        password: "Password is required.",
      });

      return false;
    }
    if (password.length < 8) {
      seterrors({
        ...createErrors,
        password: "Please enter minimum 8 characters.",
      });

      return false;
    }

    return true;
  };
  //email Suggestion

  const getEmailSuggesion = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/subadmin-random-email",
      method: "GET",
    });
    console.log("resemailSugg", response);
    if (response && response?.status === 200) {
      setEmailSugg(response?.data.data);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let selectedRoles = checkedAccess.filter((item) => item.checked === true);
    console.log({ selectedRoles });
    let roleNames = "";
    // selectedRoles.forEach((item) => roleNames + item.name + ", "); // roleNames.push(item.name)
    // for (let i = 0; i < selectedRoles.length; i++) {
    //   roleNames += selectedRoles[i].name + ", ";
    // }
    console.log({ roleNames });

    if (checkboxData?.length === 0)
      return toast.error("Atleast one Access Priviledege must be selected");
    if (img === "") {
      toast.error("Please provide a profile image");
    } else if (errors?.image !== "") {
      toast.error(
        "Please provide an image with height less than 720px and width less than 1280px"
      );
    } else {
      if (handleValidation()) {
        // if (roleNames === '') {
        //   seterrors({
        //     ...createErrors,
        //     assignAccess: 'Atleast 1 priviledge is required.',
        //   });
        // } else {
        // let bodyData = { ...subadmin, assignAccess: roleNames.slice(0, -2) };
        let bodyData = { ...subadmin, assignAccess: checkboxData };

        try {
          setisLoading(true);
          let res = await JSON_API["adminAddSubadmin"](bodyData);

          if (res.isSuccess) {
            setCheckboxData([]);
            dispatch(getAllSubadmins());
            setsubadmin(initialValues);
            if (imgInputRef.current) {
              imgInputRef.current.value = "";
            }
            setuploadedImg("");
            seterrors(createErrors);
            setisLoading(false);
            SubadminApiCallSuccess(res?.data);
            navigate("/manage-admin");
          } else {
            setisLoading(false);

            SubadminApiCallError(res?.error?.message);
          }
        } catch (error) {
          setisLoading(false);
          console.log("catch err", error.message);
        }
        // }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const SubadminApiCallSuccess = (data) => {
    setkey(Math.random());
    checkedAccess.forEach((item) => (item.checked = false));
    toast.success("Admin added successfully");
  };

  const SubadminApiCallError = (errorMsg) => {
    toast.error(errorMsg);
    setError(errorMsg);
  };
  const handleCheckBoxes = (e) => {
    console.log("NAME", e.target.checked, e.target.name);
    if (e.target.checked) {
      setCheckboxData((prev) => {
        let update = JSON.parse(JSON.stringify(prev));
        update.push(e.target.name);
        return [...update];
      });
      return;
    }

    setCheckboxData((prev) => {
      let update = JSON.parse(JSON.stringify(prev));
      console.log(update, e.target.name);
      let x = update.findIndex((item) => item === e.target.name);
      update.splice(x, 1);
      return [...update];
    });
  };
  const filterEmail = (address) => {
    let emails = emailSugg?.filter((item, index) => {
      return item.includes(address);
    });
    return emails;
  };

  useEffect(() => {
    getEmailSuggesion();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {/* <Form btntxt="Add" city={false} textarea={false} radio={false} inputFile={false} check={false} /> */}

      {/* <Formik
        key={key}
        initialValues={initialValues}
        validationSchema={subadminInfoValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { resetForm }) => {
          submitSubadminInfo(values, resetForm);
        }}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => ()}
      </Formik> */}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Admin</div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="exampleEmail11" className="form-label">
                      First Name*
                    </label>
                    <input
                      name="firstName"
                      id="firstName"
                      placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={subadmin.firstName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.firstName && (
                    <span className="errorMessageText text-danger">
                      {errors.firstName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="examplePassword11" className="form-label">
                      Last Name*
                    </label>
                    <input
                      name="lastName"
                      id="lastName"
                      placeholder="Your Last name, e.g. Smith"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={subadmin.lastName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.lastName && (
                    <span className="errorMessageText text-danger">
                      {errors.lastName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="exampleEmail11" className="form-label">
                      Email*
                    </label>
                    <input
                      name="email"
                      id="exampleEmail11"
                      placeholder="E.g.,waltersmith@gmail.com"
                      type="email"
                      className="form-control"
                      autoComplete="false"
                      value={subadmin?.email}
                      // onClick={() => {

                      //     getEmailSuggesion();

                      // }}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.email && (
                    <span className="errorMessageText text-danger">
                      {errors.email}
                    </span>
                  )}
                  {filterEmails.length > 0 && (
                    <div className="admin-email-suggesion">
                      <div style={{ color: "sandybrown" }}>
                        Email suggestions:
                      </div>
                      {filterEmails.map((item, i) => (
                        <p
                          key={i}
                          onClick={() => {
                            setsubadmin((prev) => {
                              return { ...prev, email: item };
                            });
                            setFilterEmails([]);
                          }}
                        >
                          {item}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="examplePassword11" className="form-label">
                      Password*
                    </label>
                    <input
                      name="password"
                      id="examplePassword11"
                      type="password"
                      className="form-control"
                      autoComplete="false"
                      value={subadmin.password}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.password && (
                    <span className="errorMessageText text-danger">
                      {errors.password}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="bannerImg" className="form-label">
                    Access Privilege*
                  </label>
                  <div className="d-flex px-2 mx-2">
                    {/* <div className="access-levels d-flex">
                      <input
                        type="checkbox"
                        name="all_priviledges"
                        id="all_priviledges"
                        checked={allAccessChecked}
                        onChange={e => {
                          handleAllAccessChecked();
                          // handleChange(e);
                        }}
                      />
                      <label htmlFor="all_priviledges">All</label>
                    </div> */}
                    {checkedAccess.map((item, idx) => {
                      return (
                        <div className="access-levels d-flex">
                          <input
                            type="checkbox"
                            name={item?.name}
                            checked={item.checked}
                            onChange={(e) => {
                              handleCheckedChange(e, idx);
                              handleCheckBoxes(e);
                              // handleChange(checkedAccess);
                            }}
                          />
                          <label>
                            {
                              item.name.charAt(0).toUpperCase() + item.name.slice(1)
                            }
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  {errors.assignAccess && (
                    <span className="errorMessageText text-danger">
                      {errors.assignAccess}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="profileImg" className="form-label">
                    Image*
                  </label>
                  <input
                    name="profileImg"
                    id="profileImg"
                    type="file"
                    accept={"image/*"}
                    ref={imgInputRef}
                    className="form-control"
                    onChange={(e) => {
                      setimg(e.target.files[0]);
                      // setimgRatioData(checkFileDimension(e.target.files[0]));
                      handleImageNew(e.target.files[0]);
                    }}
                  />
                  {uploadedImg.length > 0 && (
                    <img
                      alt=""
                      style={{ height: "100px", width: "100px" }}
                      src={uploadedImg}
                    />
                  )}
                  {errors && (
                    <span className="errorMessageText text-danger">
                      {errors.image}
                    </span>
                  )}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
