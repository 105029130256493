import React from "react";

// Components
import { ToastContainer } from "react-toastify";
import AppRoutes from "./routes/AppRoutes";

import "./App.css";
import "../src/assets/base.css";
import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";
import HomeContext from "./Context/HomeContext";

function App() {
  return (
    <React.Fragment>
      <HomeContext>
        <div className="App">
          <AppRoutes />
        </div>

        <ToastContainer />
        <Toaster />
      </HomeContext>
    </React.Fragment>
  );
}

export default App;

// hhhhhh
