import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { requestData } from "../../../services/api/apiHelpers";
import coupon from "../../../images/coupon.jpeg";
import bookBtn from "../../../images/BookNowBtn.png";
import "./coupon.css";
import Loader from "../../../Component/Loader";
import moment from "moment"
import Table from "../../../Component/Table";
import Swal from "sweetalert2";
import { dateFormat } from "../../../utils/date-functions";

const ManageCoupon = () => {

  const COUPON_DISCOUNT_TYPE = {
    PERCENTAGE: "percentage",
    FIXED: "fixed"
  }
  const COUPON_USAGE_TYPE = {
    UNLIMITED: "Unlimited",
    LIMITED: "Limited"
  }

  const initial = {
    couponCode: "",
    startDate: "",
    endDate: "",
    discountType: COUPON_DISCOUNT_TYPE.PERCENTAGE,
    discountValue: 0,
    usageLimit: -1,
    minPurchaseAmount: 0
  };

  const [couponData, setCouponData] = useState(initial);
  const [couponUsageType, setCouponUsageType] = useState(COUPON_USAGE_TYPE.UNLIMITED)
  const [coupons, setAllCoupon] = useState({
    coupons: [],
    totalCoupons: 0,
    totalPages: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const [editId, setEditid] = useState("")
  const [editEnable, setEditEnable] = useState(false)
  const [searchText, setsearchText] = useState("");

  let userdata = localStorage.getItem('userdata');
  userdata = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType = userdata?.userType;

  const getCoupon = async () => {
    setIsLoading(true);
    try {
      let res = await requestData("/admin/get-all-coupon", "GET");
      if (res && res.status) {
        setAllCoupon(res?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCoupon();
  }, []);

  const handleValidation = () => {
    if (!couponData.couponCode) {
      toast.error("coupon title is required");
      return false;
    }
    if (!couponData.discountValue === 0) {
      toast.error("Discount value is required ans always grater than 0 ");
      return false;
    }
    if (!couponData.startDate) {
      toast.error("coupon start date is required");
      return false;
    }
    if (!couponData.endDate) {
      toast.error("coupon end date is required");
      return false;
    }
    if (!couponData.usageLimit === 0) {
      toast.error("usage user is required ans always grater than 0 ");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let res

    try {
      if (handleValidation()) {
        const data = couponData;
        if(editEnable){
          res = await requestData(`/admin/update-coupon/${editId}`, "PUT", data);
        }
        else{
          res = await requestData("/admin/add-coupon", "POST", data);

        }
        if (res && res.status) {
          toast.success("Coupon Added Successfully");
          setCouponData(initial);
          setCouponUsageType(COUPON_USAGE_TYPE.UNLIMITED)
          getCoupon();
        } else {
          toast.error(res.message);
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const onEditClick = (couponData) => {
    
    setEditEnable(true)
    setEditid(couponData._id)
    setCouponData((prev) => ({
      ...prev,
      couponCode: couponData.couponCode,
      startDate: dateFormat(couponData.startDate),
      endDate:dateFormat(couponData.endDate),
      discountType: couponData.discountType,
      discountValue: couponData.discountValue,
      usageLimit: couponData.usageLimit,
      minPurchaseAmount: couponData.minPurchaseAmount
    }))
    setCouponUsageType(couponData.usageLimit === -1 ? COUPON_USAGE_TYPE.UNLIMITED : COUPON_USAGE_TYPE.LIMITED)
    window.scrollTo({
      top:0,
      left:0,
      behavior:"smooth"
    })

  }
  const filterData = () => {
    if (searchText !== "") {
      return coupons.coupons.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return coupons.coupons;
  };
  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Coupon Code",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Discount Type",
          field: "discountType",
          sort: "asc",
          width: 150,
        },
        {
          label: "Discount Value",
          field: "discountValue",
          sort: "asc",
          width: 150,
        },
        {
          label: "Start Date",
          field: "startDate",
          sort: "asc",
          width: 150,
        },
        {
          label: "End Date",
          field: "endDate",
          sort: "asc",
          width: 150,
        },
        {
          label: "Minimum Purchase Amount",
          field: "minPurchaseAmount",
          sort: "asc",
          width: 150,
        },
        {
          label: "Usage Limit",
          field: "usageLimit",
          sort: "asc",
          width: 150,
        },

        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
      filterData().map((item, idx) => {
          return {
            sl: idx + 1,
            name: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div
                    className="widget-content-left flex2"
                    style={{ textAlign: "center" }}
                  >
                    <div style={{ opacity: 1 }}>
                      {item?.couponCode}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            discountType: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div
                    className="widget-content-left flex2"
                    style={{ textAlign: "center" }}
                  >
                    <div style={{ opacity: 1 }}>
                      {item?.discountType}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            discountValue: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div
                    className="widget-content-left flex2"
                    style={{ textAlign: "center" }}
                  >
                    <div style={{ opacity: 1 }}>
                      {item?.discountValue}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            startDate: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div
                    className="widget-content-left flex2"
                    style={{ textAlign: "center" }}
                  >
                    <div style={{ opacity: 1 }}>
                      {item?.startDate}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ), endDate: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div
                    className="widget-content-left flex2"
                    style={{ textAlign: "center" }}
                  >
                    <div style={{ opacity: 1 }}>
                      {item?.endDate}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            minPurchaseAmount: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div
                    className="widget-content-left flex2"
                    style={{ textAlign: "center" }}
                  >
                    <div style={{ opacity: 1 }}>
                      {item?.minPurchaseAmount}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            usageLimit: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div
                    className="widget-content-left flex2"
                    style={{ textAlign: "center" }}
                  >
                    <div style={{ opacity: 1 }}>
                      {item?.usageLimit === -1 ? "Unlimited" : item?.usageLimit}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            action: (
              <div>
                {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('delete'))) && (
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      deleteHandle(item._id)
                      // openModal();
                      // setmodalToggleView(0);
                      // console.log("Delete id", activity_types[idx]._id);
                      // setdeleteId(categories[idx]._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                )}

                {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('edit'))) && (
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      onEditClick(item)
                      // navigate(`/edit-categories/${item?._id}`);
                    }}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                )}
              </div>
            ),
          };
        })

    };
  }, [coupons,searchText]);

  const deleteHandle = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await requestData(`/admin/delete-coupon/${id}`, "DELETE");
          if (res.status) {
            Swal.fire({
              title: "Deleted!",
              text: "Your coupon has been deleted.",
              icon: "success"
            });
            getCoupon();

          }
          else {
            toast.error(res.message || "something went wrong")
          }


        } catch (error) {
          toast.error(error.message || "something went wrong")

        }



      }
    });
  }
  return (
    <>
      {isLoading && <Loader />}
      <div className="addMainDiv">
        <div className="coupon_text">Add and manage coupon</div>
        <hr />
        <form onSubmit={handleSubmit}>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Coupon Code</label>
            <input
              name="couponCode"
              value={couponData.couponCode}
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Enter Coupon Title"
              onChange={(e) =>
                setCouponData((prev) => ({
                  ...prev,
                  couponCode: e.target.value,
                }))
              }
            />
          </div>
          <div className="row">
            {/* <div className="col-md-4"> */}
            <div className="form-group col-md-3">
              <label htmlFor="discountType" >
                Discount Type

              </label>
              <select
                name="discountType"
                id="discountType"
                className="form-control form-select"
                value={couponData.discountType}
                onChange={(e) => setCouponData((prev) => ({
                  ...prev,
                  discountType: e.target.value,
                }))}
                required={true}
              >
                <option
                  value={COUPON_DISCOUNT_TYPE.PERCENTAGE}
                >
                  Percentage
                </option>
                <option
                  value={COUPON_DISCOUNT_TYPE.FIXED}
                >
                  Fixed
                </option>

              </select>
            </div>

            {/* </div> */}
            <div class="form-group col-md-3">
              <label for="discountValue">Discount Value</label>
              <input
                name="discountValue"
                value={couponData.discountValue}
                type="number"
                class="form-control"
                id="discountValue"
                placeholder="Enter discount value"
                onChange={(e) =>
                  setCouponData((prev) => ({
                    ...prev,
                    discountValue: e.target.value,
                  }))
                }
              />
            </div>
            <div class="form-group col-md-3">
              <label for="exampleFormControlInput1">Start Date</label>
              <input
                name="startDate"
                value={couponData.startDate}
                type="Date"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Start Date"
                onChange={(e) =>
                  setCouponData((prev) => ({
                    ...prev,
                    startDate: e.target.value,
                  }))
                }
              />
            </div>
            <div class="form-group col-md-3">
              <label for="exampleFormControlInput1">End Date</label>
              <input
                name="endDate"
                value={couponData.endDate}
                type="Date"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter End Date"
                min={couponData.startDate}
                onChange={(e) =>
                  setCouponData((prev) => ({
                    ...prev,
                    endDate: e.target.value,
                  }))
                }
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="discountType" >
                Usage Type

              </label>
              <select
                name="usageType"
                id="usageType"
                className="form-control form-select"
                value={couponUsageType}
                onChange={(e) => {
                  let value = e.target.value
                  if (value === COUPON_USAGE_TYPE.UNLIMITED) {
                    setCouponData((prev) => ({
                      ...prev,
                      usageLimit: -1,
                    }))
                  }
                  if (value === COUPON_USAGE_TYPE.LIMITED) {
                    setCouponData((prev) => ({
                      ...prev,
                      usageLimit: 1,
                    }))
                  }
                  setCouponUsageType(value)
                }}
              // onChange={(e) => setCouponData((prev) => ({
              //   ...prev,
              //   discountType: e.target.value,
              // }))}
              >
                <option
                  value={COUPON_USAGE_TYPE.UNLIMITED}
                >
                  Unlimited
                </option>
                <option
                  value={COUPON_USAGE_TYPE.LIMITED}
                >
                  Limited
                </option>

              </select>
            </div>
            {couponUsageType === COUPON_USAGE_TYPE.LIMITED && <div class="form-group col-md-3">
              <label for="usageLimit">Usage Limit</label>
              <input
                name="usageLimit"
                value={couponData.usageLimit}
                type="number"
                class="form-control"
                id="usageLimit"
                placeholder="Enter Usage limit"
                onChange={(e) =>
                  setCouponData((prev) => ({
                    ...prev,
                    usageLimit: e.target.value,
                  }))
                }
              />
            </div>}

            <div class="form-group col-md-3">
              <label for="minPurchaseAmount">Minimum Purchase Amount</label>
              <input
                name="minPurchaseAmount"
                value={couponData.minPurchaseAmount}
                type="number"
                class="form-control"
                id="minPurchaseAmount"
                placeholder="Enter minimum purchase amount"
                onChange={(e) => {
                  if (e.target.value < 0) return
                  setCouponData((prev) => ({
                    ...prev,
                    minPurchaseAmount: e.target.value,
                  }))
                }
                }
              />
            </div>
          </div>

          <div className="coupon_submit">
            <button className="btn btn-primary">{editEnable?"Update":"Submit"}</button>
          </div>
        </form>
      </div>

      <hr style={{ marginTop: "60px" }} />
      <hr />

      {/* <div className="manage_main_div"> */}
      <Table
        striped
        tableTitle="All Coupons"
        data={tableData}
      // modal={modal}
      // setModal={setModal}
      // modalToggleView={modalToggleView}
      // renderModalView={renderModalView}
      onSearch={(val) => setsearchText(val)}
      />
      {/* {allCoupon?.length > 0 &&
          allCoupon.map((item, idx) => {
            console.log("ssss", item);
            return (
              <div className="manage_coupon">
                <div className="indx">{idx + 1}</div>
                <div className="main_coupon">
                  <div className="coupon_img">
                    <img id="cupn" src={coupon} alt="Coupon Card" />
                    <div className="Show_percentage">
                      {item?.discountPercentage}%
                    </div>
                    <div className="book_btn_img">
                      <img src={bookBtn} alt="Book Btn" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })} */}
      {/* </div> */}
    </>
  );
};

export default ManageCoupon;
