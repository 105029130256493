
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Country } from "country-state-city";
import { capitalizeFirstLetter, getOnlyFullDate } from "../../../utils";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { requestData, fileUpload } from "../../../services/api/apiHelpers";
import { getAllLanguages } from "../../../redux/adminSlice/languageSlice";
import {
  getAllMerchants,
  getAllDraftMerchants,
} from "../../../redux/adminSlice/merchantActionsSlice";
import DatePicker from "react-date-picker";
import Loader from "../../../Component/Loader";
import Select from "react-select";
import PhoneInput from "../../../constants/PhoneInput";
import { ApiHelperFunction } from "../../../services/api/apiHelpers";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./styles.css";
import "react-phone-number-input/style.css";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import currenciesJson from "../../../constants/currencies.json";
import useCountries from "../../../Hooks/useCountries";

let initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  langId: "",
  mobile: "",
  city: "",
  country: "",
  zip: "",
  image: "",
  commisionPercentage: "",
  payeeType: "",
  accountType: "",
  marBankName: "",
  bankCode: "",
  branchName: "",
  branchCode: "",
  marBankAccNo: "",
  marBankIfscCode: "",
  marAccHolderName: "",
  bankAcctCurrency: "",
  swiftCode: "",
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  companyMobile: "",
  directorName: "",
  individualName: "",
  companyDob: "",
  companyStreetAddress: "",
  postalCode: "",
  compCity: "",
  compState: "",
  compStateCode: "",
  compCountry: "",
  compCountryCode: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  compamyRegistrationImage: "",
  saveAsDraft: false,
  countryId: "",
  cityId: "",
  phonecode: "",
  phonecodecompany: "",
  isthirdperty: null,
  thirdpertyId: undefined,
  AccesKey: "",
  secretKey: ""
};

let createErrors = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  langId: "",
  mobile: "",
  city: "",
  country: "",
  zip: "",
  image: "",
  commisionPercentage: "",
  payeeType: "",
  accountType: "",
  marBankAccNo: "",
  marBankIfscCode: "",
  marBankName: "",
  marAccHolderName: "",
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  companyMobile: "",
  directorName: "",
  individualName: "",
  companyDob: "",
  companyStreetAddress: "",
  postalCode: "",
  compCity: "",
  compState: "",
  compCountry: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  compamyRegistrationImage: "",
  phonecode: "",
  phonecodecompany: "",
};

var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;
var emailReg =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var IFSCRegex = /^(?:[A-Za-z]{4}[0][A-Za-z0-9]{6}|)$/;
const worldCities = require("../../../constants/worldcities.json");
let currencies = Object.values(currenciesJson);
export default function EditMerchantAddDraft() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [editObject, seteditObject] = useState(initialValues);
  const [citiesForCountry, setcitiesForCountry] = useState([]);
  const [compCitiesForCountryArr, setCompCitiesForCountry] = useState([]);
  const [phonecode, setPhoneCode] = useState("");
  const [zipDropdownOne, setzipDropdownOne] = useState(false);
  const [zipDropdownTwo, setzipDropdownTwo] = useState(false);
  const [zipcodesForCity, setzipcodesForCity] = useState([]);
  const [zipForCompCity, setzipForCompCity] = useState([]);
  const [phonecodecompany, setPhoneCodeCompany] = useState("");
  const imgInputRef = useRef(null);
  const [img, setimg] = useState("");
  const [logo, setlogo] = useState("");
  const [insurancePolicyImg, setinsurancePolicy] = useState("");
  const [compRegCert, setcompRegCert] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [uploadedImg, setuploadedImg] = useState("");
  const [uploadedLogo, setuploadedLogo] = useState("");
  const [upInsurancePolicy, setupInsurancePolicy] = useState("");
  const [uploadedcompRegCert, setuploadedcompRegCert] = useState("");
  const [editPassword, seteditPassword] = useState("");
  const { countries, cities, fetchCities } = useCountries();
  const companyCityCountry = useCountries()

  const [thirdPartyData, setThirdPartyData] = useState([])

  const { languages } = useSelector((state) => state.languageSlice);
  const { draft_merchants } = useSelector(
    (state) => state.adminMerchantActionSlice
  );
  const draft = draft_merchants.find((item) => item?._id === params.id);

  useEffect(() => {
    dispatch(getAllLanguages());
    dispatch(getAllMerchants());
    dispatch(getAllDraftMerchants());
    let {
      _id,
      image,
      logo,
      insurancePolicy,
      compamyRegistrationImage,
      ...rest
    } = draft;
    seteditObject({ ...editObject, ...rest });
    getZipcodesForCity(rest?.city);
    getZipForCompCity(rest?.compCity);
    setimg(image);
    setlogo(logo);
    setinsurancePolicy(insurancePolicy);
    setcompRegCert(compamyRegistrationImage);
    setPhoneCode(rest?.phonecode);
    setPhoneCodeCompany(rest?.phonecodecompany);
    newMerchantData();
    getAllThirdParty()
    if(rest.compCountry){
      companyCityCountry.fetchCitiesByCountryName(rest.compCountry)
    }
  }, []);

  const newMerchantData = async () => {
    const singleMerchantData = await ApiHelperFunction({
      urlPath: "/admin/single-merchant-profile/" + params.id,
      method: "GET",
    });

    seteditPassword(singleMerchantData["data"]["data"]["password"]);
  };

  const [formProgress, setProgress] = useState(0);
  const calcProgress = () => {
    let calc = 0;
    setProgress(0);
    if (editObject?.country != "") {
      calc += 5;
    }
    if (editObject?.city != "") {
      calc += 5;
    }
    if (editObject?.zip != "") {
      calc += 5;
    }
    if (editObject?.companyName != "") {
      calc += 5;
    }
    if (editObject?.email != "") {
      calc += 5;
    }
    if (editObject?.password != "") {
      calc += 5;
    }
    if (editObject?.firstName != "") {
      calc += 5;
    }
    if (editObject?.lastName != "") {
      calc += 5;
    }
    if (editObject?.mobile != "") {
      calc += 5;
    }
    if (editObject?.commisionPercentage != "") {
      calc += 5;
    }
    if (editObject?.marBankName != "") {
      calc += 5;
    }
    if (editObject?.marAccHolderName != "") {
      calc += 5;
    }
    if (editObject?.marBankAccNo != "") {
      calc += 5;
    }
    if (editObject?.setPhoneCodeCompany != "") {
      calc += 5;
    }
    if (editObject?.legalStatus != "") {
      calc += 5;
    }
    if (editObject?.legalCompanyName != "") {
      calc += 5;
    }
    if (editObject?.compCountry != "") {
      calc += 5;
    }
    if (editObject?.compCity != "") {
      calc += 5;
    }
    if (editObject?.companyStreetAddress != "") {
      calc += 5;
    }
    if (editObject?.postalCode != "") {
      calc += 5;
    }
    if (editObject?.licenseNumber != "") {
      calc += 5;
    }
    if (editObject?.registrationNumber != "") {
      calc += 5;
    }

    setProgress(calc);
  };

  const getAllThirdParty = async () => {
    try {
      setisLoading(true)
      let res = await requestData("/admin/viewThirdPerty", "GET");
      if (res) {
        setThirdPartyData(res.data)
        setisLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setisLoading(false)
    }
  }

  const getCityAgainstCountry = (countryName) => {
    setcitiesForCountry(
      worldCities.filter((item) => item.country === countryName)
    );
  };

  const getCompCityAgainstCountry = (compCountryName) => {
    setCompCitiesForCountry(
      worldCities.filter((item, key) => {
        if (item.iso2 == compCountryName) {
          return item;
        } else {
          return null;
        }
      })
    );
  };

  const getZipcodesForCity = async (cityName) => {
    let res = await requestData("/admin/view-zipcode", "POST", { cityName });

    if (res && res.status) {
      setzipcodesForCity(res.data);
      return res.data;
    }
  };

  const getZipForCompCity = async (cityName) => {
    let res = await requestData("/admin/view-zipcode", "POST", { cityName });

    if (res && res.status) {
      setzipForCompCity(res.data);
      return res.data;
    }
  };

  const handleImage = async (event, errors) => {
    if (imageReg.test(event.target.files[0].type)) {
      if (event.target.files[0].size <= 2560000) {
        errors[event.target.name] = "";

        if (event.target.name === "image") {
          setuploadedImg(URL.createObjectURL(event.target.files[0]));
          setimg(event.target.files[0]);
        } else if (event.target.name === "logo") {
          setuploadedLogo(URL.createObjectURL(event.target.files[0]));
          setlogo(event.target.files[0]);
        } else if (event.target.name === "insurancePolicy") {
          setupInsurancePolicy(URL.createObjectURL(event.target.files[0]));
          setinsurancePolicy(event.target.files[0]);
        } else if (event.target.name === "compamyRegistrationImage") {
          setuploadedcompRegCert(URL.createObjectURL(event.target.files[0]));
          setcompRegCert(event.target.files[0]);
        }

        const DATA = new FormData();
        DATA.append("image", event.target.files[0]);
        let imgUpRes = await fileUpload("/admin/uploadMerimage", "POST", DATA);
        if (imgUpRes && imgUpRes.status) {
          seteditObject((prev) => ({
            ...prev,
            [event.target.name]: imgUpRes.url,
          }));
        } else {
          toast.error(imgUpRes.message);
        }
      } else {
        errors[event.target.name] = "Image should not exceed more the 2.5MB";
        if (event.target.name === "image") {
          setuploadedImg("");
        } else if (event.target.name === "logo") {
          setuploadedLogo("");
        } else if (event.target.name === "insurancePolicy") {
          setupInsurancePolicy("");
        } else {
          setuploadedcompRegCert("");
        }
      }
    } else {
      errors[event.target.name] = "File is not an image";
      if (event.target.name === "image") {
        setuploadedImg("");
      } else if (event.target.name === "logo") {
        setuploadedLogo("");
      } else if (event.target.name === "insurancePolicy") {
        setupInsurancePolicy("");
      } else {
        setuploadedcompRegCert("");
      }
    }
  };

  const handleDateChange = (date) => {
    seteditObject({
      ...editObject,
      companyDob: getOnlyFullDate(date),
    });
  };

  const handleChange = (event) => {
    if (
      event?.target?.name === "mobile" ||
      event?.target?.name === "zip" ||
      event?.target?.name === "commisionPercentage" ||
      event?.target?.name === "companyMobile" ||
      event?.target?.name === "postalCode"
    ) {
      if (event.target.value < 0) {
        event.target.value = "";
      }
      seteditObject({ ...editObject, [event.target.name]: event.target.value });
    }

    let textInput;
    if (
      event.target.name === "password" ||
      event.target.name === "email" ||
      event.target.name === "payeeType" ||
      event.target.name === "accountType" ||
      event.target.name === "bankCode" ||
      event.target.name === "branchCode" ||
      event.target.name === "bankAcctCurrency" ||
      event.target.name === "marBankAccNo" ||
      event.target.name === "marBankIfscCode" ||
      event.target.name === "swiftCode" ||
      event.target.name === "legalStatus" ||
      event.target.name === "licenseNumber" ||
      event.target.name === "registrationNumber" ||
      event.target.name === "description" ||
      event.target.name === "socialLink"
    ) {
      textInput = event.target.value;
    } else {
      textInput = capitalizeFirstLetter(event);
    }

    seteditObject({
      ...editObject,
      [event?.target?.name]: textInput,
    });
  };

  const scrollToErrorElement = (id) => {
    const errorElement = document.getElementById(id);
    if (errorElement) {
      errorElement.scrollIntoView({
        block: "center",
      });
    }
  };

  const handleValidation = () => {
    const {
      firstName,
      lastName,
      email,
      password,
      langId,
      mobile,
      commisionPercentage,
      image,
      country,
      city,
      zip,
      payeeType,
      accountType,
      marBankAccNo,
      marBankIfscCode,
      marBankName,
      marAccHolderName,
      legalStatus,
      companyName,
      legalCompanyName,
      companyMobile,
      directorName,
      individualName,
      companyDob,
      companyStreetAddress,
      postalCode,
      compCountry,
      compCity,
      licenseNumber,
      registrationNumber,
      socialLink,
      logo,
      insurancePolicy,
      compamyRegistrationImage,
      phonecode,
      phonecodecompany
    } = editObject;

    if (country === "") {
      setSelectedIndex(0);
      scrollToErrorElement("country");
      seterrors({
        ...createErrors,
        country: "Merchant country name is required.",
      });

      return false;
    }

    if (city === "") {
      setSelectedIndex(0);
      scrollToErrorElement("city");

      seterrors({
        ...createErrors,
        city: "Merchant city name is required.",
      });

      return false;
    }

    if (zip === "") {
      setSelectedIndex(0);
      scrollToErrorElement("zip");
      seterrors({
        ...createErrors,
        zip: "Merchant address zip is required.",
      });

      return false;
    }

    if (country === "India" && isNaN(zip)) {
      setSelectedIndex(0);
      scrollToErrorElement("zip");
      seterrors({
        ...createErrors,
        zip: "Please enter only numerics",
      });

      return false;
    }

    if (companyName === "") {
      setSelectedIndex(0);
      scrollToErrorElement("companyName");
      seterrors({
        ...createErrors,
        companyName: "Company name is required.",
      });

      return false;
    }

    if (email === "") {
      setSelectedIndex(0);
      scrollToErrorElement("email");
      seterrors({ ...createErrors, email: "Email is required." });

      return false;
    }
    if (!emailReg.test(email)) {
      setSelectedIndex(0);
      scrollToErrorElement("email");
      seterrors({
        ...createErrors,
        email: "Please enter correct email format.",
      });

      return false;
    }
    if (password?.length < 8) {
      setSelectedIndex(0);
      scrollToErrorElement("password");
      seterrors({
        ...createErrors,
        password: "Password should be of 8 characters.",
      });

      return false;
    }
    if (firstName === "") {
      setSelectedIndex(0);
      scrollToErrorElement("firstName");
      seterrors({
        ...createErrors,
        firstName: "First name is required.",
      });

      return false;
    }

    if (lastName === "") {
      setSelectedIndex(0);
      scrollToErrorElement("lastName");
      seterrors({
        ...createErrors,
        lastName: "Last name is required.",
      });

      return false;
    }
    if (langId === "") {
      setSelectedIndex(0);
      scrollToErrorElement("langId");
      seterrors({
        ...createErrors,
        langId: "Language is required.",
      });

      return false;
    }
    if (!phonecode) {
      setSelectedIndex(0);
      scrollToErrorElement("phonecode");
      seterrors({
        ...createErrors,
        phonecode: "Phone code is required.",
      });

      return false;
    }
    if (mobile === "") {
      setSelectedIndex(0);
      scrollToErrorElement("mobile");

      seterrors({
        ...createErrors,
        mobile: "Merchant mobile number is required.",
      });

      return false;
    }
    if (!mobile?.length > 5) {
      setSelectedIndex(0);
      scrollToErrorElement("mobile");

      seterrors({
        ...createErrors,
        mobile: "Please enter atmost 5 digit mobile number.",
      });

      return false;
    }
    if (isNaN(mobile)) {
      setSelectedIndex(0);
      scrollToErrorElement("mobile");

      seterrors({
        ...createErrors,
        mobile: "Please enter only numerics",
      });

      return false;
    }
    if (!commisionPercentage) {
      setSelectedIndex(0);
      scrollToErrorElement("commisionPercentage");

      seterrors({
        ...createErrors,
        commisionPercentage: "Commission percentage is required.",
      });

      return false;
    }
    if (isNaN(commisionPercentage)) {
      setSelectedIndex(0);
      scrollToErrorElement("commisionPercentage");

      seterrors({
        ...createErrors,
        commisionPercentage: "Please enter only numerics",
      });

      return false;
    }
    if (!payeeType) {
      setSelectedIndex(1);
      scrollToErrorElement("payeeType");

      seterrors({ ...createErrors, payeeType: "Payee type is required." });

      return false;
    }
    if (!accountType) {
      setSelectedIndex(1);
      scrollToErrorElement("account_type_1");

      seterrors({ ...createErrors, accountType: "Account type is required." });

      return false;
    }
    if (!marBankName) {
      setSelectedIndex(1);
      scrollToErrorElement("marBankName");

      seterrors({
        ...createErrors,
        marBankName: "Merchant bank name is required.",
      });

      return false;
    }
    if (!marAccHolderName) {
      setSelectedIndex(1);
      scrollToErrorElement("marAccHolderName");

      seterrors({
        ...createErrors,
        marAccHolderName: "Merchant bank account holder name is required.",
      });

      return false;
    }
    if (!marBankAccNo) {
      setSelectedIndex(1);
      scrollToErrorElement("marBankAccNo");

      seterrors({
        ...createErrors,
        marBankAccNo: "Merchant bank account number is required.",
      });

      return false;
    }
    if (legalStatus === "") {
      setSelectedIndex(2);
      scrollToErrorElement("legalStatus");

      seterrors({
        ...createErrors,
        legalStatus: "Company legal status is required.",
      });

      return false;
    }
    if (legalCompanyName === "") {
      setSelectedIndex(2);
      scrollToErrorElement("legalCompanyName");

      seterrors({
        ...createErrors,
        legalCompanyName: "Company legal name is required.",
      });

      return false;
    }
    if (compCountry === "") {
      setSelectedIndex(2);
      scrollToErrorElement("compCountry");
      seterrors({
        ...createErrors,
        compCountry: "Company Country name is required.",
      });

      return false;
    }
    if (compCity === "") {
      setSelectedIndex(2);
      scrollToErrorElement("compCity");
      seterrors({
        ...createErrors,
        compCity: "Company city name is required.",
      });

      return false;
    }
    if (!companyStreetAddress) {
      setSelectedIndex(2);
      scrollToErrorElement("companyStreetAddress");

      seterrors({
        ...createErrors,
        companyStreetAddress: "Company street address is required.",
      });

      return false;
    }
    if (postalCode === "") {
      setSelectedIndex(2);
      scrollToErrorElement("postalCode");
      seterrors({
        ...createErrors,
        postalCode: "Company address zip is required.",
      });

      return false;
    }
    if (compCountry == "India" && isNaN(postalCode)) {
      setSelectedIndex(2);
      scrollToErrorElement("postalCode");

      seterrors({
        ...createErrors,
        postalCode: "Please enter only numerics",
      });

      return false;
    }
    if (!phonecodecompany || phonecodecompany === "+0") {
      setSelectedIndex(2);
      scrollToErrorElement("phonecodecompany");
      seterrors({
        ...createErrors,
        phonecodecompany: "Company Phone Code is required.",
      });

      return false;
    }

    if (companyMobile === "") {
      setSelectedIndex(2);
      scrollToErrorElement("companyMobile");
      seterrors({
        ...createErrors,
        companyMobile: "Contact person mobile number is required.",
      });

      return false;
    }
    if (!companyMobile?.length > 5) {
      setSelectedIndex(2);
      scrollToErrorElement("companyMobile");
      seterrors({
        ...createErrors,
        companyMobile: "Please enter atmost 5 digit mobile number.",
      });

      return false;
    }
    if (isNaN(companyMobile)) {
      setSelectedIndex(2);
      scrollToErrorElement("companyMobile");
      seterrors({
        ...createErrors,
        companyMobile: "Please enter only numerics",
      });

      return false;
    }
    if (licenseNumber === "") {
      setSelectedIndex(2);
      scrollToErrorElement("licenseNumber");

      seterrors({
        ...createErrors,
        licenseNumber: "Company license is required.",
      });

      return false;
    }
    if (registrationNumber === "") {
      setSelectedIndex(2);
      scrollToErrorElement("registrationNumber");

      seterrors({
        ...createErrors,
        registrationNumber: "Company registration is required.",
      });

      return false;
    }
    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      let bodyData = {
        ...editObject,
        id: `${params.id}`,
        password: editPassword,
        phonecode: phonecode,
        phonecodecompany: phonecodecompany,
        saveAsDraft: false,
      };
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditMerchant"](bodyData);
        let cityZipCheck = await getZipcodesForCity(editObject.city);
        if (
          cityZipCheck?.filter((item) => item?.zipCode === editObject.zip)
            ?.length === 0
        ) {
          let res2 = await JSON_API["addCityZipCode"]({
            cityName: editObject.city,
            countryName: editObject.country,
            zipCode: editObject.zip,
          });
        }
        let compCityCheck = await getZipForCompCity(editObject.compCity);
        if (
          compCityCheck?.filter(
            (item) => item?.zipCode === editObject.postalCode
          )?.length === 0
        ) {
          let res3 = await JSON_API["addCityZipCode"]({
            cityName: editObject.compCity,
            countryName: editObject.compCountry,
            zipCode: editObject.postalCode,
          });
        }

        if (res.isSuccess) {
          dispatch(getAllMerchants());
          if (imgInputRef.current) {
            imgInputRef.current.value = "";
          }
          setuploadedImg("");
          setuploadedLogo("");
          setupInsurancePolicy("");
          setuploadedcompRegCert("");
          seteditObject(initialValues);
          seterrors(createErrors);
          setModal(false);
          setisLoading(false);
          toast.success(res.data.message);
          navigate("/manage-merchant-drafts")
        } else {
          setisLoading(false);
          toast.error(res?.error?.message)
        }
      } catch (error) {
        setisLoading(false);
        toast.error(error.message);
      }
    }
  };

  const handleDraftSave = async (event) => {
    event.preventDefault();

    const { email } = editObject;

    if (email === "") {
      setSelectedIndex(0);
      scrollToErrorElement("email");
      seterrors({ ...createErrors, email: "Email is required." });
      return;
    } else if (!emailReg.test(email)) {
      setSelectedIndex(0);
      scrollToErrorElement("email");
      seterrors({
        ...createErrors,
        email: "Please enter correct email format.",
      });
      return;
    } else {
      let bodyData = {
        ...editObject,
        id: `${params.id}`,
        password: editPassword,
        phonecode: phonecode,
        phonecodecompany: phonecodecompany,
        saveAsDraft: true,
        countryId:
          editObject.countryId === "" ? undefined : editObject.countryId,
        cityId: editObject.cityId === "" ? undefined : editObject.cityId,
      };

      if (editObject.langId === "") {
        delete bodyData.langId;
      }
      if (editObject.legalStatus === "") {
        delete bodyData.legalStatus;
      }
      setisLoading(true);
      let res = await JSON_API["adminEditMerchant"](bodyData);

      if (res.isSuccess) {
        setisLoading(false);
        seterrors(createErrors);
        toast.success("Saved As Draft Successfully!");
        navigate("/manage-merchant-drafts");
      } else {
        setisLoading(false);
      }
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">
                Complete Merchant Draft : {formProgress}%
              </div>
              <div className="row">
                <div className="col-md-12">
                  <progress
                    id="progress-bar"
                    value={formProgress}
                    max={100}
                    style={{ width: "100%" }}
                  >
                    {formProgress}%
                  </progress>
                </div>
              </div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-12">
                  <Tabs
                    selectedIndex={selectedIndex}
                    onSelect={(index) => setSelectedIndex(index)}
                  >
                    <TabList>
                      <Tab>Merchant Details</Tab>
                      <Tab>Bank Details</Tab>
                      <Tab>Company Details</Tab>
                    </TabList>
                    <TabPanel>
                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="country">
                              Country  <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <Select
                              isSearchable={true}
                              name="country"
                              id="country"
                              classNamePrefix="select"
                              options={countries?.map((item, idx) => ({
                                value: item._id,
                                label: item?.name,
                              }))}
                              value={{
                                value: editObject?.countryId,
                                label: editObject?.country,
                              }}
                              onChange={(val) => {
                                calcProgress();
                                getCityAgainstCountry(val?.value);
                                fetchCities(val.value);
                                seteditObject((prev) => {
                                  return {
                                    ...prev,
                                    country: val?.label,
                                    countryId: val.value,
                                    city: "",
                                    cityId: "",
                                    zip: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.country && (
                            <span className="errorMessageText text-danger">
                              {errors.country}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="city" className="form-label">
                              City  <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>

                            <Select
                              name="city"
                              id="city"
                              classNamePrefix="select"
                              options={cities?.map((item, idx) => ({
                                value: item?._id,
                                label: item?.cityName,
                              }))}
                              value={{
                                value: editObject?.cityId,
                                label: editObject?.city,
                              }}
                              onChange={(val) => {
                                calcProgress();
                                getZipcodesForCity(val?.value);
                                seteditObject((prev) => {
                                  return {
                                    ...prev,
                                    city: val?.label,
                                    cityId: val.value,
                                    zip: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.city && (
                            <span className="errorMessageText text-danger">
                              {errors.city}
                            </span>
                          )}
                        </div>
                        <div className="col-md-2">
                          <div className="mb-3">
                            <label htmlFor="zip" className="form-label">
                              Zip/Postcode<span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="zip"
                              id="zip"
                              type="text"
                              className="form-control"
                              value={editObject.zip}
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              onClick={() => setzipDropdownOne(true)}
                              required={true}
                            />

                            {!zipDropdownOne && (
                              <Select
                                name="zip"
                                id="zip_select"
                                className="d-none"
                                classNamePrefix="select"
                                options={zipcodesForCity?.map((item, idx) => ({
                                  value: item?.zipCode,
                                  label: item?.zipCode,
                                }))}
                                value={{
                                  value: editObject?.zip,
                                  label: editObject?.zip,
                                }}
                                onChange={(val) => {
                                  calcProgress();
                                  seteditObject((prev) => {
                                    return {
                                      ...prev,
                                      zip: val?.value,
                                    };
                                  });
                                }}
                              />
                            )}
                          </div>
                          {errors.zip && (
                            <span className="errorMessageText text-danger">
                              {errors.zip}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="companyName">
                              Company Name <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="companyName"
                              className="form-control"
                              id="companyName"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              value={editObject.companyName}
                              required
                            />
                          </div>
                          {errors.companyName && (
                            <span className="errorMessageText text-danger">
                              {errors.companyName}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Email <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="email"
                              id="email"
                              placeholder="E.g.,waltersmith@gmail.com"
                              type="email"
                              className="form-control"
                              value={editObject.email}
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              required={true}
                            />
                          </div>
                          {errors.email && (
                            <span className="errorMessageText text-danger">
                              {errors.email}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                              Password <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="password"
                              id="password"
                              type="zpassword"
                              className="form-control"
                              value={editPassword}
                              onChange={(e) => {
                                calcProgress();
                                seteditPassword(e.target.value);
                              }}
                              required={true}
                            />
                          </div>
                          {errors.password && (
                            <span className="errorMessageText text-danger">
                              {errors.password}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">
                              First Name  <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="firstName"
                              id="firstName"
                              placeholder="Your first name, e.g. Walter"
                              type="text"
                              className="form-control"
                              value={editObject.firstName}
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              required={true}
                            />
                          </div>
                          {errors.firstName && (
                            <span className="errorMessageText text-danger">
                              {errors.firstName}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="lastName" className="form-label">
                              Last Name  <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="lastName"
                              id="lastName"
                              placeholder="Your first name, e.g. Smith"
                              type="text"
                              className="form-control"
                              value={editObject.lastName}
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              required={true}
                            />
                          </div>
                          {errors.lastName && (
                            <span className="errorMessageText text-danger">
                              {errors.lastName}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="langId" className="form-label">
                              Language  <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <select
                              name="langId"
                              id="langId"
                              className="form-control form-select"
                              value={editObject.langId}
                              onChange={(e) => handleChange(e)}
                              required={true}
                            >
                              <option value="" key={0}>
                                Select
                              </option>
                              {languages.map((item, idx) => {
                                return (
                                  <>
                                    <option
                                      key={idx + 1}
                                      value={`${item?._id}`}
                                    >
                                      {item?.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.langId && (
                            <span className="errorMessageText text-danger">
                              {errors.langId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="mobile" className="form-label">
                              Mobile  <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <div className="form-control">
                              <PhoneInput
                                value={editObject.mobile}
                                onChange={(e) => {
                                  seteditObject((prev) => ({
                                    ...prev,
                                    mobile: e,
                                  }));
                                }}
                                countryCode={
                                  editObject.phonecode
                                    ? editObject.phonecode
                                    : "+0"
                                }
                                onCountryChange={(val) =>
                                  seteditObject((prev) => ({
                                    ...prev,
                                    phonecode: val,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          {errors.mobile && (
                            <span className="errorMessageText text-danger">
                              {errors.mobile}
                            </span>
                          )}
                        </div>

                        <div className="col-md-3">
                          <div className="mb-3 d-flex flex-col" style={{ gap: "1rem" }}>
                            <label
                              htmlFor="isthirdperty"
                              className="form-label"
                              style={{
                                marginLeft: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              Have joined any third party platforms ?{" "}
                              {/* <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span> */}
                            </label>
                            <div className="d-flex ml-2">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isthirdperty"
                                  id="payee_type_1"
                                  onChange={() =>
                                    seteditObject((prev) => ({
                                      ...prev,
                                      isthirdperty: true,
                                    }))
                                  }
                                  value={true}
                                  checked={editObject?.isthirdperty === true}
                                />
                                <label className="form-check-label" htmlFor="payee_type_1">
                                  Yes
                                </label>
                              </div>

                              <div className="form-check ml-8">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isthirdperty"
                                  id="payee_type_2"
                                  onChange={() =>
                                    seteditObject((prev) => ({
                                      ...prev,
                                      isthirdperty: false,
                                      thirdpertyId: undefined,
                                      AccesKey: "",
                                      secretKey: ""
                                    }))
                                  }
                                  value={false}
                                  checked={editObject?.isthirdperty === false}
                                />
                                <label className="form-check-label" htmlFor="payee_type_2">
                                  No
                                </label>
                              </div>
                            </div>

                          </div>
                          {errors.langId && (
                            <span className="errorMessageText text-danger">
                              {errors.langId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3" style={{ display: editObject?.isthirdperty === true ? "block" : "none" }}>
                          <div className="mb-3">
                            <label htmlFor="langId" className="form-label">
                              Third Party Platforms{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <select
                              name="thirdpertyId"
                              id="thirdpertyId"
                              className="form-control form-select"
                              value={editObject.thirdpertyId}
                              onChange={(e) => handleChange(e)}
                              required={true}
                            >
                              <option
                                value=""
                                disabled={true}
                                selected={true}
                                key={0}
                              >
                                Select
                              </option>
                              {thirdPartyData?.map((item, idx) => {
                                return (
                                  <>
                                    <option
                                      key={idx + 1}
                                      value={`${item?._id}`}
                                    >
                                      {item?.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.thirdpertyId && (
                            <span className="errorMessageText text-danger">
                              {errors.thirdpertyId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3" style={{ display: editObject.isthirdperty && editObject.thirdpertyId ? "block" : "none" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="AccesKey"
                              className="form-label"
                            >
                              Access key{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="AccesKey"
                              id="AccesKey"
                              type="text"
                              value={editObject.AccesKey}
                              className="form-control"
                              onChange={(e) => {
                                seteditObject((prev) => ({
                                  ...prev,
                                  AccesKey: e.target.value
                                }))
                              }}
                              required={true}
                            />
                            {errors.AccesKey && (
                              <span className="errorMessageText text-danger">
                                {errors.AccesKey}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3" style={{ display: editObject.isthirdperty && editObject.thirdpertyId ? "block" : "none" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="secretKey"
                              className="form-label"
                            >
                              Secret Key{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="secretKey"
                              id="secretKey"
                              type="text"
                              value={editObject.secretKey}
                              className="form-control"
                              onChange={(e) => {
                                seteditObject((prev) => ({
                                  ...prev,
                                  secretKey: e.target.value
                                }))
                              }}
                              required={true}
                            />
                            {errors.secretKey && (
                              <span className="errorMessageText text-danger">
                                {errors.secretKey}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="commisionPercentage"
                              className="form-label"
                            >
                              Commission Percentage <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="commisionPercentage"
                              id="commisionPercentage"
                              type="number"
                              value={editObject?.commisionPercentage}
                              className="form-control"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              required={true}
                            />
                            {errors.commisionPercentage && (
                              <span className="errorMessageText text-danger">
                                {errors.commisionPercentage}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 d-none">
                          <div className="mb-3">
                            <label htmlFor="image" className="form-label">
                              Image
                            </label>
                            <input
                              name="image"
                              id="image"
                              type="file"
                              ref={imgInputRef}
                              className="form-control"
                              onChange={(e) => {
                                calcProgress();
                                handleImage(e, errors);
                              }}
                              required={true}
                              accept="image/*"
                            />
                            {uploadedImg?.length > 0 ? (
                              <img
                                style={{ height: "100px", width: "100px" }}
                                src={uploadedImg}
                              />
                            ) : img?.length > 0 ? (
                              <img
                                style={{ height: "100px", width: "100px" }}
                                src={img}
                              />
                            ) : (
                              <></>
                            )}
                            {errors.image && (
                              <span className="errorMessageText text-danger">
                                {errors.image}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-12">
                          <div className="mb-3 d-flex" style={{ gap: "1rem" }}>
                            <label
                              htmlFor="payeeType"
                              className="form-label"
                              style={{
                                marginLeft: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              Payee Type <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="payeeType"
                                id="payee_type_1"
                                onChange={(e) => {
                                  calcProgress();
                                  handleChange(e);
                                }}
                                value="person"
                                checked={editObject.payeeType === "person"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="payee_type_1"
                              >
                                Person
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="payeeType"
                                id="payee_type_2"
                                onChange={(e) => {
                                  calcProgress();
                                  handleChange(e);
                                }}
                                value="organization"
                                checked={
                                  editObject.payeeType === "organization"
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="payee_type_2"
                              >
                                Organization
                              </label>
                            </div>
                          </div>
                          {errors.payeeType && (
                            <span className="errorMessageText text-danger">
                              {errors.payeeType}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3 d-flex" style={{ gap: "1rem" }}>
                            <label
                              htmlFor="accountType"
                              className="form-label"
                              style={{
                                marginLeft: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              Account Type <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="accountType"
                                id={`account_type_1`}
                                onChange={(e) => {
                                  calcProgress();
                                  handleChange(e);
                                }}
                                value="current"
                                checked={editObject.accountType === "current"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`account_type_1`}
                              >
                                Current
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="accountType"
                                id="account_type_2"
                                onChange={(e) => handleChange(e)}
                                value="savings"
                                checked={editObject.accountType === "savings"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="account_type_2"
                              >
                                Savings
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="accountType"
                                id="account_type_3"
                                onChange={(e) => handleChange(e)}
                                value="others"
                                checked={editObject.accountType === "others"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="account_type_3"
                              >
                                Others
                              </label>
                            </div>
                          </div>
                          {errors.accountType && (
                            <span className="errorMessageText text-danger">
                              {errors.accountType}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="marBankName" className="form-label">
                              Bank Name: (E.g HSBC) <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="marBankName"
                              id="marBankName"
                              type="text"
                              value={editObject.marBankName}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                              required={true}
                            />
                            {errors.marBankName && (
                              <span className="errorMessageText text-danger">
                                {errors.marBankName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="bankCode" className="form-label">
                              IBAN ( E.g. FR76 3000 4028 3798 7654 3210 943)
                            </label>
                            <input
                              name="bankCode"
                              id="bankCode"
                              type="text"
                              value={editObject.bankCode}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="branchName" className="form-label">
                              Branch name and address: (HSBC, Seapoint, 15 Ocean
                              Drive, 8574 )
                            </label>
                            <input
                              name="branchName"
                              id="branchName"
                              type="text"
                              value={editObject.branchName}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="branchCode" className="form-label">
                              Branch Code
                            </label>
                            <input
                              name="branchCode"
                              id="branchCode"
                              type="text"
                              value={editObject.branchCode}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="marAccHolderName"
                              className="form-label"
                            >
                              Account holder name/Recipient name <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="marAccHolderName"
                              id="marAccHolderName"
                              type="text"
                              value={editObject.marAccHolderName}
                              className="form-control"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              required={true}
                            />
                            {errors.marAccHolderName && (
                              <span className="errorMessageText text-danger">
                                {errors.marAccHolderName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="marBankAccNo"
                              className="form-label"
                            >
                              Bank Account Number <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="marBankAccNo"
                              id="marBankAccNo"
                              type="number"
                              value={editObject.marBankAccNo}
                              className="form-control"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              required={true}
                            />
                          </div>
                          {errors.marBankAccNo && (
                            <span className="errorMessageText text-danger">
                              {errors.marBankAccNo}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="marBankIfscCode"
                              className="form-label"
                            >
                              Bank Branch Address
                            </label>
                            <input
                              name="marBankIfscCode"
                              id="marBankIfscCode"
                              type="text"
                              value={editObject.marBankIfscCode}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          {errors.marBankIfscCode && (
                            <span className="errorMessageText text-danger">
                              {errors.marBankIfscCode}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="bankAcctCurrency"
                              className="form-label"
                            >
                              Bank Account Currency
                            </label>
                            <select
                              name="bankAcctCurrency"
                              id="bankAcctCurrency"
                              className="form-control form-select"
                              value={editObject.bankAcctCurrency}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="" key={0}>
                                Select
                              </option>
                              {currencies?.map((item, idx) => {
                                return (
                                  <>
                                    <option
                                      key={idx + 1}
                                      value={item?.name}
                                    >
                                      {item?.name + " (" + item?.code + ")"}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="swiftCode" className="form-label">
                              Swift Code/BIC
                            </label>
                            <input
                              name="swiftCode"
                              id="swiftCode"
                              type="text"
                              value={editObject.swiftCode}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            className="font-weight-bold"
                            htmlFor="legalStatus"
                          >
                            Legal Status <span style={{ fontSize: "20px", color: "red" }}>
                              *
                            </span>
                          </label>
                          <select
                            className="form-select"
                            id="legalStatus"
                            name="legalStatus"
                            onChange={(e) => {
                              calcProgress();
                              handleChange(e);
                            }}
                            value={editObject.legalStatus}
                          >
                            <option value="" disabled={true} selected={true}>
                              Select legal status
                            </option>
                            <option value="registered_company">
                              Registered Company
                            </option>
                            <option value="individual_traders">
                              Individual Trader
                            </option>
                          </select>
                        </div>
                        {errors.legalStatus && (
                          <span className="errorMessageText text-danger">
                            {errors.legalStatus}
                          </span>
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            className="font-weight-bold"
                            htmlFor="legalCompanyName"
                          >
                            Legal Company Name <span style={{ fontSize: "20px", color: "red" }}>
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            name="legalCompanyName"
                            className="form-control"
                            id="legalCompanyName"
                            aria-describedby="emailHelp"
                            autoComplete="off"
                            onChange={(e) => {
                              calcProgress();
                              handleChange(e);
                            }}
                            value={editObject.legalCompanyName}
                            required
                          />
                        </div>
                        {errors.legalCompanyName && (
                          <span className="errorMessageText text-danger">
                            {errors.legalCompanyName}
                          </span>
                        )}
                      </div>
                      {editObject.legalStatus === "registered_company" ? (
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="directorName"
                            >
                              Director Name
                            </label>
                            <input
                              type="text"
                              name="directorName"
                              className="form-control"
                              id="directorName"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                              value={editObject.directorName}
                              required
                            />
                          </div>
                          {errors.directorName && (
                            <span className="errorMessageText text-danger">
                              {errors.directorName}
                            </span>
                          )}
                        </div>
                      ) : editObject.legalStatus === "individual_traders" ? (
                        <div className="row" style={{ padding: "1rem" }}>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="font-weight-bold"
                                htmlFor="individualName"
                              >
                                Trading as : (E.g Billy's Tours)
                              </label>
                              <input
                                type="text"
                                name="individualName"
                                className="form-control"
                                id="individualName"
                                aria-describedby="emailHelp"
                                autoComplete="off"
                                onChange={(e) => handleChange(e)}
                                value={editObject.individualName}
                                required
                              />
                            </div>
                            {errors.individualName && (
                              <span className="errorMessageText text-danger">
                                {errors.individualName}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="font-weight-bold"
                                htmlFor="companyDob"
                              >
                                Trading since
                              </label>
                              <DatePicker
                                calendarIcon={null}
                                className="form-control"
                                clearIcon={null}
                                value={editObject.companyDob}
                                format="dd/MM/y"
                                shouldCloseCalendar={({ reason }) =>
                                  reason === "select" ||
                                  reason === "outsideAction"
                                }
                                onChange={handleDateChange}
                              />
                            </div>
                            {errors.companyDob && (
                              <span className="errorMessageText text-danger">
                                {errors.companyDob}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="compCountry"
                            >
                              Country of registration: <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <Select
                              name="compCountry"
                              id="compCountry"
                              classNamePrefix="select"
                              options={companyCityCountry.countries?.map(
                                (item, idx) => ({
                                  value: item?.name,
                                  label: item?.name,
                                })
                              )}
                              value={{
                                value: editObject?.compCountry,
                                label: editObject?.compCountry,
                              }}
                              onChange={(val) => {
                                calcProgress();
                                companyCityCountry.fetchCitiesByCountryName(val?.value)
                                // getCompCityAgainstCountry(val?.value);
                                seteditObject((prev) => {
                                  return {
                                    ...prev,
                                    compCountryCode: val?.value,
                                    compCountry: val?.label,
                                    compState: "",
                                    compCity: "",
                                    postalCode: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.compCountry && (
                            <span className="errorMessageText text-danger">
                              {errors.compCountry}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="compCity"
                            >
                              Company City <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <Select
                              name="compCity"
                              id="compCity"
                              classNamePrefix="select"
                              options={companyCityCountry.cities?.map(
                                (item, idx) => ({
                                  value: item?.cityName,
                                  label: item?.cityName,
                                })
                              )}
                              value={{
                                value: editObject?.compCity,
                                label: editObject?.compCity,
                              }}
                              onChange={(val) => {
                                calcProgress();
                                getZipForCompCity(val?.value);
                                seteditObject((prev) => {
                                  return {
                                    ...prev,
                                    compCity: val?.value,
                                    postalCode: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.compCity && (
                            <span className="errorMessageText text-danger">
                              {errors.compCity}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="companyStreetAddress"
                            >
                              Company Street Address <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="companyStreetAddress"
                              className="form-control"
                              id="companyStreetAddress"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                              value={editObject.companyStreetAddress}
                              required
                            />
                          </div>
                          {errors.companyStreetAddress && (
                            <span className="errorMessageText text-danger">
                              {errors.companyStreetAddress}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="postalCode"
                            >
                              Company Zip/Postcode <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="postalCode"
                              className="form-control"
                              id="postalCode"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              min={0}
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              onClick={() => setzipDropdownTwo(true)}
                              value={editObject.postalCode}
                              required
                            />

                            {zipDropdownTwo && (
                              <Select
                                name="postalCode"
                                id="comp_zip_select"
                                className="d-none"
                                classNamePrefix="select"
                                options={zipForCompCity?.map((item, idx) => ({
                                  value: item?.zipCode,
                                  label: item?.zipCode,
                                }))}
                                value={{
                                  value: editObject?.postalCode,
                                  label: editObject?.postalCode,
                                }}
                                onChange={(val) => {
                                  seteditObject((prev) => {
                                    return {
                                      ...prev,
                                      postalCode: val?.value,
                                    };
                                  });
                                }}
                              />
                            )}
                          </div>
                          {errors.postalCode && (
                            <span className="errorMessageText text-danger">
                              {errors.postalCode}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="companyMobile"
                            >
                              Company Mobile Number <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <div className="d-flex">
                              <div className="form-control">
                                <PhoneInput
                                  value={editObject.companyMobile}
                                  onChange={(e) => {
                                    seteditObject((prev) => ({
                                      ...prev,
                                      companyMobile: e,
                                    }));
                                  }}
                                  countryCode={
                                    editObject.phonecodecompany
                                      ? editObject.phonecodecompany
                                      : "+0"
                                  }
                                  onCountryChange={(val) =>
                                    seteditObject((prev) => ({
                                      ...prev,
                                      phonecodecompany: val,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {errors.phonecodecompany && (
                            <span className="errorMessageText text-danger">
                              {errors.phonecodecompany}
                            </span>
                          )}
                          {errors.companyMobile && (
                            <span className="errorMessageText text-danger">
                              {errors.companyMobile}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="licenseNumber"
                            >
                              License number/Official authorisation number <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="licenseNumber"
                              className="form-control"
                              id="licenseNumber"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              value={editObject.licenseNumber}
                              required
                            />
                          </div>
                          {errors.licenseNumber && (
                            <span className="errorMessageText text-danger">
                              {errors.licenseNumber}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="registrationNumber"
                            >
                              Company registration number <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="registrationNumber"
                              className="form-control"
                              id="registrationNumber"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              value={editObject.registrationNumber}
                              required
                            />
                          </div>
                          {errors.registrationNumber && (
                            <span className="errorMessageText text-danger">
                              {errors.registrationNumber}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="description"
                            >
                              Company profile (Short description of your
                              companies provided activities)
                            </label>
                            <textarea
                              name="description"
                              className="form-control"
                              id="description"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                              value={editObject.description}
                              required
                            />
                          </div>
                          {errors.description && (
                            <span className="errorMessageText text-danger">
                              {errors.description}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="socialLink"
                            >
                              Social Link
                            </label>
                            <input
                              type="text"
                              name="socialLink"
                              className="form-control"
                              id="socialLink"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                              value={editObject.socialLink}
                              required
                            />
                          </div>
                          {errors.socialLink && (
                            <span className="errorMessageText text-danger">
                              {errors.socialLink}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="font-weight-bold" htmlFor="logo">
                            Company Logo
                          </label>
                          <input
                            name="logo"
                            id="logo"
                            type="file"
                            ref={imgInputRef}
                            className="form-control"
                            onChange={(e) => {
                              handleImage(e, errors);
                            }}
                            required
                            accept="image/*"
                          />
                          {uploadedLogo?.length > 0 ? (
                            <img
                              style={{ height: "100px", width: "100px" }}
                              src={uploadedLogo}
                            />
                          ) : logo?.length > 0 ? (
                            <img
                              style={{ height: "100px", width: "100px" }}
                              src={logo}
                            />
                          ) : (
                            <></>
                          )}
                          {errors.logo && (
                            <span className="errorMessageText text-danger">
                              {errors.logo}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="insurancePolicy"
                            >
                              Insurance Policy
                            </label>
                            <input
                              type="file"
                              name="insurancePolicy"
                              id="insurancePolicy"
                              ref={imgInputRef}
                              className="form-control"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                handleImage(e, errors);
                              }}
                              required
                              accept="image/*"
                            />
                          </div>
                          {upInsurancePolicy?.length > 0 ? (
                            <img
                              style={{ height: "100px", width: "100px" }}
                              src={upInsurancePolicy}
                            />
                          ) : insurancePolicyImg?.length > 0 ? (
                            <img
                              style={{ height: "100px", width: "100px" }}
                              src={insurancePolicyImg}
                            />
                          ) : (
                            <></>
                          )}
                          {errors.insurancePolicy && (
                            <span className="errorMessageText text-danger">
                              {errors.insurancePolicy}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="font-weight-bold"
                            htmlFor="compamyRegistrationImage"
                          >
                            Company Registration Certificate
                          </label>
                          <input
                            name="compamyRegistrationImage"
                            id="compamyRegistrationImage"
                            type="file"
                            ref={imgInputRef}
                            className="form-control"
                            onChange={(e) => {
                              handleImage(e, errors);
                            }}
                            required
                            accept="image/*"
                          />
                          {uploadedcompRegCert?.length > 0 ? (
                            <img
                              style={{ height: "100px", width: "100px" }}
                              src={uploadedcompRegCert}
                            />
                          ) : compRegCert?.length > 0 ? (
                            <img
                              style={{ height: "100px", width: "100px" }}
                              src={compRegCert}
                            />
                          ) : (
                            <></>
                          )}
                          {errors.compamyRegistrationImage && (
                            <span className="errorMessageText text-danger">
                              {errors.compamyRegistrationImage}
                            </span>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <button
                      type="button"
                      className="mt-2 btn btn-secondary d-table"
                      onClick={(e) => handleDraftSave(e)}
                    >
                      Save As Draft
                    </button>
                    <button
                      type="button"
                      className="mt-2 btn btn-link ml-auto d-table"
                      onClick={() => {
                        navigate("/manage-merchant-drafts");
                        setModal(false);
                        setuploadedImg("");
                        setuploadedLogo("");
                        setuploadedcompRegCert("");
                        seteditObject(initialValues);
                        seterrors(createErrors);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="mt-2 btn btn-primary d-table"
                      onClick={(e) => handleEditSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
