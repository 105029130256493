import { useState, useEffect } from "react";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import "react-calendar/dist/Calendar.css";
import "react-datetime/css/react-datetime.css";
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";
import { useDispatch, useSelector } from "react-redux";
import { requestData } from "../../../services/api/apiHelpers";
import { toast } from "react-toastify";

let initialValues = {
  specialOfferName: "",
  duration_value: 0,
  duration_unit: "",
  activityDetailsId: [],
  offerType: "",
  discountPercentage: 0,
  description: "",
  offerStartDate: "",
};
const ManageMerchantOffers = () => {
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [banner, setbanner] = useState(
    JSON.parse(JSON.stringify(initialValues))
  );
  const [approvestatus, setApproveStatus] = useState(false)
  const [rejectStatus, setRejectStatus] = useState(false)
  const { activities } = useSelector((state) => state.adminActivityActionSlice);
  const duration_units = ["hours", "days"];

  console.log("ACTIVIIES", activities);
  // console.log(activities?.find((item) => item.activityTitle === "AVISHEK"));
  const fetchOffers = async () => {
    try {
      const res = await requestData(
        "/admin/viewMerchantApprovalSpecialOffer",
        "GET"
      );
      console.log("OFFERS", res);
      if (res && res.status) {
        console.log(res?.data);
        setOffers(res?.data);
      } else {
        console.log("ERROR fetching offers", res);
      }
    } catch (error) {
      console.log("ERROR fetching offers", error);
    }
  };

  useEffect(() => {
    dispatch(getAllActivities());
    fetchOffers();
  }, [dispatch]);

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Offer Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Discount Percentage",
        field: "discount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Approval Status",
        field: "approval_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      offers?.length > 0
        ? offers.map((item, idx) => {
          return {
            sl: idx + 1,
            name: (
              <div className="widget-content p-0" key={idx}>
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="widget-heading"
                      style={{ opacity: 0.75, textAlign: "center" }}
                    >
                      {item?.specialOfferName}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            discount: (
              <div className="widget-content p-0" key={idx}>
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="widget-heading"
                      style={{ opacity: 0.75, textAlign: "center" }}
                    >
                      {item?.discountPercentage + "%"}
                    </div>
                    {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            approval_status: (
              <div className="widget-content p-0" key={idx}>
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="widget-heading"
                      style={{ textAlign: "center" }}
                    >
                      {!item?.isApproval && !item?.rejectedStatus ? (
                        <div className="btn btn-danger">Not Approved</div>
                      ) : !item?.isApproval && item?.rejectedStatus ? (
                        <div className="btn btn-danger">Rejected</div>
                      ) : (
                        <div className="btn btn-success">Approved</div>
                      )}
                    </div>
                    {/* <div className="widget-subheading opaactivity-7">UI Designer</div> */}
                  </div>
                </div>
              </div>
            ),
            action:
              !item?.isApproval && !item?.rejectedStatus ? (
                <div key={idx}>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      setModal(true);
                      setmodalToggleView(0);
                      setApproveStatus(item?.isApproval)
                      setRejectStatus(item?.rejectedStatus)
                      setbanner({
                        specialOfferId: item?._id,
                        id: item?._id,
                        specialOfferName: item?.specialOfferName,
                        duration_value: item?.duration?.value,
                        duration_unit: item?.duration?.unit,
                        activityDetailsId: item?.activityDetailsId,
                        offerType: item?.offerType,
                        discountPercentage: item?.discountPercentage,
                        description: item?.description,
                        offerStartDate: item?.startDate,
                        remarks: "",
                      });
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ) : (
                <div key={idx}>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      setModal(true);
                      setmodalToggleView(0);
                      setApproveStatus(item?.isApproval)
                      setRejectStatus(item?.rejectedStatus)
                      setbanner({
                        specialOfferId: item?._id,
                        id: item?._id,
                        specialOfferName: item?.specialOfferName,
                        duration_value: item?.duration?.value,
                        duration_unit: item?.duration?.unit,
                        activityDetailsId: item?.activityDetailsId,
                        offerType: item?.offerType,
                        discountPercentage: item?.discountPercentage,
                        description: item?.description,
                        offerStartDate: item?.startDate,
                        remarks: "",
                      });
                    }}
                  >
                    <i class="fa-regular fa-eye" style={{ color: 'blue' }}></i>
                  </button>
                </div>
              ),
          };
        })
        : [],
  };

  const rejectOffer = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    if (banner?.remarks === "") return toast.error("Remarks is required");
    const data = {
      ...banner,
      updatedStatus: true,
      status: true,
      rejectedStatus: true,
      isApproval: false,
      visibleStatus: false,
    };
    console.log(data);
    try {
      setIsSubmitting(true);
      const res = await requestData(
        `/admin/specialOffer-approval-status/${banner?.id}`,
        "PUT",
        data
      );
      if (res && res?.status) {
        fetchOffers();
        toast.success("New offer rejected.");
        setModal(false);
      } else {
        toast.error(res?.message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
      console.log("Special offer error", error);
    }
    setIsSubmitting(false);
  };

  const approveOffer = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    if (banner?.remarks === "") return toast.error("Remarks is required");
    const data = {
      ...banner,
      updatedStatus: true,
      status: true,
      rejectedStatus: false,
      isApproval: true,
      visibleStatus: true,
    };
    console.log(data);
    try {
      setIsSubmitting(true);
      const res = await requestData(
        `/admin/specialOffer-approval-status/${banner?.id}`,
        "PUT",
        data
      );
      if (res && res?.status) {
        fetchOffers();
        toast.success("Special offer approved successfully");
        setModal(false);
      } else {
        toast.error(res?.message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
      console.log("Special offer error", error);
    }
    setIsSubmitting(false);
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            {isSubmitting && <Loader />}
            <div
              component="div"
              className="TabsAnimation appear-done enter-done"
            >
              <div className="main-card card">
                <div className="card-body">
                  <div className="card-title">Approve Offers</div>
                  <div className="row" style={{ margin: "0.1rem" }}>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="specialOfferName"
                          className="form-label"
                        >
                          Special Offer Name*
                        </label>
                        <input
                          type="text"
                          name="specialOfferName"
                          id="specialOfferName"
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          value={banner.specialOfferName}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="offerType" className="form-label">
                          Offer Starts At*
                        </label>
                        <input
                          type="text"
                          name="offerType"
                          id="offerType"
                          disabled={true}
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          value={banner?.offerStartDate}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label htmlFor="duration_value" className="form-label">
                          Valid for*
                        </label>
                      </div>
                      <div className="" style={{ display: "flex" }}>
                        <div
                          className="col-md-3"
                          style={{ marginLeft: "-15px" }}
                        >
                          <div className="mb-3">
                            <input
                              style={{ width: "50px" }}
                              type="number"
                              name="duration_value"
                              id="duration_value"
                              // placeholder="Your first name, e.g. Walter"
                              className="form-control"
                              autoComplete="false"
                              value={banner.duration_value}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <select
                              style={{ width: "100px" }}
                              disabled={true}
                              name="duration_unit"
                              id="duration_unit"
                              value={banner?.duration_unit}
                              className="form-control form-select"
                            // value={}
                            >
                              <option value="" key={0}>
                                Select
                              </option>
                              {duration_units?.map((item, idx) => {
                                return (
                                  <>
                                    <option key={idx + 1} value={`${item}`}>
                                      {item}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="activityDetailsId"
                          className="form-label"
                        >
                          Activity Details*
                        </label>
                        <Select
                          isDisabled={true}
                          name="activityDetailsId"
                          id="activityDetailsId"
                          classNamePrefix="select"
                          options={activities.map((item, idx) => ({
                            value: item?._id,
                            label: item?.activityTitle,
                          }))}
                          value={banner.activityDetailsId.map((item) => {
                            let find = activities.find((it) => it._id === item);
                            console.log("III", find, item);
                            return {
                              value: find?._id,
                              label: find?.activityTitle,
                            };
                          })}
                          isMulti
                        />
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="offerType" className="form-label">
                          Offer Type*
                        </label>
                        <input
                          type="text"
                          name="offerType"
                          id="offerType"
                          disabled={true}
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          value={banner.offerType}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="discountPercentage"
                          className="form-label"
                        >
                          Discount Percentage*
                        </label>
                        <input
                          type="number"
                          name="discountPercentage"
                          id="discountPercentage"
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          disabled={true}
                          value={banner.discountPercentage}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Offer Description*
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        // placeholder="Your first name, e.g. Walter"
                        className="form-control"
                        autoComplete="false"
                        disabled={true}
                        value={banner.description}
                      />
                    </div>
                  </div>
                  {
                    !approvestatus && !rejectStatus ?
                      <div className="col-md-12">
                        <div className="mb-3 mt-3">
                          <label
                            htmlFor="description"
                            className="form-label text-primary"
                            style={{ fontWeight: "500" }}
                          >
                            Approval Remark
                          </label>
                          <input
                            name="remarks"
                            id="remarks"
                            // placeholder="Your first name, e.g. Walter"
                            className="form-control"
                            autoComplete="false"
                            value={banner?.remarks}
                            required={true}
                            onChange={(e) =>
                              setbanner((prev) => {
                                let update = JSON.parse(JSON.stringify(prev));
                                update.remarks = e.target.value;
                                return { ...update };
                              })
                            }
                          />
                        </div>
                      </div>
                      : null
                  }
                  {
                    !approvestatus && !rejectStatus ?
                  <div style={{ display: "flex" }}>
                    <button
                      className="mt-2 btn btn-danger ml-auto d-table"
                      type="submit"
                      onClick={rejectOffer}
                    >
                      Reject
                    </button>
                    <button
                      className="mt-2 btn btn-primary ml-auto d-table"
                      type="submit"
                      onClick={approveOffer}
                    >
                      APPORVE
                    </button>
                  </div>
                  :null
                  }
                </div>
              </div>
            </div>
            {/* 
        <Table
          striped
          tableTitle="All special_offers"
          data={tableData}
          modal={modal}
          setModal={setModal}
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
        /> */}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <Table
        striped
        tableTitle="Merchant Offers"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </div>
  );
};

export default ManageMerchantOffers;
