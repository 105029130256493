import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

// Reducers
import AuthSliceReducer from "./authSlice/AuthSlice";
import AdminProfileSliceReducer from "./authSlice/adminProfileSlice";
import AdminSubaminActionReducer from "./adminSlice/subadminActionsSlice";
import LanguageSliceReducer from "./adminSlice/languageSlice";
import AdminMerchantActionReducer from "./adminSlice/merchantActionsSlice";
import MerchantAccountSliceReducer from "./adminSlice/merchantAccountSlice";
import AdminActivitiyActionReducer from "./adminSlice/activitySlice";
import AdminActivityParticipantsReducer from "./adminSlice/participantsSlice";
import AdminAssignActivityReducer from "./adminSlice/assignActivitySlice";
import CountrySliceReducer from "./adminSlice/countrySlice";
import BannerSliceReducer from "./adminSlice/bannerSlice";
import SectionSliceReducer from "./adminSlice/sectionSlice";
import categorySliceReducer from "./adminSlice/categorySlice";
import CitySliceReducer from "./adminSlice/citySlice";
import AboutusSliceReducer from "./adminSlice/aboutusSlice";
import PressSliceReducer from "./adminSlice/pressSlice";
import BlogsSliceReducer from "./adminSlice/blogSlice";
import CareerSliceReducer from "./adminSlice/careerSlice";
import LegalNoticeSliceReducer from "./adminSlice/legalNoticeSlice";
import TermsAndConSliceReducer from "./adminSlice/termsAndConSlice";
import CookiesSliceReducer from "./adminSlice/cookiesSlice";
import PrivacyPolicySliceReducer from "./adminSlice/privacyPolicySlice";
import ContactsSliceReducer from "./adminSlice/contactsSlice";
import SpecialOffersSliceReducer from './adminSlice/specialOfferSlice';
import TourModuleSliceReducer from './adminSlice/tourModuleSlice';
import DigitalInfoSlicedReducer from './adminSlice/digitalInfoSlice';
import SitemapSliceReducer from './adminSlice/sitemapSlice';
import GiftsSliceReducer from './adminSlice/giftSlice';
import GiftQuestionsReducer from './adminSlice/giftQuestionSlice';
import bookingSliceReducer from "./adminSlice/bookingSlice";
import commissionsSliceReducer from "./adminSlice/merchantCommissionSlice";
import visitedUserReducer from "./adminSlice/visitedUser"

let rootReducer = combineReducers({
  authSlice: AuthSliceReducer,
  adminProfileSlice: AdminProfileSliceReducer,
  adminSubadminActionSlice: AdminSubaminActionReducer,
  languageSlice: LanguageSliceReducer,
  adminMerchantActionSlice: AdminMerchantActionReducer,
  merchantAccountSlice: MerchantAccountSliceReducer,
  adminActivityActionSlice: AdminActivitiyActionReducer,
  adminActivityParticipantsSlice: AdminActivityParticipantsReducer,
  adminAssignActivitySlice: AdminAssignActivityReducer, 
  countrySlice: CountrySliceReducer,
  adminBannerSlice: BannerSliceReducer,
  adminSectionActionsSlice: SectionSliceReducer,
  adminCategoryActionSlice: categorySliceReducer,
  citySlice: CitySliceReducer,
  adminAboutsActionSlice: AboutusSliceReducer,
  pressSlice: PressSliceReducer,
  blogsSlice: BlogsSliceReducer,
  careerSlice: CareerSliceReducer,
  legalNoticeSlice: LegalNoticeSliceReducer,
  termsConditionSlice: TermsAndConSliceReducer,
  cookiesMarketingSlice: CookiesSliceReducer,
  privacyPolicySlice: PrivacyPolicySliceReducer,
  contactsSlice: ContactsSliceReducer,
  specialOfferSlice: SpecialOffersSliceReducer,
  tourModuleSlice: TourModuleSliceReducer,
  digitalInfoSlice: DigitalInfoSlicedReducer,
  sitemapSlice: SitemapSliceReducer,
  giftsSlice: GiftsSliceReducer,
  GiftQuestionsSlice:GiftQuestionsReducer,
  bookingSlice: bookingSliceReducer,
  commissionSlice: commissionsSliceReducer,
  VisitedUserSlice: visitedUserReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
