import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Country } from "country-state-city";
import { requestData } from "../../../services/api/apiHelpers";
import {
  getAllDetailsChangeRequest
} from "../../../redux/adminSlice/merchantActionsSlice";
import DatePicker from "react-date-picker";
import Loader from "../../../Component/Loader";
import Select from "react-select";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./styles.css";
import "react-phone-number-input/style.css";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Modal from "../../../Component/Modal/Modal";
import PhoneInput from "../../../Component/PhoneInput";
import useCountries from "../../../Hooks/useCountries";

let initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  langId: "",
  mobile: "",
  city: "",
  country: "",
  zip: "",
  image: "",
  commisionPercentage: "",
  payeeType: "",
  accountType: "",
  marBankName: "",
  bankCode: "",
  branchName: "",
  branchCode: "",
  marBankAccNo: "",
  marBankIfscCode: "",
  marAccHolderName: "",
  bankAcctCurrency: "",
  swiftCode: "",
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  companyMobile: "",
  directorName: "",
  individualName: "",
  companyDob: "",
  companyStreetAddress: "",
  postalCode: "",
  compCity: "",
  compState: "",
  compStateCode: "",
  compCountry: "",
  compCountryCode: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  rejectedStatus: "",
  compamyRegistrationImage: "",
  companyCountrystring: "",
  countryAlphaCode: "",
  saveAsDraft: false,
  isthirdperty: null,
  thirdpertyId: undefined,
  AccesKey: "",
  secretKey: ""
};

function ChangeRequestDetailView() {
  const dispatch = useDispatch();
  const params = useParams();
  const { fetchCities, cities } = useCountries();
  const navigate = useNavigate();

  const { details_change_requests_new } = useSelector(
    (state) => state.adminMerchantActionSlice
  );
  const [editObject, seteditObject] = useState(initialValues);

  useEffect(() => {
    let merchantDetail = details_change_requests_new?.[0]?.merchant;

    if (merchantDetail) {
      seteditObject(merchantDetail);
      fetchCities(merchantDetail.countryId);
    }
  }, [details_change_requests_new]);

  const [citiesForCountry, setcitiesForCountry] = useState([]);
  const [zipDropdownOne, setzipDropdownOne] = useState(false);
  const [zipDropdownTwo, setzipDropdownTwo] = useState(false);
  const [zipcodesForCity, setzipcodesForCity] = useState([]);
  const [zipForCompCity, setzipForCompCity] = useState([]);
  const imgInputRef = useRef(null);
  const [isLoading, setisLoading] = useState(false);
  const [uploadedImg, setuploadedImg] = useState("");
  const [uploadedLogo, setuploadedLogo] = useState("");
  const [upInsurancePolicy, setupInsurancePolicy] = useState("");
  const [uploadedcompRegCert, setuploadedcompRegCert] = useState("");
  const [approvalStatus, setApprovalStatus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [modal, setmodal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(0);
  const [activityStatus, setactivityStatus] = useState({ remarks: "" });
  const { languages } = useSelector((state) => state.languageSlice);
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const [thirdPartyData, setThirdPartyData] = useState([])

  useEffect(() => {
    dispatch(getAllDetailsChangeRequest(params?.id));
    getAllThirdParty()
  }, []);

  const getAllThirdParty = async () => {
    try {
      setisLoading(true)
      let res = await requestData("/admin/viewThirdPerty", "GET");
      if (res) {
        setThirdPartyData(res.data)
        setisLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setisLoading(false)
    }
  }

  const approveDetails = async (state) => {
    let id = params.id;
    try {
      let data = {
        requestId: id,
        rejectCause: activityStatus,
        rejectStatus: state,
      };
      setisLoading(true);
      let res = await requestData(
        `/admin/account-update-request`,
        "POST",
        data
      );
      if (res.status) {
        setisLoading(false);
        toast.success(res.message);
        navigate("/merchant-details-change-requests");
        setmodal(false);
      } else {
        setisLoading(false);
        toast.error(res?.error?.message);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  const openModal = () => {
    setmodal(true);
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content" style={{ width: "400px" }}>
            <div className="modal-header">
              <h5 className="modal-title">APPROVAL REMARKS</h5>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks*
                  </label>
                  <textarea
                    name="remarks"
                    id="remarks"
                    className="form-control"
                    autoComplete="false"
                    value={activityStatus.remarks}
                    onChange={(e) => setactivityStatus(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setmodal(false);
                }}
              >
                CLOSE
              </button>
              <button
                type="submit"
                className="btn btn-link btn-primary"
                onClick={(e) => {
                  approveDetails(false);
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="modal-content" style={{ width: "400px" }}>
            <div className="modal-header">
              <h5 className="modal-title">REJECTION REMARKS</h5>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks*
                  </label>
                  <textarea
                    name="remarks"
                    id="remarks"
                    className="form-control"
                    autoComplete="false"
                    value={activityStatus.remarks}
                    onChange={(e) => setactivityStatus(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setmodal(false);
                }}
              >
                CLOSE
              </button>
              <button
                type="submit"
                className="btn btn-link btn-warning"
                onClick={(e) => {
                  approveDetails(true);
                }}
              >
                REJECT
              </button>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      {modal && (
        <Modal
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
          closeModal={setmodal}
        />
      )}

      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">
                Merchant Details Approve / Reject
              </div>

              <Tabs
                selectedIndex={selectedIndex}
                onSelect={(index) => setSelectedIndex(index)}
              >
                <TabList>
                  <Tab id="merchantDetails">Merchant Details</Tab>
                  <Tab id="bankDetails">Bank Details</Tab>
                  <Tab id="companyDetails">Company Details</Tab>
                </TabList>
                <TabPanel>
                  <div className="row" style={{ padding: "1rem" }}>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="country">
                          Country{" "}
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>

                        <Select
                          isSearchable={true}
                          isDisabled={true}
                          name="country"
                          id="country"
                          classNamePrefix="select"
                          options={Country?.getAllCountries()?.map(
                            (item, idx) => ({
                              value: item.name,
                              label: item?.name,
                            })
                          )}
                          value={{
                            value: editObject?.country,
                            label: editObject?.country,
                          }}
                        />
                        {details_change_requests_new?.[0]?.country !==
                          details_change_requests_new?.[0]?.merchant?.country ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.country}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="city" className="form-label">
                          City
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>

                        <Select
                          name="city"
                          id="city"
                          isDisabled={true}
                          classNamePrefix="select"
                          options={citiesForCountry?.map((item, idx) => ({
                            value: item?.city,
                            label: item?.city,
                          }))}
                          value={{
                            value: editObject?.city,
                            label: editObject?.city,
                          }}
                          onChange={(val) => {
                            seteditObject((prev) => {
                              return {
                                ...prev,
                                city: val?.value,
                                zip: "",
                              };
                            });
                          }}
                        />
                        {details_change_requests_new?.[0]?.city !==
                          details_change_requests_new?.[0]?.merchant?.city ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.city}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label htmlFor="zip" className="form-label">
                          Zip/Postcode
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="zip"
                          id="zip"
                          type="text"
                          className="form-control"
                          value={editObject.zip}
                          onClick={() => setzipDropdownOne(true)}
                          required={true}
                          disabled
                        />

                        {zipDropdownOne && (
                          <Select
                            name="zip"
                            id="zip_select"
                            classNamePrefix="select"
                            options={zipcodesForCity?.map((item, idx) => ({
                              value: item?.zipCode,
                              label: item?.zipCode,
                            }))}
                            value={{
                              value: editObject?.zip,
                              label: editObject?.zip,
                            }}
                            onChange={(val) => {
                              seteditObject((prev) => {
                                return {
                                  ...prev,
                                  zip: val?.value,
                                };
                              });
                            }}
                          />
                        )}

                        {details_change_requests_new?.[0]?.zip !==
                          details_change_requests_new?.[0]?.merchant?.zip ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.zip}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="companyName">
                          Company Name
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="companyName"
                          className="form-control"
                          id="companyName"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          value={editObject.companyName}
                          required
                          disabled
                        />
                        {details_change_requests_new?.[0]?.companyName !==
                          details_change_requests_new?.[0]?.merchant
                            ?.companyName ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.companyName}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="marchentCode">
                          Merchant Code
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="marchentCode"
                          className="form-control"
                          id="marchentCode"
                          autoComplete="off"
                          value={editObject.marchentCode}
                          disabled
                        />
                        {details_change_requests_new?.[0]?.marchentCode !==
                          details_change_requests_new?.[0]?.merchant
                            ?.marchentCode ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.marchentCode}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="email"
                          id="email"
                          placeholder="E.g.,waltersmith@gmail.com"
                          type="email"
                          className="form-control"
                          value={editObject.email}
                          required={true}
                          disabled
                        />
                        {details_change_requests_new?.[0]?.email !==
                          details_change_requests_new?.[0]?.merchant?.email ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.email}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="password"
                          id="password"
                          type="text"
                          className="form-control"
                          value={editObject?.password}
                          required={true}
                          disabled
                        />
                        {details_change_requests_new?.[0]?.password !==
                          details_change_requests_new?.[0]?.merchant?.password ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.password}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">
                          First Name
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="firstName"
                          id="firstName"
                          placeholder="Your first name, e.g. Walter"
                          type="text"
                          className="form-control"
                          value={editObject.firstName}
                          required={true}
                          disabled
                        />
                        {details_change_requests_new?.[0]?.firstName !==
                          details_change_requests_new?.[0]?.merchant
                            ?.firstName ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.firstName}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="lastName"
                          id="lastName"
                          placeholder="Your first name, e.g. Smith"
                          type="text"
                          className="form-control"
                          value={editObject.lastName}
                          required={true}
                          disabled
                        />
                        {details_change_requests_new?.[0]?.lastName !==
                          details_change_requests_new?.[0]?.merchant?.lastName ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.lastName}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="langId" className="form-label">
                          Language
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <select
                          name="langId"
                          id="langId"
                          className="form-control form-select"
                          value={editObject.langId}
                          required={true}
                          disabled
                        >
                          <option value="" key={0}>
                            Select
                          </option>
                          {languages.map((item, idx) => {
                            return (
                              <>
                                <option key={idx + 1} value={`${item?._id}`}>
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        {details_change_requests_new?.[0]?.langId !==
                          details_change_requests_new?.[0]?.merchant?.langId ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.langId}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="mobile" className="form-label">
                          Mobile No with Country Code
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>

                        <div className="form-control">
                          <PhoneInput
                            value={editObject.mobile}
                            onChange={(e) => {
                              seteditObject((prev) => ({ ...prev, mobile: e }));
                            }}
                            countryCode={editObject.phonecode}
                            onCountryChange={(val) =>
                              seteditObject((prev) => ({
                                ...prev,
                                phonecode: val,
                              }))
                            }
                            disabled={true}
                          />
                        </div>
                        {details_change_requests_new?.[0]?.phonecode !==
                          details_change_requests_new?.[0]?.merchant
                            ?.phonecode ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.phonecode}
                          </h6>
                        ) : null}
                        {details_change_requests_new?.[0]?.mobile !==
                          details_change_requests_new?.[0]?.merchant?.mobile ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.mobile}
                          </h6>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3 d-flex flex-col" style={{ gap: "1rem" }}>
                        <label
                          htmlFor="isthirdperty"
                          className="form-label"
                          style={{
                            marginLeft: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Have joined any third party platforms ?{" "}
                        </label>
                        <div className="d-flex ml-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isthirdperty"
                              id="payee_type_1"
                              onChange={() =>
                                seteditObject((prev) => ({
                                  ...prev,
                                  isthirdperty: true,
                                }))
                              }
                              value={true}
                              checked={editObject?.isthirdperty === true}
                              disabled
                            />
                            <label className="form-check-label" htmlFor="payee_type_1">
                              Yes
                            </label>
                          </div>

                          <div className="form-check ml-8">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isthirdperty"
                              id="payee_type_2"
                              onChange={() =>
                                seteditObject((prev) => ({
                                  ...prev,
                                  isthirdperty: false,
                                  thirdpertyId: undefined,
                                  AccesKey: "",
                                  secretKey: ""
                                }))
                              }
                              value={false}
                              checked={editObject?.isthirdperty === false}
                              disabled
                            />
                            <label className="form-check-label" htmlFor="payee_type_2">
                              No
                            </label>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-md-3" style={{ display: editObject?.isthirdperty === true ? "block" : "none" }}>
                      <div className="mb-3">
                        <label htmlFor="langId" className="form-label">
                          Third Party Platforms{" "}
                          <span style={{ fontSize: "20px", color: "red" }}>
                            *
                          </span>
                        </label>
                        <select
                          name="thirdpertyId"
                          id="thirdpertyId"
                          className="form-control form-select"
                          value={editObject.thirdpertyId}
                          required={true}
                          disabled
                        >
                          <option
                            value=""
                            disabled={true}
                            selected={true}
                            key={0}
                          >
                            Select
                          </option>
                          {thirdPartyData?.map((item, idx) => {
                            return (
                              <>
                                <option
                                  key={idx + 1}
                                  value={`${item?._id}`}
                                >
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3" style={{ display: editObject.isthirdperty && editObject.thirdpertyId ? "block" : "none" }}>
                      <div className="mb-3">
                        <label
                          htmlFor="AccesKey"
                          className="form-label"
                        >
                          Access key{" "}
                          <span style={{ fontSize: "20px", color: "red" }}>
                            *
                          </span>
                        </label>
                        <input
                          name="AccesKey"
                          id="AccesKey"
                          type="text"
                          value={editObject.AccesKey}
                          className="form-control"
                          onChange={(e) => {
                            seteditObject((prev) => ({
                              ...prev,
                              AccesKey: e.target.value
                            }))
                          }}
                          required={true}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3" style={{ display: editObject.isthirdperty && editObject.thirdpertyId ? "block" : "none" }}>
                      <div className="mb-3">
                        <label
                          htmlFor="secretKey"
                          className="form-label"
                        >
                          Secret Key{" "}
                          <span style={{ fontSize: "20px", color: "red" }}>
                            *
                          </span>
                        </label>
                        <input
                          name="secretKey"
                          id="secretKey"
                          type="text"
                          value={editObject.secretKey}
                          className="form-control"
                          onChange={(e) => {
                            seteditObject((prev) => ({
                              ...prev,
                              secretKey: e.target.value
                            }))
                          }}
                          required={true}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="commisionPercentage"
                          className="form-label"
                        >
                          Commission Percentage
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="commisionPercentage"
                          id="commisionPercentage"
                          type="number"
                          value={editObject.commisionPercentage}
                          className="form-control"
                          required={true}
                          disabled
                        />
                        {details_change_requests_new?.[0]
                          ?.commisionPercentage !==
                          details_change_requests_new?.[0]?.merchant
                            ?.commisionPercentage ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {
                              details_change_requests_new?.[0]
                                ?.commisionPercentage
                            }
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 d-none">
                      <div className="mb-3">
                        <label htmlFor="image" className="form-label">
                          Image
                        </label>
                        <input
                          name="image"
                          id="image"
                          type="file"
                          ref={imgInputRef}
                          className="form-control"
                          required={true}
                          accept="image/*"
                          disabled
                        />
                        {uploadedImg.length > 0 ? (
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={uploadedImg}
                          />
                        ) : (
                          <img
                            style={{ height: "100px", width: "100px" }}
                            src={editObject.image}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row" style={{ padding: "1rem" }}>
                    <div className="col-md-6">
                      <div className="mb-3 d-flex" style={{ gap: "1rem" }}>
                        <label
                          htmlFor="payeeType"
                          className="form-label"
                          style={{
                            marginLeft: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Payee Type
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payeeType"
                            id="payee_type_1"
                            value="person"
                            checked={editObject.payeeType === "person"}
                            disabled
                          />
                          {details_change_requests_new?.[0]?.payeeType !==
                            details_change_requests_new?.[0]?.merchant
                              ?.payeeType ? (
                            <h6
                              style={{
                                fontSize: "14px",
                                backgroundColor: "yellow",
                                marginTop: "6px",
                                paddingLeft: "8px",
                              }}
                            >
                              {details_change_requests_new?.[0]?.payeeType}
                            </h6>
                          ) : null}
                          <label
                            className="form-check-label"
                            htmlFor="payee_type_1"
                          >
                            Person
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payeeType"
                            id="payee_type_2"
                            value="organization"
                            checked={editObject.payeeType === "organization"}
                            disabled
                          />
                          {details_change_requests_new?.[0]?.payeeType !==
                            details_change_requests_new?.[0]?.merchant
                              ?.payeeType ? (
                            <h6
                              style={{
                                fontSize: "14px",
                                backgroundColor: "yellow",
                                marginTop: "6px",
                                paddingLeft: "8px",
                              }}
                            >
                              {details_change_requests_new?.[0]?.payeeType}
                            </h6>
                          ) : null}
                          <label
                            className="form-check-label"
                            htmlFor="payee_type_2"
                          >
                            Organization
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 d-flex" style={{ gap: "1rem" }}>
                        <label
                          htmlFor="accountType"
                          className="form-label"
                          style={{
                            marginLeft: "15px",

                            fontWeight: "bold",
                          }}
                        >
                          Account Type
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="accountType"
                            id={`account_type_1`}
                            value="current"
                            checked={editObject.accountType === "current"}
                            disabled
                          />
                          {details_change_requests_new?.[0]?.accountType !==
                            details_change_requests_new?.[0]?.merchant
                              ?.accountType ? (
                            <h6
                              style={{
                                fontSize: "14px",
                                backgroundColor: "yellow",
                                marginTop: "6px",
                                paddingLeft: "8px",
                              }}
                            >
                              {details_change_requests_new?.[0]?.accountType}
                            </h6>
                          ) : null}
                          <label
                            className="form-check-label"
                            htmlFor={`account_type_1`}
                          >
                            Current
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="accountType"
                            id="account_type_2"
                            value="savings"
                            checked={editObject.accountType === "savings"}
                            disabled
                          />
                          {details_change_requests_new?.[0]?.accountType !==
                            details_change_requests_new?.[0]?.merchant
                              ?.accountType ? (
                            <h6
                              style={{
                                fontSize: "14px",
                                backgroundColor: "yellow",
                                marginTop: "6px",
                                paddingLeft: "8px",
                              }}
                            >
                              {details_change_requests_new?.[0]?.accountType}
                            </h6>
                          ) : null}
                          <label
                            className="form-check-label"
                            htmlFor="account_type_2"
                          >
                            Savings
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="accountType"
                            id="account_type_3"
                            value="others"
                            checked={editObject.accountType === "others"}
                            disabled
                          />
                          {details_change_requests_new?.[0]?.accountType !==
                            details_change_requests_new?.[0]?.merchant
                              ?.accountType ? (
                            <h6
                              style={{
                                fontSize: "14px",
                                backgroundColor: "yellow",
                                marginTop: "6px",
                                paddingLeft: "8px",
                              }}
                            >
                              {details_change_requests_new?.[0]?.accountType}
                            </h6>
                          ) : null}
                          <label
                            className="form-check-label"
                            htmlFor="account_type_3"
                          >
                            Others
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="marBankName" className="form-label">
                          Bank Name: (E.g HSBC)
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="marBankName"
                          id="marBankName"
                          type="text"
                          value={editObject.marBankName}
                          className="form-control"
                          required={true}
                          disabled
                        />
                        {details_change_requests_new?.[0]?.marBankName !==
                          details_change_requests_new?.[0]?.marBankName
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.marBankName}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="bankCode" className="form-label">
                          IBAN ( E.g. FR76 3000 4028 3798 7654 3210 943)
                        </label>
                        <input
                          name="bankCode"
                          id="bankCode"
                          type="text"
                          value={editObject.bankCode}
                          className="form-control"
                          disabled
                        />
                        {details_change_requests_new?.[0]?.bankCode !==
                          details_change_requests_new?.[0]?.bankCode
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.bankCode}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="branchName" className="form-label">
                          Branch name and address: (HSBC, Seapoint, 15 Ocean Drive, 8574 )
                        </label>
                        <input
                          name="branchName"
                          id="branchName"
                          type="text"
                          value={editObject.branchName}
                          className="form-control"
                          disabled
                        />
                        {details_change_requests_new?.[0]?.branchName !==
                          details_change_requests_new?.[0]?.branchName
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.branchName}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="branchCode" className="form-label">
                          Branch Code
                        </label>
                        <input
                          name="branchCode"
                          id="branchCode"
                          type="text"
                          value={editObject.branchCode}
                          className="form-control"
                          disabled
                        />
                        {details_change_requests_new?.[0]?.branchCode !==
                          details_change_requests_new?.[0]?.branchCode
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.branchCode}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="marAccHolderName"
                          className="form-label"
                        >
                          Account holder name/Recipient name
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="marAccHolderName"
                          id="marAccHolderName"
                          type="text"
                          value={editObject.marAccHolderName}
                          className="form-control"
                          required={true}
                          disabled
                        />
                        {details_change_requests_new?.[0]?.marAccHolderName !==
                          details_change_requests_new?.[0]?.marAccHolderName
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.marAccHolderName}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="marBankAccNo" className="form-label">
                          Bank Account Number
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          name="marBankAccNo"
                          id="marBankAccNo"
                          type="text"
                          value={editObject.marBankAccNo}
                          className="form-control"
                          required={true}
                          disabled
                        />
                        {details_change_requests_new?.[0]?.marBankAccNo !==
                          details_change_requests_new?.[0]?.marBankAccNo
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.marBankAccNo}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="marBankIfscCode" className="form-label">
                          Bank Branch Address
                        </label>
                        <input
                          name="marBankIfscCode"
                          id="marBankIfscCode"
                          type="text"
                          value={editObject.marBankIfscCode}
                          className="form-control"
                          disabled
                        />
                        {details_change_requests_new?.[0]?.marBankIfscCode !==
                          details_change_requests_new?.[0]?.marBankIfscCode
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.marBankIfscCode}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="bankAcctCurrency"
                          className="form-label"
                        >
                          Bank Account Currency
                        </label>
                        <select
                          name="bankAcctCurrency"
                          id="bankAcctCurrency"
                          className="form-control form-select"
                          value={editObject.bankAcctCurrency}
                          disabled
                        >
                          <option value="" key={0}>
                            Select
                          </option>
                        </select>
                        {details_change_requests_new?.[0]?.bankAcctCurrency !==
                          details_change_requests_new?.[0]?.bankAcctCurrency
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.bankAcctCurrency}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="swiftCode" className="form-label">
                          Swift Code/BIC
                        </label>
                        <input
                          name="swiftCode"
                          id="swiftCode"
                          type="text"
                          value={editObject.swiftCode}
                          className="form-control"
                          disabled
                        />
                        {details_change_requests_new?.[0]?.swiftCode !==
                          details_change_requests_new?.[0]?.swiftCode
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.swiftCode}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row" style={{ padding: "1rem" }}>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="legalStatus"
                        >
                          Legal Status
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <select
                          className="form-select"
                          id="legalStatus"
                          name="legalStatus"
                          value={editObject.legalStatus}
                          disabled
                        >
                          <option value="" disabled={true} selected={true}>
                            Select legal status
                          </option>
                          <option value="registered_company">
                            Registered Company
                          </option>
                          <option value="individual_traders">
                            Individual Trader
                          </option>
                        </select>
                        {details_change_requests_new?.[0]?.legalStatus !==
                          details_change_requests_new?.[0]?.legalStatus
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.legalStatus}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="legalCompanyName"
                        >
                          Legal Company Name
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="legalCompanyName"
                          className="form-control"
                          id="legalCompanyName"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          value={editObject.legalCompanyName}
                          required
                          disabled
                        />
                        {details_change_requests_new?.[0]?.legalCompanyName !==
                          details_change_requests_new?.[0]?.legalCompanyName
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.legalCompanyName}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    {editObject.legalStatus === "registered_company" ? (
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            className="font-weight-bold"
                            htmlFor="directorName"
                          >
                            Director Name
                          </label>
                          <input
                            type="text"
                            name="directorName"
                            className="form-control"
                            id="directorName"
                            aria-describedby="emailHelp"
                            autoComplete="off"
                            value={editObject.directorName}
                            required
                            disabled
                          />
                          {details_change_requests_new?.[0]?.directorName !==
                            details_change_requests_new?.[0]?.directorName
                              ?.accountType ? (
                            <h6
                              style={{
                                fontSize: "14px",
                                backgroundColor: "yellow",
                                marginTop: "6px",
                                paddingLeft: "8px",
                              }}
                            >
                              {details_change_requests_new?.[0]?.directorName}
                            </h6>
                          ) : null}
                        </div>
                      </div>
                    ) : editObject.legalStatus === "individual_traders" ? (
                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="individualName"
                            >
                              Trading as : (E.g Billy's Tours)
                            </label>
                            <input
                              type="text"
                              name="individualName"
                              className="form-control"
                              id="individualName"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              value={editObject.individualName}
                              required
                              disabled
                            />
                            {details_change_requests_new?.[0]
                              ?.individualName !==
                              details_change_requests_new?.[0]?.individualName
                                ?.accountType ? (
                              <h6
                                style={{
                                  fontSize: "14px",
                                  backgroundColor: "yellow",
                                  marginTop: "6px",
                                  paddingLeft: "8px",
                                }}
                              >
                                {
                                  details_change_requests_new?.[0]
                                    ?.individualName
                                }
                              </h6>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="companyDob"
                            >
                              Trading since
                            </label>
                            <DatePicker
                              calendarIcon={null}
                              disabled={true}
                              className="form-control"
                              clearIcon={null}
                              value={editObject.companyDob}
                              format="dd/MM/y"
                              shouldCloseCalendar={({ reason }) =>
                                reason === "select" ||
                                reason === "outsideAction"
                              }
                            />
                            {details_change_requests_new?.[0]?.companyDob !==
                              details_change_requests_new?.[0]?.companyDob
                                ?.accountType ? (
                              <h6
                                style={{
                                  fontSize: "14px",
                                  backgroundColor: "yellow",
                                  marginTop: "6px",
                                  paddingLeft: "8px",
                                }}
                              >
                                {details_change_requests_new?.[0]?.companyDob}
                              </h6>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row" style={{ padding: "1rem" }}>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="compCountry"
                        >
                          Country of registration:
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <Select
                          name="compCountry"
                          id="compCountry"
                          classNamePrefix="select"
                          isDisabled={true}
                          options={Country?.getAllCountries()?.map(
                            (item, idx) => ({
                              value: item?.name,
                              label: item?.name,
                            })
                          )}
                          value={{
                            value: editObject?.compCountry,
                            label: editObject?.compCountry,
                          }}
                          onChange={(val) => {
                            seteditObject((prev) => {
                              return {
                                ...prev,
                                compCountry: val?.value,
                                compCity: "",
                                postalCode: "",
                              };
                            });
                          }}
                        />
                        {details_change_requests_new?.[0]?.compCountry !==
                          details_change_requests_new?.[0]?.compCountry
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.compCountry}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="font-weight-bold" htmlFor="compCity">
                          Company City
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <Select
                          name="compCity"
                          id="compCity"
                          classNamePrefix="select"
                          isDisabled={true}
                          options={citiesForCountry?.map((item, idx) => ({
                            value: item?.city,
                            label: item?.city,
                          }))}
                          value={{
                            value: editObject?.compCity,
                            label: editObject?.compCity,
                          }}
                          onChange={(val) => {
                            seteditObject((prev) => {
                              return {
                                ...prev,
                                compCity: val?.value,
                                postalCode: "",
                              };
                            });
                          }}
                        />
                        {details_change_requests_new?.[0]?.compCity !==
                          details_change_requests_new?.[0]?.compCity
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.compCity}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="companyStreetAddress"
                        >
                          Company Street Address
                        </label>
                        <input
                          type="text"
                          name="companyStreetAddress"
                          className="form-control"
                          id="companyStreetAddress"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          value={editObject.companyStreetAddress}
                          required
                          disabled
                        />
                        {details_change_requests_new?.[0]
                          ?.companyStreetAddress !==
                          details_change_requests_new?.[0]?.companyStreetAddress
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {
                              details_change_requests_new?.[0]
                                ?.companyStreetAddress
                            }
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="postalCode"
                        >
                          Company Zip/Postcode
                        </label>
                        <input
                          type="text"
                          name="postalCode"
                          className="form-control"
                          id="postalCode"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          min={0}
                          onClick={() => setzipDropdownTwo(true)}
                          value={editObject.postalCode}
                          required
                          disabled
                        />

                        {zipDropdownTwo && (
                          <Select
                            isDisabled={true}
                            name="postalCode"
                            id="comp_zip_select"
                            classNamePrefix="select"
                            options={zipForCompCity?.map((item, idx) => ({
                              value: item?.zipCode,
                              label: item?.zipCode,
                            }))}
                            value={{
                              value: editObject?.postalCode,
                              label: editObject?.postalCode,
                            }}
                            onChange={(val) => {
                              seteditObject((prev) => {
                                return {
                                  ...prev,
                                  postalCode: val?.value,
                                };
                              });
                            }}
                          />
                        )}
                        {details_change_requests_new?.[0]?.postalCode !==
                          details_change_requests_new?.[0]?.postalCode
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.postalCode}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="companyMobile"
                        >
                          Company Mobile Number With Country Code
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </label>
                        <div className="form-control">
                          <PhoneInput
                            value={editObject.companyMobile}
                            onChange={(e) => {
                              seteditObject((prev) => ({
                                ...prev,
                                companyMobile: e,
                              }));
                            }}
                            countryCode={editObject.phonecodecompany}
                            onCountryChange={(val) =>
                              seteditObject((prev) => ({
                                ...prev,
                                phonecodecompany: val,
                              }))
                            }
                            disabled={true}
                          />
                          {details_change_requests_new?.[0]
                            ?.phonecodecompany !==
                            details_change_requests_new?.[0]?.phonecodecompany
                              ?.accountType ? (
                            <h6
                              style={{
                                fontSize: "14px",
                                backgroundColor: "yellow",
                                marginTop: "6px",
                                paddingLeft: "8px",
                              }}
                            >
                              {
                                details_change_requests_new?.[0]
                                  ?.phonecodecompany
                              }
                            </h6>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="licenseNumber"
                        >
                          License number/Official authorisation number
                        </label>
                        <input
                          type="text"
                          name="licenseNumber"
                          className="form-control"
                          id="licenseNumber"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          value={editObject.licenseNumber}
                          required
                          disabled
                        />
                        {details_change_requests_new?.[0]?.licenseNumber !==
                          details_change_requests_new?.[0]?.licenseNumber
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.licenseNumber}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="registrationNumber"
                        >
                          Company registration number
                        </label>
                        <input
                          type="text"
                          name="registrationNumber"
                          className="form-control"
                          id="registrationNumber"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          value={editObject.registrationNumber}
                          required
                          disabled
                        />
                        {details_change_requests_new?.[0]
                          ?.registrationNumber !==
                          details_change_requests_new?.[0]?.registrationNumber
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {
                              details_change_requests_new?.[0]
                                ?.registrationNumber
                            }
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="description"
                        >
                          Company profile (Short description of your companies provided activities)
                        </label>
                        <textarea
                          name="description"
                          className="form-control"
                          id="description"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          value={editObject.description}
                          required
                          disabled
                        />
                        {details_change_requests_new?.[0]?.description !==
                          details_change_requests_new?.[0]?.description
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.description}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="socialLink"
                        >
                          Social Link
                        </label>
                        <input
                          type="text"
                          name="socialLink"
                          className="form-control"
                          id="socialLink"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          value={editObject.socialLink}
                          required
                          disabled
                        />
                        {details_change_requests_new?.[0]?.socialLink !==
                          details_change_requests_new?.[0]?.socialLink
                            ?.accountType ? (
                          <h6
                            style={{
                              fontSize: "14px",
                              backgroundColor: "yellow",
                              marginTop: "6px",
                              paddingLeft: "8px",
                            }}
                          >
                            {details_change_requests_new?.[0]?.socialLink}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="font-weight-bold" htmlFor="logo">
                        Company Logo
                      </label>
                      <input
                        name="logo"
                        id="logo"
                        type="file"
                        ref={imgInputRef}
                        className="form-control"
                        required
                        accept="image/*"
                        disabled
                      />
                      {uploadedLogo.length > 0 ? (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={uploadedLogo}
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={editObject.logo}
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="insurancePolicy"
                        >
                          Insurance Policy
                        </label>
                        <input
                          type="file"
                          name="insurancePolicy"
                          id="insurancePolicy"
                          ref={imgInputRef}
                          className="form-control"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          required
                          accept="image/*"
                          disabled
                        />
                      </div>
                      {upInsurancePolicy.length > 0 ? (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={upInsurancePolicy}
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={editObject.insurancePolicy}
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        className="font-weight-bold"
                        htmlFor="compamyRegistrationImage"
                      >
                        Company Registration Certificate
                      </label>
                      <input
                        name="compamyRegistrationImage"
                        id="compamyRegistrationImage"
                        type="file"
                        ref={imgInputRef}
                        className="form-control"
                        required
                        accept="image/*"
                        disabled
                      />
                      {uploadedcompRegCert.length > 0 ? (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={uploadedcompRegCert}
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={editObject.compamyRegistrationImage}
                        />
                      )}
                    </div>
                  </div>
                </TabPanel>
              </Tabs>

              <div className="row" style={{ padding: "1rem" }}>
                <div
                  className={
                    (editObject?.newSupplier &&
                      editObject?.newSupplier == true) ||
                      editObject?.rejectedStatus === true
                      ? "d-flex"
                      : "d-none"
                  }
                  style={{ justifyContent: "flex-start" }}
                >
                  <input
                    type="checkbox"
                    name="isApprovalStatus"
                    value="true"
                    onClick={(e) => {
                      setApprovalStatus(e.target.checked);
                    }}
                  />{" "}
                  &nbsp; Merchant abroad ?
                </div>
                <div
                  className={
                    editObject?.newSupplier && editObject?.newSupplier == true
                      ? "d-flex"
                      : "d-none"
                  }
                  style={{ justifyContent: "flex-end" }}
                >
                  {!approvalStatus && (
                    <button
                      type="button"
                      className="mt-2 btn btn-danger d-table"
                      onClick={() => {
                        setmodalToggleView(1);
                      }}
                    >
                      Reject
                    </button>
                  )}

                  <button
                    type="button"
                    className="mt-2 btn btn-primary d-table ml-4"
                    onClick={() => {
                      setmodalToggleView(0);
                    }}
                  >
                    Approve
                  </button>
                </div>
                <div
                  className={
                    editObject?.newSupplier && editObject?.newSupplier == true
                      ? "d-none"
                      : "d-flex"
                  }
                  style={{ justifyContent: "flex-end" }}
                >
                  <button
                    type="button"
                    className="mt-2 btn btn-warning d-table"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(1);
                    }}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="mt-2 btn btn-primary d-table ml-4"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                    }}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ChangeRequestDetailView;
