import React, { useEffect, useState } from "react";
import { db } from "../utils/FirebaseConfig";
import {
  get,
  limitToLast,
  off,
  onChildAdded,
  onChildChanged,
  onValue,
  push,
  query,
  ref,
  set,
  update,
} from "firebase/database";

const homeContext = React.createContext();

export const useHome = () => React.useContext(homeContext);

function HomeContext({ children }) {
  const [chatCount, setChatCount] = useState(0);
  const [allChatList, setAllChatList] = useState([]);
  
  const fbChatListListener = () => {
    onChildChanged(ref(db, `/chats`), (snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        setAllChatList((state) => {
          let index = state.findIndex((it) => it.id == data.id);
          if (index >= 0) {
            state[index] = data;
            return [...state];
          }
          return [...state];
        });
      }
    });
  };
  

   const getChatList = ( ) => {
    get(ref(db, `/chats`)).then((snapshot) => {
      if (snapshot.exists()) {
        const chatList = Object.values(snapshot.val());
  
        chatList.forEach((chat) => {
          let unreadCount = 0;
          Object.values(chat.messages).forEach((message) => {
            if (message.read === false) {
              unreadCount++;
            }
          });
  
          chat.unreadCount = unreadCount;
        });
  
        setAllChatList(chatList);
        fbChatListListener();
      } else {
        fbChatListListener();
      }
    });
  };


useEffect(()=>{

  getChatList()
},[])
useEffect(()=>{
  const unseenCount = allChatList?.filter((count) => count.unseen).length;
setChatCount(unseenCount);
},[allChatList])





  return <homeContext.Provider value={{ chatCount, setChatCount}}>{children}</homeContext.Provider>;
}

export default HomeContext;
